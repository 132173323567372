import { Record } from 'immutable';

import {
  LOGIN,
  LOGOUT,
  LOGGING_IN,
  LOGGED_IN,
  LOGGING_IN_FAILED,
  SSO_AUTHENTICATE,
  FETCH_ME,
  REGISTER_USER,
  FORGOT_PASSWORD,
  FIND_TOKEN,
  RESET_PASSWORD,
  EMAIL_EXISTS,
  ENABLESIGNUPBUTTON,
  SEND_CONFIRMATION,
  UPDATE_USER,
  GET_USER, VERIFY_CONFIRMATION,
  GET_STATES,
  IMAGE_UPLOAD,
  CHANGE_PASSWORD,
  FIND_GOOGLE_TOKEN,
  GET_ALL_EMAILS,
  AUTO_LOGIN,
  LOGIN_MOBILE,
  RESENDOTP,
  SECONDARY_USER
} from 'shared/state/types';
import { setToken, removeToken } from 'shared/auth';

class State extends Record({
  id: localStorage.getItem('id') ? localStorage.getItem('id') : 0,
  email: null,
  first_name: null,
  last_name: null,
  type: localStorage.getItem('type') ? localStorage.getItem('type') : null,
  loggingIn: true,
  loggedIn: false,
  phone_number: null,
  image: '',
  error: '',
  success: '',
  fetching: '',
  fetched: '',
  sending: '',
  sended: '',
  resetEmail: '',
  resetToken: '',
  emailExistsMessage: '',
  emailExistsError: false,
  updated: false,
  updating: false,
  userData: '',
  states: [],
  allEmails:[]
}) { }

export default function reducer(state = new State(), action) {
  switch (action.type) {
    case LOGGING_IN:
      return state.merge({
        loggingIn: true,
        loggedIn: false,
        error: ''
      });
    case LOGGED_IN:
      return state.merge({
        loggingIn: false,
        loggedIn: true,

      });
    case LOGGING_IN_FAILED:
      return state.merge({
        loggingIn: false,
        loggedIn: false,
        error: 'Incorrect email or password'
      });
    case FETCH_ME.SUCCESS:
      return state.merge({ ...action.payload });
    case SSO_AUTHENTICATE.SUCCESS:
    case REGISTER_USER.REQUEST:
      return state.merge({
        sending: true,
        sended: false,
        success: '',
        error: ''
      });
    case REGISTER_USER.SUCCESS:
      setToken(action.payload.token, action.payload.person)
      return state.merge({
        ...action.payload.person,
        sending: false,
        sended: true,
        success: 'Your account has been successfully created.',
        error: ''
      });
    case REGISTER_USER.FAILURE:
      return state.merge({
        sending: false,
        sended: false,
        success: '',
        error: action.error
      });
    case LOGIN:
      setToken(action.payload.token, action.payload.person);

      return state.merge({
        ...action.payload.person,
        loggingIn: false,
        loggedIn: true,
      });
    case AUTO_LOGIN.SUCCESS:
      setToken(action.payload.token, action.payload.person);

      return state.merge({
        ...action.payload.person,
        loggingIn: false,
        loggedIn: true,
      });
    case LOGOUT:
      removeToken(undefined);
      return state.merge({
        id: 0,
        email: null,
        first_name: null,
        last_name: null,
        type: null,
        loggingIn: false,
        loggedIn: false,
        image: '',
        success: 'Successfully Logged Out'
      });
    case FORGOT_PASSWORD.REQUEST:
      return state.merge({
        sending: true,
        sended: false,
        error: ''
      });
    case FORGOT_PASSWORD.SUCCESS:
      return state.merge({
        sending: false,
        sended: true,
        error: '',
        success: action.message
      });
    case FORGOT_PASSWORD.FAILURE:
      return state.merge({
        sending: false,
        sended: false,
        error: "We can't find a user with that e-mail address."
      });
    case FIND_TOKEN.REQUEST:
      return state.merge({
        fetching: true,
        fetched: false,
        error: ''
      });
    case FIND_TOKEN.SUCCESS:
      return state.merge({
        fetching: false,
        fetched: true,
        error: '',
        success: '',
        resetEmail: action.data.email,
        resetToken: action.data.token
      });
    case FIND_TOKEN.FAILURE:
      return state.merge({
        fetching: false,
        fetched: false,
        error: "This password reset token is invalid."
      });
    case RESET_PASSWORD.REQUEST:
      return state.merge({
        sending: true,
        sended: false,
        error: ''
      });
    case RESET_PASSWORD.SUCCESS:
      return state.merge({
        sending: false,
        sended: true,
        error: '',
        success: 'Your password has been reset successfully.'
      });
    case RESET_PASSWORD.FAILURE:
      return state.merge({
        sending: false,
        sended: false,
        error: ''
      });
    case EMAIL_EXISTS.REQUEST:
      return state.merge({
        sending: true,
        sended: false,
        error: ''
      });
    case EMAIL_EXISTS.SUCCESS:
      return state.merge({
        sending: false,
        sended: true,
        error: '',
        emailExistsError: false
      });
    case EMAIL_EXISTS.FAILURE:
      return state.merge({
        sending: false,
        sended: false,
        emailExistsError: true,
        error: 'The email has already been taken'
      });
    case SEND_CONFIRMATION.REQUEST:
      return state.merge({
        sending: true,
        sended: false,
        error: '',
        success: ''
      });
    case SEND_CONFIRMATION.SUCCESS:
      return state.merge({
        sending: false,
        sended: true,
        error: '',
        success: action.message
      });
    case SEND_CONFIRMATION.FAILURE:
      return state.merge({
        sending: false,
        sended: false,
        success: '',
        error: action.error
    });
    case ENABLESIGNUPBUTTON:
      return state.merge({
        emailExistsError: action.disableEmailSignupButton,
        error: ''
      })
    case UPDATE_USER.REQUEST:
      return state.merge({
        updating: true,
        updated: false,
        success: '',
        error: ''
      });
    case UPDATE_USER.SUCCESS:
      return state.merge({
        ...action.payload,
        updating: false,
        updated: true,
        success: 'Your account has been successfully updated.',
        error: ''
      });
    case UPDATE_USER.FAILURE:
      return state.merge({
        updating: false,
        updated: false,
        success: '',
        error: action.error
      });
    case GET_USER.REQUEST:
      return state.merge({
        fetching: true,
        fetched: false,
        error: ''
      });
    case GET_USER.SUCCESS:
      return state.merge({
        ...action.payload,
        fetching: false,
        userData: action.payload,
        fetched: true,
        error: ''
      });
    case GET_USER.FAILURE:
      return state.merge({
        fetching: false,
        fetched: false,
        error: action.error
      });
    case VERIFY_CONFIRMATION.REQUEST:
      return state.merge({
        sending: true,
        sended: false,
        error: '',
        success: ''
      });
    case VERIFY_CONFIRMATION.SUCCESS:
      return state.merge({
        sending: false,
        sended: true,
        error: '',
        success: action.message
      });
    case VERIFY_CONFIRMATION.FAILURE:
      return state.merge({
        sending: false,
        sended: false,
        success: '',
        error: action.error
      });
    case GET_STATES.REQUEST:
      return state.merge({
        fetching: true,
        fetched: false,
        error: ''
      });
    case GET_STATES.SUCCESS:
      return state.merge({
        fetching: false,
        fetched: true,
        states: action.states,
        error: ''
      });
    case GET_STATES.FAILURE:
      return state.merge({
        fetching: false,
        fetched: false,
        error: action.error
      });
    case IMAGE_UPLOAD.REQUEST:
      return state.merge({
        sending: true,
        sended: false,
        error: ''
      });
    case IMAGE_UPLOAD.SUCCESS:
      return state.merge({
        sending: false,
        sended: true,
        error: ''
      });
    case IMAGE_UPLOAD.FAILURE:
      return state.merge({
        sending: false,
        sended: false,
        error: action.error
      });
    case CHANGE_PASSWORD.REQUEST:
      return state.merge({
        updating: true,
        updated: false,
        success: '',
        error: ''
      });
    case CHANGE_PASSWORD.SUCCESS:
      return state.merge({
        ...action.payload,
        updating: false,
        updated: true,
        success: 'Password has been successfully updated.',
        error: ''
      });
    case CHANGE_PASSWORD.FAILURE:
      return state.merge({
        updating: false,
        updated: false,
        success: '',
        error: action.error
      });
    case FIND_GOOGLE_TOKEN.REQUEST:
      return state.merge({
        sending: true,
        sended: false,
        error: ''
      });
    case FIND_GOOGLE_TOKEN.SUCCESS:
      return state.merge({
        sending: false,
        sended: true,
        error: ''
      });
    case FIND_GOOGLE_TOKEN.FAILURE:
      return state.merge({
        sending: false,
        sended: false,
        error: action.error
      });
    case GET_ALL_EMAILS.REQUEST:
      return state.merge({
        fetching: true,
        fetched: false,
        error: ''
      });
    case GET_ALL_EMAILS.SUCCESS:
      return state.merge({
        fetching: false,
        fetched: true,
        allEmails: action.emails,
        error: ''
      });
    case GET_ALL_EMAILS.FAILURE:
      return state.merge({
        fetching: false,
        fetched: false,
        error: action.error
      });
    case LOGIN_MOBILE.REQUEST:
        return state.merge({
          fetching: true,
          fetched: false,
          error: '',
          success:''
    });
    case LOGIN_MOBILE.SUCCESS:
      return state.merge({
        sending: false,
        sended: false,
        success: action.payload
    });
    case LOGIN_MOBILE.FAILURE:
      return state.merge({
        sending: false,
        sended: false,
        success: '',
        error: action.payload
    });
    case RESENDOTP.REQUEST:
      return state.merge({
        fetching: true,
        fetched: false,
        error: '',
        success:''
    });
    case RESENDOTP.SUCCESS:
      return state.merge({
        sending: false,
        sended: false,
        success: action.payload
      });
    case RESENDOTP.FAILURE:
      return state.merge({
        sending: false,
        sended: false,
        success: '',
        error: action.payload
      });
      case SECONDARY_USER.SUCCESS:
      return state.merge({
        ...action.payload,
        updating: false,
        updated: true,
        success: '',
        error: ''
      });
    default:
      return state;
  }
}

