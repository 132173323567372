import React from 'react';
import PropTypes from 'prop-types';

import CustomCheckBox from '../../../shared/components/CustomCheckBox';
import NestedForm from './nestedForm';
import CustomSelectWithScroll from '../../../shared/components/CustomSelectWithScroll';

import { Grid, Icon, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { FieldArray, ErrorMessage } from 'formik';
import Popover from '@material-ui/core/Popover';
import Button from '@material-ui/core/Button';
import '../assets/styles/addSpace.scss';
import CopyTimeslotPopup from '../../../shared/components/copyTimeslotPopup';


const styles = theme => ({
    icon: {
        // marginLeft: '8px',
        // paddingTop: '1px',
        color: '#b0b0b0',
        fontSize: '15px',
        cursor: 'pointer'
    },
    activeIcon: {
        marginLeft: '8px',
        paddingTop: '1px',
        color: '#1397e1',
        fontSize: '15px'
    },
    popper: {
        paddingLeft: '15px',
        paddingTop: '237px'
    },
    root:{
        boxShadow: '0px 9px 11px 4px rgba(23,0,19,0.14)',
        borderRadius: '6px'
    },
    typography: {
        width: '237px',
        height: '135px',
        fontSize: '14px',
        fontWeight: '500',
        fontStyle: 'normal',
        fontStretch: 'normal',
        lineHeight: '1.43',
        letterSpacing: 'normal',
        padding: '19px',
        color: '#1397e1'
    },

    paper: {
        width: '237px',
        height: 'auto',
        fontSize: '14px',
        fontWeight: '500',
        fontStyle: 'normal',
        fontStretch: 'normal',
        lineHeight: '1.43',
        letterSpacing: 'normal',
        padding: '19px',
        color: '#1397e1',
        boxShadow: '0px 4px 11px 4px rgba(23,0,19,0.14)',
        fontFamily: 'Raleway',
        backgroundColor: '#ffffff'
      }
});

function AddSpaceAvailability(props) {
    const { classes } = props;
    const [anchorElFixed,setAnchorElFixed] = React.useState(null);
    const [anchorElFlexible,setAnchorElFlexible] = React.useState(null);

    const openFixed = Boolean(anchorElFixed);
    const openFlexible = Boolean(anchorElFlexible);

    const handleClickFixed = event => {
      setAnchorElFixed(anchorElFixed ? null : event.currentTarget);
    };

    const handleCloseFixed = () => {
        setAnchorElFixed(null);
      };

      const handleCloseFlexible = () => {
        setAnchorElFlexible(null);
      };

  
    const handleClickFlexible = event => {
        setAnchorElFlexible(anchorElFlexible ? null : event.currentTarget);
      };
    const idFixed = openFixed ? 'simple-popper' : undefined;

    const idFlexible = openFlexible ? 'simple-popper' : undefined;
  
  

    return (
        props.spaceList &&
        <div className="add_space_availability_container">
            <Grid container className="add_space_availability">
                <Grid className="space_name_container" item xs={12}>
                    <div className="space_name"><span className="upper_case">{props.venueName}</span> - <span className="capitalise">{props.spaceName}</span></div>
                </Grid>
                <Grid item xs={12}>
                    <Grid container className="add_space_availability_form">
                        <FieldArray
                            name="spaceAvailabilityGroup"
                            render={arrayHelpers => (
                                <Grid item xs={12} className="space_availability_heading">

                                    When can <span className="venue_code">{props.venueName} - </span><span className="space_name_heading">{props.spaceName}</span> be booked? 
                                    <p className="sub-heading" >On this page, create separate <b>time slots</b> for the way you price each day or event time.</p>
                                   
                                    <p className="sub-heading" style={{marginTop:'12px',marginBottom:'12px'}}>Example: Create one time slot for Mon-Fri (price $1,000) and a separate time slot for Sat-Sun (price $3,000) or <br /> create one time slot for 10am-4pm (price $1,000) and a separate time slot for 5pm-11pm (price $2,000).</p>
                                   
                                    <a className="sub-heading"   href="https://firsthold.groovehq.com/help/fixed-rental-vs-flexible-rental" target="_blank">More info</a>
                                    {/* <p style={{color:'red',fontSize:'13px',paddingTop:'6px'}}>HINT: If pricing varies per day, group days of the week by price.</p> */}
                                    <div className="error"><ErrorMessage name="spaceAvailabilityGroup" /></div>
                                 
                                    <p className="select-one" >Select One:</p>
                                    <div className="space_availability_checkboxes">
                                    <Grid container onMouseOut={props.handleCloseFixedPopper}>
                                        <Grid item>
                                            <CustomCheckBox onMouseOut={props.handleCloseFixedPopper} disabled={!props.formikProps.values.fixedValue && (props.formikProps.values.flexibleRentalAvailability.length === 4)} name="fixedValue" label="Fixed Rental" value={props.formikProps.values.fixedValue} arrayHelpers={arrayHelpers} onRentalChange={props.onRentalChange} setFieldValue={props.formikProps.setFieldValue} handleChange={props.formikProps.handleChange} checkboxGroup={props.formikProps.values.spaceAvailabilityGroup}/>
                                        </Grid>
                                        <Grid item className="help_icon">
                                            {/* <Grid onMouseOver={props.handleOpenFixedPopper} onTouchStart={props.handleOpenFixedPopper} onMouseOut={props.handleCloseFixedPopper}>
                                                <Icon className={props.isFixedToggleOpen ? classes.activeIcon : classes.icon}>
                                                    help_outline
                                                </Icon>
                                            </Grid>
                                            <Popper className={classes.popper} open={openFixed} anchorEl={anchorElFixed} placement="right-end">
                                                <Paper classes={{ root: classes.root }}>
                                                    <Typography className={classes.typography}>Your venue has specific time slots (Example:  We rent from 6.00am - 11.00pm daily or we rent from 8am-12.00pm and 2.00pm-5.00pm).</Typography>
                                                </Paper>
                                            </Popper> */}
                                            {/* <button aria-describedby={idFixed} type="button" onClick={handleClickFixed}>
                                                <Icon className={props.isFixedToggleOpen ? classes.activeIcon : classes.icon}>
                                                    help_outline
                                                </Icon>
                                            </button>
                                            <Popper id={idFixed} open={openFixed} anchorEl={anchorElFixed}>
                                                    <div className={classes.paper}>Your venue has specific time slots (Example:  We rent from 6.00am - 11.00pm daily or we rent from 8am-12.00pm and 2.00pm-5.00pm)</div>
                                            </Popper> */}
                                            <Button aria-describedby={idFixed} variant="contained" color="primary" onClick={handleClickFixed}>
                                            <Icon className={props.isFixedToggleOpen ? classes.activeIcon : classes.icon}>
                                                    help_outline
                                            </Icon>
                                            </Button>
                                            <Popover
                                                id={idFixed}
                                                open={openFixed}
                                                anchorEl={anchorElFixed}
                                                onClose={handleCloseFixed}
                                                anchorOrigin={{
                                                vertical: 'bottom',
                                                horizontal: 'center',
                                                }}
                                                transformOrigin={{
                                                vertical: 'top',
                                                horizontal: 'center',
                                                }}
                                            >
                                                <Typography className={classes.typography}>Your venue has specific time slots (Example:  We rent from 6.00am - 11.00pm daily or we rent from 8am-12.00pm and 2.00pm-5.00pm).</Typography>
                                            </Popover>
                                        </Grid>
                                    </Grid>
                                        <div className="checkbox_instructions">Planners can only book this space for specific time slots.</div>
                                    </div>
                                    <div className="nested_form">
                                        <NestedForm fieldArrayName="fixedRentalAvailability" fixedRentalDaysArray={props.fixedRentalDaysArray} onFixedDaySelect={props.onFixedDaySelect} onFlexibleDaySelect={props.onFlexibleDaySelect} values={props.formikProps.values} handleChange={props.formikProps.handleChange} openForm={props.formikProps.values.fixedValue} setFieldValue={props.formikProps.setFieldValue} errors={props.formikProps.errors} deleteTimeslotForFixed={props.deleteTimeslotForFixed} handleAddTimeSlot={props.handleAddTimeSlot} startTime='startTime' endTime='endTime'/>
                                    </div>
                                    <div className="space_availability_checkboxes">
                                        <Grid container onMouseOut={props.handleCloseFlexiblePopper}>
                                            <Grid item>
                                                <CustomCheckBox name="flexibleRental" label="Flexible Rental" disabled={!props.formikProps.values.flexibleRental && (props.formikProps.values.fixedRentalAvailability.length === 4)} value={props.formikProps.values.flexibleRental} arrayHelpers={arrayHelpers} setFieldValue={props.formikProps.setFieldValue} handleChange={props.formikProps.handleChange} onRentalChange={props.onRentalChange} checkboxGroup={props.formikProps.values.spaceAvailabilityGroup}/>
                                            </Grid>
                                            <Grid item className="help_icon">
                                                {/* <Grid onTouchStart={props.handleOpenFlexiblePopper} onMouseOver={props.handleOpenFlexiblePopper} onMouseOut={props.handleCloseFlexiblePopper}>
                                                    <Icon className={props.isFlexibleToggleOpen ? classes.activeIcon : classes.icon}>
                                                        help_outline
                                                    </Icon>
                                                </Grid>
                                                <Popper className={classes.popper} open={openFlexible} anchorEl={anchorElFlexible} placement="right-end">
                                                    <Paper classes={{ root: classes.root }}>
                                                        <Typography className={classes.typography}>Your venue has flexible time slots (Example: We rent based on a time frame (i.e. 4 hour minimum) but flexible on the actual times.</Typography>
                                                    </Paper>
                                                </Popper> */}
                                                {/* <button aria-describedby={idFlexible} type="button" onClick={handleClickFlexible} onMouseOut={props.handleCloseFlexiblePopper}>
                                                <Icon className={props.isFlexibleToggleOpen ? classes.activeIcon : classes.icon}>
                                                    help_outline
                                                </Icon>
                                                </button>
                                                <Popper id={idFlexible} open={openFlexible} anchorEl={anchorElFlexible}>
                                                        <div className={classes.paper}>Your venue has flexible time slots (Example: We rent based on a time frame (i.e. 4 hour minimum) but flexible on the actual times.</div>
                                                </Popper> */}
                                                <Button aria-describedby={idFlexible} variant="contained" color="primary" onClick={handleClickFlexible}>
                                            <Icon className={props.isFlexibleToggleOpen ? classes.activeIcon : classes.icon}>
                                                    help_outline
                                            </Icon>
                                            </Button>
                                            <Popover
                                                id={idFlexible}
                                                open={openFlexible}
                                                anchorEl={anchorElFlexible}
                                                onClose={handleCloseFlexible}
                                                anchorOrigin={{
                                                vertical: 'bottom',
                                                horizontal: 'center',
                                                }}
                                                transformOrigin={{
                                                vertical: 'top',
                                                horizontal: 'center',
                                                }}
                                            >
                                                <Typography className={classes.typography}>Your venue has flexible time slots (Example: We rent based on a time frame (i.e. 4 hour minimum) but flexible on the actual times.</Typography>
                                            </Popover>
                                            </Grid>
                                        </Grid>
                                            <div className="checkbox_instructions">Planners can book this space for any hours within a certain window.</div>
                                    </div>
                                    <div className="nested_form">
                                        <NestedForm fieldArrayName="flexibleRentalAvailability" fixedRentalDaysArray={props.fixedRentalDaysArray} flexibleRentalDaysArray={props.flexibleRentalDaysArray} values={props.formikProps.values} handleChange={props.formikProps.handleChange} openForm={props.formikProps.values.flexibleRental} setFieldValue={props.formikProps.setFieldValue} onFlexibleDaySelect={props.onFlexibleDaySelect} deleteTimeslotForFlexible={props.deleteTimeslotForFlexible} handleAddTimeSlot={props.handleAddTimeSlot} errors={props.formikProps.errors} startTime='startTime' endTime='endTime'/>
                                    </div>
                                    {props.spaceList.length > 0 &&
                                    <>
                                    <div className="space_availability_checkboxes">
                                        <CustomCheckBox name="copyAvailable" label="Copy availability and pricing from another space" arrayHelpers={arrayHelpers} handleChange={props.formikProps.handleChange} checkboxGroup={props.formikProps.values.spaceAvailabilityGroup}/>
                                    </div>
                                    <div className="nested_form copy-availability">
                                        {props.formikProps.values.copyAvailable &&
                                            <Grid container className="formula">
                                                <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
                                                    <CustomSelectWithScroll placeholder="Select" options={props.spaceList} name="copyAvailableName" value={props.formikProps.values.copyAvailableName} setFieldValue={props.formikProps.setFieldValue} handleChange={props.handleCopyTimeSlotPopup} handleBlur={props.formikProps.handleBlur} />
                                                </Grid>
                                                {/* {
                                                    props.open&&
                                                    <button aria-describedby={idFlexible} type="button" onClick={props.handleCopyTimeSlot}>Copy</button>
                                                } */}
                                                <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
                                                    <CopyTimeslotPopup  open={props.open} handleCopyTimeSlot={props.handleCopyTimeSlot} closeCopyTimeslotPopup={props.closeCopyTimeslotPopup}/>
                                                </Grid>
                                            </Grid>
                                            
                                        }
                                    </div>
                                    </>
                                    }
                                </Grid>
                            )}
                        />
                    </Grid>
                </Grid>
            </Grid>
        </div>
    );
}

AddSpaceAvailability.propTypes = {
    classes: PropTypes.object.isRequired,
};

const enhance = withStyles(styles)

export default enhance(AddSpaceAvailability);
