import React from 'react';
import {Grid,Button} from '@material-ui/core';
import { Link } from "react-router-dom";
import '../assets/myVenue.scss';
import { sendConfirmation, updateUser, getUser } from 'data/person/actions';
import { bindActionCreators, compose } from "redux";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
function VenuePlanExpiredMessage(props) {

  const openVenuePlanOnClickOfLink = (e,venueId) => {
    props.openVenuePlanOnClickOfLink(e,venueId);
  }
  const emailVerify=()=>{
    let contact_type=0
    const formData = { contact_type: contact_type }
    props.sendConfirmation(formData)
      .then((response) => {
        if (response === 'Contact is verified already.') {
          props.history.push("/login")
        }
        else {
        //   if (contactType === 0) {
            //const Verifytype = new URLSearchParams(window.location.search).get("verify")?'true':'false'
            props.history.push(`/confirmEmail?verify=true`)
          // }
          // else if (contactType === 1) {
          //   this.props.history.push("/confirmPhone")
          // }
        }
      })
      .catch(error => { })
}
    return (
        <Grid
            md={12}
            xs={12}
            sm={12}
            xl={12}
            lg={12}
            className="venue-plan"
           >
            <Grid className="expired-msg" >
              <p> { props.expiredVenues[0].name } is inactive : &nbsp;<Button variant="contained" onClick={(e)=>{openVenuePlanOnClickOfLink(e, props.expiredVenues[0].id)}} className="subscrib-btn" >RESUBSCRIBE</Button></p>
            </Grid> 
            { props.userData&&props.userData.email_verified_at===null && 
            <Grid className="email-verification" >
              <p><Link onClick={emailVerify} style={{textDecoration:'none'}}>Email Verification Required { props.userData.email }</Link></p>
            </Grid>
            }
        </Grid>
    );
}
const mapStateToProps = state => {
  return {
      // userData: state.data.usersVenue.userInfo
  };
};
const enhance = compose(
  withRouter,
  connect(mapStateToProps, dispatch =>
      bindActionCreators(
          {

            sendConfirmation
          },
          dispatch
      )
  )
);

export default enhance(withRouter(VenuePlanExpiredMessage));
