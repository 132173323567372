import React from 'react';
import { Grid } from '@material-ui/core';
import './assets/styles/webCalendar.scss';
import { Formik, Form } from 'formik';
import { bindActionCreators, compose } from "redux";
import CustomSelect from '../shared/components/CustomSelect';
import CustomSelectWithScroll from '../shared/components/CustomSelectWithScroll';
import { Redirect } from 'react-router-dom'
import { userDetails } from '../shared/venueList/actions/userDetails';
import { getGoogleToken} from '../../src/data/venues/actions/getGoogleToken'; 
import { getSpace, updateSpace } from '../../src/data/spaces/actions';
import { getVenue } from '../../src/data/venues/actions';
import { getGoogleCalnderList } from '../../src/data/venues/actions/getGoogleCalendarList';
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
//import axios from 'axios';
import CustomizedButton from '../shared/components/customizedButton';
// import { Link } from "react-router-dom";

// const options = [
//   'Select',
//   'Hold State 1',
//   'Hold State 2',
//   'Hold State 3'
// ];


class WebCalendar extends React.Component {
  //const spaceId = this.props.match.params.spaceId;
  
  // constructor(props) {
  //   super(props);
  //   //const { handleChange, handleBlur } = useFormikContext();
    
  // }
  state = {
    spaceId: '',
    vanueId: '',
    spaceName: '',
    refreshToken: '',
    calenderItems:'',
    venueData:'',
    spaceList:[]
  }
  componentWillMount() {
    this.setState({ spaceId: this.props.match.params.id});
    

    //const spaceId = this.props.match.params.spaceId
    
    this.props.getGoogleToken().then((response) => {
      localStorage.setItem('accesstoken', response.access_token);

      this.setState({ refreshToken: response.access_token })

      this.props.getGoogleCalnderList().then((result) => {
        let stateArray =[];
        result.items.map(function(data) {
          if(data.summary!=='Contacts'){
            stateArray.push({"id":data.id,"name":data.summary})
          }
          return stateArray;
        });
        this.setState({ calenderItems: stateArray })
      }).catch(error =>
        {}
      );
    }).catch(error =>
      {}
    );
     this.props.getSpace(this.props.match.params.id).then((res) => {
      this.setState({ spaceName: res.name })
      this.setState({ vanueId: res.venue_id })
      
      this.props.getVenue(this.state.vanueId).then((response) => {
        this.setState({venueData:response})
        this.setState({spaceList:response.data.spaces})

      }).catch(error =>
        {}
      );

    }).catch(error =>
      {}
    );
    
  }
  state = {
    radioSelectedValue: '',
    redirect: false,

  };

  setRedirect = () => {
    this.setState({
      redirect: true
    })
  }
  renderRedirect = () => {
    if (this.state.redirect) {
      return <Redirect to='syncingCalendar' />
    }
  }

 
 
  handleSubmit= (values, actions) => {

    let id='';
    if(!values.spaceName){
      id=parseInt(this.props.match.params.id,10)

    }
    else{
      id=values.spaceName;
    }
    let paramsData =  `calendar_id=${encodeURIComponent(values.googlecalendar)}&last_sync_calendar=1`;
    let thisProp =  this;
    if(localStorage.getItem('googleCalendarVal'))
    { 
      if(localStorage.getItem('googleCalendarVal')==='justLooking')
      {
        //localStorage.removeItem('googleCalendarVal');

        let propsVar = this.props;
          if(this.state.venueData.data.spaces !== undefined)
          {
            this.state.venueData.data.spaces.map(function(data) {
            propsVar.updateSpace(data.id,paramsData).then((response) => {
              thisProp.props.history.push(`/searchForStatus/${id}/${encodeURIComponent(values.googlecalendar)}`);
             }).catch(error =>
             {}
             );
             return true;
            });

            

          }

      
      }
      else
      {
   
        this.props.updateSpace(id,paramsData).then((response) => {
          this.props.history.push(`/searchForStatus/${id}/${encodeURIComponent(values.googlecalendar)}`);
          }).catch(error =>
            {}
          );
      }

    }
    
  
      
  }
  render() {
   

    return (
      <Formik
        initialValues={{
          googlecalendar: 'Select'
        }}
        onSubmit={(values,actions) => {
         
          this.handleSubmit(values, actions)
        }}
      >
        {({ isSubmitting,values,handleBlur,handleChange,setFieldValue }) => (
          <Form>
            <div className="home_page">
              <div className="search_page">
              
              </div>
            </div>

            <div className="webCalendar">
              <div className="header">

              </div>
              <Grid container>
                  <Grid item md={12} lg={12} xl={12}>
                      <div className="main">
                            <p className="large-text">
                              Set up your Google Calendar FIRST-HOLD Integration
                            </p>
                            <div className="content sm-d-none">
                              <p className="bold-text">
                                <p className="left">FIRST-HOLD Space</p>
                                <p className="right">Google Calendar</p>
                              </p>
                            </div>
                            <div className="content pad-top">
                              <p className="blue-text">
                                {/* <p className="left">{this.state.spaceName}</p> */}

                                <p className="blue-left">
                                <CustomSelect 
                                id="spaceName" 
                                value={parseInt(this.props.match.params.id,10)}
                                name="spaceName" 
                                spaceData={this.state.spaceList} 
                                placeholder="Select Space" 
                                color="#62717a" 
                                disable={true}
                                />
                                </p>

                                <p className="right">
                                  <div className="input-field-section">
                                    <CustomSelectWithScroll 
                                    value={values.googlecalendar} 
                                    setFieldValue={setFieldValue} 
                                    placeholder="Select" 
                                    options={this.state.calenderItems} 
                                    handleChange={handleChange} 
                                    handleBlur={handleBlur} 
                                    name="googlecalendar"  label="Google Calendar"/>
                                  </div>
                                </p>
                              </p>
                            </div>
                            <div className="content content-sm">
                              <p className="blue-text">
                              <p className="left">FIRST-HOLD Space</p>
                                <p className="blue-left">
                                <CustomSelect 
                                id="spaceName" 
                                value={parseInt(this.props.match.params.id,10)}
                                name="spaceName" 
                                spaceData={this.state.spaceList} 
                                placeholder="Select Space" 
                                color="#62717a" 
                                disable={true}
                                />
                                </p>
                              </p>
                              <p className="blue-text">
                              <p className="right heading-right">Google Calendar</p>
                                  <p className="right">
                                      <div className="input-field-section">
                                        <CustomSelectWithScroll 
                                        value={values.googlecalendar} 
                                        setFieldValue={setFieldValue} 
                                        placeholder="Select" 
                                        options={this.state.calenderItems} 
                                        handleChange={handleChange} 
                                        handleBlur={handleBlur} 
                                        name="googlecalendar"  label="Google Calendar"/>
                                      </div>
                                    </p>
                              </p>
                            </div>
                            <div className="buttonContent">
                            {values.googlecalendar!=='Select' && values.spaceName!==''&&values.googlecalendar!==''?
                                  <CustomizedButton type="submit" label="Next" disabled={false}/>                            
                                :
                                <CustomizedButton type="submit" label="Next" disabled={true}/>                            
                                }
                          
                            </div>
                      </div>
                  </Grid>
              </Grid>             
            </div>              
          </Form>
        )}
      </Formik>
    );
  }
}

//export default WebCalendar;
const mapStateToProps = state => {

  return {
    userData: state.data.usersVenue.userInfo
    
  };
};

const enhance = compose(
  withRouter,
  connect(mapStateToProps, dispatch =>
    bindActionCreators(
      {
        userDetails,
        getGoogleToken,
        getSpace,updateSpace,
        getGoogleCalnderList,
        getVenue
      },
      dispatch
    )
  )
);
export default enhance(WebCalendar);
