import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { connect } from "react-redux";
import { bindActionCreators, compose } from "redux";
import EventCalendar from '../../../shared/components/calendar_demo/index.js';
import moment from 'moment';
import { Button,Grid } from '@material-ui/core';
import SeperatePlceHold from './seperatePlaceHold';
import LoginDialogs from './loginPopup';
import SeperateFollowDate from './seperateFollowDate';
import SeperateContactInfo from './seperateContactInfo';
import { spaceProfile } from "../../../shared/spaceProfile/actions";
import { object, func } from "prop-types";
import DialogActions from '@material-ui/core/DialogActions';
import {Formik,FieldArray,Form} from 'formik';
import CustomCheckBox from "../../../shared/components/CustomCheckBox";
import CustomizedButton from "../../../shared/components/customizedButton";
import { contactData } from "../../../shared/contact/actions";
import { AppBar, Toolbar, IconButton, MenuItem, Menu, Icon } from '@material-ui/core';
import RightSideDropMenu from 'shared/components/RightSideDropMenu';
import { userDetails } from "shared/venueList/actions";
import { withStyles } from '@material-ui/core/styles';
import { venueProfile,getPlannerCalendarData } from "shared/venue/actions";
import { deleteEvent } from "shared/deleteEvent/actions";
import '../../../widgetTypeTwo/assets/styles/widget.scss';
import '../../../shared/components/assets/style/widget_calendar.scss';
import DatePicker from "react-datepicker";



const logo = require("../../components/assets/images/user-default.png");

    var numeral = require('numeral');
    var ordinal = require('ordinal');
    
    let eventGroup = [];
    let arrTime=[];



    const styles = theme => ({

      root: {
        display: 'flex',
      },
      paper: {
        marginRight: theme.spacing.unit * 2,
        boxShadow: 'none',
      },
      signupDiv: {
        width: '100%',
    
      },
      grow: {
        flexGrow: 1,
      },
      menuButton: {
        marginLeft: -12,
        marginRight: 20,
      },
      title: {
        display: 'none',
        [theme.breakpoints.up('sm')]: {
          display: 'block',
        },
      },
      inputRoot: {
        color: 'inherit',
        width: '100%',
      },
      inputInput: {
        transition: 'none',
        paddingLeft: '45px',
        paddingRight: '8px',
        fontSize: '11px',
        fontWeight: '500',
        fontStyle: 'normal',
        fontStretch: 'normal',
        lineHeight: 'normal',
        letterSpacing: 'normal',
      },
      sectionDesktop: {
        display: 'none',
        [theme.breakpoints.up('sm')]: {
          display: 'flex',
        },
      },
      sectionMobile: {
        display: 'flex',
        [theme.breakpoints.up('sm')]: {
          display: 'none',
        },
      },
      appbar: {
        backgroundColor: '#ffffff',
        color: '#36434b',
        boxShadow: 'none'
      },
    
      selected: {
        borderBottom: '2px #ee2738 solid !important',
        backgroundColor: '#ffffff !important'
      },
      image: {
        width: '32.5px',
        height: '32.5px',
        borderRadius: '50px',
        border: '1px black solid'
      },
      profileImageIcon: {
        padding: '0px 0px 0px 20px'
      },
      notificationsIcon: {
        padding: '0px 0px 0px 0px'
      },
      MuiMenuItem: {
        height: 'auto',
        lineHeight: '0.5',
      }
    });
    
    const monthNames = ["",
    {short:"Jan",full:"January"}, 
    {short:"Feb",full:"February"},
    {short:"Mar",full:"March"}, 
    {short:"Apr",full:"April"},
    {short:"May",full:"May"}, 
    {short:"Jun",full:"June"},
    {short:"Jul",full:"July"}, 
    {short:"Aug",full:"August"},
    {short:"Sep", full:"September"},
    {short:"Oct", full:"October"},
    {short:"Nov", full:"November"},
    {short:"Dec",full:"December"}
    ];

    var weekday = new Array(7);
    weekday[0] = "sunday";
    weekday[1] = "monday";
    weekday[2] = "tuesday";
    weekday[3] = "wednesday";
    weekday[4] = "thursday";
    weekday[5] = "friday";
    weekday[6] = "saturday";

function tConvert(time) {
  time = time.toString().match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [
    time
  ];
  if (time.length > 1) {
    time = time.slice(1);
    time[5] = +time[0] < 12 ? " AM" : " PM";
    time[0] = +time[0] % 12 || 12;
  }
  return time.join("");
}

var getDaysArray = function(start, end) {
  for(var arr=[],dt=start; dt<=end; dt.setDate(dt.getDate()+1)){
      arr.push(new Date(dt));
  }
  
  return arr;
};
class SeperateCalendar extends Component {
  

  constructor(props) {
    super(props);

    this.state={   
      new_event:false,
      makeToken:false,
      anchorEl: null,
      mobileMoreAnchorEl: null,
      selectedSeachkey: '',
      suggestions: [
        { abbr: 'OneKearnyClub', name: 'One Kearny Club' },
        { abbr: 'ZappaRoom', name: 'Zappa Room' },
        { abbr: '2ndStreetGallery', name: '2nd Street Gallery' }
      ],
       
      moment: moment(),
      makeAvailable:[],
      price:'',
      daySlot:[],
      max_hold:0,
      values:[],
      error:'',
      timeSlot:[],
      weekOff:[],
      xCoordinate: '',
      yCoordinate: '',
      openCalendarPopup:false,
      open:false,
      space: {},
      errorValue: false,
      releaseState:false,
      releaseHoldId:'',
      releaseHoldRank:'',
      eventGroup:[],
      followData:[],
      date:[],
      loginFlag:false,
      selectedDate:"",
      startdate:new Date(),
      MIN_Date:(moment().subtract(1,'months')).toDate()
    };
    this.handlePreviousMonth = this.handlePreviousMonth.bind(this);
    this.handleToday = this.handleToday.bind(this);
    this.handleNextMonth = this.handleNextMonth.bind(this);
    this.onClickCalendar = this.onClickCalendar.bind(this);
    this.handlMonthSelect =this.handlMonthSelect.bind(this);
    
  }



    ordinal_suffix_of(i) {
      var j = i % 10,
        k = i % 100;
      if (parseInt(j) === 1 && parseInt(k) !== 11) {
        return i + "st";
      }
      if (parseInt(j) === 2 && parseInt(k) !== 12) {
        return i + "nd";
      }
      if (parseInt(j) === 3 && parseInt(k) !== 13) {
        return i + "rd";
      }
      return i + "th";
    }

    componentWillMount() {
      if(localStorage.getItem('selectedDate')!==undefined&&localStorage.getItem('selectedDate')!==null)
      {
        this.setState({selectedDate:localStorage.getItem('selectedDate'),open:true})
        localStorage.removeItem('selectedDate')
      }
      this.setState({makeToken:false});
      this.props.userDetails().then((res) => {
      });   
      if(this.props.match.params.spaceId){
        let id=this.props.match.params.spaceId;     
        if(id){
          this.props.spaceProfile(id).then((response) => {            
            this.setState({
              getSpace: true,
              venueId: this.props.match.params.venueId,
              space:response.spaceDetails.data,
              areaUnit:response.spaceDetails.data.area_unit,
              dimensionUnit:response.spaceDetails.data.dimension_unit
    
            });
            this.props.venueProfile(response.spaceDetails.data.venue_id).then(() => {
              this.setState({
                getResponse: true,
                venueId: response.spaceDetails.data.venue_id,
                max_hold:this.props.venue.max_hold_limit ? this.props.venue.max_hold_limit: 0
              });
            
      
            eventGroup=[];
            let time=[];
            let eventType=[];
            let mon=0;
            let tue=0;
            let wed=0;
            let thur=0;
            let fri=0;
            let sat=0;
            let sun=0;
            let days=[];
    
            let timeDaysMon=[];
            let timeDaysTue=[];
            let timeDaysWed=[];
            let timeDaysThur=[];
            let timeDaysFri=[];
            let timeDaysSat=[];
            let timeDaysSun=[];
    
            let objtimeMon={};
            let objtimeTue={};
            let objtimeWed={};
            let objtimeThur={};
            let objtimeFri={};
            let objtimeSat={};
            let objtimeSun={};
            let weekOff   = [];
            let time_len=response.spaceDetails.data.timeslots;
            if(time_len.length>0){
    
            for(let i=0;i<time_len.length;i++){
              let obj={};
              
              let price=0;
              let avg=0;
              
              obj.id=time_len[i].id;
              obj.time=tConvert(time_len[i].start_time.substring(0,5)) +"-"+tConvert(time_len[i].end_time.substring(0,5));
              obj.flexible=time_len[i].fixed_rental;
              obj.hours=time_len[i].min_hour;
              if(parseInt(time_len[i].monday)===1){
                timeDaysMon.push(time_len[i].id);
                weekOff.push(1)  
                mon=mon+1;
              }
              if(parseInt(time_len[i].tuesday)===1){
                timeDaysTue.push(time_len[i].id);
                weekOff.push(2)  
                tue=tue+1;
              }
              if(parseInt(time_len[i].wednesday)===1){
                timeDaysWed.push(time_len[i].id);
                weekOff.push(3)  
                wed=wed+1;
              }
              if(parseInt(time_len[i].thursday)===1){
                timeDaysThur.push(time_len[i].id);
                weekOff.push(4)  
                thur=thur+1;
              }
              if(parseInt(time_len[i].friday)===1){
                timeDaysFri.push(time_len[i].id);
                weekOff.push(5)  
                fri=fri+1;
              }
              if(parseInt(time_len[i].saturday)===1){
                timeDaysSat.push(time_len[i].id);
                weekOff.push(6)  
                sat=sat+1;
              }
              if(parseInt(time_len[i].sunday)===1){
                timeDaysSun.push(time_len[i].id);
                weekOff.push(0)  
                sun=sun+1;
              }
    
    
              if(time_len[i].event_types.length>0){
                for(let j=0;j<time_len[i].event_types.length;j++){
                    eventType.push(time_len[i].event_types[j].name)
                      if(parseInt(avg)===0){
                        if(parseInt(time_len[i].event_types[j].pivot.pricing_type)===1 || parseInt(time_len[i].event_types[j].pivot.pricing_type)===2){
                          avg=price+time_len[i].event_types[j].pivot.base_rate;
                          
                        }
      
                        if(parseInt(time_len[i].event_types[j].pivot.pricing_type)===3){
                          avg=price+time_len[i].event_types[j].pivot.fnb_rate + time_len[i].event_types[j].pivot.base_rate;
                        }
      
                        if(parseInt(time_len[i].event_types[j].pivot.pricing_type)===4){
                          avg=price+time_len[i].event_types[j].pivot.base_rate * time_len[i].event_types[j].pivot.min_guest;
                        }                  }
                      
                        
                      
                  
                      if(parseInt(time_len[i].event_types[j].pivot.pricing_type)===1 || parseInt(time_len[i].event_types[j].pivot.pricing_type)===2){
                        price=price+time_len[i].event_types[j].pivot.base_rate;
                        
                      }
    
                      if(parseInt(time_len[i].event_types[j].pivot.pricing_type)===3){
                        price=price+time_len[i].event_types[j].pivot.fnb_rate + time_len[i].event_types[j].pivot.base_rate;
                      }
    
                      if(parseInt(time_len[i].event_types[j].pivot.pricing_type)===4){
                        price=price+time_len[i].event_types[j].pivot.base_rate * time_len[i].event_types[j].pivot.min_guest;
                      }
                      if(price < avg){
                        avg=price;
                      }
                      
                      
                        
                      
                }
                
                obj.avg=avg
              }
              else{
                obj.avg=0;
              }
    
              time.push(obj)
            }
            this.setState({weekOff:weekOff});
    
            let fd = eventType.filter( (ele, ind) => ind === eventType.findIndex( elem => elem === ele))
    
    
            objtimeMon.count=mon;
            objtimeMon.id=timeDaysMon;
    
            objtimeTue.count=tue;
            objtimeTue.id=timeDaysTue;
    
            objtimeWed.count=wed;
            objtimeWed.id=timeDaysWed;
    
            objtimeThur.count=thur;
            objtimeThur.id=timeDaysThur;
    
            objtimeFri.count=fri;
            objtimeFri.id=timeDaysFri;
    
            objtimeSat.count=sat;
            objtimeSat.id=timeDaysSat;
    
            objtimeSun.count=sun;
            objtimeSun.id=timeDaysSun;
    
    
            days.push(objtimeMon);
            days.push(objtimeTue);
            days.push(objtimeWed);
            days.push(objtimeThur);
            days.push(objtimeFri);
            days.push(objtimeSat);
            days.push(objtimeSun);
    
            this.setState({timeSlot:time,spaceEventType:fd,days:days})
    
            }
            let token=0;
            let daylist=[];
            let makeAvailabledates=[];
            var block_list=[];
            
    
            if(response.spaceDetails.data.avilability.length>0){
              for(let bi=0;bi<response.spaceDetails.data.avilability.length;bi++){
                daylist=[]
                let d=new Date(response.spaceDetails.data.avilability[bi].end_date);
                d= new Date(d.setDate(d.getDate()));
                let month=d.getMonth()+1;
                
                if(month.toString().length===1){
                  month="0"+month;
                }
                let date=d.getDate();
                if(parseInt(response.spaceDetails.data.avilability[bi].status)===0){
            
                  daylist = getDaysArray(new Date(moment(response.spaceDetails.data.avilability[bi].start_date).format("MM/DD/YYYY")),new Date(moment(d.getFullYear()+"-"+month+"-"+d.getDate()).format("MM/DD/YYYY")));         
                  for(let i=0;i<daylist.length;i++){
                    let obj={};
    
                    obj.description=" ";
                    obj.title='';
                    obj.eventClasses='blockOut';
                    obj.start=moment(daylist[i]).format("YYYY-MM-DD");
                    obj.end=moment(daylist[i]).format("YYYY-MM-DD");
                    obj.date=moment(daylist[i]).format("YYYY-MM-DD");
                    eventGroup.push(obj);
                    block_list.push(daylist[i])
                  }
    
    
                }
                if(parseInt(response.spaceDetails.data.avilability[bi].status)===1){
                  // let maObj={};
                  this.setState({makeToken:true})
                  // maObj.eventClasses='makeAvailableClass';
                  // maObj.description=" ";
                  // maObj.title='';
                  // maObj.start=response.spaceDetails.data.avilability[bi].start_date;
                  // maObj.end=d.getFullYear()+"-"+month+"-"+date;
                  // maObj.date=response.spaceDetails.data.avilability[bi].start_date;
          
                  // eventGroup.push(maObj);
    
                  daylist = getDaysArray(new Date(moment(response.spaceDetails.data.avilability[bi].start_date).format("MM/DD/YYYY")),new Date(moment(d.getFullYear()+"-"+month+"-"+date).format("MM/DD/YYYY")));         
                  for(let i=0;i<daylist.length;i++){
                    let maObj={};
                    this.setState({makeToken:true})
                    maObj.eventClasses='makeAvailableClass';
                    maObj.description=" ";
                    maObj.title='';
                    maObj.start=moment(daylist[i]).format("YYYY-MM-DD");
                    maObj.end=moment(daylist[i]).format("YYYY-MM-DD");
                    maObj.date=response.spaceDetails.data.avilability[bi].start_date;
            
                    eventGroup.push(maObj);
                    makeAvailabledates.push(daylist[i])
                  }
    
                }
                if(parseInt(response.spaceDetails.data.avilability[bi].is_recurring)===1 && parseInt(response.spaceDetails.data.avilability[bi].status)===0){
                  let s_d=new Date(response.spaceDetails.data.avilability[bi].start_date);
                  let s_month=s_d.getMonth()+1;
                  let s_date=s_d.getDate();
                  let year=s_d.getFullYear()+1;
                  let year2=s_d.getFullYear()+2;
    
                  if(s_d.getMonth().length===1){
                    s_month="0"+s_month;
                  }
                  if(s_d.getDay().length===1){
                    s_date="0"+s_date
                  }
                  let e_d=new Date(response.spaceDetails.data.avilability[bi].end_date);
                  e_d= new Date(e_d.setDate(e_d.getDate()));
    
                  let e_month=e_d.getMonth()+1;
                  let e_date=e_d.getDate();
    
                  if(e_d.getMonth().length===1){
                    e_month="0"+e_month;
                  }
                  if(s_d.getDay().length===1){
                    s_date="0"+e_date
                  }                
                    let obj={};
                    obj.description=" ";
                    obj.title='';
                    obj.eventClasses='blockOut';
                    obj.start=year + "-"+s_month+"-"+s_date;
                    obj.end=year + "-"+e_month+"-"+e_date;
                    obj.date=year + "-"+s_month+"-"+s_date;
                    daylist = getDaysArray(new Date(moment(year + "-"+s_month+"-"+s_date).format("MM/DD/YYYY")),new Date(moment(year + "-"+e_month+"-"+e_date).format("MM/DD/YYYY")));         
                    for(let i=0;i<daylist.length;i++){
                      block_list.push(daylist[i])
                    }
      
                    eventGroup.push(obj);
                    obj={};
                    obj.description=" ";
                    obj.title='';
                    obj.eventClasses='blockOut';
                    obj.start=year2 + "-"+s_month+"-"+s_date;
                    obj.end=year2 + "-"+e_month+"-"+e_date;
                    obj.date=year2 + "-"+s_month+"-"+s_date;
                    eventGroup.push(obj);
                    daylist = getDaysArray(new Date(moment(year2 + "-"+s_month+"-"+s_date).format("MM/DD/YYYY")),new Date(moment(year2 + "-"+e_month+"-"+e_date).format("MM/DD/YYYY")));         
                    for(let i=0;i<daylist.length;i++){
                      block_list.push(daylist[i])
                    }
    
                }
    
        
        
              }
            }
            let dd=new Date();
            dd=new Date(dd.setDate(dd.getDate()))
            let y=dd.getFullYear();
            let m=dd.getMonth() + 1;
            let d=dd.getDate() -1 ;
            
            if(parseInt(m)===1 ||  parseInt(m)===2 || parseInt(m)===3 || parseInt(m)===4 || parseInt(m)===5 || parseInt(m)===6 || parseInt(m)===7 || parseInt(m)===8 || parseInt(m)===9){
              m="0"+m;
            }
            
            
            
    
            let objD={};
            objD.description=" ";
            objD.title='';
            objD.eventClasses='previous_dates';
            objD.start=y + 
            "-"+m+
            "-"+
            "01";
            objD.end=y + "-"+m+"-"+d;
            objD.date=y + 
            "-"+
            m+"-"+
            '01';
    
          eventGroup.push(objD);
          this.setState({daylist:block_list,makeAvailable:makeAvailabledates});            
          let max_hold=this;
          if(localStorage.getItem('moment')){         
            this.setState({moment:moment(localStorage.getItem('moment'))});
          }          
          const month = this.state.moment.month() + 1;
          const year  = this.state.moment.year();
          this.props.getPlannerCalendarData(id, month, year).then((events)=>{
            response.spaceDetails.data.event_timeslots && response.spaceDetails.data.event_timeslots.map(function(eventData, idx) { 
              if(eventData.parent_id!==null){  
              
              token=token+1;
              let obj={};
              const date = new Date(eventData.date); 
              const month = date.toLocaleString('default', { month: 'long' });
              var day = date.getDate();
              var year=date.getFullYear();
              var week_day= weekday[date.getDay()];
              obj.date=month.substring(0,3) + ' '+day+ ' ' + year;
              obj.name=eventData.event.name;
              obj.planner_id=eventData.event.event_planner && eventData.event.event_planner.id;
              obj.id=eventData.id;
              let day_count;
              let array=[];
              if(week_day==="monday"){
                day_count=0;
                array=days[0].id;
        
              }
              else if(week_day==="tuesday"){
                day_count=1;
                array=days[1].id;
        
              }
              else if(week_day==="wednesday"){
                day_count=2;
                array=days[2].id;
        
              }
              else if(week_day==="thursday"){
                day_count=3;
                array=days[3].id;
        
              }
              else if(week_day==='friday'){
                day_count=4;
                array=days[4].id;
        
              }
              else if(week_day==='saturday'){
                day_count=5;
                array=days[5].id;
        
              }
              else if(week_day==="sunday"){
                day_count=6;
                array=days[6].id;
        
              }
                if(eventData.hold_rank + 1 >max_hold.props.venue.max_hold_limit ){
                obj.available="No hold available.";
                }
                else
                {
                obj.available= ordinal(eventData.hold_rank + 1) + " hold available";
                }
                obj.holdStatus= ordinal(eventData.hold_rank) + " hold";
                obj.bookingType=eventData.hold_type;
        
                if(parseInt(eventData.hold_type)===1 && eventData.event.event_planner && parseInt(eventData.event.event_planner.id) === parseInt(localStorage.getItem('id')) && parseInt(days[day_count].count)===2)
                  {
                    if(parseInt(eventData.timeslot_id)===parseInt(array[0])){
                    obj.eventClasses='holdClassRight'; 
                    }
                    else{
                      obj.eventClasses='holdClassLeft'; 
                    }
                }
                else if(parseInt(eventData.hold_type)===1 && eventData.event.event_planner && parseInt(eventData.event.event_planner.id) === parseInt(localStorage.getItem('id')) && parseInt(days[day_count].count)===1){
        
                    obj.eventClasses='holdClassSingle';
                }
                
              else if(parseInt(eventData.hold_type)===1 && eventData.event.event_planner && parseInt(eventData.event.event_planner.id) === localStorage.getItem('id') && parseInt(days[day_count].count)===4){
                    if(parseInt(eventData.timeslot_id)===parseInt(array[0])){
                      obj.eventClasses='holdClass';
                    }
                    else if(parseInt(eventData.timeslot_id)===parseInt(array[1])){
                      obj.eventClasses='holdClassOne4';
          
                    }
                    else if(parseInt(eventData.timeslot_id)===parseInt(array[2])){
                      obj.eventClasses='holdClassTwo4';
        
                    }
                    else if(parseInt(eventData.timeslot_id)===parseInt(array[3])){
                      obj.eventClasses='holdClassThree4';
        
                    }
                }    
        
                else if(parseInt(eventData.hold_type)===1 && eventData.event.event_planner && parseInt(eventData.event.event_planner.id) === parseInt(localStorage.getItem('id')) && parseInt(days[day_count].count)===3){
                    if(parseInt(eventData.timeslot_id)===parseInt(array[0])){
                      obj.eventClasses='holdClass3';
                    }
                    else if(parseInt(eventData.timeslot_id)===parseInt(array[1])){
                      obj.eventClasses='holdClassOne3';
          
                    }
                    else if(parseInt(eventData.timeslot_id)===parseInt(array[2])){
                      obj.eventClasses='holdClassTwo3';
        
                    }
                }    
        
                //block condition
                else if((parseInt(eventData.hold_type)===4 && parseInt(days[day_count].count)===2)||  
                (parseInt(eventData.hold_type)===2 && parseInt(days[day_count].count)===2) ||
                (parseInt(eventData.hold_type)===3 && parseInt(days[day_count].count)===2))
                  {
                    if(parseInt(eventData.timeslot_id)===parseInt(array[0])){
                    obj.eventClasses='blockClassRight'; 
                    }
                    else{
                      obj.eventClasses='blockClassLeft'; 
                    }
                }
        
                else if((parseInt(eventData.hold_type)===4 && parseInt(days[day_count].count)===1)
                ||  (parseInt(eventData.hold_type)===2 && parseInt(days[day_count].count)===1) ||
                (parseInt(eventData.hold_type)===3 && parseInt(days[day_count].count)===1)){
                  obj.eventClasses='blockClassSingle';
                }
            
                else if((parseInt(eventData.hold_type)===4 && parseInt(days[day_count].count)===4)
                ||  (parseInt(eventData.hold_type)===2 && parseInt(days[day_count].count)===4) ||
                (parseInt(eventData.hold_type)===3 && parseInt(days[day_count].count)===4)){
                  if(parseInt(eventData.timeslot_id)===parseInt(array[0])){
                    obj.eventClasses='blockClass';
                  }
                  else if(parseInt(eventData.timeslot_id)===parseInt(array[1])){
                    obj.eventClasses='blockClassOne4';
        
                  }
                  else if(parseInt(eventData.timeslot_id)===parseInt(array[2])){
                    obj.eventClasses='blockClassTwo4';
        
                  }
                  else if(parseInt(eventData.timeslot_id)===parseInt(array[3])){
                    obj.eventClasses='blockClassThree4';
        
                  }
                }  
        
              else if((parseInt(eventData.hold_type)===4 && parseInt(days[day_count].count)===3)
                ||  (parseInt(eventData.hold_type)===2 && parseInt(days[day_count].count)===3)
                || (parseInt(eventData.hold_type)===3 && parseInt(days[day_count].count)===3)){
                  if(parseInt(eventData.timeslot_id)===parseInt(array[0])){
                    obj.eventClasses='blockClass3';
                  }
                  else if(parseInt(eventData.timeslot_id)===parseInt(array[1])){
                    obj.eventClasses='blockClassOne3';
        
                  }
                  else if(parseInt(eventData.timeslot_id)===parseInt(array[2])){
                    obj.eventClasses='blockClassTwo3';
        
                  }
              }  
              else if(parseInt(eventData.hold_type)===4){
                obj.eventClasses='blockClassSingle';
                }
                else
                {
                obj.eventClasses='blankClass';
          
                }
        
                obj.description=" ";
                obj.title='';
                obj.start=eventData.date;
                obj.end=eventData.date;
                obj.timeslotID=eventData.timeslot_id;
          
                if(eventData.timeslot.event_types){
                for(let i=0;i<eventData.timeslot.event_types.length;i++)
                {
                for(let j=0;j<eventData.timeslot.event_types[i].pivot.weekdays.length;j++){
                if(date.getDay().toString()===eventData.timeslot.event_types[i].pivot.weekdays[j].toString()){
                obj.startTime=eventData.timeslot.start_time;
                obj.endTime=eventData.timeslot.end_time;
                obj.eventType=eventData.timeslot.event_types[i].name;
                obj.price=eventData.timeslot.event_types[i].pivot.base_rate;
                obj.additionalPrice=eventData.timeslot.event_types[i].pivot.overage_rate;
                break;
                }
                }
                }
                }    
                eventGroup.push(obj);        
              }
          });

          
      
          return eventGroup;
    
          })

          let e_arr=[];
          let present_t=false;
          let e_obj={};
        for(let e_i=0;e_i<eventGroup.length;e_i++){
    
          present_t=false;
          e_obj={};
          if(e_arr.length!==0){
    
          for(let e_j=0;e_j<e_arr.length;e_j++){
            if(parseInt(eventGroup[e_i].id) === parseInt(e_arr[e_j].id)){
    
              if(eventGroup[e_i].available){
    
              present_t=true;
    
              if(eventGroup[e_i].available.toString()==='No hold available.'){
                e_obj.available=eventGroup[e_i].available
    
              }
              else{
    
            let av=eventGroup[e_i].available.split(' ');
              av=numeral(av[0]);
    
              let av1=e_arr[e_j].available.split(' ');
              av1=numeral(av1[0]);
    
    
          
              if(av.value() > av1.value()){
                e_obj.available=ordinal(av.value());
              }
              else
              {
                e_obj.available=e_arr[e_j].available;
              }
              }
          
              if(parseInt(eventGroup[e_i].planner_id)===parseInt(localStorage.getItem('id'))){
    
                e_obj.bookingType=eventGroup[e_i].bookingType;
                e_obj.date= eventGroup[e_i].date;
                e_obj.description=eventGroup[e_i].description;
                e_obj.end=eventGroup[e_i].end;
                e_obj.eventClasses=eventGroup[e_i].eventClasses;
                e_obj.holdStatus=eventGroup[e_i].holdStatus;
                e_obj.name= eventGroup[e_i].name;
                e_obj.planner_id= eventGroup[e_i].planner_id;
                e_obj.start= eventGroup[e_i].start;
                e_obj.timeslotID=eventGroup[e_i].timeslotID;
                e_obj.title=eventGroup[e_i].title;
              }
              else{
    
              
                e_obj.bookingType=e_arr[e_j].bookingType;
                e_obj.date= e_arr[e_j].date;
                e_obj.description=e_arr[e_j].description;
                e_obj.end=e_arr[e_j].end;
                e_obj.eventClasses=e_arr[e_j].eventClasses;
                e_obj.holdStatus=e_arr[e_j].holdStatus;
                e_obj.name=e_arr[e_j].name;
                e_obj.planner_id= e_arr[e_j].planner_id;
                e_obj.start= e_arr[e_j].start;
                e_obj.timeslotID=e_arr[e_j].timeslotID;
                e_obj.title=e_arr[e_j].title;
    
              }
    
              e_arr=e_arr.splice(e_j, 1);
    
              }
            }
          }
          }
    
          if(present_t===false){
            e_arr.push(eventGroup[e_i]);
          }
          else{
            e_arr.push(e_obj);
          }
          }
    
      //  let fd = eventGroup.filter( (ele, ind) => ind === eventGroup.findIndex( elem => elem.timeslotID == ele.timeslotID && elem.date === ele.date ))
    
    
    this.setState({eventGroup:e_arr})
  })
  .catch(error =>
    this.setState({
      getResponse: false,
      errorValue: true,
      venueId: response.spaceDetails.data.venue_id
    })
  );



          })
        }
      }
    }

    componentWillReceiveProps(nextProps){
      this.setState({makeToken:false})
      let urlValue=this.props.location.search;
      this.setState({url:urlValue});    
      if(this.props.match.params.spaceId !==nextProps.match.params.spaceId)
      {
       
        this.props.venueProfile(nextProps.match.params.venueId).then(() => {
            this.setState({
              getResponse: true,
              venueId: nextProps.match.params.venueId,
              max_hold:this.props.venue.max_hold_limit ? this.props.venue.max_hold_limit: 0
        
            });
        
            if (nextProps.match.params.spaceId) {
              for (let i = 0; i < this.props.venue.spaces.length; i++) {
                if (
                  parseInt(nextProps.match.params.spaceId) === parseInt(this.props.venue.spaces[i].id)
                ) {
                  this.setState({ space: this.props.venue.spaces[i] });
                  break;
                }
              }
            }
          })
          .catch(error =>{    
            this.setState({
              getResponse: false,
              errorValue: true,
              venueId: nextProps.match.params.venueId
            })
          });
    
          if(nextProps.match.params.spaceId){
            let id=nextProps.match.params.spaceId;
            if(id){               
              this.props.spaceProfile(id).then((response) => {                
                this.setState({
                  getSpace: true,
                  venueId: this.props.match.params.venueId,
                  space:response.spaceDetails.data,
                  areaUnit:response.spaceDetails.data.area_unit,
                  dimensionUnit:response.spaceDetails.data.dimension_unit
                });
              eventGroup=[];
              let time=[];
                    let eventType=[];
                    let mon=0;
                    let tue=0;
                    let wed=0;
                    let thur=0;
                    let fri=0;
                    let sat=0;
                    let sun=0;
                    let days=[];
            
                    let timeDaysMon=[];
                    let timeDaysTue=[];
                    let timeDaysWed=[];
                    let timeDaysThur=[];
                    let timeDaysFri=[];
                    let timeDaysSat=[];
                    let timeDaysSun=[];
            
                    let objtimeMon={};
                    let objtimeTue={};
                    let objtimeWed={};
                    let objtimeThur={};
                    let objtimeFri={};
                    let objtimeSat={};
                    let objtimeSun={};
                    let weekOff   = [];
            
              let time_len=response.spaceDetails.data.timeslots;
                if(time_len.length>0){
            
              for(let i=0;i<time_len.length;i++){
                let obj={};
                let price=0;
                let avg=0;
                obj.id=time_len[i].id;
                obj.time=tConvert(time_len[i].start_time.substring(0,5)) +"-"+tConvert(time_len[i].end_time.substring(0,5));
                obj.flexible=time_len[i].fixed_rental;
                obj.hours=time_len[i].min_hour;
                if(parseInt(time_len[i].monday)===1){
                  timeDaysMon.push(time_len[i].id);
                  weekOff.push(1)
                  mon=mon+1;
                }   
            
                if(parseInt(time_len[i].tuesday)===1){
                  timeDaysTue.push(time_len[i].id);
                  weekOff.push(2)
                  tue=tue+1;
                }
              
            
                if(parseInt(time_len[i].wednesday)===1){
                  timeDaysWed.push(time_len[i].id);
                  weekOff.push(3)
                  wed=wed+1;
                }
                
            
                if(parseInt(time_len[i].thursday)===1){
                  timeDaysThur.push(time_len[i].id);
                  weekOff.push(4)
                  thur=thur+1;
                }   
            
                if(parseInt(time_len[i].friday)===1){
                  timeDaysFri.push(time_len[i].id);
                  weekOff.push(5)
                  fri=fri+1;
                }
              
            
                if(parseInt(time_len[i].saturday)===1){
                  timeDaysSat.push(time_len[i].id);
                  weekOff.push(6)
                  sat=sat+1;
                }
              
            
                if(parseInt(time_len[i].sunday)===1){
                  timeDaysSun.push(time_len[i].id);
                  weekOff.push(0)
                  sun=sun+1;
                }  
            
            
                if(time_len[i].event_types.length>0){
                  for(let j=0;j<time_len[i].event_types.length;j++){
                      eventType.push(time_len[i].event_types[j].name)
            
                      if(parseInt(avg)===0){
                        if(parseInt(time_len[i].event_types[j].pivot.pricing_type)===1 || parseInt(time_len[i].event_types[j].pivot.pricing_type)===2){
                          avg=price+time_len[i].event_types[j].pivot.base_rate;
                        }
            
                        if(parseInt(time_len[i].event_types[j].pivot.pricing_type)===3){
                          avg=price+time_len[i].event_types[j].pivot.fnb_rate + time_len[i].event_types[j].pivot.base_rate;
                        }
            
                        if(parseInt(time_len[i].event_types[j].pivot.pricing_type)===4){
                          if(time_len[i].event_types[j].pivot.min_guest!=0){
                            avg=price+time_len[i].event_types[j].pivot.base_rate * time_len[i].event_types[j].pivot.min_guest;
                          }
                          else
                          {
                            avg=price+time_len[i].event_types[j].pivot.base_rate;
            
                          }
                        }          
                      }
                    
                        if(parseInt(time_len[i].event_types[j].pivot.pricing_type)===1 || parseInt(time_len[i].event_types[j].pivot.pricing_type)===2){
                          price=price+time_len[i].event_types[j].pivot.base_rate;
                        }
            
                        if(parseInt(time_len[i].event_types[j].pivot.pricing_type)===3){
                          price=price+time_len[i].event_types[j].pivot.fnb_rate + time_len[i].event_types[j].pivot.base_rate;
                        }
            
                        if(parseInt(time_len[i].event_types[j].pivot.pricing_type)===4){
                          if(time_len[i].event_types[j].pivot.min_guest!=0){
                            price=price+time_len[i].event_types[j].pivot.base_rate * time_len[i].event_types[j].pivot.min_guest;
            
                          }
                          else
                          {
                            price=price+time_len[i].event_types[j].pivot.base_rate;
            
                          }
                        }
                        if(price<avg){
                          avg=price;
                        }
                
                  }
                  obj.avg=avg
                }
                else{
                  obj.avg=0;
                }
            
                time.push(obj)
              }
            
              this.setState({weekOff:weekOff});
            
              objtimeMon.count=mon;
              objtimeMon.id=timeDaysMon;
            
              objtimeTue.count=tue;
              objtimeTue.id=timeDaysTue;
            
              objtimeWed.count=wed;
              objtimeWed.id=timeDaysWed;
            
              objtimeThur.count=thur;
              objtimeThur.id=timeDaysThur;
            
              objtimeFri.count=fri;
              objtimeFri.id=timeDaysFri;
            
              objtimeSat.count=sat;
              objtimeSat.id=timeDaysSat;
            
              objtimeSun.count=sun;
              objtimeSun.id=timeDaysSun;
            
            
              days.push(objtimeMon);
              days.push(objtimeTue);
              days.push(objtimeWed);
              days.push(objtimeThur);
              days.push(objtimeFri);
              days.push(objtimeSat);
              days.push(objtimeSun);
            
            
              let fd = eventType.filter( (ele, ind) => ind === eventType.findIndex( elem => elem === ele))
            
              this.setState({timeSlot:time,spaceEventType:fd,days:days})
              
            
                }
                let token=0;
                let daylist=[];
                let makeAvailabledates=[];
                var block_list=[];
            
                if(response.spaceDetails.data.avilability.length>0){
                  for(let bi=0;bi<response.spaceDetails.data.avilability.length;bi++){
                    let d=new Date(response.spaceDetails.data.avilability[bi].end_date);
                  d=new Date(d.setDate(d.getDate()));
                  let month=d.getMonth()+1;
            
                    let date=d.getDate();
                    if(month.length===1){
                      month="0"+month;
                    }
            
                    if(parseInt(response.spaceDetails.data.avilability[bi].status)===0){
                
                    daylist = getDaysArray(new Date(moment(response.spaceDetails.data.avilability[bi].start_date).format("MM/DD/YYYY")),new Date(moment(d.getFullYear()+"-"+month+"-"+date).format("MM/DD/YYYY")));         
                    for(let i=0;i<daylist.length;i++){
                      block_list.push(daylist[i])
                      let obj={};
                      obj.description=" ";
                      obj.title='';
                      obj.eventClasses='blockOut';
                      obj.start=moment(daylist[i]).format("YYYY-MM-DD");
                      obj.end=moment(daylist[i]).format("YYYY-MM-DD");
                      obj.date=moment(daylist[i]).format("YYYY-MM-DD");
                      eventGroup.push(obj);
                    }
            
                    }
                    else if(parseInt(response.spaceDetails.data.avilability[bi].status)===1){
                    //  let maObj={};
                    this.setState({makeToken:true})
            
                    //  maObj.eventClasses='makeAvailableClass';
                    //  maObj.description=" ";
                    //  maObj.title='';
                    //  maObj.start=response.spaceDetails.data.avilability[bi].start_date;
                    //  maObj.end=d.getFullYear()+"-"+month+"-"+date;
                    //  maObj.date=response.spaceDetails.data.avilability[bi].start_date;
            
                    //  eventGroup.push(maObj);
            
                    daylist = getDaysArray(new Date(moment(response.spaceDetails.data.avilability[bi].start_date).format("MM/DD/YYYY")),new Date(moment(d.getFullYear()+"-"+month+"-"+date).format("MM/DD/YYYY")));         
                    for(let i=0;i<daylist.length;i++){
                      let maObj={};
                      this.setState({makeToken:true})
                      maObj.eventClasses='makeAvailableClass';
                      maObj.description=" ";
                      maObj.title='';
                      maObj.start=moment(daylist[i]).format("YYYY-MM-DD");
                      maObj.end=moment(daylist[i]).format("YYYY-MM-DD");
                      maObj.date=response.spaceDetails.data.avilability[bi].start_date;
              
                      eventGroup.push(maObj);
                      makeAvailabledates.push(daylist[i])
                    }
                    }
                    if(parseInt(response.spaceDetails.data.avilability[bi].is_recurring)===1 && parseInt(response.spaceDetails.data.avilability[bi].status)===0){
                      let s_d=new Date(response.spaceDetails.data.avilability[bi].start_date);
                      let s_month=s_d.getMonth()+1;
                      let s_date=s_d.getDate();
                      let year=s_d.getFullYear()+1;
            
                      let year2=s_d.getFullYear()+2;
            
                      if(s_d.getMonth().length===1){
                      s_month="0"+s_month;
                      }
                      if(s_d.getDay().length===1){
                      s_date="0"+s_date
                      }
                      let e_d=new Date(response.spaceDetails.data.avilability[bi].end_date);
                      e_d=new Date(e_d.setDate(e_d.getDate()));
            
                      let e_month=e_d.getMonth()+1;
            
                      let e_date=e_d.getDate()
            
                      if(e_d.getMonth().length===1){
                      e_month="0"+e_month;
                      }
                      if(s_d.getDay().length===1){
                      s_date="0"+e_date
                      }                
                      let obj={};
                      obj.description=" ";
                      obj.title='';
                      obj.eventClasses='blockOut';
                      obj.start=year + "-"+s_month+"-"+s_date;
                      obj.end=year + "-"+e_month+"-"+e_date;
                      obj.date=year + "-"+s_month+"-"+s_date;
            
                      eventGroup.push(obj);
                      obj={};
                      obj.description=" ";
                      obj.title='';
                      obj.eventClasses='blockOut';
                      obj.start=year2 + "-"+s_month+"-"+s_date;
                      obj.end=year2 + "-"+e_month+"-"+e_date;
                      obj.date=year2 + "-"+s_month+"-"+s_date;
                      eventGroup.push(obj);
            
                    }
            
            
            
                  }
                }
                this.setState({daylist:block_list,makeAvailable:makeAvailabledates});
            
            
                let dd=new Date();
                dd=new Date(dd.setDate(dd.getDate()))
                let y=dd.getFullYear();
                let m=dd.getMonth() + 1;
                let d=dd.getDate() - 1;
                
            
                if(parseInt(m)===1 ||  parseInt(m)===2 || parseInt(m)===3 || parseInt(m)===4 || parseInt(m)===5 || parseInt(m)===6 ||parseInt(m)===7 || parseInt(m)===8 || parseInt(m)===9){
                m="0"+m;
                }
              
            
              let objD={};
                objD.description=" ";
                objD.title='';
                objD.eventClasses='previous_dates';
                objD.start=y + 
                "-"+
                m+
                "-"+
                "01";
                objD.end=y + "-"+m+"-"+d;
                objD.date=y + 
                "-"+
                m+
                "-"+
                '01';
            
                eventGroup.push(objD);
              
                let max_hold=this.state.max_hold;
            
              response.spaceDetails.data.event_timeslots && response.spaceDetails.data.event_timeslots.map(function(eventData, idx) {    
            if(eventData.parent_id!==null){  
            token=token+1;
            let obj={};
            
            const date = moment(eventData.date).toDate();
            const month = date.toLocaleString('default', { month: 'long' });
            var day = date.getDate();
            var year=date.getFullYear();
            var week_day= weekday[date.getDay()];
            obj.date=month.substring(0,3) + ' '+day+ ' ' + year;
            obj.name=eventData.event.name;
            obj.planner_id=eventData.event.event_planner && eventData.event.event_planner.id;
            obj.id=eventData.id;
            let day_count;
            let array=[];
            if(week_day==="monday"){
              day_count=0;
              array=days[0].id;
            
            }
            else if(week_day==="tuesday"){
              day_count=1;
              array=days[1].id;
            
            }
            else if(week_day==="wednesday"){
              day_count=2;
              array=days[2].id;
            
            }
            else if(week_day==="thursday"){
              day_count=3;
              array=days[3].id;
            
            }
            else if(week_day==='friday'){
              day_count=4;
              array=days[4].id;
            
            }
            else if(week_day==='saturday'){
              day_count=5;
              array=days[5].id;
            
            }
            else if(week_day==="sunday"){
              day_count=6;
              array=days[6].id;
            
            }
            
              if(eventData.hold_rank + 1 > max_hold){
              obj.available="No hold available.";
              }
              else
              {
              obj.available= ordinal(eventData.hold_rank + 1) + " hold available";
              }
              obj.holdStatus= ordinal(eventData.hold_rank) + " hold";
              obj.bookingType=eventData.hold_type;
            
            
              if(parseInt(eventData.hold_type)===1 
                && eventData.event.event_planner && parseInt(eventData.event.event_planner.id) === parseInt(localStorage.getItem('id'))
                && parseInt(days[day_count].count)===2)
                {
                  
            
                  if(parseInt(eventData.timeslot_id)===parseInt(array[0])){
                  obj.eventClasses='holdClassRight'; 
                  }
                  else{
                    obj.eventClasses='holdClassLeft'; 
                  }
              }
            
              else 
                if(parseInt(eventData.hold_type)===1 
                  && eventData.event.event_planner && parseInt(eventData.event.event_planner.id) === parseInt(localStorage.getItem('id'))
                  && parseInt(days[day_count].count)===1){
            
                  obj.eventClasses='holdClassSingle';
              }
              
            else if(parseInt(eventData.hold_type)===1 
                && eventData.event.event_planner && parseInt(eventData.event.event_planner.id)=== parseInt(localStorage.getItem('id')) && 
                parseInt(days[day_count].count)===4){
                  if(parseInt(eventData.timeslot_id)===parseInt(array[0])){
                    obj.eventClasses='holdClass';
                  }
                  else if(parseInt(eventData.timeslot_id)===parseInt(array[1])){
                    obj.eventClasses='holdClassOne4';
            
                  }
                  else if(parseInt(eventData.timeslot_id)===parseInt(array[2])){
                    obj.eventClasses='holdClassTwo4';
            
                  }
                  else if(parseInt(eventData.timeslot_id)===parseInt(array[3])){
                    obj.eventClasses='holdClassThree4';
            
                  }
              }    
            
              else if(parseInt(eventData.hold_type)===1 
                && eventData.event.event_planner && parseInt(eventData.event.event_planner.id) === parseInt(localStorage.getItem('id')) && 
                parseInt(days[day_count].count)===3){
                  if(parseInt(eventData.timeslot_id)===parseInt(array[0])){
                    obj.eventClasses='holdClass3';
                  }
                  else if(parseInt(eventData.timeslot_id)===parseInt(array[1])){
                    obj.eventClasses='holdClassOne3';
            
                  }
                  else if(parseInt(eventData.timeslot_id)===parseInt(array[2])){
                    obj.eventClasses='holdClassTwo3';
            
                  }
              }    
              else if((parseInt(eventData.hold_type)===3 && parseInt(days[day_count].count)===2) ||
              (parseInt(eventData.hold_type)===4 && parseInt(days[day_count].count)===2)||  
              (parseInt(eventData.hold_type)===2 && parseInt(days[day_count].count)===2))
              {
            
            
                if(parseInt(eventData.timeslot_id)===parseInt(array[0])){
                obj.eventClasses='blockClassRight'; 
                }
                else{
                  obj.eventClasses='blockClassLeft'; 
                }
            }
            
            else if(
              (parseInt(eventData.hold_type)===3 && parseInt(days[day_count].count)===1) ||
              (parseInt(eventData.hold_type)===4 && parseInt(days[day_count].count)===1)
            ||  (parseInt(eventData.hold_type)===2 && parseInt(days[day_count].count)===1)){
              obj.eventClasses='blockClassSingle';
            }
            
            else if((parseInt(eventData.hold_type)===4 && parseInt(days[day_count].count)===4)
            ||  (parseInt(eventData.hold_type)===2 && parseInt(days[day_count].count)===4)
            || (parseInt(eventData.hold_type)===3 && parseInt(days[day_count].count)===4)){
              if(parseInt(eventData.timeslot_id)===parseInt(array[0])){
                obj.eventClasses='blockClass';
              }
              else if(parseInt(eventData.timeslot_id)===parseInt(array[1])){
                obj.eventClasses='blockClassOne4';
            
              }
              else if(parseInt(eventData.timeslot_id)===parseInt(array[2])){
                obj.eventClasses='blockClassTwo4';
            
              }
              else if(parseInt(eventData.timeslot_id)===parseInt(array[3])){
                obj.eventClasses='blockClassThree4';
            
              }
            }  
            
            
            else if((parseInt(eventData.hold_type)===3 && parseInt(days[day_count].count)===3) ||
              (parseInt(eventData.hold_type)===4 && parseInt(days[day_count].count)===3)
            ||  (parseInt(eventData.hold_type)===2 && parseInt(days[day_count].count)===3)){
              if(parseInt(eventData.timeslot_id)===parseInt(array[0])){
                obj.eventClasses='blockClass3';
              }
              else if(parseInt(eventData.timeslot_id)===parseInt(array[1])){
                obj.eventClasses='blockClassOne3';
            
              }
              else if(parseInt(eventData.timeslot_id)===parseInt(array[2])){
                obj.eventClasses='blockClassTwo3';
            
              }
            } 
            
            else if(parseInt(eventData.hold_type)===4){
              obj.eventClasses='blockClassSingle';
              }
              else
              {
              obj.eventClasses='blankClass';
            
              }
              obj.description=" ";
              obj.title='';
              obj.start=eventData.date;
              obj.end=eventData.date;
              obj.timeslotID=eventData.timeslot_id;
            
              if(eventData.timeslot.event_types){
              for(let i=0;i<eventData.timeslot.event_types.length;i++)
              {
              for(let j=0;j<eventData.timeslot.event_types[i].pivot.weekdays.length;j++){
              if(date.getDay().toString()===eventData.timeslot.event_types[i].pivot.weekdays[j].toString()){
              obj.startTime=eventData.timeslot.start_time;
              obj.endTime=eventData.timeslot.end_time;
              obj.eventType=eventData.timeslot.event_types[i].name;
              obj.price=eventData.timeslot.event_types[i].pivot.base_rate;
              obj.additionalPrice=eventData.timeslot.event_types[i].pivot.overage_rate;
              break;
              }
              }
              }
              }
                eventGroup.push(obj);
            
            
            }
            
            
            return eventGroup;
            })
            
            
            // let fd = eventGroup.filter( (ele, ind) => ind === eventGroup.findIndex( elem => elem.timeslotID == ele.timeslotID && elem.date === ele.date ))
            let e_arr=[];
            let present_t=false;
            let e_obj={};
            for(let e_i=0;e_i<eventGroup.length;e_i++){
            present_t=false;
            e_obj={};
            if(e_arr.length!==0){
            for(let e_j=0;e_j<e_arr.length;e_j++){
              if(parseInt(eventGroup[e_i].id) === parseInt(e_arr[e_j].id)){
                if(eventGroup[e_i].available){
                present_t=true;
            
                if(eventGroup[e_i].available==='No hold available.'){
                  e_obj.available=eventGroup[e_i].available
            
                }
                else{
              let av=eventGroup[e_i].available.split(' ');
                av=numeral(av[0]);
            
                let av1=e_arr[e_j].available.split(' ');
                av1=numeral(av1[0]);
            
            
            
                if(av.value() > av1.value()){
                  e_obj.available=ordinal(av.value());
                }
                else
                {
                  e_obj.available=e_arr[e_j].available;
                }
                }
            
                if(parseInt(eventGroup[e_i].planner_id)===parseInt(localStorage.getItem('id'))){
                  e_obj.bookingType=eventGroup[e_i].bookingType;
                  e_obj.date= eventGroup[e_i].date;
                  e_obj.description=eventGroup[e_i].description;
                  e_obj.end=eventGroup[e_i].end;
                  e_obj.eventClasses=eventGroup[e_i].eventClasses;
                  e_obj.holdStatus=eventGroup[e_i].holdStatus;
                  e_obj.name= eventGroup[e_i].name;
                  e_obj.planner_id= eventGroup[e_i].planner_id;
                  e_obj.start= eventGroup[e_i].start;
                  e_obj.timeslotID=eventGroup[e_i].timeslotID;
                  e_obj.title=eventGroup[e_i].title;
                }
                else{
                
            
            
                  e_obj.bookingType=e_arr[e_j].bookingType;
                  e_obj.date= e_arr[e_j].date;
                  e_obj.description=e_arr[e_j].description;
                  e_obj.end=e_arr[e_j].end;
                  e_obj.eventClasses=e_arr[e_j].eventClasses;
                  e_obj.holdStatus=e_arr[e_j].holdStatus;
                  e_obj.name=e_arr[e_j].name;
                  e_obj.planner_id= e_arr[e_j].planner_id;
                  e_obj.start= e_arr[e_j].start;
                  e_obj.timeslotID=e_arr[e_j].timeslotID;
                  e_obj.title=e_arr[e_j].title;
            
                }
            
                e_arr=e_arr.splice(e_j, 1);
            
                }
              }
            }
            }
            if(present_t===false){
              e_arr.push(eventGroup[e_i]);
            }
            else{
              e_arr.push(e_obj);
            }
            }
            
            this.setState({eventGroup:e_arr})
            
              })      
            }
          }
    
    }
    
    let token = 0;
    let max_hold=this.state.max_hold
    let days = this.state.days
    if(this.props.plannerCalendarData !== nextProps.plannerCalendarData && this.state.days){
      eventGroup = this.state.eventGroup
      nextProps.plannerCalendarData && nextProps.plannerCalendarData && nextProps.plannerCalendarData.map(function(eventData, idx) {    
      if(eventData.parent_id!==null){  
      token=token+1;
      let obj={};
      const date = moment(eventData.date).toDate(); 
      const month = date.toLocaleString('default', { month: 'long' });
      var day = date.getDate();
      var year=date.getFullYear();
      var week_day= weekday[date.getDay()];
      obj.date=month.substring(0,3) + ' '+day+ ' ' + year;
      obj.name=eventData.event.name;
      obj.planner_id=eventData.event.event_planner && eventData.event.event_planner.id;
      obj.id=eventData.id;
      let day_count;
      let array=[];
      if(week_day==="monday"){
        day_count=0;
        array=days[0].id;
    
      }
      else if(week_day==="tuesday"){
        day_count=1;
        array=days[1].id;
    
      }
      else if(week_day==="wednesday"){
        day_count=2;
        array=days[2].id;
    
      }
      else if(week_day==="thursday"){
        day_count=3;
        array=days[3].id;
    
      }
      else if(week_day==='friday'){
        day_count=4;
        array=days[4].id;
    
      }
      else if(week_day==='saturday'){
        day_count=5;
        array=days[5].id;
    
      }
      else if(week_day==="sunday"){
        day_count=6;
        array=days[6].id;
    
      }
        if(eventData.hold_rank + 1 > max_hold ){
        obj.available="No hold available.";
        }
        else
        {
        obj.available= ordinal(eventData.hold_rank + 1) + " hold available";
        }
        obj.holdStatus= ordinal(eventData.hold_rank) + " hold";
        obj.bookingType=eventData.hold_type;
    
        if(parseInt(eventData.hold_type)===1 && eventData.event.event_planner && parseInt(eventData.event.event_planner.id) === parseInt(localStorage.getItem('id')) && parseInt(days[day_count].count)===2)
          {
            if(parseInt(eventData.timeslot_id)===parseInt(array[0])){
             obj.eventClasses='holdClassRight'; 
            }
            else{
              obj.eventClasses='holdClassLeft'; 
            }
        }
        else if(parseInt(eventData.hold_type)===1 && eventData.event.event_planner && parseInt(eventData.event.event_planner.id) === parseInt(localStorage.getItem('id')) && parseInt(days[day_count].count)===1){
    
            obj.eventClasses='holdClassSingle';
        }
        
       else if(parseInt(eventData.hold_type)===1 && eventData.event.event_planner && parseInt(eventData.event.event_planner.id) === localStorage.getItem('id') && parseInt(days[day_count].count)===4){
            if(parseInt(eventData.timeslot_id)===parseInt(array[0])){
              obj.eventClasses='holdClass';
            }
            else if(parseInt(eventData.timeslot_id)===parseInt(array[1])){
              obj.eventClasses='holdClassOne4';
    
            }
            else if(parseInt(eventData.timeslot_id)===parseInt(array[2])){
              obj.eventClasses='holdClassTwo4';
    
            }
            else if(parseInt(eventData.timeslot_id)===parseInt(array[3])){
              obj.eventClasses='holdClassThree4';
    
            }
        }    
    
        else if(parseInt(eventData.hold_type)===1 && eventData.event.event_planner && parseInt(eventData.event.event_planner.id) === parseInt(localStorage.getItem('id')) && parseInt(days[day_count].count)===3){
            if(parseInt(eventData.timeslot_id)===parseInt(array[0])){
              obj.eventClasses='holdClass3';
            }
            else if(parseInt(eventData.timeslot_id)===parseInt(array[1])){
              obj.eventClasses='holdClassOne3';
    
            }
            else if(parseInt(eventData.timeslot_id)===parseInt(array[2])){
              obj.eventClasses='holdClassTwo3';
    
            }
        }    
    
        //block condition
        else if((parseInt(eventData.hold_type)===4 && parseInt(days[day_count].count)===2)||  
        (parseInt(eventData.hold_type)===2 && parseInt(days[day_count].count)===2) ||
        (parseInt(eventData.hold_type)===3 && parseInt(days[day_count].count)===2))
          {
            if(parseInt(eventData.timeslot_id)===parseInt(array[0])){
             obj.eventClasses='blockClassRight'; 
            }
            else{
              obj.eventClasses='blockClassLeft'; 
            }
        }
    
        else if((parseInt(eventData.hold_type)===4 && parseInt(days[day_count].count)===1)
         ||  (parseInt(eventData.hold_type)===2 && parseInt(days[day_count].count)===1) ||
         (parseInt(eventData.hold_type)===3 && parseInt(days[day_count].count)===1)){
          obj.eventClasses='blockClassSingle';
         }
    
        else if((parseInt(eventData.hold_type)===4 && parseInt(days[day_count].count)===4)
         ||  (parseInt(eventData.hold_type)===2 && parseInt(days[day_count].count)===4) ||
         (parseInt(eventData.hold_type)===3 && parseInt(days[day_count].count)===4)){
          if(parseInt(eventData.timeslot_id)===parseInt(array[0])){
            obj.eventClasses='blockClass';
          }
          else if(parseInt(eventData.timeslot_id)===parseInt(array[1])){
            obj.eventClasses='blockClassOne4';
    
          }
          else if(parseInt(eventData.timeslot_id)===parseInt(array[2])){
            obj.eventClasses='blockClassTwo4';
    
          }
          else if(parseInt(eventData.timeslot_id)===parseInt(array[3])){
            obj.eventClasses='blockClassThree4';
    
          }
         }  
    
      else if((parseInt(eventData.hold_type)===4 && parseInt(days[day_count].count)===3)
        ||  (parseInt(eventData.hold_type)===2 && parseInt(days[day_count].count)===3)
        || (parseInt(eventData.hold_type)===3 && parseInt(days[day_count].count)===3)){
          if(parseInt(eventData.timeslot_id)===parseInt(array[0])){
            obj.eventClasses='blockClass3';
          }
          else if(parseInt(eventData.timeslot_id)===parseInt(array[1])){
            obj.eventClasses='blockClassOne3';
    
          }
          else if(parseInt(eventData.timeslot_id)===parseInt(array[2])){
            obj.eventClasses='blockClassTwo3';
    
          }
      }  
      else if(parseInt(eventData.hold_type)===4){
        obj.eventClasses='blockClassSingle';
        }
        else
        {
         obj.eventClasses='blankClass';
    
        }
    
        obj.description=" ";
        obj.title='';
        obj.start=eventData.date;
        obj.end=eventData.date;
        obj.timeslotID=eventData.timeslot_id;
    
        if(eventData.timeslot.event_types){
        for(let i=0;i<eventData.timeslot.event_types.length;i++)
        {
        for(let j=0;j<eventData.timeslot.event_types[i].pivot.weekdays.length;j++){
        if(date.getDay().toString()===eventData.timeslot.event_types[i].pivot.weekdays[j].toString()){
        obj.startTime=eventData.timeslot.start_time;
        obj.endTime=eventData.timeslot.end_time;
        obj.eventType=eventData.timeslot.event_types[i].name;
        obj.price=eventData.timeslot.event_types[i].pivot.base_rate;
        obj.additionalPrice=eventData.timeslot.event_types[i].pivot.overage_rate;
        break;
        }
        }
        }
        }
    
    
            eventGroup.push(obj);
    }
    // return eventGroup;
    
      })
    
      this.setState({eventGroup: eventGroup})
    }
    }

    handleProfileMenuOpen = event => {
      this.setState({ anchorEl: event.currentTarget });
    };

    handleMenuClose = () => {
      this.setState({ anchorEl: null });
      this.handleMobileMenuClose();
    };

    handleMobileMenuOpen = event => {
      this.setState({ mobileMoreAnchorEl: event.currentTarget });
    };

    handleMobileMenuClose = () => {
      this.setState({ mobileMoreAnchorEl: null });
    };

    openListSpaceLink = () => {
      // this.props.openListYourSpace()
      this.handleMobileMenuClose()
    }

    handleFind = (obj, id) => {
      return obj.find(data => parseInt(data.timeslotID)===parseInt(id))
    }

    getMouseCoordinate = (e) => {
        // var xCoordinate;
        // if(e.pageX<120){

        //   xCoordinate = e.pageX - 60 + 'px'
        // }
        // else{
        //   const xCoordinate = e.pageX - 120 + 'px'
        // }
        // const yCoordinate = e.pageY - 100 + 'px'
        // this.setState({
        // xCoordinate: xCoordinate,
        // yCoordinate: yCoordinate
        // })
        // this.setState({
        // openCalendarPopup: false,
        // eventInfo: {}
        // })
        if (window.screen.width >= 600) {
          const xCoordinate = e.pageX - 269 + 'px'
          const yCoordinate = e.pageY - 100 + 'px'
        this.setState({
          xCoordinate: xCoordinate,
          yCoordinate: yCoordinate
          })
          } else {
          this.setState({
          xCoordinate: '',
          yCoordinate: ''
          })
          }
          this.setState({
            openCalendarPopup: false,
          eventInfo: {}
          })
    };

    findDataObj=(obj, match )=> {
      return obj.find(data => parseInt(data.timeslotID)===parseInt(match))
    }
    
    onClickCalendar=(e,{selected})=>{
              window.scroll({top: document.body.scrollHeight, left: 0, behavior: 'smooth' });
              this.setState({first_hold:false,open:false,price:'',error:'',values:''})
  
              let month=e.props.day.month+1;
              const date = monthNames[month].short+" "+e.props.day.day+" " +e.props.day.year;
              this.setState({day:monthNames[month].short+" "+e.props.day.day +" "+ e.props.day.year})
              let dateToken=false;
              let makeToken=false;
              let placeHoldPopup = true;
              if(e && 'day' in e.props){
                this.setState({selectedDate:date});
              }
              if(this.state.makeAvailable && parseInt(this.state.makeAvailable.length)===0)
              {
  
                if(month.toString() === (new Date().getMonth()+1).toString() && e.props.day.day < new Date().getDate())
                { 
  
                 dateToken=false;
               }
             else{
               dateToken=true;
             }
              }
              else{
                dateToken=false;
              }
        
              let token=false;
              if(this.state.daylist && this.state.daylist.length > 0)
              {
  
  
              for(let i=0;i<this.state.daylist.length;i++){
                const month = this.state.daylist[i].toLocaleString('default', { month: 'long' });
                var day = this.state.daylist[i].getDate();
                var year=this.state.daylist[i].getFullYear();
                if(date.toString()===(month.substring(0,3) + ' '+day+ ' ' + year).toString()){
                  token=true;
                  break;
                }
              }
            }
            if(this.state.makeAvailable && this.state.makeAvailable.length>0){
              for(let i=0;i<this.state.makeAvailable.length;i++){
                const month = this.state.makeAvailable[i].toLocaleString('default', { month: 'long' });
                var day_m = this.state.makeAvailable[i].getDate();
                var year_m=this.state.makeAvailable[i].getFullYear();
  
                if(date.toString()===(month.substring(0,3) + ' '+day_m+ ' ' + year_m).toString()){
                  makeToken=true;
                  break;
                }
              }
            }
  
  
              if(makeToken===true  || dateToken===true){
  
                if(token===false){
  
                    let arr=[];
                    let d;
                    if(e.props.day.weekDay===0){
                      d=6;
    
                    }
                    else{
                      d=e.props.day.weekDay-1;
                    }
  
  
                                      
                    arrTime=[];
    
                    for(let jt=0;jt<this.state.timeSlot.length;jt++){
                      let objtime={};
  
                    for(let i=0;i<this.state.eventGroup.length;i++){
                      if(date.toString()===this.state.eventGroup[i].date.toString() && 
                        parseInt(this.state.timeSlot[jt].id)===parseInt(this.state.eventGroup[i].timeslotID)){
                          let time=this.state.eventGroup[i].available.split(' ');
                          if(this.state.eventGroup[i].bookingType===4 || this.state.eventGroup[i].bookingType===3){
                            placeHoldPopup = false;
                          }  
                        if(time[0]==='No'){
                          objtime.position='N/A';
  
                        }
                          else{
                            objtime.position=time[0];
  
                          }                      
                      }
                      objtime.avg=this.state.timeSlot[jt].avg;
                      objtime.flexible=this.state.timeSlot[jt].flexible;
                      objtime.hours=this.state.timeSlot[jt].hours;
                      objtime.id=this.state.timeSlot[jt].id;
                      objtime.time=this.state.timeSlot[jt].time;
  
  
                    }
  
                    arrTime.push(objtime);
  
                  }
              
                  this.setState({timeSlot:arrTime})
                 
                    let t1=new Date(month+'/'+e.props.day.day+'/'+e.props.day.year);
                    var today = new Date();
                    var dd = String(today.getDate()).padStart(2, '0');
                    var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
                    var yyyy = today.getFullYear();
                    
                    today =new Date(mm + '/' + dd + '/' + yyyy);
  
                if(t1.getTime() >= today.getTime() && placeHoldPopup){
  
                    let obj = this.state.eventGroup.filter(data => data.date === date)
                    
                    for(let i=0;i<this.state.days[d].id.length;i++){
                      for(let j=0;j<arrTime.length;j++){
                        if(parseInt(this.state.days[d].id[i])===parseInt(arrTime[j].id)){
                          let t_obj = this.findDataObj(obj, arrTime[j].id);//obj.find(data => parseInt(data.timeslotID)===parseInt(arrTime[j].id))
                       if(t_obj && t_obj.bookingType!==1 && parseInt(t_obj.timeslotID)===parseInt(arrTime[j].id)){
  
                 
                          let timeObj={};
                          timeObj.avg=arrTime[j].avg;
                          timeObj.flexible= arrTime[j].flexible;
                          timeObj.hours= arrTime[j].hours;
                          timeObj.id= arrTime[j].id;
                          timeObj.position='N/A';
                          timeObj.time=arrTime[j].time;
                          arr.push(timeObj);
                        }
                        else{
                          arr.push(arrTime[j]);
  
                        }
                        }
                      }
                    }
  
                  this.setState({daySlot:arr});
                 
                 
                 
                    if (obj && parseInt(obj.bookingType) === 1) {
                      this.setState({openCalendarPopup:true});
  
                     let available;
                    var eventTime='N/A';
                    if(obj.startTime && obj.endTime){
                    eventTime = `${moment(obj.startTime, 'HH:mm').format('hh:mm A')} - ${moment(obj.endTime, 'HH:mm').format('hh:mm A')}`
                    }
          
                    this.setState({
                    openCalendarPopup: true,
                    planner_id:obj.planner_id,
                    eventInfo: obj,
                    day: monthNames[month].short+" "+e.props.day.day+" "+e.props.day.year,
                    eventTime: eventTime,
                    eventType: obj.eventType,
                    bookingType: obj.bookingType,
                    holdStatus:available
                    })
                    } 
                 
                    else if(obj){
                    
                      this.setState({openCalendarPopup:true});
  
                      let arr=[];
                      for(let i=0;i<this.state.timeSlot.length;i++){
                        let objtime={};
                        objtime.position='1st'
                        objtime.avg=this.state.timeSlot[i].avg;
                        objtime.flexible=this.state.timeSlot[i].flexible;
                        objtime.hours=this.state.timeSlot[i].hours;
                        objtime.id=this.state.timeSlot[i].id;
                        objtime.time=this.state.timeSlot[i].time;
                        arr.push(objtime);
      
                      }
                      this.setState({timeSlot:arr}) 
                    this.setState({
                    openCalendarPopup: true,
                    eventInfo: obj
                    })
          
                    }
                    else {
                    this.setState({openCalendarPopup:true});
  
                    let event_data=this.state.space;
                    let event_type='';
                    // let additionalPrice='N/A';
                    // let price='N/A';
                    if(event_data.timeslots.length>0){
          
                    if(event_data.timeslots[0].event_types.length>0){
                    event_type=event_data.timeslots[0].event_types[0].name;
                    // price=event_data.timeslots[0].event_types[0].pivot.base_rate;
                    // additionalPrice=event_data.timeslots[0].event_types[0].pivot.overage_rate;
                    }
                    let startTime=event_data.timeslots[0].start_time;
                    let endTime=event_data.timeslots[0].end_time;
          
                    // if(additionalPrice!==null && price!==null)
                    // {
                    // additionalPrice=`$${price} + $${additionalPrice}/addtional hour`
                    // }
                    // else if(price){
                    // additionalPrice=price && `$${price}` 
                    // }
          
                    let arr=[];
                    for(let i=0;i<this.state.timeSlot.length;i++){
                      let objtime={};
                      objtime.position='1st'
                      objtime.avg=this.state.timeSlot[i].avg;
                      objtime.flexible=this.state.timeSlot[i].flexible;
                      objtime.hours=this.state.timeSlot[i].hours;
                      objtime.id=this.state.timeSlot[i].id;
                      objtime.time=this.state.timeSlot[i].time;
                      arr.push(objtime);
    
                    }
                    this.setState({timeSlot:arr}) 
                    var eventTime_else='N/A';
                    if(startTime && endTime){
                      eventTime_else = `${moment(startTime, 'HH:mm').format('hh:mm A')} - ${moment(endTime, 'HH:mm').format('hh:mm A')}`
                    }
                    this.setState({
                    eventInfo: obj,
                    openCalendarPopup:true,
                    planner_id:'',
                    day: monthNames[month].short+" "+e.props.day.day +" "+e.props.day.year,
                    eventTime: eventTime_else,
                    eventType: event_type,
                    holdStatus:'1st hold available',
                    first_hold:true
                    })
                    } 
                    }
                  
                    
                }
              }
              }
    }
    
    handleChangeBox=()=>{
        document.getElementById('SaveData').click();
    }
    
      checkClick=(values)=>{
        let price=0;
        let hour='';
      this.setState({values:values.additional,error:'',open:false})
      if(values.additional.length>0){
      for(let i=0;i<values.additional.length;i++){
        for(let j=0;j<this.state.timeSlot.length;j++){
          if(parseInt(values.additional[i])===parseInt(this.state.timeSlot[j].id)){
      
            price=price+this.state.timeSlot[j].avg;
            if(parseInt(this.state.timeSlot[j].flexible)===0){
              if(hour===''){
      
                hour=this.state.timeSlot[j].hours;
              }
              if(hour>this.state.timeSlot[j].hours){
                hour=this.state.timeSlot[j].hours;
              }
          
            }
          }
        }
      }
      }
      
      if(hour && hour!==0){
        price=price+" (Based on "+ hour +" hours)"
      }
      this.setState({price:price})
    }
    
      handlePreviousMonth() {
        this.setState({
            moment: this.state.moment.subtract(1, 'M'),
        });
        this.setState({
          startdate: this.state.moment.toDate(),
      });
        const month = this.state.moment.month() + 1;
        const year  = this.state.moment.year();
        localStorage.setItem('moment',this.state.moment);
        this.props.getPlannerCalendarData(this.props.match.params.spaceId, month, year)
    }
    
    handleNextMonth() {
        this.setState({
            moment: this.state.moment.add(1, 'M'),
        });
        this.setState({
          startdate: this.state.moment.toDate(),
      });
        const month = this.state.moment.month() + 1;
        const year = this.state.moment.year();
        localStorage.setItem('moment',this.state.moment);
        this.props.getPlannerCalendarData(this.props.match.params.spaceId, month, year)
    }
    
    handleToday() {
        this.setState({
            moment: moment(),
        });
    }
    handlMonthSelect(date) {
      this.setState({startdate:date})
      const selectedMonth=(date.getMonth())
      const fullyear=date.getFullYear();
      const addMonth=selectedMonth-this.state.moment.month()
      this.setState({
        moment: this.state.moment.set({'year': fullyear}),
    }); 
     this.setState({
      moment: this.state.moment.add(addMonth, 'M'),
  }); 
        this.props.getPlannerCalendarData(this.props.match.params.spaceId, this.state.moment.month()+1, fullyear)
        this.props.getPlannerCalendarData(this.props.match.params.spaceId, this.state.moment.month()+1, fullyear)

    }

    updatePlacehold=()=>{
  
        if(this.state.values.length>0){
          this.setState({open:true,openCalendarPopup:false})
        
        }
        else{
          this.setState({error:"Select preferred time(s)"})
        
        }
        
        
    }

    updatePlaceholdClose=()=>{
      this.setState({open:false})
    
    }

    handleCloseCalendar = () => {
          this.setState({ openCalendarPopup: false });
    };

    localUpdate=(eventData)=>{

            // let arr=this.state.eventGroup;
            let obj={};
            
            
            
            // let time_len=this.state.timeSlot;
            let days=this.state.days;
            obj.name=eventData.event.name;
            obj.planner_id=eventData.event.event_planner.id;
            const date = new Date(eventData.date); 
            const month = date.toLocaleString('default', { month: 'long' });
            var day = date.getDate();
            var year=date.getFullYear();
            var week_day= weekday[date.getDay()];
            obj.date=month.substring(0,3) + ' '+day+ ' ' + year;
            obj.id=eventData.id;
            let day_count;
            let array=[];
            if(week_day==="monday"){
              day_count=0;
              array=days[0].id;
            
            }
            else if(week_day==="tuesday"){
              day_count=1;
              array=days[1].id;
            
            }
            else if(week_day==="wednesday"){
              day_count=2;
              array=days[2].id;
            
            }
            else if(week_day==="thursday"){
              day_count=3;
              array=days[3].id;
            
            }
            else if(week_day==='friday'){
              day_count=4;
              array=days[4].id;
            
            }
            else if(week_day==='saturday'){
              day_count=5;
              array=days[5].id;
            
            }
            else if(week_day==="sunday"){
              day_count=6;
              array=days[6].id;
            
            }
              if(eventData.hold_rank + 1 > this.props.venue.max_hold_limit){
              obj.available="No hold available.";
              }
              else
              {
              obj.available= ordinal(eventData.hold_rank + 1) + " hold available";
              }
              obj.holdStatus= ordinal(eventData.hold_rank) + " hold";
              obj.bookingType=eventData.hold_type;
            
              if(parseInt(eventData.hold_type)===1 && parseInt(eventData.event.event_planner.id) === parseInt(localStorage.getItem('id')) && parseInt(days[day_count].count)===2)
            {
                  if(parseInt(eventData.timeslot_id)===parseInt(array[0])){
                   obj.eventClasses='holdClassRight'; 
                  }
            
                  else if(parseInt(eventData.timeslot_id)===parseInt(array[1])){
                    obj.eventClasses='holdClassLeft'; 
                  }
              }
            
              else 
                if(parseInt(eventData.hold_type)===1 
                  && parseInt(eventData.event.event_planner.id) === parseInt(localStorage.getItem('id'))
                  && parseInt(days[day_count].count)===1){
            
                  obj.eventClasses='holdClassSingle';
              }
              
             else if(parseInt(eventData.hold_type)===1 
                && parseInt(eventData.event.event_planner.id)=== parseInt(localStorage.getItem('id')) && 
                parseInt(days[day_count].count===4)){
                  if(parseInt(eventData.timeslot_id)===parseInt(array[0])){
                    obj.eventClasses='holdClass';
                  }
                  else if(parseInt(eventData.timeslot_id)===parseInt(array[1])){
                    obj.eventClasses='holdClassOne4';
            
                  }
                  else if(parseInt(eventData.timeslot_id)===parseInt(array[2])){
                    obj.eventClasses='holdClassTwo4';
            
                  }
                  else if(parseInt(eventData.timeslot_id)===parseInt(array[3])){
                    obj.eventClasses='holdClassThree4';
            
                  }
              }    
            
              else if(parseInt(eventData.hold_type)===1 
                && parseInt(eventData.event.event_planner.id) === parseInt(localStorage.getItem('id')) && 
                parseInt(days[day_count].count)===3){
                  if(parseInt(eventData.timeslot_id)===parseInt(array[0])){
                    obj.eventClasses='holdClass3';
                  }
                  else if(parseInt(eventData.timeslot_id)===parseInt(array[1])){
                    obj.eventClasses='holdClassOne3';
            
                  }
                  else if(parseInt(eventData.timeslot_id)===parseInt(array[2])){
                    obj.eventClasses='holdClassTwo3';
            
                  }
              }    
              
             else if(parseInt(eventData.hold_type)===4 &&
               parseInt(days[day_count].count===2)){     
                if(parseInt(eventData.timeslot_id)===parseInt(array[0])){
                  obj.eventClasses='blockClassLeft';
                } 
            
                else{
                  obj.eventClasses='blockClassRight';
            
                }
              }
              else if(parseInt(eventData.hold_type)===4){
                obj.eventClasses='blockClass';
              }
              else{
              obj.eventClasses='blankClass';
              }
            
            
            obj.description=" ";
            obj.title='';
            obj.start=eventData.date;
            obj.end=eventData.date;
            obj.timeslotID=eventData.timeslot_id;
            
            if(eventData.timeslot.event_types){
            for(let i=0;i<eventData.timeslot.event_types.length;i++)
            {
            for(let j=0;j<eventData.timeslot.event_types[i].pivot.weekdays.length;j++){
            if(date.getDay().toString()===eventData.timeslot.event_types[i].pivot.weekdays[j].toString()){
            obj.startTime=eventData.timeslot.start_time;
            obj.endTime=eventData.timeslot.end_time;
            obj.eventType=eventData.timeslot.event_types[i].name;
            obj.price=eventData.timeslot.event_types[i].pivot.base_rate;
            obj.additionalPrice=eventData.timeslot.event_types[i].pivot.overage_rate;
            break;
            }
            }
            }
            }
            
            eventGroup.push(obj);
            
            // let fd = eventGroup.filter( (ele, ind) => ind === eventGroup.findIndex( elem => elem.timeslotID == ele.timeslotID && elem.date === ele.date))
            
            
            this.setState({eventGroup:eventGroup})
            
    }

    contactClick = values => {
    const spaceId = this.props.match && this.props.match.params && this.props.match.params.spaceId
      const formData = {
        space_id: spaceId,
        message:values
      }
      this.props.contactData(formData,this.props.venue.id).then(response => {
        
      })    
    };
    
    followDataUpdate=(event,new_event)=>{
            this.setState({followData:[]});
          
            if(new_event==='new added'){
              this.setState({new_event:true});
              this.setState({followData:event});
          
            }
            else{
              this.setState({new_event:false});
          
              this.setState({followData:event});
            }
    }

    dateList=(data)=>{
      this.setState({date:data})
    }
    releaseHold = (id,rank) => {
    
      this.setState({releaseHoldRank:rank,releaseHoldId:id,releaseState:true})
      
    };

    releaseholdClick=()=>{
      this.props.deleteEvent(this.state.releaseHoldId).then(response => {
        let arr=this.state.eventGroup;
        let updateArr=[];
        let arr1=this.state.date;
    
        for(let i=0;i<arr.length;i++){
          let match=false;
          for(let j=0;j<this.state.date.length;j++)
          {
            if(parseInt(arr[i].id)===parseInt(arr1[j].id)){
          match=false;
    
    
            }
            else{
              match=true;
            }
          }
            if(match===true)
            {
              updateArr.push(arr[i]);
      
            }
          }
          // let fd = updateArr.filter( (ele, ind) => ind === updateArr.findIndex( elem => elem.timeslotID == ele.timeslotID && elem.date === ele.date))
    
            this.setState({eventGroup:updateArr})
      })
      this.setState({releaseState:false})
    }    
    
    calendarAddData=(values)=>{
      let eventData=values.holeEventDetails.data;
      let max_hold=this;
    for(let i=1;i<eventData.length;i++){
      let obj={};
    
      const date = new Date(eventData[i].date);  
      const month = date.toLocaleString('default', { month: 'long' });
      var day = date.getDate();
      var year=date.getFullYear();
      // var week_day= weekday[date.getDay()];
      obj.date=month.substring(0,3) + ' '+day+ ' ' + year;
      obj.name=eventData[i].event.name;
      obj.id=eventData[i].id;
      if(eventData[0].hold_rank + 1 > max_hold.props.venue.max_hold_limit){
        obj.available="No hold available.";
        }
        else
        {
        obj.available= ordinal(eventData[0].hold_rank + 1) + " hold available";
        }
        obj.holdStatus= ordinal(eventData[0].hold_rank) + " hold";
    
      obj.bookingType=eventData[0].hold_type;
      obj.startTime=eventData[i].event.start_time;
      obj.endTime=eventData[i].event.end_time;
      obj.description=" ";
      obj.title='';
      obj.start=eventData[i].event.start_date;
      obj.end=eventData[i].event.end_date;
      obj.timeslotID=eventData[i].timeslot_id;
    
      if(eventData[i].timeslot.event_types){
        for(let k=0;k<eventData[i].timeslot.event_types.length;k++)
        {
          for(let j=0;j<eventData[i].timeslot.event_types[k].pivot.weekdays.length;j++){
            if(date.getDay().toString()===eventData[i].timeslot.event_types[k].pivot.weekdays[j].toString()){
              obj.eventType=eventData[i].timeslot.event_types[k].name;
              obj.price=eventData[i].timeslot.event_types[k].pivot.base_rate;
              obj.additionalPrice=eventData[i].timeslot.event_types[k].pivot.overage_rate;
              break;
          }
        }
        }
      }
      eventGroup.push(obj);
    }
    this.setState({eventGroup:eventGroup})
    
    }

    openLoginDailog = ()=>{
      this.setState({loginFlag:true});
    }

    closeLoginDailog = ()=>{
      this.setState({loginFlag:false});
    }
    closeWindow = ()=>{
      document.getElementsByClassName("widget_close_btn")[0].click();
    }
        
    render() {    
      const t=this;
      const { classes } = this.props;
      // const param = this.props.match.param;
      // let urlpatharr = this.props.location.pathname.trim('/').split('/')
      const { mobileMoreAnchorEl } = this.state;
      const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);
      // function matchSearchVal(state, value) {
        // return (
        //   state.name.toLowerCase().indexOf(value.toLowerCase()) !== -1 ||
        //   state.abbr.toLowerCase().indexOf(value.toLowerCase()) !== -1
        // );
      // }
      const renderMobileMenu = (
        <Menu
          anchorEl={mobileMoreAnchorEl}
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          transformOrigin={{ vertical: 'top', horizontal: 'right' }}
          open={isMobileMenuOpen}
          onClose={this.handleMenuClose}
        >
          <MenuItem className="mob-btn-red">

          <SeperatePlceHold
                        selectedDate={this.state.selectedDate}
                        handleMobileMenuClose={this.handleMenuClose}
                        localUpdate={this.localUpdate}
                        message={this.props.messageContact} 
                        sendData={this.props.contactDetails} 
                        contactClick={this.contactClick} 
                        calendar_enabled={this.state.space.calendar_enabled}
                        space={this.state.space}
                        dateList={this.dateList} 
                        release={true} 
                        cityVal={this.props.venue && this.props.venue.address}
                        updatePlacehold={this.updatePlacehold} 
                        updatePlaceholdClose={this.updatePlaceholdClose}
                        open={this.state.open} 
                        followDataUpdate={this.followDataUpdate} 
                        label="Place Hold" 
                        openLoginDailog={this.openLoginDailog}
                        releaseHold={this.releaseHold} 
                        venueName={this.props.venue && this.props.venue.name} 
                        calendarData={this.calendarAddData}
                        closeWindow={this.closeWindow}/>    
         </MenuItem>
          <MenuItem className="mob-btn-white">
          <SeperateFollowDate 
                           handleMobileMenuClose={this.handleMenuClose}
                            message={this.props.messageContact} 
                            sendData={this.props.contactDetails} 
                            contactClick={this.contactClick}  
                            calendar_enabled={this.state.space.calendar_enabled} 
                            followData={this.state.followData} 
                            newEvent={this.state.new_event}
                            openLoginDailog={this.openLoginDailog}
                            venueName={this.props.venue && this.props.venue.name} 
                            className="follow_button" variant="outlined" classes={{ root: classes.root }} spaceId={this.props.match.params.spaceId} label="Follow Date"
                            closeWindow={this.closeWindow}/>
          </MenuItem>
          <MenuItem className="mob-contact-btn">
          <SeperateContactInfo 
          calendar_enabled={this.state.space.calendar_enabled} 
          handleMobileMenuClose={this.handleMenuClose}
           message={this.props.messageContact} sendData={this.props.contactDetails} 
           contactClick={this.contactClick} label="Contact" className="contactBtn" openLoginDailog={this.openLoginDailog}  />
          </MenuItem>
          {(localStorage.getItem('type') && localStorage.getItem('type').toString() === '1') ?
          (localStorage.getItem('fhtoken') === null)?
          <MenuItem component={Link} to="/" onClick={()=>{this.handleMobileMenuClose(); this.openListSpaceLink()}}><p>List Your Space</p></MenuItem>
          :
          <MenuItem component={Link} to="/myVenue" onClick={this.openListSpaceLink}>
            <p>My Venues</p>
          </MenuItem>
          :
          <MenuItem component={Link} to="/myEvents" onClick={this.handleMobileMenuClose}><p>My Events</p></MenuItem>
          }
          <MenuItem  component={Link} to="/logout"  onClick={this.handleMobileMenuClose}>
              <p>Logout</p>
          </MenuItem>
        </Menu>
      )
  
        return (
          <Grid container className="widget widgetMobile" >
       <div id="bt" className={`${classes.signupDiv} event-header`}>
        <AppBar className={classes.appbar}>
          <Toolbar className="header-div widget-header">
            <Grid item xs={12}>
              <Grid container alignContent="center" className="topEventNav Notify">
                <Grid item md={5} xs={10} sm={5} xl={6} lg={6}>
                  <Grid container justify="center" className="logo_and_search" style={{display:'block',paddingTop:'10px'}}>
                    {/* <Grid item xs={2} sm={3} md={2} lg={2} xl={2}>
                      <div className="toplogoDiv widgetImg">
                        <Link to='/'>
                          <img className={classes.image} src={require('./../assets/images/logo.png')} alt="FIRST-HOLD" />
                        </Link>
                      </div>
                    </Grid> */}
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12} >
                      <div className="topSearchDiv">
                        <div className="headesearch_container" style={{marginTop:'0',marginLeft:'20px'}}>
                       <b>{this.props.venue.name}</b>
                    <p>{this.state.space.name}</p>

                      </div>
                      </div>
                    </Grid>
                  </Grid>
                </Grid>


                <Grid item md={7} xs={2} sm={7} xl={6} lg={6}>
                  <div className={classes.sectionDesktop}>
                    <div className="topEventNav" style={{justifyContent:'flex-end'}}>
                      <MenuItem
                        className="topEventMenu"
                        classes={{ selected: classes.selected }}
                        disableGutters={true}
                      >

                   <SeperatePlceHold     
                        selectedDate={this.state.selectedDate}
                        localUpdate={this.localUpdate}
                        message={this.props.messageContact} 
                        sendData={this.props.contactDetails} 
                        contactClick={this.contactClick} 
                        calendar_enabled={this.state.space.calendar_enabled}
                        space={this.state.space}
                        dateList={this.dateList} 
                        cityVal={this.props.venue && this.props.venue.address}
                        updatePlacehold={this.updatePlacehold} 
                        updatePlaceholdClose={this.updatePlaceholdClose}
                        open={this.state.open} 
                        followDataUpdate={this.followDataUpdate} 
                        label="Place Hold" 
                        venueName={this.props.venue && this.props.venue.name} 
                        releaseHold={this.releaseHold} 
                        openLoginDailog={this.openLoginDailog}
                        closeWindow={this.closeWindow} 
                        calendarData={this.calendarAddData}
                        holdButtonHideOnLoad={this.state.days}/>                       
                         </MenuItem>
               
                        <MenuItem className="topEventMenu" 
                        classes={{ selected: classes.selected }} 
                        disableGutters={true}
                      >
                       <SeperateFollowDate 
                       message={this.props.messageContact} 
                       sendData={this.props.contactDetails} 
                       contactClick={this.contactClick}  
                       calendar_enabled={this.state.space.calendar_enabled} 
                       followData={this.state.followData} 
                       newEvent={this.state.new_event}
                       openLoginDailog={this.openLoginDailog}
                       venueName={this.props.venue && this.props.venue.name} 
                       className="follow_button" variant="outlined" classes={{ root: classes.root }} spaceId={this.props.match.params.spaceId} label="Follow Date" style={{marginLeft:'0px'}}
                       closeWindow={this.closeWindow}/>
                        </MenuItem>
                        
                       <MenuItem
                            classes={{ selected: classes.selected }} 
                            disableGutters={true} 
                            >
                       <SeperateContactInfo 
                       calendar_enabled={this.state.space.calendar_enabled} 

                       message={this.props.messageContact} 
                       sendData={this.props.contactDetails} 
                       contactClick={this.contactClick} 
                       venueProfile={this.props.venue} 
                       spaceId={this.props.match.params.spaceId} 
                       spaceInfo={this.state.space}
                       openLoginDailog={this.openLoginDailog}
                       />
                       </MenuItem>
                    </div>
                    <IconButton color="inherit">
                    {localStorage.getItem('fhtoken')!==null && parseInt(localStorage.getItem('type'))===0 ?
                    this.props.userData.data && this.props.userData.data.profilePhoto ?
                                      <RightSideDropMenu profilePhoto={this.props.userData.data && this.props.userData.data.profilePhoto.path}/> 
                                      :                                      
                                      <RightSideDropMenu profilePhoto={logo}/> 
                            :null
                                  }
                    </IconButton>
                  </div>
                  <div className={classes.sectionMobile}>
                    <IconButton aria-haspopup="true" onClick={this.handleMobileMenuOpen} color="inherit">
                      <Icon>more_vert</Icon>
                    </IconButton>
                  </div>
                </Grid>
              
    
              </Grid>
            </Grid>
          </Toolbar>
        </AppBar>
        {renderMobileMenu}

      </div>
                {/*this.state.releaseState === true ? 
                  <Grid container style={{paddingTop:'15px',marginTop:'35px'}}>                    
                    <div style={{display:'flex', width:'100%',justifyContent:'center',paddingTop:'60px'}}>
                    <p style={{marginRight:'10px' , paddingTop:'10px'}}> You have {this.ordinal_suffix_of(this.state.releaseHoldRank)} Hold</p>
                    <CustomizedButton  
                    label="Release Hold"
                    width="165px"
                    height="36px"
                    variant="outlined"
                    onClickEvent={this.releaseholdClick}
                    />
                    </div>
                  </Grid>
                  :
                  null*/ }
                
                 { this.state.releaseState === true && 
                  <Grid container  alignItems="center" justifyContent="center" style={{paddingTop:'15px',marginTop:'35px'}}>  
                    <div style={{display:'flex', width:'100%',justifyContent:'center',paddingTop:'60px'}}>
                    <CustomizedButton  
                      label="My Events"
                      width="165px"
                      height="36px"
                      variant="outlined"
                      onClickEvent={()=>{window.open('/myEvents','_blank');}}
                    />
                    </div>
                  </Grid>
                 }

                {this.state.days&& <h3 className="h3" style={this.state.releaseState===true?{marginTop:'50px'}:null}>Availability Calendar</h3>}

                {this.state.days&&<Grid style={{paddingTop:'0px',margin:' 75px 30px auto'}} className="calendar" item xs={12} 
                 onMouseDown = { (e) => { this.getMouseCoordinate(e) }} >
            
                       <div className="button-calendar">
                       {new Date() > this.state.moment?
                     <Button style={{color:'grey'}}>&lt;</Button>
                          :
                     <Button onClick={this.handlePreviousMonth}>&lt;</Button>
                          } 

                          <div>                         
                             <DatePicker
                               minDate={this.state.MIN_Date}
                                selected={this.state.startdate}
                                onChange={(date) => this.handlMonthSelect(date)}
                                className='today'  
                                name='start' 
                                dateFormat="MMMM yyyy"
                                showMonthYearPicker
                                showFullMonthYearPicker               
                                />
                           </div>                   
                  <Button onClick={this.handleNextMonth}>&gt;</Button>
                      </div>
                          <EventCalendar
                          makeToken={this.state.makeToken}
                          month={this.state.moment.month()}
                          year={this.state.moment.year()}
                          events={this.state.eventGroup}
                          onEventClick={this.onClickCalendar}
                          onDayClick={this.onClickCalendar}
                          weekOff = {this.state.weekOff}
                           />

              </Grid>}             
                 
              {this.state.openCalendarPopup===true?
                        <Grid container justify = "center"
                        className = "event_calendar" >
                        <Grid item className = "event_info_popup"  style = {{left: (this.state.xCoordinate !== '' && this.state.xCoordinate),
                          top: (this.state.yCoordinate !== '' && this.state.yCoordinate)}}>
                          <Grid className = "event_date" > 
                          {this.state.day} 
                          <DialogActions className="dialogActions calendar-close">
                          <span className="material-icons close closeButton" onClick={()=>this.handleCloseCalendar()}>close</span>
                          </DialogActions>
                          </Grid>
                        <Grid className = "event_type_heading" style={{paddingTop:'15px'}} ></Grid>  
                          
                          <Grid className = "time_heading" >
                          <p style={{display:'inline-block'}}>Time(s) Available </p>
                          {
                            parseInt(this.state.space.calendar_enabled)===1 &&
                            <p  style={{display:'inline-block',float:'right'}}>Hold Position</p>
                          }
                          </Grid> 
                          <Grid className = "event_time" > 
                          <Formik
                          initialValues={{additional:[],calendar_enabled:this.state.space.calendar_enabled}}
                          onSubmit={(values, actions) => {
                            this.checkClick(values, actions)
                          }}
                          render={(formikProps,values) => (
                           <Form>
                         {this.state.daySlot.length===0
                              ?
                              "No Timeslot Available"
                              :
                            <FieldArray
                            name="additional"
                            render={arrayHelpers => (
                              this.state.daySlot.map(function(data, idx) { 
                                return(      
                                  <div>
                            <CustomCheckBox
                            title="venueProfile"
                            onClick={t.handleChangeBox}
                            name={data.id}
                            label={data.time}
                            arrayHelpers={arrayHelpers}
                            handleChange={formikProps.handleChange}
                            checkboxGroup={formikProps.values.additional}
                  
                          />   
                          { parseInt(formikProps.values.calendar_enabled)===1 &&                         
                            <p style={{display:'inline-block',marginLeft:'20px'}}>{!data.position?'1st' : data.position} </p>
                          }
                          </div>
                                )
                            })
                            )}
                            />
                            }
                              <CustomizedButton
                                        height='24px'
                                        width='80px'
                                        label="Done"
                                        type="submit"
                                        id="SaveData"
                                        visibility="hide"
                                         />
                            </Form>
                           )} />
                 
                          </Grid>
                          {this.state.price!==''?
                          <div>
                                <Grid className = "event_price_heading" > Prices start at</Grid> 
                                <Grid className = "event_price" > 
                                {this.state.price.toString()==='0 (Based on 2 hours)' || this.state.price.toString() ==='0'?'N/A':"$"+this.state.price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} 
                                <p style={{padding: '0px',margin: '0px',fontSize: '10px'}}>*All prices subject to approval from venue</p>
                                </Grid> 
                          </div>:null
                          }

                          <Grid className = "event_price_venue" style={{textAlign:'right'}}> 
                          {this.state.holdStatus==='No hold available.'?
                          null
                          : 
                          <div>
                          <CustomizedButton 
                          label={parseInt(this.state.space.calendar_enabled)===0?"Contact Venue":'Place Hold'}
                          width="165px"
                          height="36px"
                          variant="outlined"
                          onClickEvent={(e)=>this.updatePlacehold?this.updatePlacehold():null}
                          />
                          <p style={{color:'red',fontSize:'12px'}}>{this.state.error}</p>
                          </div>
                          }
                          </Grid> 
                          </Grid >
                       </Grid>
                       :
                       null
              }
              
              {
                this.state.loginFlag && <LoginDialogs venueName={this.props.venue.name} venueId={this.state.venueId} closeLoginDailog={this.closeLoginDailog} />
              } 
             </Grid>
            )
    }
}

SeperateCalendar.propTypes = {
  classes: object.isRequired,
  spaceProfile: func.isRequired,
  contactData:func.isRequired,
  venueProfile: func.isRequired,
  deleteEvent:func.isRequired,



};


const mapStateToProps = state => {
    return {
      contactDetails : state.data.contactData.sending,
      messageContact: state.data.contactData.contactInfo,  
      userData: state.data.usersVenue.userInfo,
      venue: state.data.venue.venueDetails,
      plannerCalendarData: state.data.venue.plannerCalendarData
    };
  };
const enhance = compose(
    withRouter,
    withStyles(styles),

    connect(mapStateToProps, dispatch =>
      bindActionCreators(
        {
          spaceProfile,
          contactData,
          userDetails,
          venueProfile,
          deleteEvent,
          getPlannerCalendarData
        },
        dispatch
      )
    )
  );
  
export default enhance(SeperateCalendar);
