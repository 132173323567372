import React from 'react';
import PropTypes from 'prop-types';

import CustomInput from '../../shared/components/CustomInput';
import CustomizedButton from '../../shared/components/customizedButton';

import { Formik,Field } from 'formik';
import { Grid, Hidden,Icon,Link } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { sendConfirmation, updateUser, getUser } from 'data/person/actions';
import { bindActionCreators, compose } from "redux";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
const styles = theme => ({
    icon: {
        marginLeft: '8px',
        paddingTop: '1px',
        color: '#b0b0b0',
        fontSize: '15px'
    },
    activeIcon: {
        marginLeft: '8px',
        paddingTop: '1px',
        color: '#1397e1',
        fontSize: '15px'
    },
    popper: {
        paddingLeft: '15px',
        paddingTop: '237px'
    },
    root: {
        boxShadow: '0px 4px 11px 4px rgba(23,0,19,0.14)',
        borderRadius: '6px'
    },
    typography: {
        width: '237px',
        height: '120px',
        fontSize: '14px',
        fontWeight: '500',
        fontStyle: 'normal',
        fontStretch: 'normal',
        lineHeight: '1.43',
        letterSpacing: 'normal',
        padding: '19px',
        color: '#1397e1',
        fontFamily: 'Raleway'
    }
});

function BasicInfo(props) {
    function onEmailDelete(e){
    props.handleSaveSecondaryUser(e.target.id)
    }
    let secondaryUserList=[]
    if(props.secondaryEmail===true)
    {
         secondaryUserList=props.secondaryUserList
    }
    // const { classes } = props;
    const emailVerify=()=>{
        let contact_type=0
        const formData = { contact_type: contact_type }
        props.sendConfirmation(formData)
          .then((response) => {
            if (response === 'Contact is verified already.') {
              props.history.push("/login")
            }
            else {
            //   if (contactType === 0) {
                //const Verifytype = new URLSearchParams(window.location.search).get("verify")?'true':'false'
                props.history.push(`/confirmEmail?verify=true`)
              // }
              // else if (contactType === 1) {
              //   this.props.history.push("/confirmPhone")
              // }
            }
          })
          .catch(error => { })
    }
    return (
        <div>
            {props.secondaryEmail!==true?
            <Grid container className="edit_profile_component">
                
                <Grid className="headings" item xs={12}>
                    <h4 className="form_name">Update Email</h4>
                </Grid>
                <Grid item xs={12}>
                    <Formik
                        initialValues={props.initialValues}
                        enableReinitialize
                        validationSchema={props.validationSchema}
                        onSubmit={(values, actions) => {
                            props.handleSubmit(values, actions)
                        }}
                        render={props => (
                            <form onSubmit={props.handleSubmit}>
                                <Grid container>
                                    <Hidden xsDown={true}>
                                        <Grid item sm={2} md={2} lg={2} xl={2}></Grid>
                                    </Hidden>
                                    <Grid item xs={12} sm={8} md={8} lg={8} xl={8}>
                                        { props.values.isEmailBlank==false &&
                                            <Grid container className="edit_profile_component_form">
                                                <Grid className="left_panel" item xs={12} sm={12} md={6} lg={6} xl={6}>
                                                    <div className="field_div">
                                                        <CustomInput disabled label="Current Email" type="email" name="currentEmail" placeholder="Current Email" />
                                                        {  props.values.emailVerfication ? 
                                                            <div className="verified" >
                                                                <Icon>check_circle</Icon> <p>Verified</p>
                                                            </div> 
                                                            :
                                                            <div className="verify-it" >
                                                                   {/* <Icon>cancel_circle</Icon> <p>Not Verified</p> */}
                                                                   <Icon>cancel_circle</Icon><p><Link  onClick={emailVerify}  style={{textDecoration:'none'}}>Not Verified</Link></p>
                                                            </div>
                                                        }
                                                    </div>
                                                </Grid>
                                                <Grid className="right_panel" item xs={12} sm={12} md={6} lg={6} xl={6}>
                                                    <div className="field_div">
                                                        <CustomInput label="New Email" type="email" name="newEmail" placeholder="New Email" />
                                                    </div>
                                                </Grid>                                          
                                            </Grid>
                                        }
                                        { props.values.isEmailBlank==true &&
                                            <Grid container className="edit_profile_component_form">
                                                <Grid className="left_panel" item xs={12} sm={12} md={6} lg={6} xl={6}>
                                                    <div className="field_div">                    
                                                        <CustomInput  label="Email" type="email" name="newEmail" placeholder="Email" />
                                                        {  props.values.emailVerfication? 
                                                            <div className="verified" >
                                                                <Icon>check_circle</Icon> <p>Verified</p>
                                                            </div>
                                                            :
                                                            <div className="verify-it" >
                                                                   <Icon>cancel_circle</Icon> <p>Not Verified</p>
                                                            </div>
                                                        }  
                                                    </div>
                                                </Grid>                          
                                            </Grid>
                                        }
                                    </Grid>
                                    <Grid className="save_button" item xs={12}>
                                        <Grid container justify='flex-end'>
                                            <Grid item>
                                                <CustomizedButton label="Save" type="submit" />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Hidden xsDown={true}>
                                        <Grid item sm={2} md={2} lg={2} xl={2}></Grid>
                                    </Hidden>
                                </Grid>
                            </form>
                        )}
                    />
                </Grid>
            </Grid>
            :
            <Grid container className="edit_profile_component">
                
            <Grid className="headings" item xs={12}>
                <h4 className="form_name">Secondary Email</h4>
            </Grid>
            <Grid item xs={12}>
                <Formik
                    initialValues={props.initialValues}
                    enableReinitialize
                    validationSchema={props.validationSchema}
                    onSubmit={(values, actions) => {
                        props.handleSubmit(values, actions)
                    }}
                    render={props => (
                        <form onSubmit={props.handleSubmit}>
                            <Grid container>
                                <Hidden xsDown={true}>
                                    <Grid item sm={12} md={2} lg={2} xl={2}></Grid>
                                </Hidden>
                                <Grid item xs={12} sm={12} md={12} lg={8} xl={8}>
                                    {/* { props.values.isSecondaryEmailBlank==false && */}
                                        <Grid container className="edit_profile_component_form  secondary-form">
                                        <Grid className="" item xs={12} sm={12} md={12} lg={12} xl={12}>
                                                <div className="field_div row-container-box">
                                               {secondaryUserList.length>0&&secondaryUserList.map(({ id,email}) => (
                                                <div className="div-alignment-box">
                                                    <Field placeholder="Select" className="emailField" name={email} value={email}  readOnly={true}/>
                                                    <div className="red_icon_div" >
                                                    <span id={email} className="material-icons delete" onClick={(e) => onEmailDelete(e)}>delete</span>
                                                </div>
                                           </div>
                                             ))
                                               }
                                                    {/* {  props.values.secondaryEmailVerfication ? 
                                                        <div className="verified" >
                                                            <Icon>check_circle</Icon> <p>Verified</p>
                                                        </div> 
                                                        :
                                                        <div className="verify-it" >
                                                               <Icon>cancel_circle</Icon> <p>Not Verified</p>
                                                        </div>
                                                    } */}
                                                </div>
                                            </Grid>
                                           
                                            {/* <Grid className="right_panel" item xs={12} sm={12} md={6} lg={6} xl={6}>
                                                <div className="field_div">
                                                    <CustomInput label="New Secondary Email" type="email" name="newSecondaryEmail" placeholder="New Email" />
                                                </div>
                                            </Grid>                                           */}
                                        </Grid>
                                    {/* } */}
                                    {/* { props.values.isEmailBlank==true &&
                                        <Grid container className="edit_profile_component_form">
                                            <Grid className="left_panel" item xs={12} sm={12} md={6} lg={6} xl={6}>
                                                <div className="field_div">                    
                                                    <CustomInput  label="Email" type="email" name="newSecondaryEmail" placeholder="Email" />
                                                    {  props.values.emailVerfication? 
                                                        <div className="verified" >
                                                            <Icon>check_circle</Icon> <p>Verified</p>
                                                        </div>
                                                        :
                                                        <div className="verify-it" >
                                                               <Icon>cancel_circle</Icon> <p>Not Verified</p>
                                                        </div>
                                                    }  
                                                </div>
                                            </Grid>                          
                                        </Grid>
                                    } */}
                                     <Grid className="left_panel" item xs={12} sm={12} md={6} lg={6} xl={6}>
                                                <div className="field_div">
                                                    <CustomInput  label="Add Secondary Email" type="email" name="currentSecondaryEmail" placeholder="Add Secondary Email" />
                                                    {/* {  props.values.secondaryEmailVerfication ? 
                                                        <div className="verified" >
                                                            <Icon>check_circle</Icon> <p>Verified</p>
                                                        </div> 
                                                        :
                                                        <div className="verify-it" >
                                                               <Icon>cancel_circle</Icon> <p>Not Verified</p>
                                                        </div>
                                                    } */}
                                                </div>
                                            </Grid>
                                </Grid>
                               
                                <Grid className="save_button" item xs={12}>
                                    <Grid container justify='flex-end'>
                                        <Grid item>
                                            <CustomizedButton disabled={secondaryUserList.length===10?true:false} label="Save" type="submit" />
                                            {secondaryUserList.length>=10&&
                                                <p class="small-text-box">You can not add more than 10 secondary emails.</p>
                                            }
                                        </Grid>
                                      
                                    </Grid>
                                </Grid>
                                <Hidden xsDown={true}>
                                    <Grid item sm={2} md={2} lg={2} xl={2}></Grid>
                                </Hidden>
                            </Grid>
                        </form>
                    )}
                />
            </Grid>
        </Grid>
}
        </div>
    );
}
BasicInfo.propTypes = {
    classes: PropTypes.object.isRequired,
};

const mapStateToProps = state => {
    return {
        // userData: state.data.usersVenue.userInfo
    };
  };
  const enhance = compose(withStyles(styles),
    connect(mapStateToProps, dispatch =>
        bindActionCreators(
            {
  
              sendConfirmation
            },
            dispatch
        )
    )
  );
  
  export default enhance(withRouter(BasicInfo));
  



