import React from 'react';
import { object,func } from 'prop-types';
import { withRouter } from "react-router-dom";
import { connect } from 'react-redux';
import { bindActionCreators,compose } from 'redux';
import CustomSelect from "../../shared/components/CustomSelect";
import {Formik, Field,FieldArray,Form } from 'formik';
import { Grid, Button } from '@material-ui/core';
import '../assets/styles/spaceProfileEditComponent.scss';
import { spaceDocumentUpload } from 'data/spaces/actions';
import { venueDocumentUpload } from 'data/venues/actions';
import { getUploadPostUrl, uploadtoS3} from 'data/master/actions';
import CustomizedCheckBox from '../../shared/components/CustomizedCheckbox';
import CustomizedButton from '../../shared/components/customizedButton';
import { getSpace } from 'data/spaces/actions';
import CustomCheckBox from "../../shared/components/CustomCheckBox";
const FILE_SIZE = 1024 * 1024 * 10;
class AddPdfComponent extends React.Component {
constructor(props) {
super(props);
this.handleAddPdfClick = this.handleAddPdfClick.bind(this);
}
state={
error:'',
error_size:'',
showList:false,
state:[],
pdfDatalen:this.props.pdfData && this.props.pdfData.length,
copyClicked:false,
selectLinkError:'',
spaceName:'',
spaceId:'',
noLink:false,
}
componentWillMount(){
if(this.props.venueDetails && this.props.venueDetails.spaces.length>0){
let space=[];
let prop=this;
this.props.venueDetails && this.props.venueDetails.spaces.map(function(data,id){
if(parseInt(prop.props.id)!==parseInt(data.id)){
space.push({name:data.name,value:data.id})
}
return space;
})
this.setState({spaces:space}) 
}
}
componentWillReceiveProps(nextProps){
if(this.props.pdfData!==nextProps.pdfData){
this.setState({pdfDatalen:nextProps.pdfData && nextProps.pdfData.length})
}
if(this.props.venueDetails !==nextProps.venueDetails)
{
if(nextProps.venueDetails && nextProps.venueDetails.spaces.length>0){
let space=[];
nextProps.venueDetails && nextProps.venueDetails.spaces.map(function(data,id){
if(parseInt(nextProps.id)!==parseInt(data.id)){
space.push({name:data.name,value:data.id})
}
return space;
})
this.setState({spaces:space}) 
}
}
}
handleAddPdfClick() {
var link = document.getElementById('document');
link.click();
}
handleChangeBox=(e)=>{
this.setState({copyClicked:false})
if(e.target.value.toString()==='false'){
this.setState({showList:true})
}
else{
this.setState({showList:false,selectLinkError:'',documents:[],error:'',noLink:false,spaceId:''})
}
}
finalcopy=(values,setFieldValue)=>{
let prop=this;
let pdflen=this.props.pdfData && this.props.pdfData.length;
for(let j=0;j<values.length;j++)
{
for(let i=0;i<this.state.documents.length;i++){
if(parseInt(values[j])===parseInt(this.state.documents[i].id)){
let document_formData = new FormData();
document_formData.append('name',this.state.documents[i].name);
document_formData.append('document',this.state.documents[i].name);
if(pdflen<10){
this.setState({pdfDatalen:prop.state.pdfDatalen+1})
pdflen=pdflen+1;
this.props.spaceDocumentUpload(document_formData,this.props.id)
.then((res) => {
if(parseInt(j)===parseInt(values.length-1)){
this.props.handleDocumentUploaded() 
}
})
}
else{
this.setState({pdfDatalen:10})
this.props.handleDocumentUploaded();
break;
}
}
}
} 
if(this.state.documents.length!=0&&values.length!=0)
{
setFieldValue('copySpace', false);
setFieldValue('additional', []);
setFieldValue('space', '');
this.setState({selectLinkError:'',noLink:false,spaceId:''})
this.setState({ copyClicked: false });
if (this.props.copyFalse) {
this.props.copyFalse();
}
}
else
{
this.setState({selectLinkError:"*Please select PDF(s)."})
}
}
handleSubmit=(values)=>{
if(values.space && values.space!==''){
let spaceList=this.state.spaces
this.setState({spaceId:values.space})
this.setState({error:"",error_size:'',copyClicked:false})
if(this.props.copy){
this.props.copy()
}
this.props.getSpace(values.space).then((response) => {
if(response.documents.length>0){
let checkSelectSpace=spaceList.filter(data => data.value.toString()===values.space);
this.setState({showList:false,copyClicked:true,documents:response.documents,spaceName:checkSelectSpace[0].name,noLink:false})
}
else
{
this.setState({error:"*No Documents Found.",noLink:true})
}
})
.catch(error => {})
}
else{
this.setState({error:"*Please select space."})
}
}
onDocumentChange = (event,setFieldValue) => {
this.setState({error:'',error_size:'',copyClicked:false})
setFieldValue('upload_document','');
let document_formData = new FormData();
let document_file=event.currentTarget.files[0];
if(event.target.files[0])
{
if (!event.target.files[0].name.match(/.(pdf)$/i)) {
this.setState({error_size:'*Unsupported Format. Only pdf files will be accepted'})
}
else if(event.target.files[0].size >= FILE_SIZE)
{
this.setState({error_size:'*File size should not be greater than 10MB.'})
}
else{
if(this.props.type.toString()==='VENUE') {
document_formData.append('name', event.currentTarget.files[0].name);
this.props.getUploadPostUrl(2,event.currentTarget.files[0].name).then((res) => {
document_formData.append('document', res.keyName);
this.props.uploadtoS3(res.url, document_file).then( ()=>{
this.props.venueDocumentUpload(document_formData, this.props.id)
.then((res) => {
this.setState({pdfDatalen:this.state.pdfDatalen+1})
this.props.handleDocumentUploaded(res)
})
.catch(error => {})
})
})
} else {
document_formData.append('name', event.currentTarget.files[0].name);
this.props.getUploadPostUrl(2,event.currentTarget.files[0].name).then((res) => {
document_formData.append('document', res.keyName);
this.props.uploadtoS3(res.url, document_file).then( ()=>{
this.props.spaceDocumentUpload(document_formData, this.props.id)
.then((res) => {
this.setState({pdfDatalen:this.state.pdfDatalen+1})
this.props.handleDocumentUploaded(res.payload)
})
.catch(error => {})
})
})
}
}
}
}
render() {
return (
<Grid container >
    <Formik
        initialValues={{copySpace:false,additional:[],space:''}}
        onSubmit={(values, actions) =>
    {
    this.handleSubmit(values)
    }}
    render={(props,formikProps,arrayHelpers,setFieldValue) => (
    <Form>
        <div className="grid-section-vertical">
            <h3 style={{fontWeight:'bold',marginBottom:'10px',color:'#62717a'}}>Documents (Max 10)</h3>
            {this.state.pdfDatalen > 9
            ?
            <div>
                <p>Max limit reached</p>
                <br />
            </div>
            :
            <div>
                <Button variant="outline-danger" class="white-button" onClick={this.handleAddPdfClick}>
                + Add PDF
                </Button>
                <div className="error">{this.state.error_size}</div>
                {this.props.name==='SPACE'
                ?
                this.state.spaces.length > 0?
                <CustomizedCheckBox 
                    name='copySpace' 
                    marginTop="20px"
                    value={props.values.copySpace} 
                    label="Copy PDF(s)"
                    handleChange={props.handleChange}
                    onChange={this.handleChangeBox} />
                :null
                :null}
                {(this.state.showList && this.state.spaces.length > 0) === true?
                <div>
                    <CustomSelect 
                        id="space"
                        name="space" 
                        value={props.values.space}
                        spaceData={this.state.spaces} 
                        placeholder="Select Space" color="#62717a" width="100%" />
                    <div className="error">{props.values.space!=''&&this.state.noLink===false?'':props.values.space!=this.state.spaceId?'':this.state.error}</div>
                    <CustomizedButton marginTop="10px" label="Select" type='submit' />
                </div>
                :null}
                {this.state.copyClicked===true ?
                <div >
                    {this.state.spaceName} PDF(s)
                    <FieldArray
                        name="additional"
                        render={arrayHelpers =>
                    (
                    this.state.documents.map(function(data, idx) { 
                    return( 
                    <div style={{paddingTop:'15px', borderTop:'1px solid #cccc', marginTop:'10px'}}>
                        <CustomCheckBox
                            title="venueProfile"
                            // onClick={t.handleChangeBox}
                            name={data.id}
                            label={data.name}
                            arrayHelpers={arrayHelpers}
                            handleChange={props.handleChange}
                            checkboxGroup={props.values.additional}
                            />
                    </div>
                    )
                    })
                    )}
                    /> 
                    <div className="error">{props.values.additional.length!=0?'':this.state.selectLinkError}</div>
                    <CustomizedButton marginTop="10px" label="Add" onClickEvent={()=>
                    this.finalcopy(props.values.additional,props.setFieldValue)} />
                </div>
                :
                null}
            </div>
            }
            <Field className="document_upload_file" name="upload_document" type="file" id="document" accept="image/png, image/jpeg, application/pdf, text/csv"
                onChange={e =>
            {
            this.onDocumentChange(e,props.setFieldValue)
            }} />
        </div>
    </Form>
    )}
    />
</Grid>
);
}
}
AddPdfComponent.propTypes = {
classes: object.isRequired,
getSpace:func.isRequired,
};
const mapStateToProps = state => {
return {
spaceData: state.data.spaces.spaceData,
};
};
const enhance = compose(
withRouter,
connect(mapStateToProps, dispatch =>
bindActionCreators(
{
getSpace,
spaceDocumentUpload,
venueDocumentUpload,
getUploadPostUrl,
uploadtoS3
},
dispatch
)
)
);
export default enhance(AddPdfComponent);