import React, { Component } from 'react';
import { withRouter } from "react-router-dom";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import moment from 'moment';

import { addSpace, updateSpace, updateSpaceName, getSpace,timeslotCopy,getSpaceTimeslots } from 'data/spaces/actions';
import { eventTypes, getArrangements } from 'data/master/actions';
import StepProgressBar from '../../../shared/components/StepProgressBar';
import AddSpaceName from '../components/addSpaceName';
import AddSpaceCapacity from '../components/addSpaceCapacity';
import AddSpaceAvailability from '../components/addSpaceAvailability';
import AddSpaceRates from '../components/addSpaceRates';
import { getVenue } from 'data/venues/actions';

import { Formik } from 'formik';
import * as Yup from 'yup';

import '../assets/styles/addSpace.scss';
// import { set } from 'immutable';

let step3ParamsLength = 0
let step4ParamsLength = 0
let formData = {}
let step2FormData = ''
let step3FormData = ''
let step4FormData = ''
let arr = [];
let submissionType = ''
let step4FixedHasError = false
let step4FlexibleHasError = false
let checkboxGroup = []
let step4Error = []
const Step1ValidationSchema = Yup.object().shape({
  spaceName: Yup.string()
    .required('*Required')
    .max(27,"Space name should not be more than 27 characters"),
});

const Step2ValidationSchema = Yup.object().shape({
  checkboxGroup: Yup.array()
    .required("*Select at least one checkbox"),
  arrangements: Yup
    .array()
    .of(
      Yup.object().shape({
        min: Yup.number()
          .typeError("*Must be a number")
          .when('enable', {
            is: true,
            then: Yup.number().typeError("*Must be a number").required('*Required')
            .max(999999999,"Minimum value should not be more than 10 characters")
          }),
        max: Yup.number().typeError("*Must be a number")
          .when('enable', {
            is: true,
            then: Yup.number().typeError("*Must be a number").required('*Required')
            .max(999999999,"Maximum value should not be more than 10 characters").moreThan(Yup.ref('min'), 'Should be greater than Minimum value')
          })
      })
    )
});

const Step3ValidationSchema = Yup.object().shape({
  spaceAvailabilityGroup: Yup.array()   
    .required("*Select fixed rental or flexible rental"),
  fixedRentalAvailability: Yup
    .array()
    .when("fixedValue", {
      is: true,
      then: Yup.array()
        .of(
          Yup.object().shape({
            startTime: Yup.string().required("*Required"),
            endTime: Yup.string().required("*Required")
            .test("is-greater", "End time should be greater than start time", function (value) {
              const compare=moment(value, "h:mm A").format("HH:mm:ss").toString()
              const { startTime } = this.parent;
              if(compare!=="02:00:00"&&compare!=="01:00:00"&&compare!=="00:00:00"&&compare!=="00:30:00"&&compare!=="01:30:00")
              {
              return moment(value, "HH:mm A").isAfter(moment(startTime, "HH:mm A"));
              }
              else{
                return true
              }
            })
            ,
            sunday: Yup.bool(),
            monday: Yup.bool(),
            tuesday: Yup.bool(),
            wednesday: Yup.bool(),
            thursday: Yup.bool(),
            friday: Yup.bool(),
            saturday: Yup.bool(),
          }).test(
            'myCustomCheckboxFixedTest',
            null,
            (obj) => {
              if (obj.sunday || obj.monday || obj.tuesday || obj.wednesday || obj.thursday || obj.friday || obj.saturday) {
                return true;
              }

              return new Yup.ValidationError(
                '*Check at least one checkbox',
                null,
                'myCustomCheckboxFixedTest'
              );
            }
          )
        )
    }),
  flexibleRentalAvailability: Yup
    .array()
    .when("flexibleRental", {
      is: true,
      then: Yup.array()
        .of(
          Yup.object().shape({
            startTime: Yup.string().required("*Required"),
            endTime: Yup.string().required("*Required"),
            multipleEvent: Yup.boolean().required("*Required"),
            minHour: Yup.string().required("*Required"),
            maxHour: Yup.string().when("showMaxHour",{
              is: true,
              then:Yup.string().required("*Required")
            }),
            timeBetweenEvent: Yup.string().when("multipleEvent",{
              is: true,
              then:Yup.string().required("*Required")
            }),
            endTime: Yup.string().required("*Required")
            .test("is-greater", "End time should be greater than start time", function (value) {
              const { startTime } = this.parent;
              const compare=moment(value, "h:mm A").format("HH:mm:ss").toString()
              if(compare!=="02:00:00"&&compare!=="01:00:00"&&compare!=="00:00:00"&&compare!=="00:30:00"&&compare!=="01:30:00")
              {
              return moment(value, "HH:mm A").isAfter(moment(startTime, "HH:mm A"));
              }
              else{
                return true
              }
            }),
            sunday: Yup.bool(),
            monday: Yup.bool(),
            tuesday: Yup.bool(),
            wednesday: Yup.bool(),
            thursday: Yup.bool(),
            friday: Yup.bool(),
            saturday: Yup.bool(),
          }).test(
            'myCustomCheckboxFlexibleTest',
            null,
            (obj) => {
              if (obj.sunday || obj.monday || obj.tuesday || obj.wednesday || obj.thursday || obj.friday || obj.saturday) {
                return true;
              }

              return new Yup.ValidationError(
                '*Check at least one checkbox',
                null,
                'myCustomCheckboxFlexibleTest'
              );
            }
          )
        )
    }),
  copyAvailableName: Yup
    .string()
    .when("copyAvailable", {
      is: true,
      then: Yup.string().required("*Required")
    })
});
const Step4ValidationSchema = Yup.object().shape({
  eventSpaceRatesArray: Yup
    .array()
    .of(
      Yup.object().shape({
        flatRate: Yup.string().when("pricingType", {
          is: (pricingType) => pricingType !== 0,
          then: Yup.string().required("*Required").matches(/^[ 0-9 $,]*$/,"*Invalid Venue Rental"),
        }),
        additionalFlatRate: Yup.string().when('hasAddPricingTypeName', {
          is: true,
          then: Yup.string().matches(/^[ 0-9 $,]*$/,"*Invalid Venue Rental"),

          // then: Yup.string().required("*Required456").matches(/^[ 0-9 $,]*$/,"*Invalid Venue Rental"),
        }),
        additionalPricingTypeName: Yup.string().when('hasAddFlatRate', {
          is: true,
          then: Yup.string().required("*Required"),
        }),
        additionalPricingTypeName:Yup.string().max(25, 'Only 25 characters allowed'),
        fnbRate: Yup.string("*F&B Minimum must be a string"),
        additionalRate: Yup.string().matches(/^[ 0-9 $,]*$/,"*Invalid Additional Rate"),
        days: Yup.array()
          .required("*Select at least one checkbox"),
        minGuest:  Yup.string().matches(/^[ 0-9]*$/,"Min guest must be a number").max(6,"Min Guest should be less than 1000000")
      })
    ),
  copyEventTypeAndPricingName: Yup
    .string()
    .when("copyEventTypeAndPricing", {
      is: true,
      then: Yup.string().required("*Required"),
    }),
  spaceRatesGroup: Yup.array().required('*Select atleast one event'),
});
class AddSpace extends Component {
  state = {
    step: 1,
    spaceName: '',
    formCompleted: false,
    step4Error: [],
    // spaceName: '',
    checkboxGroup: [],
    spaceAvailabilityGroup: [],
    sitdown: false,
    classroom: false,
    boardroom: false,
    theater: false,
    reception: false,
    tradeshow: false,
    preexisting: false,
    empty: false,
    sitdownMin: 100,
    classroomMin: 100,
    boardroomMin: 100,
    theaterMin: 100,
    receptionMin: 100,
    tradeshowMin: 100,
    preexistingMin: 100,
    emptyMin: 100,
    sitdownMax: 300,
    classroomMax: 300,
    boardroomMax: 300,
    theaterMax: 300,
    receptionMax: 300,
    tradeshowMax: 300,
    preexistingMax: 300,
    emptyMax: 300,
    submitAction: false,
    fixedValue: false,
    flexibleRental: false,
    copyAvailable: false,
    copyAvailableName: '',
    fixedRentalAvailability: [{ idx: 1, id: '', startTime: '', endTime: '', days: [], additionalCost: false, sunday: false, monday: false, tuesday: false, wednesday: false, thursday: false, friday: false, saturday: false }],
    flexibleRentalAvailability: [{ idx: 1, id: '', startTime: '', endTime: '', days: [], additionalCost: false, sunday: false, monday: false, tuesday: false, wednesday: false, thursday: false, friday: false, saturday: false, maxHour:'', minHour:'', showMaxHour:false, multipleEvent:1, timeBetweenEvent:'' }],
    spaceRatesGroup: [],
    eventTypesGroup: [],
    eventSpaceRatesArray: [],
    copyEventTypeAndPricing: false,
    copyEventTypeAndPricingName: '',
    pricingCategory: [],
    pricingCategories: {},
    anotherPricingVariable: { fixedCorporate: '', flexibleCorporate: '', fixedSocialEvent: '', flexibleSocialEvent: '', fixedNonProfitEvent: '', flexibleNonProfitEvent: '' },
    arrangements: [],
    arrangementsError: [],
    fixedRentalDaysArray: [],
    flexibleRentalDaysArray: [],
    fixedRentalAvailabilitySortedDays: [],
    flexibleRentalAvailabilitySortedDays: [],
    spaceList: [],
    copyTimeSlot: false,
    copyEventTypes: false,
    spaceId: '',
    isAddSpace: true,
    isFixedToggleOpen : false,
    anchorElFixed : null,
    isFlexibleToggleOpen: false,
    anchorElFlexible: null,
    venueName: '',
    deletedFixedSlotOnCopy: [],
    deleteFlexibleSlotOnCopy: [],
    // anchorElFixedAddOn: null,
    // isFixedAddonToggleOpen: false,
    addVariable:[],
    fixedEventPopper:[],
    flexibleEventPopper:[],
    exitclick:'',
    open:false,
    copyTargetSpaceId:'',
    copyAvailableTargetName:''
  }
  // toDataURL = url => fetch(url)
  // .then(response => response.blob())
  // .then(blob => new Promise((resolve, reject) => {
  //   const reader = new FileReader()
  //   reader.onloadend = () => resolve(reader.result)
  //   reader.onerror = reject
  //   reader.readAsDataURL(blob)
  // }))

  UNSAFE_componentWillMount() {
    if (this.props.match.params.step !== undefined) {
      this.setState({ step: parseInt(this.props.match.params.step) })
    }
    this.props.eventTypes().then((response) => {
      if (this.props.match.params.spaceId) {
        const spaceId = this.props.match.params.spaceId
        this.setState({ spaceId: this.props.match.params.spaceId, isAddSpace: false })
        this.props.getSpace(spaceId).then((response) => {
          if (response.venueId) {
            this.props.getVenue(response.venueId)
          }
        })
          .catch((errors) => {
            if (errors) {
              this.props.history.push('/myVenue')
            }
          }
          )
      }
    });

    this.props.getArrangements()
    if (this.props.match.params.venueId) {
      this.props.getVenue(this.props.match.params.venueId)
    }
  }
  UNSAFE_componentWillReceiveProps(nextProps) {
    let arrayValue = []
    let pricingCategoryValue = []
    let pricingCategoryError = []
    let pricingCategoriesValue = {}
    let timeSlotType = []
    let fixedRentalAvailability = []
    let flexibleRentalAvailability = []
    let eventSpaceRatesArray = []
    let spaceRatesGroup = []
    let pricingCategory = this.state.pricingCategory
    let spaceList = []
    let addVariable = []

    if (this.props.eventTypeList !== nextProps.eventTypeList && Object.keys(nextProps.eventTypeList).length !== 0) {
      Object.keys(nextProps.eventTypeList).map((events, i) => {
        arrayValue.push({ name: nextProps.eventTypeList[events].name, id: nextProps.eventTypeList[events].id })
        addVariable.push({fixed:{name:'',anchorEl:null, isToggleOpen: false, price:''}, flexible:{name:'',anchorEl:null, isToggleOpen: false, price:''}})
        // const fixed = `fixed${nextProps.eventTypeList[events].name}`
        // const flexible = `flexibleCorporate${nextProps.eventTypeList[events].name}`
        if (this.props.match.params.venueId) {
          pricingCategoryValue.push(['', ''])
          this.setState({ pricingCategory: pricingCategoryValue })
        }
        pricingCategoryError.push(['', ''])
        pricingCategoriesValue[`fixed`] = ['Venue Rental', 'Food & Bev Minimum', 'Venue Rental + Food & Bev Minimum', 'Per Person']
        pricingCategoriesValue[`flexible`] = ['Venue Rental', 'Food & Bev Minimum', 'Venue Rental + Food & Bev Minimum', 'Per Person']
        return pricingCategoriesValue;
      })
      step4Error = pricingCategoryError
      this.setState({ step4Error: pricingCategoryError, eventTypesGroup: arrayValue, pricingCategories: pricingCategoriesValue, addVariable: addVariable })

    }

    if (this.props.venueDetails !== nextProps.venueDetails && Object.keys(nextProps.venueDetails).length !== 0) {
      const  venueName = nextProps.venueDetails.code
      this.setState({venueName: venueName})
    }

    if (this.props.spaceData !== nextProps.spaceData && Object.keys(nextProps.spaceData).length !== 0 ) {
      let arrangementArray = this.state.arrangements
      let pricingCategoryArray = this.state.pricingCategory
      this.setState({ arrangements: [] })
      const spaceData = nextProps.spaceData
      this.props.getVenue(spaceData.venue_id)
      if(!this.state.copyAvailableName){
      this.setState({ spaceName: spaceData.name })
      spaceData.arrangements.map(arrangement => {
        arrangementArray.map((arrange, idx) => {
          if (arrangement.id === arrange.id) {
            arrangementArray[idx].min = arrangement.pivot.minimum
            arrangementArray[idx].max = arrangement.pivot.maximum
            arrangementArray[idx].enable = true
            if (!checkboxGroup.includes(arrangement.name)) {
              checkboxGroup.push(arrangement.name)
            }
          }
          return checkboxGroup;
        })
        return checkboxGroup;
      })
    }
      let days = []
      let flexibleDays = []
      let weekdays = []
      let fixedRentalDays = []
      let arr = []
      let array = []
      let fixedEventPopper = []
      let flexibleEventPopper = []
      spaceData.timeslots.map((timeslot, index) => {
        days = []
        fixedRentalDays =[]
        flexibleDays=[]
        if (timeslot.fixed_rental === 1) {
          this.setState({ fixedValue: true })
          if (timeslot.sunday === 1) {
            days.push('sunday')
            fixedRentalDays.push('sunday')
          }
          if (timeslot.monday === 1) {
            days.push('monday')
            fixedRentalDays.push('monday')
          }
          if (timeslot.tuesday === 1) {
            days.push('tuesday')
            fixedRentalDays.push('tuesday')
          }
          if (timeslot.wednesday === 1) {
            days.push('wednesday')
            fixedRentalDays.push('wednesday')
          }
          if (timeslot.thursday === 1) {
            days.push('thursday')
            fixedRentalDays.push('thursday')
          }
          if (timeslot.friday === 1) {
            days.push('friday')
            fixedRentalDays.push('friday')
          }
          if (timeslot.saturday === 1) {
            days.push('saturday')
            fixedRentalDays.push('saturday')
          }
          if (!timeSlotType.includes("fixedValue")) {
            timeSlotType.push("fixedValue")
          }
          fixedRentalAvailability.push({ id: timeslot.id, idx: index + 1, startTime: timeslot.start_time.substring(0, timeslot.start_time.length - 3), endTime: timeslot.end_time.substring(0, timeslot.end_time.length - 3), days: days, additionalCost: timeslot.addl_hours === 1 ? true : false, sunday: timeslot.sunday === 1 ? true : false, monday: timeslot.monday === 1 ? true : false, tuesday: timeslot.tuesday ? true : false, wednesday: timeslot.wednesday ? true : false, thursday: timeslot.thursday ? true : false, friday: timeslot.friday ? true : false, saturday: timeslot.saturday ? true : false })
          if (days.length === 1) {
            arr.push(`${days[0].charAt(0).toUpperCase() + days[0].substring(1, 3)}`)
          }
          else if (days.length === 2) {
            if(fixedRentalDays.includes('sunday') && fixedRentalDays.includes('monday')){
              arr.push('Sun-Mon')
            }
            else if(fixedRentalDays.includes('monday') && fixedRentalDays.includes('tuesday')){
              arr.push('Mon-Tues')
            }
            else if(fixedRentalDays.includes('tuesday') && fixedRentalDays.includes('wednesday')){
              arr.push('Tues-Wed')
            }
            else if(fixedRentalDays.includes('wednesday') && fixedRentalDays.includes('thursday')){
              arr.push('Wed-Thu')
            }
            else if(fixedRentalDays.includes('thursday') && fixedRentalDays.includes('friday')){
              arr.push('Thu-Fri')
            }
            else if(fixedRentalDays.includes('friday') && fixedRentalDays.includes('saturday')){
              arr.push('Fri-Sat')
            }
            else if(fixedRentalDays.includes('saturday') && fixedRentalDays.includes('sunday')){
              arr.push('Sat-Sun')
            }
            else{
              arr.push(`${days[0].charAt(0).toUpperCase() + days[0].substring(1, 3)}-${days[1].charAt(0).toUpperCase() + days[1].substring(1, 3)}`)
            }
          }
          else {
            if (days.includes('sunday') && days.includes('monday') && days.includes('tuesday') && days.includes('wednesday') && days.includes('thursday') && days.includes('friday') && days.includes('saturday') && days.length === 7) {
              arr.push('Mon-Sun')
            }
            else if (days.includes('monday') && days.includes('tuesday') && days.includes('wednesday') && days.includes('thursday') && days.includes('friday') && days.includes('saturday') && days.length === 6) {
              arr.push('Mon-Sat')
            }
            else if (days.includes('tuesday') && days.includes('wednesday') && days.includes('thursday') && days.includes('friday') && days.includes('saturday') && days.length === 5) {
              arr.push('Tues-Sat')
            }
            else if (days.includes('wednesday') && days.includes('thursday') && days.includes('friday') && days.includes('saturday') && days.length === 4) {
              arr.push('Wed-Sat')
            }
            else if (days.includes('thursday') && days.includes('friday') && days.includes('saturday') && days.length === 3) {
              arr.push('Thu-Sat')
            }
            else if (days.includes('sunday') && days.includes('monday') && days.includes('tuesday') && days.includes('wednesday') && days.includes('thursday') && days.includes('friday') && days.length === 6) {
              arr.push('Sun-Fri')
            }
            else if (days.includes('sunday') && days.includes('monday') && days.includes('tuesday') && days.includes('wednesday') && days.includes('thursday') && days.length === 5) {
              arr.push('Sun-Thu')
            }
            else if (days.includes('sunday') && days.includes('monday') && days.includes('tuesday') && days.includes('wednesday') && days.length === 4) {
              arr.push('Sun-Wed')
            }
            else if (days.includes('sunday') && days.includes('monday') && days.includes('tuesday') && days.length === 3) {
              arr.push('Sun-Tues')
            }
            else if (days.includes('monday') && days.includes('tuesday') && days.includes('wednesday') && days.includes('thursday') && days.includes('friday') && days.length === 5) {
              arr.push('Mon-Fri')
            }
            else if (days.includes('monday') && days.includes('tuesday') && days.includes('wednesday') && days.includes('thursday') && days.length === 4) {
              arr.push('Mon-Thu')
            }
            else if (days.includes('monday') && days.includes('tuesday') && days.includes('wednesday') && days.length === 3) {
              arr.push('Mon-Wed')
            }
            else if (days.includes('tuesday') && days.includes('wednesday') && days.includes('thursday') && days.includes('friday') && days.length === 4) {
              arr.push('Tues-Fri')
            }
            else if (days.includes('tuesday') && days.includes('wednesday') && days.includes('thursday') && days.length === 3) {
              arr.push('Tues-Thu')
            }
            else if (days.includes('wednesday') && days.includes('thursday') && days.includes('friday') && days.length === 3) {
              arr.push('Wed-Fri')
            }
            else {
              let dayStr = ''
              if (days.includes('sunday')) {
                dayStr = dayStr + 'Sun'
              }
              if (days.includes('monday')) {
                if (dayStr.length > 0) {
                  dayStr = dayStr + ',Mon'
                }
                else {
                  dayStr = dayStr + 'Mon'
                }
              }
              if (days.includes('tuesday')) {
                if (dayStr.length > 0) {
                  dayStr = dayStr + ',Tues'
                }
                else {
                  dayStr = dayStr + 'Tues'
                }
              }
              if (days.includes('wednesday')) {
                if (dayStr.length > 0) {
                  dayStr = dayStr + ',Wed'
                }
                else {
                  dayStr = dayStr + 'Wed'
                }
              }
              if (days.includes('thursday')) {
                if (dayStr.length > 0) {
                  dayStr = dayStr + ',Thu'
                }
                else {
                  dayStr = dayStr + 'Thu'
                }
              }
              if (days.includes('friday')) {
                if (dayStr.length > 0) {
                  dayStr = dayStr + ',Fri'
                }
                else {
                  dayStr = dayStr + 'Fri'
                }
              }
              if (days.includes('saturday')) {
                if (dayStr.length > 0) {
                  dayStr = dayStr + ',Sat'
                }
                else {
                  dayStr = dayStr + 'Sat'
                }
              }
              arr.push(dayStr)
            }
          }
        }
        else if (timeslot.fixed_rental === 0) {
          this.setState({ flexibleRental: true })
          if (timeslot.sunday === 1) {
            flexibleDays.push('sunday')
          }
          if (timeslot.monday === 1) {
            flexibleDays.push('monday')
          }
          if (timeslot.tuesday === 1) {
            flexibleDays.push('tuesday')
          }
          if (timeslot.wednesday === 1) {
            flexibleDays.push('wednesday')
          }
          if (timeslot.thursday === 1) {
            flexibleDays.push('thursday')
          }
          if (timeslot.friday === 1) {
            flexibleDays.push('friday')
          }
          if (timeslot.saturday === 1) {
            flexibleDays.push('saturday')
          }
          if (flexibleDays.length === 1) {
            array.push(`${flexibleDays[0].charAt(0).toUpperCase() + flexibleDays[0].substring(1, 3)}`)
          }
          else if (flexibleDays.length === 2) {
            if(flexibleDays.includes('sunday') && flexibleDays.includes('monday')){
              array.push('Sun-Mon')
            }
            else if(flexibleDays.includes('monday') && flexibleDays.includes('tuesday')){
              array.push('Mon-Tues')
            }
            else if(flexibleDays.includes('tuesday') && flexibleDays.includes('wednesday')){
              array.push('Tues-Wed')
            }
            else if(flexibleDays.includes('wednesday') && flexibleDays.includes('thursday')){
              array.push('Wed-Thu')
            }
            else if(flexibleDays.includes('thursday') && flexibleDays.includes('friday')){
              array.push('Thu-Fri')
            }
            else if(flexibleDays.includes('friday') && flexibleDays.includes('saturday')){
              array.push('Fri-Sat')
            }
            else if(flexibleDays.includes('saturday') && flexibleDays.includes('sunday')){
              array.push('Sat-Sun')
            }
            else{
              array.push(`${flexibleDays[0].charAt(0).toUpperCase() + flexibleDays[0].substring(1, 3)}-${flexibleDays[1].charAt(0).toUpperCase() + flexibleDays[1].substring(1, 3)}`)
            }
          }
          else {
            if (flexibleDays.includes('sunday') && flexibleDays.includes('monday') && flexibleDays.includes('tuesday') && flexibleDays.includes('wednesday') && flexibleDays.includes('thursday') && flexibleDays.includes('friday') && flexibleDays.includes('saturday') && flexibleDays.length === 7) {
              array.push('Mon-Sun')
            }
            else if (flexibleDays.includes('monday')&& flexibleDays.includes('tuesday') && flexibleDays.includes('wednesday') && flexibleDays.includes('thursday') && flexibleDays.includes('friday') && flexibleDays.includes('saturday') && flexibleDays.length === 6) {
              array.push('Mon-Sat')
            }
            else if (flexibleDays.includes('tuesday') && flexibleDays.includes('wednesday') && flexibleDays.includes('thursday') && flexibleDays.includes('friday') && flexibleDays.includes('saturday') && flexibleDays.length === 5) {
              array.push('Tues-Sat')
            }
            else if (flexibleDays.includes('wednesday') && flexibleDays.includes('thursday') && flexibleDays.includes('friday') && flexibleDays.includes('saturday') && flexibleDays.length === 4) {
              array.push('Wed-Sat')
            }
            else if (flexibleDays.includes('thursday') && flexibleDays.includes('friday') && flexibleDays.includes('saturday') && flexibleDays.length === 3) {
              array.push('Thu-Sat')
            }
            else if (flexibleDays.includes('sunday') && flexibleDays.includes('monday') && flexibleDays.includes('tuesday') && flexibleDays.includes('wednesday') && flexibleDays.includes('thursday') && flexibleDays.includes('friday') && flexibleDays.length === 6) {
              array.push('Sun-Fri')
            }
            else if (flexibleDays.includes('sunday') && flexibleDays.includes('monday') && flexibleDays.includes('tuesday') && flexibleDays.includes('wednesday') && flexibleDays.includes('thursday') && flexibleDays.length === 5) {
              array.push('Sun-Thu')
            }
            else if (flexibleDays.includes('sunday') && flexibleDays.includes('monday') && flexibleDays.includes('tuesday') && flexibleDays.includes('wednesday') && flexibleDays.length === 4) {
              array.push('Sun-Wed')
            }
            else if (flexibleDays.includes('sunday') && flexibleDays.includes('monday') && flexibleDays.includes('tuesday') && flexibleDays.length === 3) {
              array.push('Sun-Tues')
            }
            else if (flexibleDays.includes('monday') && flexibleDays.includes('tuesday') && flexibleDays.includes('wednesday') && flexibleDays.includes('thursday') && flexibleDays.includes('friday') && flexibleDays.length === 5) {
              array.push('Mon-Fri')
            }
            else if (flexibleDays.includes('monday') && flexibleDays.includes('tuesday') && flexibleDays.includes('wednesday') && flexibleDays.includes('thursday') && flexibleDays.length === 4) {
              array.push('Mon-Thu')
            }
            else if (flexibleDays.includes('monday') && flexibleDays.includes('tuesday') && flexibleDays.includes('wednesday') && flexibleDays.length === 3) {
              array.push('Mon-Wed')
            }
            else if (flexibleDays.includes('tuesday') && flexibleDays.includes('wednesday') && flexibleDays.includes('thursday') && flexibleDays.includes('friday') && flexibleDays.length === 4) {
              array.push('Tues-Fri')
            }
            else if (flexibleDays.includes('tuesday') && flexibleDays.includes('wednesday') && flexibleDays.includes('thursday') && flexibleDays.length === 3) {
              array.push('Tues-Thu')
            }
            else if (flexibleDays.includes('wednesday') && flexibleDays.includes('thursday') && flexibleDays.includes('friday') && flexibleDays.length === 3) {
              array.push('Wed-Fri')
            }
            else {
              let dayStr = ''
              if (flexibleDays.includes('sunday')) {
                dayStr = dayStr + 'Sun'
              }
              if (flexibleDays.includes('monday')) {
                if (dayStr.length > 0) {
                  dayStr = dayStr + ',Mon'
                }
                else {
                  dayStr = dayStr + 'Mon'
                }
              }
              if (flexibleDays.includes('tuesday')) {
                if (dayStr.length > 0) {
                  dayStr = dayStr + ',Tues'
                }
                else {
                  dayStr = dayStr + 'Tues'
                }
              }
              if (flexibleDays.includes('wednesday')) {
                if (dayStr.length > 0) {
                  dayStr = dayStr + ',Wed'
                }
                else {
                  dayStr = dayStr + 'Wed'
                }
              }
              if (flexibleDays.includes('thursday')) {
                if (dayStr.length > 0) {
                  dayStr = dayStr + ',Thu'
                }
                else {
                  dayStr = dayStr + 'Thu'
                }
              }
              if (flexibleDays.includes('friday')) {
                if (dayStr.length > 0) {
                  dayStr = dayStr + ',Fri'
                }
                else {
                  dayStr = dayStr + 'Fri'
                }
              }
              if (flexibleDays.includes('saturday')) {
                if (dayStr.length > 0) {
                  dayStr = dayStr + ',Sat'
                }
                else {
                  dayStr = dayStr + 'Sat'
                }
              }
              array.push(dayStr)
            }
          }
          this.setState({ flexibleRentalDaysArray: flexibleDays })
          const startTime = timeslot.start_time.substring(0, timeslot.start_time.length - 3)
          const endTime = timeslot.end_time.substring(0, timeslot.end_time.length - 3)
          const minHour = timeslot.min_hour ? timeslot.min_hour : ''
          const showMaxHour = timeslot.max_hour ? true : false
          const maxHour = timeslot.max_hour ? timeslot.max_hour : ''
          const multipleEvent =timeslot.multiple_event
          const timeBetweenEvent = timeslot.time_between_event ? timeslot.time_between_event :''
          flexibleRentalAvailability.push({ id: timeslot.id,idx: index , startTime: startTime, endTime: endTime, days: flexibleDays, additionalCost: false, monday: timeslot.monday === 1 ? true : false, tuesday: timeslot.tuesday ? true : false, wednesday: timeslot.wednesday ? true : false, thursday: timeslot.thursday ? true : false, friday: timeslot.friday ? true : false, saturday: timeslot.saturday ? true : false, sunday:timeslot.sunday ? true : false, maxHour:maxHour, minHour:minHour, showMaxHour:showMaxHour, multipleEvent:multipleEvent, timeBetweenEvent:timeBetweenEvent })
          if (!timeSlotType.includes("flexibleRental")) {
            timeSlotType.push("flexibleRental")
          }
        }
        this.setState({flexibleRentalDaysArray: flexibleDays, fixedEventPopper: fixedEventPopper, flexibleEventPopper:flexibleEventPopper})
        if (this.props.match.params.spaceId||this.state.spaceId) {
          if (timeslot.event_types && timeslot.event_types.length > 0) {
            timeslot.event_types.map(event => {
              weekdays=[]
              if (!spaceRatesGroup.includes(event.name)) {
                spaceRatesGroup.push(event.name)
              }
              if (event.pivot.weekdays.includes('1')) {
                weekdays.push('sunday')
              }
              if (event.pivot.weekdays.includes('2')) {
                weekdays.push('monday')
              }
              if (event.pivot.weekdays.includes('3')) {
                weekdays.push('tuesday')
              }
              if (event.pivot.weekdays.includes('4')) {
                weekdays.push('wednesday')
              }
              if (event.pivot.weekdays.includes('5')) {
                weekdays.push('thursday')
              }
              if (event.pivot.weekdays.includes('6')) {
                weekdays.push('friday')
              }
              if (event.pivot.weekdays.includes('7')) {
                weekdays.push('saturday')
              }
              const hasAdditionalCost = event.overage_rate ? true : false
              const pricingType = event.pivot.pricing_type === 1 ? 'Venue Rental' : event.pivot.pricing_type === 2 ? 'Food & Bev Minimum' : event.pivot.pricing_type === 3 ? 'Venue Rental + Food & Bev Minimum' : event.pivot.pricing_type === 4 ?'Per Person':''
              const rentalType = timeslot.fixed_rental === 1 ? 'fixed' : 'flexible'
              // const startTime = moment(timeslot.start_time, "HH:mm:ss").format('hh:mm A').toLowerCase().replace(/^0(?:0:0?)?/, '')
              // const endTime = moment(timeslot.end_time, "HH:mm:ss").format('hh:mm A').toLowerCase().replace(/^0(?:0:0?)?/, '')
              const startTime = timeslot.start_time.substring(0, timeslot.start_time.length - 3)
              const endTime = timeslot.end_time.substring(0, timeslot.end_time.length - 3)
              const flatRate = Math.trunc(parseFloat(event.pivot.base_rate)).toString()
              const fnbRate = Math.trunc(parseFloat(event.pivot.fnb_rate)).toString()
              const addRate = event.pivot.overage_rate === null ? "" :Math.trunc(parseFloat(event.pivot.overage_rate)).toString()
              if (timeslot.fixed_rental === 1 && !pricingCategoryArray[index]) {
                pricingCategoryArray.push([pricingType, ''])
              }
              else if (timeslot.fixed_rental === 0 && !pricingCategoryArray[index]) {
                pricingCategoryArray.push(['', pricingType])
              }
              let eventSpacesLen = 0
              const seqNo = eventSpaceRatesArray.length + 1;
              if(event.pivot.pricing_type === 0){
                eventSpacesLen = eventSpaceRatesArray.filter(function (eventSpaceRates) {
                  return eventSpaceRates.pricingType === 0 &&
                  eventSpaceRates.pricingTypeName === ''
                }).length
              }
              else{
                eventSpacesLen = eventSpaceRatesArray.filter(function (eventSpaceRates) {
                  return eventSpaceRates.pricingTypeName === pricingType &&
                    eventSpaceRates.rentalType === rentalType &&
                    eventSpaceRates.startTime === startTime &&
                    eventSpaceRates.endTime === endTime &&
                    eventSpaceRates.eventName === event.name
                }).length
                
              }              
              if(event.pivot.pricing_type_name !== null){
                eventSpaceRatesArray.push({ idx: eventSpacesLen + 1, seqNo:seqNo, id: event.pivot.id, flatRate:'',additionalFlatRate:flatRate, hasAddFlatRate:true, hasAddPricingTypeName:true, additionalRate: addRate, days: weekdays, eventName: event.name, eventId: event.id, additionalCost: hasAdditionalCost, sunday: timeslot.sunday === 1 ? true : false, monday: timeslot.monday === 1 ? true : false, tuesday: timeslot.tuesday ? true : false, wednesday: timeslot.wednesday ? true : false, thursday: timeslot.thursday ? true : false, friday: timeslot.friday ? true : false, saturday: timeslot.saturday ? true : false, pricingTypeName: pricingType, pricingType: event.pivot.pricing_type, rentalType: rentalType, startTime: startTime, endTime: endTime, timeSlotId: timeslot.id, additionalPricingTypeName: event.pivot.pricing_type_name, fnbRate:fnbRate, minGuest:event.pivot.min_guest ? event.pivot.min_guest.toString():'', up:'yes' })
                // eventSpaceRatesArray.push({ idx: eventSpacesLen + 1, seqNo:seqNo, id: '', flatRate: '', additionalRate: '', days: weekdays, eventName: event.name, eventId: event.id, additionalCost: false, sunday: timeslot.sunday === 1 ? true : false, monday: timeslot.monday === 1 ? true : false, tuesday: timeslot.tuesday ? true : false, wednesday: timeslot.wednesday ? true : false, thursday: timeslot.thursday ? true : false, friday: timeslot.friday ? true : false, saturday: timeslot.saturday ? true : false, pricingTypeName: '', pricingType: 0, rentalType: rentalType, startTime: startTime, endTime: endTime, timeSlotId: timeslot.id, additionalPricingTypeName: '', fnbRate:'', minGuest:'', up:'no' })
              }
              else{
                eventSpaceRatesArray.push({ idx: eventSpacesLen + 1, seqNo:seqNo, id: event.pivot.id, flatRate: flatRate,additionalFlatRate:'', hasAddFlatRate:false, hasAddPricingTypeName:false, additionalRate: addRate, days: weekdays, eventName: event.name, eventId: event.id, additionalCost: hasAdditionalCost, sunday: timeslot.sunday === 1 ? true : false, monday: timeslot.monday === 1 ? true : false, tuesday: timeslot.tuesday ? true : false, wednesday: timeslot.wednesday ? true : false, thursday: timeslot.thursday ? true : false, friday: timeslot.friday ? true : false, saturday: timeslot.saturday ? true : false, pricingTypeName: pricingType, pricingType: event.pivot.pricing_type, rentalType: rentalType, startTime: startTime, endTime: endTime, timeSlotId: timeslot.id, additionalPricingTypeName: event.pivot.pricing_type_name === null ? '' : event.pivot.pricing_type_name, fnbRate:fnbRate, minGuest:event.pivot.min_guest ? event.pivot.min_guest.toString():'' })
                eventSpaceRatesArray.push({ idx: eventSpacesLen + 1, seqNo:seqNo + 1, id: '', flatRate:'',additionalFlatRate:'', hasAddFlatRate:false, hasAddPricingTypeName:false, additionalRate: '', days: weekdays, eventName: event.name, eventId: event.id, additionalCost: false, sunday: timeslot.sunday === 1 ? true : false, monday: timeslot.monday === 1 ? true : false, tuesday: timeslot.tuesday ? true : false, wednesday: timeslot.wednesday ? true : false, thursday: timeslot.thursday ? true : false, friday: timeslot.friday ? true : false, saturday: timeslot.saturday ? true : false, pricingTypeName: '', pricingType: 0, rentalType: rentalType, startTime: startTime, endTime: endTime, timeSlotId: timeslot.id, additionalPricingTypeName: '', fnbRate:'', minGuest:'', up:'no' })
              }
              return eventSpaceRatesArray;
            })
          }
        }
          if (fixedRentalAvailability.length > 0) {
            this.setState({ fixedRentalAvailability: fixedRentalAvailability })
          }
          else{
            const fixedArrayVal = [{ idx: 1, id: '', startTime: '', endTime: '', days: [], additionalCost: false, sunday: false, monday: false, tuesday: false, wednesday: false, thursday: false, friday: false, saturday: false }]
            this.setState({ fixedRentalAvailability: fixedArrayVal, fixedValue:false })
          }
          if (flexibleRentalAvailability.length > 0) {
            this.setState({ flexibleRentalAvailability: flexibleRentalAvailability })
          }
          else{
            const flexibleArrayVal = [{ idx: 1, id: '', startTime: '', endTime: '', days: [], additionalCost: false, sunday: false, monday: false, tuesday: false, wednesday: false, thursday: false, friday: false, saturday: false, maxHour:'', minHour:'', showMaxHour:false, multipleEvent:1, timeBetweenEvent:'' }]
            this.setState({ flexibleRentalAvailability: flexibleArrayVal, flexibleRental:false })
          }
          if (eventSpaceRatesArray.length > 0) {
            this.setState({ eventSpaceRatesArray: eventSpaceRatesArray, spaceRatesGroup: spaceRatesGroup, pricingCategory: pricingCategory })
          }
          else{
            this.setState({ eventSpaceRatesArray: eventSpaceRatesArray, spaceRatesGroup: spaceRatesGroup, pricingCategory: pricingCategory })
          }
          return '';
      })
      if(this.props.eventTypeList.length > 0){
        for(let i=0; i<= this.props.eventTypeList.length-1; i++){
          let fixedArrayVal = []
          let flexibleArrayVal = []
          spaceData.timeslots.map((timeslot, index) => {
            if (timeslot.fixed_rental === 1) {
              fixedArrayVal.push({anchorEl:null, isToggleOpen:false, name:'', price:''})
            }
            else if (timeslot.fixed_rental === 0) {
              flexibleArrayVal.push({anchorEl:null, isToggleOpen:false, name:'', price:''})
            }
            return flexibleArrayVal;
          })
          if(fixedArrayVal.length > 0){
            fixedEventPopper.push(fixedArrayVal)
          }
          if(flexibleArrayVal.length > 0){
            flexibleEventPopper.push(flexibleArrayVal)
          }
        }
      }
      if(fixedRentalAvailability.length > 0){
        this.setState({ fixedRentalDaysArray: fixedRentalDays })
      }
      this.setState({ fixedRentalAvailabilitySortedDays: arr, flexibleRentalAvailabilitySortedDays: array })
      this.setState({ spaceAvailabilityGroup: timeSlotType, pricingCategory: pricingCategoryArray })
      this.setState({ arrangements: arrangementArray, checkboxGroup: checkboxGroup })
    }
    if (this.props.arrangements !== nextProps.arrangements && Object.keys(nextProps.arrangements).length !== 0) {
      let arrangementArray = []
      nextProps.arrangements.map(arrangement => {
        let arrangementObj = {}
        if(arrangement.name === 'Empty' || arrangement.name === 'Pre-Existing Arrangement'){
          arrangementObj = { id: arrangement.id, name: arrangement.name, image: arrangement.illustration,min:'0', max: '', enable: false }
        }
        else{
          arrangementObj = { id: arrangement.id, name: arrangement.name, image: arrangement.illustration, min: '', max: '', enable: false }
        }
        arrangementArray.push(arrangementObj)
        return arrangementArray;
      })
      this.setState({ arrangements: arrangementArray })
    }
    if (this.props.venueDetails !== nextProps.venueDetails && Object.keys(nextProps.venueDetails).length !== 0) {
      nextProps.venueDetails.spaces.map((space, index) => {
        const space_id = space.id.toString()
        const spaceId = this.state.spaceId.toString()
        if (spaceId !== space_id) {
          spaceList.push({ id: space_id, name: space.name })
        }
        return spaceList;
      })
      this.setState({ spaceList: spaceList })
    }
  }



  handleSubmit = (values, actions) => {
    actions.setSubmitting(false);
    this.setState({ spaceName: values.spaceName })
    if (this.state.step === 1) {
      formData = {
        "name": values.spaceName
      }
      if (this.props.spaceData.id) {
        this.props.updateSpaceName(this.props.spaceData.id, formData).then((response) => {
          if (response.id) {
            if (submissionType === 'save') {
              if (this.props.spaceId) {
                this.props.history.push(`/spaceProfileEdit/${response.id}`)
              }
              else {
                this.props.history.push(`/spaceComplete/${response.id}`)
              }
            }
            else if (submissionType === 'next') {
              this.incrementStep(actions.setFieldValue, values)
            }
          }
        })
          .catch(() => {})
      }
      else {
        this.props.addSpace(this.props.match.params.venueId, formData).then((response) => {
          if (response.id) {
            this.setState({ spaceId: response.id })
            if (submissionType === 'save') {
                this.props.getSpace(response.id)
              if (this.props.match.params.spaceId) {
                this.props.history.push(`/spaceProfileEdit/${response.id}`)
              }
              else {
                this.props.history.push(`/spaceComplete/${response.id}`)
              }
            }
            else if (submissionType === 'next') {
              this.incrementStep(actions.setFieldValue, values)
            }
          }
        })
          .catch(error => {})
      }
    }
    else if (this.state.step === 2) {
      step2FormData = ''
      // let error = this.state.arrangementsError
      if (this.props.spaceData !== {} && this.props.spaceData.id) {
        formData = {}

        values.arrangements.map((arrangement, i) => {
          if (values.checkboxGroup.includes(arrangement.name) && arrangement.enable === true) {
            step2FormData = step2FormData + `arrangements[${arrangement.id}][minimum]=${arrangement.min}&arrangements[${arrangement.id}][maximum]=${arrangement.max}&`
          }
          return step2FormData;
        })
        this.props.updateSpace(this.props.spaceData.id, step2FormData).then((response) => {
          if (response.id) {
            if (submissionType === 'save') {
              if (this.props.match.params.spaceId) {
                this.props.history.push(`/spaceProfileEdit/${response.id}`)
              }
              else {
                this.props.history.push(`/spaceComplete/${response.id}`)
              }
            }
            else if (submissionType === 'next') {
              this.incrementStep(actions.setFieldValue, values)
            }
          }
        })
          .catch(error => {})
      }
    }
    else if (this.state.step === 3) {
      // let timeSlotType = []
      // let fixedRentalAvailability = []
      // let flexibleRentalAvailability = []
      // let spaceRatesGroup = []
      // let spaceAvailabilityGroup = []
      step3ParamsLength = 0
      step3FormData = ''
      if (this.props.spaceData !== {} && this.props.spaceData.id) {
        const spaceData = this.props.spaceData
        // if (values.fixedValue === true) {
        if (values.fixedRentalAvailability.length > 0) {
          values.fixedRentalAvailability.map((data, index) => {
            let startTime = '', endTime = ''
            startTime = moment(data.startTime, "h:mm A").format("HH:mm:ss")
            endTime = moment(data.endTime, "h:mm A").format("HH:mm:ss")
            const sunday = data.sunday ? 1 : 0
            const monday = data.monday ? 1 : 0
            const tuesday = data.tuesday ? 1 : 0
            const wednesday = data.wednesday ? 1 : 0
            const thursday = data.thursday ? 1 : 0
            const friday = data.friday ? 1 : 0
            const saturday = data.saturday ? 1 : 0
            step3ParamsLength = step3ParamsLength + 1
            // let timeSlotExists
            if (spaceData.timeslots.length === 0 && values.fixedValue === true) {
              step3FormData = step3FormData + `timeslots[${step3ParamsLength}][fixed_rental]=1&timeslots[${step3ParamsLength}][addl_hours]=${data.additionalCost ? 1 : 0}&timeslots[${step3ParamsLength}][start_time]=${startTime}&timeslots[${step3ParamsLength}][end_time]=${endTime}&timeslots[${step3ParamsLength}][monday]=${monday}&timeslots[${step3ParamsLength}][tuesday]=${tuesday}&timeslots[${step3ParamsLength}][wednesday]=${wednesday}&timeslots[${step3ParamsLength}][thursday]=${thursday}&timeslots[${step3ParamsLength}][friday]=${friday}&timeslots[${step3ParamsLength}][saturday]=${saturday}&timeslots[${step3ParamsLength}][sunday]=${sunday}&`
            }
            else {
              if (data.id && values.fixedValue === false) {
                step3FormData = step3FormData + `timeslots[${step3ParamsLength}][id]=${data.id}&timeslots[${step3ParamsLength}][fixed_rental]=1&timeslots[${step3ParamsLength}][deleted]=1&timeslots[${step3ParamsLength}][addl_hours]=${data.additionalCost ? 1 : 0}&timeslots[${step3ParamsLength}][start_time]=${startTime}&timeslots[${step3ParamsLength}][end_time]=${endTime}&timeslots[${step3ParamsLength}][monday]=${monday}&timeslots[${step3ParamsLength}][tuesday]=${tuesday}&timeslots[${step3ParamsLength}][wednesday]=${wednesday}&timeslots[${step3ParamsLength}][thursday]=${thursday}&timeslots[${step3ParamsLength}][friday]=${friday}&timeslots[${step3ParamsLength}][saturday]=${saturday}&timeslots[${step3ParamsLength}][sunday]=${sunday}&`
              }
              else if (data.id && values.fixedValue === true) {
                step3FormData = step3FormData + `timeslots[${step3ParamsLength}][id]=${data.id}&timeslots[${step3ParamsLength}][fixed_rental]=1&timeslots[${step3ParamsLength}][addl_hours]=${data.additionalCost ? 1 : 0}&timeslots[${step3ParamsLength}][start_time]=${startTime}&timeslots[${step3ParamsLength}][end_time]=${endTime}&timeslots[${step3ParamsLength}][monday]=${monday}&timeslots[${step3ParamsLength}][tuesday]=${tuesday}&timeslots[${step3ParamsLength}][wednesday]=${wednesday}&timeslots[${step3ParamsLength}][thursday]=${thursday}&timeslots[${step3ParamsLength}][friday]=${friday}&timeslots[${step3ParamsLength}][saturday]=${saturday}&timeslots[${step3ParamsLength}][sunday]=${sunday}&`
              }
              else if(!data.id && values.fixedValue === true) {
                step3FormData = step3FormData + `timeslots[${step3ParamsLength}][fixed_rental]=1&timeslots[${step3ParamsLength}][addl_hours]=${data.additionalCost ? 1 : 0}&timeslots[${step3ParamsLength}][start_time]=${startTime}&timeslots[${step3ParamsLength}][end_time]=${endTime}&timeslots[${step3ParamsLength}][monday]=${monday}&timeslots[${step3ParamsLength}][tuesday]=${tuesday}&timeslots[${step3ParamsLength}][wednesday]=${wednesday}&timeslots[${step3ParamsLength}][thursday]=${thursday}&timeslots[${step3ParamsLength}][friday]=${friday}&timeslots[${step3ParamsLength}][saturday]=${saturday}&timeslots[${step3ParamsLength}][sunday]=${sunday}&`
              }
            }
            return step3FormData;
          })
          if(this.state.deletedFixedSlotOnCopy.length > 0){
            this.state.deletedFixedSlotOnCopy.map((data, index) => {
              let startTime = '', endTime = ''
              startTime = moment(data.startTime, "h:mm A").format("HH:mm:ss")
              endTime = moment(data.endTime, "h:mm A").format("HH:mm:ss")
              const sunday = data.sunday ? 1 : 0
              const monday = data.monday ? 1 : 0
              const tuesday = data.tuesday ? 1 : 0
              const wednesday = data.wednesday ? 1 : 0
              const thursday = data.thursday ? 1 : 0
              const friday = data.friday ? 1 : 0
              const saturday = data.saturday ? 1 : 0
              step3ParamsLength = step3ParamsLength + 1
              // let timeSlotExists
                if (data.id) {
                  step3FormData = step3FormData + `timeslots[${step3ParamsLength}][id]=${data.id}&timeslots[${step3ParamsLength}][deleted]=1&timeslots[${step3ParamsLength}][fixed_rental]=1&timeslots[${step3ParamsLength}][addl_hours]=${data.additionalCost ? 1 : 0}&timeslots[${step3ParamsLength}][start_time]=${startTime}&timeslots[${step3ParamsLength}][end_time]=${endTime}&timeslots[${step3ParamsLength}][monday]=${monday}&timeslots[${step3ParamsLength}][tuesday]=${tuesday}&timeslots[${step3ParamsLength}][wednesday]=${wednesday}&timeslots[${step3ParamsLength}][thursday]=${thursday}&timeslots[${step3ParamsLength}][friday]=${friday}&timeslots[${step3ParamsLength}][saturday]=${saturday}&timeslots[${step3ParamsLength}][sunday]=${sunday}&`
                }
                return step3FormData;
            }) 
          }
        }

        if(values.flexibleRentalAvailability.length > 0){
          values.flexibleRentalAvailability.map(function (data) {
            let startTime = '', endTime = ''
            startTime = moment(data.startTime, "h:mm A").format("HH:mm:ss")
            endTime = moment(data.endTime, "h:mm A").format("HH:mm:ss")
            const sunday = data.sunday ? 1 : 0
            const monday = data.monday ? 1 : 0
            const tuesday = data.tuesday ? 1 : 0
            const wednesday = data.wednesday ? 1 : 0
            const thursday = data.thursday ? 1 : 0
            const friday = data.friday ? 1 : 0
            const saturday = data.saturday ? 1 : 0
            step3ParamsLength = step3ParamsLength + 1
            // let timeSlotExists
            if (spaceData.timeslots.length === 0 && values.flexibleRental === true) {
              if(data.maxHour && data.showMaxHour){
                if(data.multipleEvent === 1){
                  step3FormData = step3FormData + `timeslots[${step3ParamsLength}][fixed_rental]=0&timeslots[${step3ParamsLength}][addl_hours]=${data.additionalCost ? 1 : 0}&timeslots[${step3ParamsLength}][start_time]=${startTime}&timeslots[${step3ParamsLength}][end_time]=${endTime}&timeslots[${step3ParamsLength}][monday]=${monday}&timeslots[${step3ParamsLength}][tuesday]=${tuesday}&timeslots[${step3ParamsLength}][wednesday]=${wednesday}&timeslots[${step3ParamsLength}][thursday]=${thursday}&timeslots[${step3ParamsLength}][friday]=${friday}&timeslots[${step3ParamsLength}][saturday]=${saturday}&timeslots[${step3ParamsLength}][sunday]=${sunday}&timeslots[${step3ParamsLength}][max_hour]=${data.maxHour}&timeslots[${step3ParamsLength}][min_hour]=${data.minHour}&timeslots[${step3ParamsLength}][multiple_event]=${data.multipleEvent}&timeslots[${step3ParamsLength}][time_between_event]=${data.timeBetweenEvent}&`
                }
                else{
                  step3FormData = step3FormData + `timeslots[${step3ParamsLength}][fixed_rental]=0&timeslots[${step3ParamsLength}][addl_hours]=${data.additionalCost ? 1 : 0}&timeslots[${step3ParamsLength}][start_time]=${startTime}&timeslots[${step3ParamsLength}][end_time]=${endTime}&timeslots[${step3ParamsLength}][monday]=${monday}&timeslots[${step3ParamsLength}][tuesday]=${tuesday}&timeslots[${step3ParamsLength}][wednesday]=${wednesday}&timeslots[${step3ParamsLength}][thursday]=${thursday}&timeslots[${step3ParamsLength}][friday]=${friday}&timeslots[${step3ParamsLength}][saturday]=${saturday}&timeslots[${step3ParamsLength}][sunday]=${sunday}&timeslots[${step3ParamsLength}][max_hour]=${data.maxHour}&timeslots[${step3ParamsLength}][min_hour]=${data.minHour}&timeslots[${step3ParamsLength}][multiple_event]=${data.multipleEvent}&`
                }
              }
              else{
                if(data.multipleEvent === 1){
                  step3FormData = step3FormData + `timeslots[${step3ParamsLength}][fixed_rental]=0&timeslots[${step3ParamsLength}][addl_hours]=${data.additionalCost ? 1 : 0}&timeslots[${step3ParamsLength}][start_time]=${startTime}&timeslots[${step3ParamsLength}][end_time]=${endTime}&timeslots[${step3ParamsLength}][monday]=${monday}&timeslots[${step3ParamsLength}][tuesday]=${tuesday}&timeslots[${step3ParamsLength}][wednesday]=${wednesday}&timeslots[${step3ParamsLength}][thursday]=${thursday}&timeslots[${step3ParamsLength}][friday]=${friday}&timeslots[${step3ParamsLength}][saturday]=${saturday}&timeslots[${step3ParamsLength}][sunday]=${sunday}&timeslots[${step3ParamsLength}][min_hour]=${data.minHour}&timeslots[${step3ParamsLength}][multiple_event]=${data.multipleEvent}&timeslots[${step3ParamsLength}][time_between_event]=${data.timeBetweenEvent}&`
                }
                else{
                  step3FormData = step3FormData + `timeslots[${step3ParamsLength}][fixed_rental]=0&timeslots[${step3ParamsLength}][addl_hours]=${data.additionalCost ? 1 : 0}&timeslots[${step3ParamsLength}][start_time]=${startTime}&timeslots[${step3ParamsLength}][end_time]=${endTime}&timeslots[${step3ParamsLength}][monday]=${monday}&timeslots[${step3ParamsLength}][tuesday]=${tuesday}&timeslots[${step3ParamsLength}][wednesday]=${wednesday}&timeslots[${step3ParamsLength}][thursday]=${thursday}&timeslots[${step3ParamsLength}][friday]=${friday}&timeslots[${step3ParamsLength}][saturday]=${saturday}&timeslots[${step3ParamsLength}][sunday]=${sunday}&timeslots[${step3ParamsLength}][min_hour]=${data.minHour}&timeslots[${step3ParamsLength}][multiple_event]=${data.multipleEvent}&`
                }
              }
            }
            else {
              if (data.id && values.flexibleRental === true) {
                if(data.maxHour && data.showMaxHour){
                  if(data.multipleEvent === 1){
                    step3FormData = step3FormData + `timeslots[${step3ParamsLength}][id]=${data.id}&timeslots[${step3ParamsLength}][fixed_rental]=0&timeslots[${step3ParamsLength}][addl_hours]=${data.additionalCost ? 1 : 0}&timeslots[${step3ParamsLength}][start_time]=${startTime}&timeslots[${step3ParamsLength}][end_time]=${endTime}&timeslots[${step3ParamsLength}][monday]=${monday}&timeslots[${step3ParamsLength}][tuesday]=${tuesday}&timeslots[${step3ParamsLength}][wednesday]=${wednesday}&timeslots[${step3ParamsLength}][thursday]=${thursday}&timeslots[${step3ParamsLength}][friday]=${friday}&timeslots[${step3ParamsLength}][saturday]=${saturday}&timeslots[${step3ParamsLength}][sunday]=${sunday}&timeslots[${step3ParamsLength}][max_hour]=${data.maxHour}&timeslots[${step3ParamsLength}][min_hour]=${data.minHour}&timeslots[${step3ParamsLength}][multiple_event]=${data.multipleEvent}&timeslots[${step3ParamsLength}][time_between_event]=${data.timeBetweenEvent}&`
                  }
                  else{
                    step3FormData = step3FormData + `timeslots[${step3ParamsLength}][id]=${data.id}&timeslots[${step3ParamsLength}][fixed_rental]=0&timeslots[${step3ParamsLength}][addl_hours]=${data.additionalCost ? 1 : 0}&timeslots[${step3ParamsLength}][start_time]=${startTime}&timeslots[${step3ParamsLength}][end_time]=${endTime}&timeslots[${step3ParamsLength}][monday]=${monday}&timeslots[${step3ParamsLength}][tuesday]=${tuesday}&timeslots[${step3ParamsLength}][wednesday]=${wednesday}&timeslots[${step3ParamsLength}][thursday]=${thursday}&timeslots[${step3ParamsLength}][friday]=${friday}&timeslots[${step3ParamsLength}][saturday]=${saturday}&timeslots[${step3ParamsLength}][sunday]=${sunday}&timeslots[${step3ParamsLength}][max_hour]=${data.maxHour}&timeslots[${step3ParamsLength}][min_hour]=${data.minHour}&timeslots[${step3ParamsLength}][multiple_event]=${data.multipleEvent}&`
                  }
                }
                else{
                  if(data.multipleEvent === 1){
                    step3FormData = step3FormData + `timeslots[${step3ParamsLength}][id]=${data.id}&timeslots[${step3ParamsLength}][fixed_rental]=0&timeslots[${step3ParamsLength}][addl_hours]=${data.additionalCost ? 1 : 0}&timeslots[${step3ParamsLength}][start_time]=${startTime}&timeslots[${step3ParamsLength}][end_time]=${endTime}&timeslots[${step3ParamsLength}][monday]=${monday}&timeslots[${step3ParamsLength}][tuesday]=${tuesday}&timeslots[${step3ParamsLength}][wednesday]=${wednesday}&timeslots[${step3ParamsLength}][thursday]=${thursday}&timeslots[${step3ParamsLength}][friday]=${friday}&timeslots[${step3ParamsLength}][saturday]=${saturday}&timeslots[${step3ParamsLength}][sunday]=${sunday}&timeslots[${step3ParamsLength}][min_hour]=${data.minHour}&timeslots[${step3ParamsLength}][multiple_event]=${data.multipleEvent}&timeslots[${step3ParamsLength}][time_between_event]=${data.timeBetweenEvent}&`
                  }
                  else{
                    step3FormData = step3FormData + `timeslots[${step3ParamsLength}][id]=${data.id}&timeslots[${step3ParamsLength}][fixed_rental]=0&timeslots[${step3ParamsLength}][addl_hours]=${data.additionalCost ? 1 : 0}&timeslots[${step3ParamsLength}][start_time]=${startTime}&timeslots[${step3ParamsLength}][end_time]=${endTime}&timeslots[${step3ParamsLength}][monday]=${monday}&timeslots[${step3ParamsLength}][tuesday]=${tuesday}&timeslots[${step3ParamsLength}][wednesday]=${wednesday}&timeslots[${step3ParamsLength}][thursday]=${thursday}&timeslots[${step3ParamsLength}][friday]=${friday}&timeslots[${step3ParamsLength}][saturday]=${saturday}&timeslots[${step3ParamsLength}][sunday]=${sunday}&timeslots[${step3ParamsLength}][min_hour]=${data.minHour}&timeslots[${step3ParamsLength}][multiple_event]=${data.multipleEvent}&`
                  }
                }
                
              }
              else if (data.id && values.flexibleRental === false) {
                if(data.maxHour && data.showMaxHour){
                  if(data.multipleEvent === 1){
                    step3FormData = step3FormData + `timeslots[${step3ParamsLength}][id]=${data.id}&timeslots[${step3ParamsLength}][deleted]=1&timeslots[${step3ParamsLength}][fixed_rental]=0&timeslots[${step3ParamsLength}][addl_hours]=${data.additionalCost ? 1 : 0}&timeslots[${step3ParamsLength}][start_time]=${startTime}&timeslots[${step3ParamsLength}][end_time]=${endTime}&timeslots[${step3ParamsLength}][monday]=${monday}&timeslots[${step3ParamsLength}][tuesday]=${tuesday}&timeslots[${step3ParamsLength}][wednesday]=${wednesday}&timeslots[${step3ParamsLength}][thursday]=${thursday}&timeslots[${step3ParamsLength}][friday]=${friday}&timeslots[${step3ParamsLength}][saturday]=${saturday}&timeslots[${step3ParamsLength}][sunday]=${sunday}&timeslots[${step3ParamsLength}][max_hour]=${data.maxHour}&timeslots[${step3ParamsLength}][min_hour]=${data.minHour}&timeslots[${step3ParamsLength}][multiple_event]=${data.multipleEvent}&timeslots[${step3ParamsLength}][time_between_event]=${data.timeBetweenEvent}&`
                  }
                  else{
                    step3FormData = step3FormData + `timeslots[${step3ParamsLength}][id]=${data.id}&timeslots[${step3ParamsLength}][deleted]=1&timeslots[${step3ParamsLength}][fixed_rental]=0&timeslots[${step3ParamsLength}][addl_hours]=${data.additionalCost ? 1 : 0}&timeslots[${step3ParamsLength}][start_time]=${startTime}&timeslots[${step3ParamsLength}][end_time]=${endTime}&timeslots[${step3ParamsLength}][monday]=${monday}&timeslots[${step3ParamsLength}][tuesday]=${tuesday}&timeslots[${step3ParamsLength}][wednesday]=${wednesday}&timeslots[${step3ParamsLength}][thursday]=${thursday}&timeslots[${step3ParamsLength}][friday]=${friday}&timeslots[${step3ParamsLength}][saturday]=${saturday}&timeslots[${step3ParamsLength}][sunday]=${sunday}&timeslots[${step3ParamsLength}][max_hour]=${data.maxHour}&timeslots[${step3ParamsLength}][min_hour]=${data.minHour}&timeslots[${step3ParamsLength}][multiple_event]=${data.multipleEvent}&`
                  }
                }
                else{
                  if(data.multipleEvent === 1){
                    step3FormData = step3FormData + `timeslots[${step3ParamsLength}][id]=${data.id}&timeslots[${step3ParamsLength}][deleted]=1&timeslots[${step3ParamsLength}][fixed_rental]=0&timeslots[${step3ParamsLength}][addl_hours]=${data.additionalCost ? 1 : 0}&timeslots[${step3ParamsLength}][start_time]=${startTime}&timeslots[${step3ParamsLength}][end_time]=${endTime}&timeslots[${step3ParamsLength}][monday]=${monday}&timeslots[${step3ParamsLength}][tuesday]=${tuesday}&timeslots[${step3ParamsLength}][wednesday]=${wednesday}&timeslots[${step3ParamsLength}][thursday]=${thursday}&timeslots[${step3ParamsLength}][friday]=${friday}&timeslots[${step3ParamsLength}][saturday]=${saturday}&timeslots[${step3ParamsLength}][sunday]=${sunday}&timeslots[${step3ParamsLength}][min_hour]=${data.minHour}&timeslots[${step3ParamsLength}][multiple_event]=${data.multipleEvent}&timeslots[${step3ParamsLength}][time_between_event]=${data.timeBetweenEvent}&`
                  }
                  else{
                    step3FormData = step3FormData + `timeslots[${step3ParamsLength}][id]=${data.id}&timeslots[${step3ParamsLength}][deleted]=1&timeslots[${step3ParamsLength}][fixed_rental]=0&timeslots[${step3ParamsLength}][addl_hours]=${data.additionalCost ? 1 : 0}&timeslots[${step3ParamsLength}][start_time]=${startTime}&timeslots[${step3ParamsLength}][end_time]=${endTime}&timeslots[${step3ParamsLength}][monday]=${monday}&timeslots[${step3ParamsLength}][tuesday]=${tuesday}&timeslots[${step3ParamsLength}][wednesday]=${wednesday}&timeslots[${step3ParamsLength}][thursday]=${thursday}&timeslots[${step3ParamsLength}][friday]=${friday}&timeslots[${step3ParamsLength}][saturday]=${saturday}&timeslots[${step3ParamsLength}][sunday]=${sunday}&timeslots[${step3ParamsLength}][min_hour]=${data.minHour}&timeslots[${step3ParamsLength}][multiple_event]=${data.multipleEvent}&`
                  }
                }
                
              }
              else if(!data.id && values.flexibleRental === true) {
                if(data.maxHour && data.showMaxHour){
                  if(data.multipleEvent === 1){
                    step3FormData = step3FormData + `timeslots[${step3ParamsLength}][fixed_rental]=0&timeslots[${step3ParamsLength}][addl_hours]=${data.additionalCost ? 1 : 0}&timeslots[${step3ParamsLength}][start_time]=${startTime}&timeslots[${step3ParamsLength}][end_time]=${endTime}&timeslots[${step3ParamsLength}][monday]=${monday}&timeslots[${step3ParamsLength}][tuesday]=${tuesday}&timeslots[${step3ParamsLength}][wednesday]=${wednesday}&timeslots[${step3ParamsLength}][thursday]=${thursday}&timeslots[${step3ParamsLength}][friday]=${friday}&timeslots[${step3ParamsLength}][saturday]=${saturday}&timeslots[${step3ParamsLength}][sunday]=${sunday}&timeslots[${step3ParamsLength}][max_hour]=${data.maxHour}&timeslots[${step3ParamsLength}][min_hour]=${data.minHour}&timeslots[${step3ParamsLength}][multiple_event]=${data.multipleEvent}&timeslots[${step3ParamsLength}][time_between_event]=${data.timeBetweenEvent}&`
                  }
                  else{
                    step3FormData = step3FormData + `timeslots[${step3ParamsLength}][fixed_rental]=0&timeslots[${step3ParamsLength}][addl_hours]=${data.additionalCost ? 1 : 0}&timeslots[${step3ParamsLength}][start_time]=${startTime}&timeslots[${step3ParamsLength}][end_time]=${endTime}&timeslots[${step3ParamsLength}][monday]=${monday}&timeslots[${step3ParamsLength}][tuesday]=${tuesday}&timeslots[${step3ParamsLength}][wednesday]=${wednesday}&timeslots[${step3ParamsLength}][thursday]=${thursday}&timeslots[${step3ParamsLength}][friday]=${friday}&timeslots[${step3ParamsLength}][saturday]=${saturday}&timeslots[${step3ParamsLength}][sunday]=${sunday}&timeslots[${step3ParamsLength}][max_hour]=${data.maxHour}&timeslots[${step3ParamsLength}][min_hour]=${data.minHour}&timeslots[${step3ParamsLength}][multiple_event]=${data.multipleEvent}&`
                  }
                }
                else{
                  if(data.multipleEvent === 1){
                    step3FormData = step3FormData + `timeslots[${step3ParamsLength}][fixed_rental]=0&timeslots[${step3ParamsLength}][addl_hours]=${data.additionalCost ? 1 : 0}&timeslots[${step3ParamsLength}][start_time]=${startTime}&timeslots[${step3ParamsLength}][end_time]=${endTime}&timeslots[${step3ParamsLength}][monday]=${monday}&timeslots[${step3ParamsLength}][tuesday]=${tuesday}&timeslots[${step3ParamsLength}][wednesday]=${wednesday}&timeslots[${step3ParamsLength}][thursday]=${thursday}&timeslots[${step3ParamsLength}][friday]=${friday}&timeslots[${step3ParamsLength}][saturday]=${saturday}&timeslots[${step3ParamsLength}][sunday]=${sunday}&timeslots[${step3ParamsLength}][min_hour]=${data.minHour}&timeslots[${step3ParamsLength}][multiple_event]=${data.multipleEvent}&timeslots[${step3ParamsLength}][time_between_event]=${data.timeBetweenEvent}&`
                  }
                  else{
                    step3FormData = step3FormData + `timeslots[${step3ParamsLength}][fixed_rental]=0&timeslots[${step3ParamsLength}][addl_hours]=${data.additionalCost ? 1 : 0}&timeslots[${step3ParamsLength}][start_time]=${startTime}&timeslots[${step3ParamsLength}][end_time]=${endTime}&timeslots[${step3ParamsLength}][monday]=${monday}&timeslots[${step3ParamsLength}][tuesday]=${tuesday}&timeslots[${step3ParamsLength}][wednesday]=${wednesday}&timeslots[${step3ParamsLength}][thursday]=${thursday}&timeslots[${step3ParamsLength}][friday]=${friday}&timeslots[${step3ParamsLength}][saturday]=${saturday}&timeslots[${step3ParamsLength}][sunday]=${sunday}&timeslots[${step3ParamsLength}][min_hour]=${data.minHour}&timeslots[${step3ParamsLength}][multiple_event]=${data.multipleEvent}&`
                  }
                }
              }
            }
            return step3FormData;
          })
          if(this.state.deleteFlexibleSlotOnCopy.length > 0){
            this.state.deleteFlexibleSlotOnCopy.map(function (data) {
              let startTime = '', endTime = ''
              startTime = moment(data.startTime, "h:mm A").format("HH:mm:ss")
              endTime = moment(data.endTime, "h:mm A").format("HH:mm:ss")
              const sunday = data.sunday ? 1 : 0
              const monday = data.monday ? 1 : 0
              const tuesday = data.tuesday ? 1 : 0
              const wednesday = data.wednesday ? 1 : 0
              const thursday = data.thursday ? 1 : 0
              const friday = data.friday ? 1 : 0
              const saturday = data.saturday ? 1 : 0
              step3ParamsLength = step3ParamsLength + 1
              // let timeSlotExists
                if (data.id) {
                  if(data.minHour){
                    step3FormData = step3FormData + `timeslots[${step3ParamsLength}][id]=${data.id}&timeslots[${step3ParamsLength}][deleted]=1&timeslots[${step3ParamsLength}][fixed_rental]=0&timeslots[${step3ParamsLength}][addl_hours]=${data.additionalCost ? 1 : 0}&timeslots[${step3ParamsLength}][start_time]=${startTime}&timeslots[${step3ParamsLength}][end_time]=${endTime}&timeslots[${step3ParamsLength}][monday]=${monday}&timeslots[${step3ParamsLength}][tuesday]=${tuesday}&timeslots[${step3ParamsLength}][wednesday]=${wednesday}&timeslots[${step3ParamsLength}][thursday]=${thursday}&timeslots[${step3ParamsLength}][friday]=${friday}&timeslots[${step3ParamsLength}][saturday]=${saturday}&timeslots[${step3ParamsLength}][sunday]=${sunday}&timeslots[${step3ParamsLength}][max_hour]=${data.maxHour}&timeslots[${step3ParamsLength}][min_hour]=${data.minHour}&timeslots[${step3ParamsLength}][multiple_event]=${data.multipleEvent}&timeslots[${step3ParamsLength}][time_between_event]=${data.timeBetweenEvent}&`
                  }
                  else{
                    step3FormData = step3FormData + `timeslots[${step3ParamsLength}][id]=${data.id}&timeslots[${step3ParamsLength}][deleted]=1&timeslots[${step3ParamsLength}][fixed_rental]=0&timeslots[${step3ParamsLength}][addl_hours]=${data.additionalCost ? 1 : 0}&timeslots[${step3ParamsLength}][start_time]=${startTime}&timeslots[${step3ParamsLength}][end_time]=${endTime}&timeslots[${step3ParamsLength}][monday]=${monday}&timeslots[${step3ParamsLength}][tuesday]=${tuesday}&timeslots[${step3ParamsLength}][wednesday]=${wednesday}&timeslots[${step3ParamsLength}][thursday]=${thursday}&timeslots[${step3ParamsLength}][friday]=${friday}&timeslots[${step3ParamsLength}][saturday]=${saturday}&timeslots[${step3ParamsLength}][sunday]=${sunday}&timeslots[${step3ParamsLength}][max_hour]=${data.maxHour}&timeslots[${step3ParamsLength}][multiple_event]=${data.multipleEvent}&timeslots[${step3ParamsLength}][time_between_event]=${data.timeBetweenEvent}&`
                  }
                  
                }
                return step3FormData;
            })
          }
        }

        this.props.updateSpace(this.state.spaceId, step3FormData).then((response) => {
          if (response.id) {
            this.props.getSpace(response.id)
            if (submissionType === 'save') {
              if (this.props.match.params.spaceId) {
                this.props.history.push(`/spaceProfileEdit/${response.id}`)
              }
              else {
                this.props.history.push(`/spaceComplete/${response.id}`)
              }
            }
            else if (submissionType === 'next') {
              this.incrementStep(actions.setFieldValue, values)
            }
          }
        })
          .catch(error => {})
      }
    }
    else if (this.state.step === 4 || submissionType === 'save') {
      step4FormData = []
      arr=[];

      step4ParamsLength = 0;
      // let step4ErrorVar = this.state.step4Error
      let step4ErrorVar = step4Error
      if (values.fixedValue === true) {
        values.spaceRatesGroup.map((event, index) => {
          values.fixedRentalAvailability.map((rental,idx) =>{
            const hasFixedRentalObject = (values.eventSpaceRatesArray.find(o => o.rentalType === 'fixed' &&  o.eventName === event && o.endTime === rental.endTime && o.startTime === rental.startTime && rental.days.sort().join(',')=== o.days.sort().join(','))) ? true : false
            if (!hasFixedRentalObject) {
              step4ErrorVar[index][0] = "*Required"
              step4FixedHasError = true
              this.setState({ formCompleted: false })
            }
            else {
              step4ErrorVar[index][0] = ""
              step4FixedHasError = false
            }
            // return step4FixedHasError;
          })
        })
      }
      if (values.flexibleRental === true) {
        values.spaceRatesGroup.map((event, index) => {
          values.flexibleRentalAvailability.map((rental,idx)=>{
            const hasFlexibleRentalObject = (values.eventSpaceRatesArray.find(o => o.rentalType === 'flexible' && o.eventName === event && o.endTime === rental.endTime && o.startTime === rental.startTime && rental.days.sort().join(',')=== o.days.sort().join(','))) ? true : false
            if (!hasFlexibleRentalObject) {
              step4ErrorVar[index][1] = "*Required"
              step4FlexibleHasError = true
              this.setState({ formCompleted: false })
            }
            else {
              step4ErrorVar[index][1] = ""
              step4FlexibleHasError = false
            }
            // return step4FlexibleHasError;
          })
        })
      }
      // this.setState({ step4Error: step4ErrorVar })
      step4Error = step4ErrorVar
      if (values.spaceRatesGroup.length > 0) {
        // actions.validateForm().then(errors => {
          
          // if (!Object.keys(errors).length > 0 && step4HasError === false) {
          if (step4FixedHasError === false && step4FlexibleHasError === false) {
            step4FormData = []
            arr=[];
            step4ParamsLength = 0
            values.eventSpaceRatesArray.map(function (data, index) {
              step4ParamsLength = step4ParamsLength + 1
              const weekday1 = data.days.indexOf('sunday') !== -1 ? 1 : 0
              const weekday2 = data.days.indexOf('monday') !== -1 ? 2 : 0
              const weekday3 = data.days.indexOf('tuesday') !== -1 ? 3 : 0
              const weekday4 = data.days.indexOf('wednesday') !== -1 ? 4 : 0
              const weekday5 = data.days.indexOf('thursday') !== -1 ? 5 : 0
              const weekday6 = data.days.indexOf('friday') !== -1 ? 6 : 0
              const weekday7 = data.days.indexOf('saturday') !== -1 ? 7 : 0
              const flatRate = data.flatRate !== '' ? data.flatRate.replace(/\$|,/g, '') : ''
              const additionalFlatRate = data.additionalFlatRate !== '' ? data.additionalFlatRate.replace(/\$|,/g, ''):'0'
              const fnbRate = data.fnbRate !== 'NaN' && data.fnbRate.length > 0 ? data.fnbRate.replace(/\$|,/g, ''):''
              const addRate = data.additionalRate.replace(/\$|,/g, '')
          
              let weekday=[];
              if(weekday1!==0){
                weekday.push(1);
              }
              if(weekday2!==0){
                weekday.push(2);

              }
              if(weekday3!==0){
                weekday.push(3);

              }
      
              if(weekday4!==0){
                weekday.push(4);

              }
              if(weekday5!==0){
                weekday.push(5);

              }
              if(weekday6!==0){
                weekday.push(6);

              }
              if(weekday7!==0){
                weekday.push(7);

              }
              if (data.id) {
                let obj={};
                if(data.additionalPricingTypeName && data.additionalPricingTypeName !== '' && data.flatRate === ''){
                  if(data.pricingType === 4){
                    step4FormData = step4FormData + `event_type_timeslot_pricings[${step4ParamsLength}][id]=${data.id}${!values.spaceRatesGroup.includes(data.eventName) ? `&event_type_timeslot_pricings[${step4ParamsLength}][deleted]=1`:''}&event_type_timeslot_pricings[${step4ParamsLength}][timeslot_id]=${data.timeSlotId}&event_type_timeslot_pricings[${step4ParamsLength}][event_type_id]=${data.eventId}&event_type_timeslot_pricings[${step4ParamsLength}][pricing_type]=${data.pricingType}${weekday1 !== 0 ? `&event_type_timeslot_pricings[${step4ParamsLength}][weekdays][1]=${weekday1}` : ''}${weekday2 !== 0 ? `&event_type_timeslot_pricings[${step4ParamsLength}][weekdays][2]=${weekday2}` : ''}${weekday3 !== 0 ? `&event_type_timeslot_pricings[${step4ParamsLength}][weekdays][3]=${weekday3}` : ''}${weekday4 !== 0 ? `&event_type_timeslot_pricings[${step4ParamsLength}][weekdays][4]=${weekday4}` : ''}${weekday5 !== 0 ? `&event_type_timeslot_pricings[${step4ParamsLength}][weekdays][5]=${weekday5}` : ''}${weekday6 !== 0 ? `&event_type_timeslot_pricings[${step4ParamsLength}][weekdays][6]=${weekday6}` : ''}${weekday7 !== 0 ? `&event_type_timeslot_pricings[${step4ParamsLength}][weekdays][7]=${weekday7}` : ''}&event_type_timeslot_pricings[${step4ParamsLength}][base_rate]=${additionalFlatRate}${data.additionalRate ? `&event_type_timeslot_pricings[${step4ParamsLength}][overage_rate]=${addRate}` : ''}&event_type_timeslot_pricings[${step4ParamsLength}][min_guest]=${data.minGuest}&event_type_timeslot_pricings[${step4ParamsLength}][pricing_type_name]=${encodeURIComponent(data.additionalPricingTypeName)}&`
                    obj.id=data.id;
                    obj.timeslot_id=data.timeSlotId;
                    obj.event_type_id=data.eventId;
                    obj.pricing_type=data.pricingType;
                    obj.weekdays=weekday;
                    obj.base_rate=additionalFlatRate;
                    obj.min_guest=data.minGuest?data.minGuest:0; 
                    obj.pricing_type_name=data.additionalPricingTypeName;

                    if(!values.spaceRatesGroup.includes(data.eventName)){
                      obj.deleted=1;
                    }
                    if(data.additionalRate){
                      obj.overage_rate=addRate;
                    }
                    arr.push(obj);
                  }
                  else if(fnbRate && data.pricingType === 3){
                    step4FormData = step4FormData + `event_type_timeslot_pricings[${step4ParamsLength}][id]=${data.id}${!values.spaceRatesGroup.includes(data.eventName) ? `&event_type_timeslot_pricings[${step4ParamsLength}][deleted]=1`:''}&&event_type_timeslot_pricings[${step4ParamsLength}][timeslot_id]=${data.timeSlotId}&event_type_timeslot_pricings[${step4ParamsLength}][event_type_id]=${data.eventId}&event_type_timeslot_pricings[${step4ParamsLength}][pricing_type]=${data.pricingType}${weekday1 !== 0 ? `&event_type_timeslot_pricings[${step4ParamsLength}][weekdays][1]=${weekday1}` : ''}${weekday2 !== 0 ? `&event_type_timeslot_pricings[${step4ParamsLength}][weekdays][2]=${weekday2}` : ''}${weekday3 !== 0 ? `&event_type_timeslot_pricings[${step4ParamsLength}][weekdays][3]=${weekday3}` : ''}${weekday4 !== 0 ? `&event_type_timeslot_pricings[${step4ParamsLength}][weekdays][4]=${weekday4}` : ''}${weekday5 !== 0 ? `&event_type_timeslot_pricings[${step4ParamsLength}][weekdays][5]=${weekday5}` : ''}${weekday6 !== 0 ? `&event_type_timeslot_pricings[${step4ParamsLength}][weekdays][6]=${weekday6}` : ''}${weekday7 !== 0 ? `&event_type_timeslot_pricings[${step4ParamsLength}][weekdays][7]=${weekday7}` : ''}&event_type_timeslot_pricings[${step4ParamsLength}][base_rate]=${additionalFlatRate}${data.additionalRate ? `&event_type_timeslot_pricings[${step4ParamsLength}][overage_rate]=${addRate}` : ''}&event_type_timeslot_pricings[${step4ParamsLength}][fnb_rate]=${fnbRate}&event_type_timeslot_pricings[${step4ParamsLength}][pricing_type_name]=${encodeURIComponent(data.additionalPricingTypeName)}&`
                    obj.id=data.id;
                    obj.timeslot_id=data.timeSlotId;
                    obj.event_type_id=data.eventId;
                    obj.pricing_type=data.pricingType;
                    obj.weekdays=weekday;
                    obj.base_rate=additionalFlatRate;
                    obj.fnb_rate=fnbRate; 
                    obj.pricing_type_name=data.additionalPricingTypeName;

                    if(!values.spaceRatesGroup.includes(data.eventName)){
                      obj.deleted=1;
                    }
                    if(data.additionalRate){
                      obj.overage_rate=addRate;
                    }
                    arr.push(obj);
                }
                  else{
                    step4FormData = step4FormData + `event_type_timeslot_pricings[${step4ParamsLength}][id]=${data.id}${!values.spaceRatesGroup.includes(data.eventName) ? `&event_type_timeslot_pricings[${step4ParamsLength}][deleted]=1`:''}&&event_type_timeslot_pricings[${step4ParamsLength}][timeslot_id]=${data.timeSlotId}&event_type_timeslot_pricings[${step4ParamsLength}][event_type_id]=${data.eventId}&event_type_timeslot_pricings[${step4ParamsLength}][pricing_type]=${data.pricingType}${weekday1 !== 0 ? `&event_type_timeslot_pricings[${step4ParamsLength}][weekdays][1]=${weekday1}` : ''}${weekday2 !== 0 ? `&event_type_timeslot_pricings[${step4ParamsLength}][weekdays][2]=${weekday2}` : ''}${weekday3 !== 0 ? `&event_type_timeslot_pricings[${step4ParamsLength}][weekdays][3]=${weekday3}` : ''}${weekday4 !== 0 ? `&event_type_timeslot_pricings[${step4ParamsLength}][weekdays][4]=${weekday4}` : ''}${weekday5 !== 0 ? `&event_type_timeslot_pricings[${step4ParamsLength}][weekdays][5]=${weekday5}` : ''}${weekday6 !== 0 ? `&event_type_timeslot_pricings[${step4ParamsLength}][weekdays][6]=${weekday6}` : ''}${weekday7 !== 0 ? `&event_type_timeslot_pricings[${step4ParamsLength}][weekdays][7]=${weekday7}` : ''}&event_type_timeslot_pricings[${step4ParamsLength}][base_rate]=${additionalFlatRate}${data.additionalRate ? `&event_type_timeslot_pricings[${step4ParamsLength}][overage_rate]=${addRate}` : ''}&event_type_timeslot_pricings[${step4ParamsLength}][pricing_type_name]=${encodeURIComponent(data.additionalPricingTypeName)}&`
                    obj.id=data.id;
                    obj.timeslot_id=data.timeSlotId;
                    obj.event_type_id=data.eventId;
                    obj.pricing_type=data.pricingType;
                    obj.weekdays=weekday;
                    obj.base_rate=additionalFlatRate;
                    obj.pricing_type_name=data.additionalPricingTypeName;

                    if(!values.spaceRatesGroup.includes(data.eventName)){
                      obj.deleted=1;
                    }
                    if(data.additionalRate){
                      obj.overage_rate=addRate;
                    }
                    arr.push(obj);
                  }
                }
                else if(data.flatRate !== '' && data.additionalPricingTypeName === ''){

                  if(data.pricingType === 4){
                    step4FormData = step4FormData + `event_type_timeslot_pricings[${step4ParamsLength}][id]=${data.id}${!values.spaceRatesGroup.includes(data.eventName) ? `&event_type_timeslot_pricings[${step4ParamsLength}][deleted]=1`:''}&&event_type_timeslot_pricings[${step4ParamsLength}][timeslot_id]=${data.timeSlotId}&event_type_timeslot_pricings[${step4ParamsLength}][event_type_id]=${data.eventId}&event_type_timeslot_pricings[${step4ParamsLength}][pricing_type]=${data.pricingType}${weekday1 !== 0 ? `&event_type_timeslot_pricings[${step4ParamsLength}][weekdays][1]=${weekday1}` : ''}${weekday2 !== 0 ? `&event_type_timeslot_pricings[${step4ParamsLength}][weekdays][2]=${weekday2}` : ''}${weekday3 !== 0 ? `&event_type_timeslot_pricings[${step4ParamsLength}][weekdays][3]=${weekday3}` : ''}${weekday4 !== 0 ? `&event_type_timeslot_pricings[${step4ParamsLength}][weekdays][4]=${weekday4}` : ''}${weekday5 !== 0 ? `&event_type_timeslot_pricings[${step4ParamsLength}][weekdays][5]=${weekday5}` : ''}${weekday6 !== 0 ? `&event_type_timeslot_pricings[${step4ParamsLength}][weekdays][6]=${weekday6}` : ''}${weekday7 !== 0 ? `&event_type_timeslot_pricings[${step4ParamsLength}][weekdays][7]=${weekday7}` : ''}&event_type_timeslot_pricings[${step4ParamsLength}][base_rate]=${flatRate}${data.additionalRate ? `&event_type_timeslot_pricings[${step4ParamsLength}][overage_rate]=${addRate}` : ''}&event_type_timeslot_pricings[${step4ParamsLength}][min_guest]=${data.minGuest}&`
                
                    obj.id=data.id;
                    obj.timeslot_id=data.timeSlotId;
                    obj.event_type_id=data.eventId;
                    obj.pricing_type=data.pricingType;
                    obj.weekdays=weekday;
                    obj.base_rate=flatRate;
                    obj.min_guest=data.minGuest ? data.minGuest : 0; 

                    if(!values.spaceRatesGroup.includes(data.eventName)){
                      obj.deleted=1;
                    }
                    if(data.additionalRate){
                      obj.overage_rate=addRate;
                    }
                    arr.push(obj);
                  }
                  else if(fnbRate && data.pricingType === 3){
                    step4FormData = step4FormData + `event_type_timeslot_pricings[${step4ParamsLength}][id]=${data.id}&event_type_timeslot_pricings[${step4ParamsLength}][timeslot_id]=${data.timeSlotId}&${!values.spaceRatesGroup.includes(data.eventName) ? `&event_type_timeslot_pricings[${step4ParamsLength}][deleted]=1`:''}&event_type_timeslot_pricings[${step4ParamsLength}][event_type_id]=${data.eventId}&event_type_timeslot_pricings[${step4ParamsLength}][pricing_type]=${data.pricingType}${weekday1 !== 0 ? `&event_type_timeslot_pricings[${step4ParamsLength}][weekdays][1]=${weekday1}` : ''}${weekday2 !== 0 ? `&event_type_timeslot_pricings[${step4ParamsLength}][weekdays][2]=${weekday2}` : ''}${weekday3 !== 0 ? `&event_type_timeslot_pricings[${step4ParamsLength}][weekdays][3]=${weekday3}` : ''}${weekday4 !== 0 ? `&event_type_timeslot_pricings[${step4ParamsLength}][weekdays][4]=${weekday4}` : ''}${weekday5 !== 0 ? `&event_type_timeslot_pricings[${step4ParamsLength}][weekdays][5]=${weekday5}` : ''}${weekday6 !== 0 ? `&event_type_timeslot_pricings[${step4ParamsLength}][weekdays][6]=${weekday6}` : ''}${weekday7 !== 0 ? `&event_type_timeslot_pricings[${step4ParamsLength}][weekdays][7]=${weekday7}` : ''}&event_type_timeslot_pricings[${step4ParamsLength}][base_rate]=${flatRate}${data.additionalRate ? `&event_type_timeslot_pricings[${step4ParamsLength}][overage_rate]=${addRate}` : ''}&event_type_timeslot_pricings[${step4ParamsLength}][fnb_rate]=${fnbRate}&`
                    obj.id=data.id;
                    obj.timeslot_id=data.timeSlotId;
                    obj.event_type_id=data.eventId;
                    obj.pricing_type=data.pricingType;
                    obj.weekdays=weekday;
                    obj.base_rate=flatRate;
                    obj.fnb_rate=fnbRate; 

                    if(!values.spaceRatesGroup.includes(data.eventName)){
                      obj.deleted=1;
                    }
                    if(data.additionalRate){
                      obj.overage_rate=addRate;
                    }
                    arr.push(obj);

                  }
                  else{
                    step4FormData = step4FormData + `event_type_timeslot_pricings[${step4ParamsLength}][id]=${data.id}&${!values.spaceRatesGroup.includes(data.eventName) ? `&event_type_timeslot_pricings[${step4ParamsLength}][deleted]=1`:''}&event_type_timeslot_pricings[${step4ParamsLength}][timeslot_id]=${data.timeSlotId}&event_type_timeslot_pricings[${step4ParamsLength}][event_type_id]=${data.eventId}&event_type_timeslot_pricings[${step4ParamsLength}][pricing_type]=${data.pricingType}${weekday1 !== 0 ? `&event_type_timeslot_pricings[${step4ParamsLength}][weekdays][1]=${weekday1}` : ''}${weekday2 !== 0 ? `&event_type_timeslot_pricings[${step4ParamsLength}][weekdays][2]=${weekday2}` : ''}${weekday3 !== 0 ? `&event_type_timeslot_pricings[${step4ParamsLength}][weekdays][3]=${weekday3}` : ''}${weekday4 !== 0 ? `&event_type_timeslot_pricings[${step4ParamsLength}][weekdays][4]=${weekday4}` : ''}${weekday5 !== 0 ? `&event_type_timeslot_pricings[${step4ParamsLength}][weekdays][5]=${weekday5}` : ''}${weekday6 !== 0 ? `&event_type_timeslot_pricings[${step4ParamsLength}][weekdays][6]=${weekday6}` : ''}${weekday7 !== 0 ? `&event_type_timeslot_pricings[${step4ParamsLength}][weekdays][7]=${weekday7}` : ''}&event_type_timeslot_pricings[${step4ParamsLength}][base_rate]=${flatRate}${data.additionalRate ? `&event_type_timeslot_pricings[${step4ParamsLength}][overage_rate]=${addRate}` : ''}&`
                    obj.id=data.id;
                    obj.timeslot_id=data.timeSlotId;
                    obj.event_type_id=data.eventId;
                    obj.pricing_type=data.pricingType;
                    obj.weekdays=weekday;
                    obj.base_rate=flatRate;

                    if(!values.spaceRatesGroup.includes(data.eventName)){
                      obj.deleted=1;
                    }
                    if(data.additionalRate){
                      obj.overage_rate=addRate;
                    }
                    arr.push(obj);

                  }
                }
              }
              else {
                let obj={};
                if(data.additionalPricingTypeName && data.additionalPricingTypeName !== '' && data.flatRate === ''){
                  if(data.pricingType === 4){
                    step4FormData = step4FormData = step4FormData + `event_type_timeslot_pricings[${step4ParamsLength}][timeslot_id]=${data.timeSlotId}&event_type_timeslot_pricings[${step4ParamsLength}][event_type_id]=${data.eventId}&event_type_timeslot_pricings[${step4ParamsLength}][pricing_type]=${data.pricingType}${weekday1 !== 0 ? `&event_type_timeslot_pricings[${step4ParamsLength}][weekdays][1]=${weekday1}` : ''}${weekday2 !== 0 ? `&event_type_timeslot_pricings[${step4ParamsLength}][weekdays][2]=${weekday2}` : ''}${weekday3 !== 0 ? `&event_type_timeslot_pricings[${step4ParamsLength}][weekdays][3]=${weekday3}` : ''}${weekday4 !== 0 ? `&event_type_timeslot_pricings[${step4ParamsLength}][weekdays][4]=${weekday4}` : ''}${weekday5 !== 0 ? `&event_type_timeslot_pricings[${step4ParamsLength}][weekdays][5]=${weekday5}` : ''}${weekday6 !== 0 ? `&event_type_timeslot_pricings[${step4ParamsLength}][weekdays][6]=${weekday6}` : ''}${weekday7 !== 0 ? `&event_type_timeslot_pricings[${step4ParamsLength}][weekdays][7]=${weekday7}` : ''}&event_type_timeslot_pricings[${step4ParamsLength}][base_rate]=${additionalFlatRate}${data.additionalRate ? `&event_type_timeslot_pricings[${step4ParamsLength}][overage_rate]=${addRate}` : ''}&event_type_timeslot_pricings[${step4ParamsLength}][min_guest]=${data.minGuest}&event_type_timeslot_pricings[${step4ParamsLength}][pricing_type_name]=${encodeURIComponent(data.additionalPricingTypeName)}&`
                    
                    obj.timeslot_id=data.timeSlotId;
                    obj.event_type_id=data.eventId;
                    obj.pricing_type=data.pricingType;
                    obj.weekdays=weekday;
                    obj.base_rate=additionalFlatRate;
                    obj.min_guest=data.minGuest ? data.minGuest:0; 
                    obj.pricing_type_name=data.additionalPricingTypeName;

                    if(data.additionalRate){
                      obj.overage_rate=addRate;
                    }


                    arr.push(obj);

                  }
                  else if(data.fnbRate && data.pricingType === 3){
                    step4FormData = step4FormData + `event_type_timeslot_pricings[${step4ParamsLength}][timeslot_id]=${data.timeSlotId}&event_type_timeslot_pricings[${step4ParamsLength}][event_type_id]=${data.eventId}&event_type_timeslot_pricings[${step4ParamsLength}][pricing_type]=${data.pricingType}${weekday1 !== 0 ? `&event_type_timeslot_pricings[${step4ParamsLength}][weekdays][1]=${weekday1}` : ''}${weekday2 !== 0 ? `&event_type_timeslot_pricings[${step4ParamsLength}][weekdays][2]=${weekday2}` : ''}${weekday3 !== 0 ? `&event_type_timeslot_pricings[${step4ParamsLength}][weekdays][3]=${weekday3}` : ''}${weekday4 !== 0 ? `&event_type_timeslot_pricings[${step4ParamsLength}][weekdays][4]=${weekday4}` : ''}${weekday5 !== 0 ? `&event_type_timeslot_pricings[${step4ParamsLength}][weekdays][5]=${weekday5}` : ''}${weekday6 !== 0 ? `&event_type_timeslot_pricings[${step4ParamsLength}][weekdays][6]=${weekday6}` : ''}${weekday7 !== 0 ? `&event_type_timeslot_pricings[${step4ParamsLength}][weekdays][7]=${weekday7}` : ''}&event_type_timeslot_pricings[${step4ParamsLength}][base_rate]=${additionalFlatRate}${data.additionalRate ? `&event_type_timeslot_pricings[${step4ParamsLength}][overage_rate]=${addRate}` : ''}&event_type_timeslot_pricings[${step4ParamsLength}][fnb_rate]=${fnbRate}&event_type_timeslot_pricings[${step4ParamsLength}][pricing_type_name]=${encodeURIComponent(data.additionalPricingTypeName)}&`
                    obj.timeslot_id=data.timeSlotId;
                    obj.event_type_id=data.eventId;
                    obj.pricing_type=data.pricingType;
                    obj.weekdays=weekday;
                    obj.base_rate=additionalFlatRate;
                    obj.fnb_rate=fnbRate; 
                    obj.pricing_type_name=data.additionalPricingTypeName;

                    if(data.additionalRate){
                      obj.overage_rate=addRate;
                    }
                    arr.push(obj);
                  }
                  else{
                    step4FormData = step4FormData + `event_type_timeslot_pricings[${step4ParamsLength}][timeslot_id]=${data.timeSlotId}&event_type_timeslot_pricings[${step4ParamsLength}][event_type_id]=${data.eventId}&event_type_timeslot_pricings[${step4ParamsLength}][pricing_type]=${data.pricingType}${weekday1 !== 0 ? `&event_type_timeslot_pricings[${step4ParamsLength}][weekdays][1]=${weekday1}` : ''}${weekday2 !== 0 ? `&event_type_timeslot_pricings[${step4ParamsLength}][weekdays][2]=${weekday2}` : ''}${weekday3 !== 0 ? `&event_type_timeslot_pricings[${step4ParamsLength}][weekdays][3]=${weekday3}` : ''}${weekday4 !== 0 ? `&event_type_timeslot_pricings[${step4ParamsLength}][weekdays][4]=${weekday4}` : ''}${weekday5 !== 0 ? `&event_type_timeslot_pricings[${step4ParamsLength}][weekdays][5]=${weekday5}` : ''}${weekday6 !== 0 ? `&event_type_timeslot_pricings[${step4ParamsLength}][weekdays][6]=${weekday6}` : ''}${weekday7 !== 0 ? `&event_type_timeslot_pricings[${step4ParamsLength}][weekdays][7]=${weekday7}` : ''}&event_type_timeslot_pricings[${step4ParamsLength}][base_rate]=${additionalFlatRate}${data.additionalRate ? `&event_type_timeslot_pricings[${step4ParamsLength}][overage_rate]=${addRate}` : ''}&event_type_timeslot_pricings[${step4ParamsLength}][pricing_type_name]=${encodeURIComponent(data.additionalPricingTypeName)}&`
                    obj.timeslot_id=data.timeSlotId;
                    obj.event_type_id=data.eventId;
                    obj.pricing_type=data.pricingType;
                    obj.weekdays=weekday;
                    obj.base_rate=additionalFlatRate;
                    obj.pricing_type_name=data.additionalPricingTypeName;

                    if(data.additionalRate){
                      obj.overage_rate=addRate;
                    }


                    arr.push(obj);
                  }
                }
                else if(data.flatRate !== '' && data.additionalPricingTypeName === ''){
                  if(data.pricingType === 4){
                    step4FormData = step4FormData = step4FormData + `event_type_timeslot_pricings[${step4ParamsLength}][timeslot_id]=${data.timeSlotId}&event_type_timeslot_pricings[${step4ParamsLength}][event_type_id]=${data.eventId}&event_type_timeslot_pricings[${step4ParamsLength}][pricing_type]=${data.pricingType}${weekday1 !== 0 ? `&event_type_timeslot_pricings[${step4ParamsLength}][weekdays][1]=${weekday1}` : ''}${weekday2 !== 0 ? `&event_type_timeslot_pricings[${step4ParamsLength}][weekdays][2]=${weekday2}` : ''}${weekday3 !== 0 ? `&event_type_timeslot_pricings[${step4ParamsLength}][weekdays][3]=${weekday3}` : ''}${weekday4 !== 0 ? `&event_type_timeslot_pricings[${step4ParamsLength}][weekdays][4]=${weekday4}` : ''}${weekday5 !== 0 ? `&event_type_timeslot_pricings[${step4ParamsLength}][weekdays][5]=${weekday5}` : ''}${weekday6 !== 0 ? `&event_type_timeslot_pricings[${step4ParamsLength}][weekdays][6]=${weekday6}` : ''}${weekday7 !== 0 ? `&event_type_timeslot_pricings[${step4ParamsLength}][weekdays][7]=${weekday7}` : ''}&event_type_timeslot_pricings[${step4ParamsLength}][base_rate]=${flatRate}${data.additionalRate ? `&event_type_timeslot_pricings[${step4ParamsLength}][overage_rate]=${addRate}` : ''}&event_type_timeslot_pricings[${step4ParamsLength}][min_guest]=${data.minGuest}&`
                    obj.timeslot_id=data.timeSlotId;
                    obj.event_type_id=data.eventId;
                    obj.pricing_type=data.pricingType;
                    obj.weekdays=weekday;
                    obj.base_rate=flatRate;
                    obj.min_guest=data.minGuest ? data.minGuest : 0; 

                    if(data.additionalRate){
                      obj.overage_rate=addRate;
                    }


                    arr.push(obj);
                  }
                  else if(data.fnbRate && data.pricingType === 3){
                    step4FormData = step4FormData + `event_type_timeslot_pricings[${step4ParamsLength}][timeslot_id]=${data.timeSlotId}&event_type_timeslot_pricings[${step4ParamsLength}][event_type_id]=${data.eventId}&event_type_timeslot_pricings[${step4ParamsLength}][pricing_type]=${data.pricingType}${weekday1 !== 0 ? `&event_type_timeslot_pricings[${step4ParamsLength}][weekdays][1]=${weekday1}` : ''}${weekday2 !== 0 ? `&event_type_timeslot_pricings[${step4ParamsLength}][weekdays][2]=${weekday2}` : ''}${weekday3 !== 0 ? `&event_type_timeslot_pricings[${step4ParamsLength}][weekdays][3]=${weekday3}` : ''}${weekday4 !== 0 ? `&event_type_timeslot_pricings[${step4ParamsLength}][weekdays][4]=${weekday4}` : ''}${weekday5 !== 0 ? `&event_type_timeslot_pricings[${step4ParamsLength}][weekdays][5]=${weekday5}` : ''}${weekday6 !== 0 ? `&event_type_timeslot_pricings[${step4ParamsLength}][weekdays][6]=${weekday6}` : ''}${weekday7 !== 0 ? `&event_type_timeslot_pricings[${step4ParamsLength}][weekdays][7]=${weekday7}` : ''}&event_type_timeslot_pricings[${step4ParamsLength}][base_rate]=${flatRate}${data.additionalRate ? `&event_type_timeslot_pricings[${step4ParamsLength}][overage_rate]=${addRate}` : ''}&event_type_timeslot_pricings[${step4ParamsLength}][fnb_rate]=${fnbRate}&`
                    obj.timeslot_id=data.timeSlotId;
                    obj.event_type_id=data.eventId;
                    obj.pricing_type=data.pricingType;
                    obj.weekdays=weekday;
                    obj.base_rate=flatRate;
                    obj.fnb_rate=fnbRate; 

                    if(data.additionalRate){
                      obj.overage_rate=addRate;
                    }


                    arr.push(obj);
                  }
                  else{
                    step4FormData = step4FormData + `event_type_timeslot_pricings[${step4ParamsLength}][timeslot_id]=${data.timeSlotId}&event_type_timeslot_pricings[${step4ParamsLength}][event_type_id]=${data.eventId}&event_type_timeslot_pricings[${step4ParamsLength}][pricing_type]=${data.pricingType}${weekday1 !== 0 ? `&event_type_timeslot_pricings[${step4ParamsLength}][weekdays][1]=${weekday1}` : ''}${weekday2 !== 0 ? `&event_type_timeslot_pricings[${step4ParamsLength}][weekdays][2]=${weekday2}` : ''}${weekday3 !== 0 ? `&event_type_timeslot_pricings[${step4ParamsLength}][weekdays][3]=${weekday3}` : ''}${weekday4 !== 0 ? `&event_type_timeslot_pricings[${step4ParamsLength}][weekdays][4]=${weekday4}` : ''}${weekday5 !== 0 ? `&event_type_timeslot_pricings[${step4ParamsLength}][weekdays][5]=${weekday5}` : ''}${weekday6 !== 0 ? `&event_type_timeslot_pricings[${step4ParamsLength}][weekdays][6]=${weekday6}` : ''}${weekday7 !== 0 ? `&event_type_timeslot_pricings[${step4ParamsLength}][weekdays][7]=${weekday7}` : ''}&event_type_timeslot_pricings[${step4ParamsLength}][base_rate]=${flatRate}${data.additionalRate ? `&event_type_timeslot_pricings[${step4ParamsLength}][overage_rate]=${addRate}` : ''}&`
                    obj.timeslot_id=data.timeSlotId;
                    obj.event_type_id=data.eventId;
                    obj.pricing_type=data.pricingType;
                    obj.weekdays=weekday;
                    obj.base_rate=flatRate;

                    if(data.additionalRate){
                      obj.overage_rate=addRate;
                    }


                    arr.push(obj);
                  }
                }
              }
              return step4FormData;
            })
            let obj_final={};
            obj_final.p=arr;
            this.props.updateSpace(this.props.spaceData.id, obj_final,'spaceEditpub').then((response) => {
              if (response.id&&this.state.exitclick==='exit') {
                if (this.props.match.params.spaceId) {
                  this.props.history.push(`/spaceProfileEdit/${response.id}`)
                }
                else {
                  this.props.history.push(`/spaceComplete/${response.id}`)
                }
              }
              else if (submissionType === 'next') {
                this.incrementStep(actions.setFieldValue, values)
              }
            })
              .catch(error => {})
          }
        // });
      }
    }
  };
  changeSubmission = (type) => {
    submissionType = type
    this.setState({exitclick:false})
  }

  handleCopyTimeSlot = (event,values) => {
    // let ok=window.confirm("Are you sure you want to copy availability and pricing ?")
    // if(ok)
    //{
    let timeSlotType = []
    let fixedRentalAvailability = []
    let flexibleRentalAvailability = []
    let eventSpaceRatesArray = []
    let spaceRatesGroup = []
    let pricingCategory = this.state.pricingCategory
    let pricingCategoryArray = this.state.pricingCategory
    let fixedEventPopper = []
    let flexibleEventPopper = []
    let flexibleRental = false
    let fixedValue = false
    let arr = []
    let array = []
    let responeTimeslot
    let timeSlotSave=[]
    let weekdays = []
    const spaceId = this.state.copyTargetSpaceId.toString()
    this.setState({ copyTimeSlot: true, copyAvailableName: this.state.copyAvailableTargetName})
    this.props.getSpaceTimeslots(spaceId).then((response) => {
      responeTimeslot=response
      response.timeslots.map((timeslot, index) => {
        let days = [], flexibleDays = []
        
        if (timeslot.fixed_rental === 1) {
          fixedValue =  true
          if (timeslot.sunday === 1) {
            days.push('sunday')
          }
          if (timeslot.monday === 1) {
            days.push('monday')
          }
          if (timeslot.tuesday === 1) {
            days.push('tuesday')
          }
          if (timeslot.wednesday === 1) {
            days.push('wednesday')
          }
          if (timeslot.thursday === 1) {
            days.push('thursday')
          }
          if (timeslot.friday === 1) {
            days.push('friday')
          }
          if (timeslot.saturday === 1) {
            days.push('saturday')
          }
          if (!timeSlotType.includes("fixedValue")) {
            timeSlotType.push("fixedValue")
          }
          if (days.length === 1) {
            arr.push(`${days[0].charAt(0).toUpperCase() + days[0].substring(1, 3)}`)
          }
          else if (days.length === 2) {
            if(days.includes('sunday') && days.includes('monday')){
              arr.push('Sun-Mon')
            }
            else if(days.includes('monday') && days.includes('tuesday')){
              arr.push('Mon-Tues')
            }
            else if(days.includes('tuesday') && days.includes('wednesday')){
              arr.push('Tues-Wed')
            }
            else if(days.includes('wednesday') && days.includes('thursday')){
              arr.push('Wed-Thu')
            }
            else if(days.includes('thursday') && days.includes('friday')){
              arr.push('Thu-Fri')
            }
            else if(days.includes('friday') && days.includes('saturday')){
              arr.push('Fri-Sat')
            }
            else if(days.includes('saturday') && days.includes('sunday')){
              arr.push('Sat-Sun')
            }
            else{
              arr.push(`${days[0].charAt(0).toUpperCase() + days[0].substring(1, 3)}-${days[1].charAt(0).toUpperCase() + days[1].substring(1, 3)}`)
            }
          }
          else {
            if (days.includes('sunday') && days.includes('monday') && days.includes('tuesday') && days.includes('wednesday') && days.includes('thursday') && days.includes('friday') && days.includes('saturday') && days.length === 7) {
              arr.push('Sat-Sun')
            }
            else if (days.includes('monday') && days.includes('tuesday') && days.includes('wednesday') && days.includes('thursday') && days.includes('friday') && days.includes('saturday') && days.length === 6) {
              arr.push('Mon-Sat')
            }
            else if (days.includes('tuesday') && days.includes('wednesday') && days.includes('thursday') && days.includes('friday') && days.includes('saturday') && days.length === 5) {
              arr.push('Tues-Sat')
            }
            else if (days.includes('wednesday') && days.includes('thursday') && days.includes('friday') && days.includes('saturday') && days.length === 4) {
              arr.push('Wed-Sat')
            }
            else if (days.includes('thursday') && days.includes('friday') && days.includes('saturday') && days.length === 3) {
              arr.push('Thu-Sat')
            }
            else if (days.includes('sunday') && days.includes('monday') && days.includes('tuesday') && days.includes('wednesday') && days.includes('thursday') && days.includes('friday') && days.length === 6) {
              arr.push('Sun-Fri')
            }
            else if (days.includes('sunday') && days.includes('monday') && days.includes('tuesday') && days.includes('wednesday') && days.includes('thursday') && days.length === 5) {
              arr.push('Sun-Thu')
            }
            else if (days.includes('sunday') && days.includes('monday') && days.includes('tuesday') && days.includes('wednesday') && days.length === 4) {
              arr.push('Sun-Wed')
            }
            else if (days.includes('sunday') && days.includes('monday') && days.includes('tuesday') && days.length === 3) {
              arr.push('Sun-Tues')
            }
            else if (days.includes('monday') && days.includes('tuesday') && days.includes('wednesday') && days.includes('thursday') && days.includes('friday') && days.length === 5) {
              arr.push('Mon-Fri')
            }
            else if (days.includes('monday') && days.includes('tuesday') && days.includes('wednesday') && days.includes('thursday') && days.length === 4) {
              arr.push('Mon-Thu')
            }
            else if (days.includes('monday') && days.includes('tuesday') && days.includes('wednesday') && days.length === 3) {
              arr.push('Mon-Wed')
            }
            else if (days.includes('tuesday') && days.includes('wednesday') && days.includes('thursday') && days.includes('friday') && days.length === 4) {
              arr.push('Tues-Fri')
            }
            else if (days.includes('tuesday') && days.includes('wednesday') && days.includes('thursday') && days.length === 3) {
              arr.push('Tues-Thu')
            }
            else if (days.includes('wednesday') && days.includes('thursday') && days.includes('friday') && days.length === 3) {
              arr.push('Wed-Fri')
            }
            else {
              let dayStr = ''
              if (days.includes('sunday')) {
                dayStr = dayStr + 'Sun'
              }
              if (days.includes('monday')) {
                if (dayStr.length > 0) {
                  dayStr = dayStr + '-Mon'
                }
                else {
                  dayStr = dayStr + 'Mon'
                }
              }
              if (days.includes('tuesday')) {
                if (dayStr.length > 0) {
                  dayStr = dayStr + '-Tues'
                }
                else {
                  dayStr = dayStr + 'Tues'
                }
              }
              if (days.includes('wednesday')) {
                if (dayStr.length > 0) {
                  dayStr = dayStr + '-Wed'
                }
                else {
                  dayStr = dayStr + 'Wed'
                }
              }
              if (days.includes('thursday')) {
                if (dayStr.length > 0) {
                  dayStr = dayStr + '-Thu'
                }
                else {
                  dayStr = dayStr + 'Thu'
                }
              }
              if (days.includes('friday')) {
                if (dayStr.length > 0) {
                  dayStr = dayStr + '-Fri'
                }
                else {
                  dayStr = dayStr + 'Fri'
                }
              }
              if (days.includes('saturday')) {
                if (dayStr.length > 0) {
                  dayStr = dayStr + '-Sat'
                }
                else {
                  dayStr = dayStr + 'Sat'
                }
              }
              arr.push(dayStr)
            }
          }
          const startTime = timeslot.start_time.substring(0, timeslot.start_time.length - 3)
          const endTime = timeslot.end_time.substring(0, timeslot.end_time.length - 3)
          this.setState({ fixedRentalDaysArray: days })
          fixedRentalAvailability.push({ startTime: startTime, endTime: endTime, days: days, additionalCost: false, sunday: timeslot.sunday === 1 ? true : false, monday: timeslot.monday === 1 ? true : false, tuesday: timeslot.tuesday ? true : false, wednesday: timeslot.wednesday ? true : false, thursday: timeslot.thursday ? true : false, friday: timeslot.friday ? true : false, saturday: timeslot.saturday ? true : false })
        }
        else if (timeslot.fixed_rental === 0) {
          flexibleRental = true
          if (timeslot.sunday === 1) {
            flexibleDays.push('sunday')
          }
          if (timeslot.monday === 1) {
            flexibleDays.push('monday')
          }
          if (timeslot.tuesday === 1) {
            flexibleDays.push('tuesday')
          }
          if (timeslot.wednesday === 1) {
            flexibleDays.push('wednesday')
          }
          if (timeslot.thursday === 1) {
            flexibleDays.push('thursday')
          }
          if (timeslot.friday === 1) {
            flexibleDays.push('friday')
          }
          if (timeslot.saturday === 1) {
            flexibleDays.push('saturday')
          }
          if (flexibleDays.length === 1) {
            array.push(`${flexibleDays[0].charAt(0).toUpperCase() + flexibleDays[0].substring(1, 3)}`)
          }
          else if (flexibleDays.length === 2) {
            if(flexibleDays.includes('sunday') && flexibleDays.includes('monday')){
              array.push('Sun-Mon')
            }
            else if(flexibleDays.includes('monday') && flexibleDays.includes('tuesday')){
              array.push('Mon-Tues')
            }
            else if(flexibleDays.includes('tuesday') && flexibleDays.includes('wednesday')){
              array.push('Tues-Wed')
            }
            else if(flexibleDays.includes('wednesday') && flexibleDays.includes('thursday')){
              array.push('Wed-Thu')
            }
            else if(flexibleDays.includes('thursday') && flexibleDays.includes('friday')){
              array.push('Thu-Fri')
            }
            else if(flexibleDays.includes('friday') && flexibleDays.includes('saturday')){
              array.push('Fri-Sat')
            }
            else if(flexibleDays.includes('saturday') && flexibleDays.includes('sunday')){
              array.push('Sat-Sun')
            }
            else{
              array.push(`${flexibleDays[0].charAt(0).toUpperCase() + flexibleDays[0].substring(1, 3)}-${flexibleDays[1].charAt(0).toUpperCase() + flexibleDays[1].substring(1, 3)}`)
            }
          }
          else {
            if (flexibleDays.includes('sunday') && flexibleDays.includes('monday') && flexibleDays.includes('tuesday') && flexibleDays.includes('wednesday') && flexibleDays.includes('thursday') && flexibleDays.includes('friday') && flexibleDays.includes('saturday') && flexibleDays.length === 7) {
              array.push('Sat-Sun')
            }
            else if (flexibleDays.includes('monday')&& flexibleDays.includes('tuesday') && flexibleDays.includes('wednesday') && flexibleDays.includes('thursday') && flexibleDays.includes('friday') && flexibleDays.includes('saturday') && flexibleDays.length === 6) {
              array.push('Mon-Sat')
            }
            else if (flexibleDays.includes('tuesday') && flexibleDays.includes('wednesday') && flexibleDays.includes('thursday') && flexibleDays.includes('friday') && flexibleDays.includes('saturday') && flexibleDays.length === 5) {
              array.push('Tues-Sat')
            }
            else if (flexibleDays.includes('wednesday') && flexibleDays.includes('thursday') && flexibleDays.includes('friday') && flexibleDays.includes('saturday') && flexibleDays.length === 4) {
              array.push('Wed-Sat')
            }
            else if (flexibleDays.includes('thursday') && flexibleDays.includes('friday') && flexibleDays.includes('saturday') && flexibleDays.length === 3) {
              array.push('Thu-Sat')
            }
            else if (flexibleDays.includes('sunday') && flexibleDays.includes('monday') && flexibleDays.includes('tuesday') && flexibleDays.includes('wednesday') && flexibleDays.includes('thursday') && flexibleDays.includes('friday') && flexibleDays.length === 6) {
              array.push('Sun-Fri')
            }
            else if (flexibleDays.includes('sunday') && flexibleDays.includes('monday') && flexibleDays.includes('tuesday') && flexibleDays.includes('wednesday') && flexibleDays.includes('thursday') && flexibleDays.length === 5) {
              array.push('Sun-Thu')
            }
            else if (flexibleDays.includes('sunday') && flexibleDays.includes('monday') && flexibleDays.includes('tuesday') && flexibleDays.includes('wednesday') && flexibleDays.length === 4) {
              array.push('Sun-Wed')
            }
            else if (flexibleDays.includes('sunday') && flexibleDays.includes('monday') && flexibleDays.includes('tuesday') && flexibleDays.length === 3) {
              array.push('Sun-Tues')
            }
            else if (flexibleDays.includes('monday') && flexibleDays.includes('tuesday') && flexibleDays.includes('wednesday') && flexibleDays.includes('thursday') && flexibleDays.includes('friday') && flexibleDays.length === 5) {
              array.push('Mon-Fri')
            }
            else if (flexibleDays.includes('monday') && flexibleDays.includes('tuesday') && flexibleDays.includes('wednesday') && flexibleDays.includes('thursday') && flexibleDays.length === 4) {
              array.push('Mon-Thu')
            }
            else if (flexibleDays.includes('monday') && flexibleDays.includes('tuesday') && flexibleDays.includes('wednesday') && flexibleDays.length === 3) {
              array.push('Mon-Wed')
            }
            else if (flexibleDays.includes('tuesday') && flexibleDays.includes('wednesday') && flexibleDays.includes('thursday') && flexibleDays.includes('friday') && flexibleDays.length === 4) {
              array.push('Tues-Fri')
            }
            else if (flexibleDays.includes('tuesday') && flexibleDays.includes('wednesday') && flexibleDays.includes('thursday') && flexibleDays.length === 3) {
              array.push('Tues-Thu')
            }
            else if (flexibleDays.includes('wednesday') && flexibleDays.includes('thursday') && flexibleDays.includes('friday') && flexibleDays.length === 3) {
              array.push('Wed-Fri')
            }
            else {
              let dayStr = ''
              if (flexibleDays.includes('sunday')) {
                dayStr = dayStr + 'Sun'
              }
              if (flexibleDays.includes('monday')) {
                if (dayStr.length > 0) {
                  dayStr = dayStr + '-Mon'
                }
                else {
                  dayStr = dayStr + 'Mon'
                }
              }
              if (flexibleDays.includes('tuesday')) {
                if (dayStr.length > 0) {
                  dayStr = dayStr + '-Tues'
                }
                else {
                  dayStr = dayStr + 'Tues'
                }
              }
              if (flexibleDays.includes('wednesday')) {
                if (dayStr.length > 0) {
                  dayStr = dayStr + '-Wed'
                }
                else {
                  dayStr = dayStr + 'Wed'
                }
              }
              if (flexibleDays.includes('thursday')) {
                if (dayStr.length > 0) {
                  dayStr = dayStr + '-Thu'
                }
                else {
                  dayStr = dayStr + 'Thu'
                }
              }
              if (flexibleDays.includes('friday')) {
                if (dayStr.length > 0) {
                  dayStr = dayStr + '-Fri'
                }
                else {
                  dayStr = dayStr + 'Fri'
                }
              }
              if (flexibleDays.includes('saturday')) {
                if (dayStr.length > 0) {
                  dayStr = dayStr + '-Sat'
                }
                else {
                  dayStr = dayStr + 'Sat'
                }
              }
              array.push(dayStr)
            }
          }
          this.setState({ flexibleRentalDaysArray: days })
          this.setState({ flexibleRentalDaysArray: flexibleDays })
          const startTime = timeslot.start_time.substring(0, timeslot.start_time.length - 3)
          const endTime = timeslot.end_time.substring(0, timeslot.end_time.length - 3)
          const minHour = timeslot.min_hour ? timeslot.min_hour : ''
          const showMaxHour = timeslot.max_hour ? true : false
          const maxHour = timeslot.max_hour ? timeslot.max_hour : ''
          const multipleEvent =timeslot.multiple_event
          const timeBetweenEvent = timeslot.time_between_event ? timeslot.time_between_event :''
          flexibleRentalAvailability.push({ startTime: startTime, endTime: endTime, days: flexibleDays, additionalCost: false, monday: timeslot.monday === 1 ? true : false, tuesday: timeslot.tuesday ? true : false, wednesday: timeslot.wednesday ? true : false, thursday: timeslot.thursday ? true : false, friday: timeslot.friday ? true : false, saturday: timeslot.saturday ? true : false, sunday:timeslot.sunday ? true : false, maxHour:maxHour, minHour:minHour, showMaxHour:showMaxHour, multipleEvent:multipleEvent, timeBetweenEvent:timeBetweenEvent })
          if (!timeSlotType.includes("flexibleRental")) {
            timeSlotType.push("flexibleRental")
          }
        }
        this.setState({fixedValue: fixedValue, flexibleRental: flexibleRental})
        if (fixedRentalAvailability.length > 0 && fixedValue === true) {
          this.setState({ fixedRentalAvailability: fixedRentalAvailability })
        }
        else{
          const fixedRentalAvailability= [{ idx: 1, id: '', startTime: '', endTime: '', days: [], additionalCost: false, sunday: false, monday: false, tuesday: false, wednesday: false, thursday: false, friday: false, saturday: false }]
          this.setState({ fixedRentalAvailability: fixedRentalAvailability })
        }
        if (flexibleRentalAvailability.length > 0 && flexibleRental === true) {
          this.setState({ flexibleRentalAvailability: flexibleRentalAvailability })
        }
        else{
          const flexibleRentalAvailability= [{ idx: 1, id: '', startTime: '', endTime: '', days: [], additionalCost: false, sunday: false, monday: false, tuesday: false, wednesday: false, thursday: false, friday: false, saturday: false, maxHour:'', minHour:'', showMaxHour:false, multipleEvent:1, timeBetweenEvent:'' }]
          this.setState({ flexibleRentalAvailability: flexibleRentalAvailability })
        }
        if (eventSpaceRatesArray.length > 0) {
          this.setState({ eventSpaceRatesArray: eventSpaceRatesArray, spaceRatesGroup: spaceRatesGroup, pricingCategory: pricingCategory })
       }

        return '';
      })
      this.setState({fixedRentalAvailabilitySortedDays: arr, flexibleRentalAvailabilitySortedDays: array})
      if(arr.length>0||array.length>0)
        {  
            step3ParamsLength = 0
            step3FormData = ''
            if (this.props.spaceData !== {} && this.props.spaceData.id) {
              const spaceData = this.props.spaceData
              // if (values.fixedValue === true) {
              if (fixedRentalAvailability.length > 0) {
                fixedRentalAvailability.map((data, index) => {
                  let startTime = '', endTime = ''
                  startTime = moment(data.startTime, "h:mm A").format("HH:mm:ss")
                  endTime = moment(data.endTime, "h:mm A").format("HH:mm:ss")
                  const sunday = data.sunday ? 1 : 0
                  const monday = data.monday ? 1 : 0
                  const tuesday = data.tuesday ? 1 : 0
                  const wednesday = data.wednesday ? 1 : 0
                  const thursday = data.thursday ? 1 : 0
                  const friday = data.friday ? 1 : 0
                  const saturday = data.saturday ? 1 : 0
                  step3ParamsLength = step3ParamsLength + 1
                  // let timeSlotExists
                  if (spaceData.timeslots.length === 0 && fixedValue === true) {
                    step3FormData = step3FormData + `timeslots[${step3ParamsLength}][fixed_rental]=1&timeslots[${step3ParamsLength}][addl_hours]=${data.additionalCost ? 1 : 0}&timeslots[${step3ParamsLength}][start_time]=${startTime}&timeslots[${step3ParamsLength}][end_time]=${endTime}&timeslots[${step3ParamsLength}][monday]=${monday}&timeslots[${step3ParamsLength}][tuesday]=${tuesday}&timeslots[${step3ParamsLength}][wednesday]=${wednesday}&timeslots[${step3ParamsLength}][thursday]=${thursday}&timeslots[${step3ParamsLength}][friday]=${friday}&timeslots[${step3ParamsLength}][saturday]=${saturday}&timeslots[${step3ParamsLength}][sunday]=${sunday}&`
                  }
                  else {
                    if (data.id && fixedValue === false) {
                      step3FormData = step3FormData + `timeslots[${step3ParamsLength}][id]=${data.id}&timeslots[${step3ParamsLength}][fixed_rental]=1&timeslots[${step3ParamsLength}][deleted]=1&timeslots[${step3ParamsLength}][addl_hours]=${data.additionalCost ? 1 : 0}&timeslots[${step3ParamsLength}][start_time]=${startTime}&timeslots[${step3ParamsLength}][end_time]=${endTime}&timeslots[${step3ParamsLength}][monday]=${monday}&timeslots[${step3ParamsLength}][tuesday]=${tuesday}&timeslots[${step3ParamsLength}][wednesday]=${wednesday}&timeslots[${step3ParamsLength}][thursday]=${thursday}&timeslots[${step3ParamsLength}][friday]=${friday}&timeslots[${step3ParamsLength}][saturday]=${saturday}&timeslots[${step3ParamsLength}][sunday]=${sunday}&`
                    }
                    else if (data.id && fixedValue === true) {
                      step3FormData = step3FormData + `timeslots[${step3ParamsLength}][id]=${data.id}&timeslots[${step3ParamsLength}][fixed_rental]=1&timeslots[${step3ParamsLength}][addl_hours]=${data.additionalCost ? 1 : 0}&timeslots[${step3ParamsLength}][start_time]=${startTime}&timeslots[${step3ParamsLength}][end_time]=${endTime}&timeslots[${step3ParamsLength}][monday]=${monday}&timeslots[${step3ParamsLength}][tuesday]=${tuesday}&timeslots[${step3ParamsLength}][wednesday]=${wednesday}&timeslots[${step3ParamsLength}][thursday]=${thursday}&timeslots[${step3ParamsLength}][friday]=${friday}&timeslots[${step3ParamsLength}][saturday]=${saturday}&timeslots[${step3ParamsLength}][sunday]=${sunday}&`
                    }
                    else if(!data.id &&fixedValue === true) {
                      step3FormData = step3FormData + `timeslots[${step3ParamsLength}][fixed_rental]=1&timeslots[${step3ParamsLength}][addl_hours]=${data.additionalCost ? 1 : 0}&timeslots[${step3ParamsLength}][start_time]=${startTime}&timeslots[${step3ParamsLength}][end_time]=${endTime}&timeslots[${step3ParamsLength}][monday]=${monday}&timeslots[${step3ParamsLength}][tuesday]=${tuesday}&timeslots[${step3ParamsLength}][wednesday]=${wednesday}&timeslots[${step3ParamsLength}][thursday]=${thursday}&timeslots[${step3ParamsLength}][friday]=${friday}&timeslots[${step3ParamsLength}][saturday]=${saturday}&timeslots[${step3ParamsLength}][sunday]=${sunday}&`
                    }
                  }
                  return step3FormData;
                })
               
              }
      
              if(flexibleRentalAvailability.length > 0){
                flexibleRentalAvailability.map(function (data) {
                  let startTime = '', endTime = ''
                  startTime = moment(data.startTime, "h:mm A").format("HH:mm:ss")
                  endTime = moment(data.endTime, "h:mm A").format("HH:mm:ss")
                  const sunday = data.sunday ? 1 : 0
                  const monday = data.monday ? 1 : 0
                  const tuesday = data.tuesday ? 1 : 0
                  const wednesday = data.wednesday ? 1 : 0
                  const thursday = data.thursday ? 1 : 0
                  const friday = data.friday ? 1 : 0
                  const saturday = data.saturday ? 1 : 0
                  step3ParamsLength = step3ParamsLength + 1
                  // let timeSlotExists
                  if (spaceData.timeslots.length === 0 && flexibleRental === true) {
                    if(data.maxHour && data.showMaxHour){
                      if(data.multipleEvent === 1){
                        step3FormData = step3FormData + `timeslots[${step3ParamsLength}][fixed_rental]=0&timeslots[${step3ParamsLength}][addl_hours]=${data.additionalCost ? 1 : 0}&timeslots[${step3ParamsLength}][start_time]=${startTime}&timeslots[${step3ParamsLength}][end_time]=${endTime}&timeslots[${step3ParamsLength}][monday]=${monday}&timeslots[${step3ParamsLength}][tuesday]=${tuesday}&timeslots[${step3ParamsLength}][wednesday]=${wednesday}&timeslots[${step3ParamsLength}][thursday]=${thursday}&timeslots[${step3ParamsLength}][friday]=${friday}&timeslots[${step3ParamsLength}][saturday]=${saturday}&timeslots[${step3ParamsLength}][sunday]=${sunday}&timeslots[${step3ParamsLength}][max_hour]=${data.maxHour}&timeslots[${step3ParamsLength}][min_hour]=${data.minHour}&timeslots[${step3ParamsLength}][multiple_event]=${data.multipleEvent}&timeslots[${step3ParamsLength}][time_between_event]=${data.timeBetweenEvent}&`
                      }
                      else{
                        step3FormData = step3FormData + `timeslots[${step3ParamsLength}][fixed_rental]=0&timeslots[${step3ParamsLength}][addl_hours]=${data.additionalCost ? 1 : 0}&timeslots[${step3ParamsLength}][start_time]=${startTime}&timeslots[${step3ParamsLength}][end_time]=${endTime}&timeslots[${step3ParamsLength}][monday]=${monday}&timeslots[${step3ParamsLength}][tuesday]=${tuesday}&timeslots[${step3ParamsLength}][wednesday]=${wednesday}&timeslots[${step3ParamsLength}][thursday]=${thursday}&timeslots[${step3ParamsLength}][friday]=${friday}&timeslots[${step3ParamsLength}][saturday]=${saturday}&timeslots[${step3ParamsLength}][sunday]=${sunday}&timeslots[${step3ParamsLength}][max_hour]=${data.maxHour}&timeslots[${step3ParamsLength}][min_hour]=${data.minHour}&timeslots[${step3ParamsLength}][multiple_event]=${data.multipleEvent}&`
                      }
                    }
                    else{
                      if(data.multipleEvent === 1){
                        step3FormData = step3FormData + `timeslots[${step3ParamsLength}][fixed_rental]=0&timeslots[${step3ParamsLength}][addl_hours]=${data.additionalCost ? 1 : 0}&timeslots[${step3ParamsLength}][start_time]=${startTime}&timeslots[${step3ParamsLength}][end_time]=${endTime}&timeslots[${step3ParamsLength}][monday]=${monday}&timeslots[${step3ParamsLength}][tuesday]=${tuesday}&timeslots[${step3ParamsLength}][wednesday]=${wednesday}&timeslots[${step3ParamsLength}][thursday]=${thursday}&timeslots[${step3ParamsLength}][friday]=${friday}&timeslots[${step3ParamsLength}][saturday]=${saturday}&timeslots[${step3ParamsLength}][sunday]=${sunday}&timeslots[${step3ParamsLength}][min_hour]=${data.minHour}&timeslots[${step3ParamsLength}][multiple_event]=${data.multipleEvent}&timeslots[${step3ParamsLength}][time_between_event]=${data.timeBetweenEvent}&`
                      }
                      else{
                        step3FormData = step3FormData + `timeslots[${step3ParamsLength}][fixed_rental]=0&timeslots[${step3ParamsLength}][addl_hours]=${data.additionalCost ? 1 : 0}&timeslots[${step3ParamsLength}][start_time]=${startTime}&timeslots[${step3ParamsLength}][end_time]=${endTime}&timeslots[${step3ParamsLength}][monday]=${monday}&timeslots[${step3ParamsLength}][tuesday]=${tuesday}&timeslots[${step3ParamsLength}][wednesday]=${wednesday}&timeslots[${step3ParamsLength}][thursday]=${thursday}&timeslots[${step3ParamsLength}][friday]=${friday}&timeslots[${step3ParamsLength}][saturday]=${saturday}&timeslots[${step3ParamsLength}][sunday]=${sunday}&timeslots[${step3ParamsLength}][min_hour]=${data.minHour}&timeslots[${step3ParamsLength}][multiple_event]=${data.multipleEvent}&`
                      }
                    }
                  }
                  else {
                    if (data.id && flexibleRental === true) {
                      if(data.maxHour && data.showMaxHour){
                        if(data.multipleEvent === 1){
                          step3FormData = step3FormData + `timeslots[${step3ParamsLength}][id]=${data.id}&timeslots[${step3ParamsLength}][fixed_rental]=0&timeslots[${step3ParamsLength}][addl_hours]=${data.additionalCost ? 1 : 0}&timeslots[${step3ParamsLength}][start_time]=${startTime}&timeslots[${step3ParamsLength}][end_time]=${endTime}&timeslots[${step3ParamsLength}][monday]=${monday}&timeslots[${step3ParamsLength}][tuesday]=${tuesday}&timeslots[${step3ParamsLength}][wednesday]=${wednesday}&timeslots[${step3ParamsLength}][thursday]=${thursday}&timeslots[${step3ParamsLength}][friday]=${friday}&timeslots[${step3ParamsLength}][saturday]=${saturday}&timeslots[${step3ParamsLength}][sunday]=${sunday}&timeslots[${step3ParamsLength}][max_hour]=${data.maxHour}&timeslots[${step3ParamsLength}][min_hour]=${data.minHour}&timeslots[${step3ParamsLength}][multiple_event]=${data.multipleEvent}&timeslots[${step3ParamsLength}][time_between_event]=${data.timeBetweenEvent}&`
                        }
                        else{
                          step3FormData = step3FormData + `timeslots[${step3ParamsLength}][id]=${data.id}&timeslots[${step3ParamsLength}][fixed_rental]=0&timeslots[${step3ParamsLength}][addl_hours]=${data.additionalCost ? 1 : 0}&timeslots[${step3ParamsLength}][start_time]=${startTime}&timeslots[${step3ParamsLength}][end_time]=${endTime}&timeslots[${step3ParamsLength}][monday]=${monday}&timeslots[${step3ParamsLength}][tuesday]=${tuesday}&timeslots[${step3ParamsLength}][wednesday]=${wednesday}&timeslots[${step3ParamsLength}][thursday]=${thursday}&timeslots[${step3ParamsLength}][friday]=${friday}&timeslots[${step3ParamsLength}][saturday]=${saturday}&timeslots[${step3ParamsLength}][sunday]=${sunday}&timeslots[${step3ParamsLength}][max_hour]=${data.maxHour}&timeslots[${step3ParamsLength}][min_hour]=${data.minHour}&timeslots[${step3ParamsLength}][multiple_event]=${data.multipleEvent}&`
                        }
                      }
                      else{
                        if(data.multipleEvent === 1){
                          step3FormData = step3FormData + `timeslots[${step3ParamsLength}][id]=${data.id}&timeslots[${step3ParamsLength}][fixed_rental]=0&timeslots[${step3ParamsLength}][addl_hours]=${data.additionalCost ? 1 : 0}&timeslots[${step3ParamsLength}][start_time]=${startTime}&timeslots[${step3ParamsLength}][end_time]=${endTime}&timeslots[${step3ParamsLength}][monday]=${monday}&timeslots[${step3ParamsLength}][tuesday]=${tuesday}&timeslots[${step3ParamsLength}][wednesday]=${wednesday}&timeslots[${step3ParamsLength}][thursday]=${thursday}&timeslots[${step3ParamsLength}][friday]=${friday}&timeslots[${step3ParamsLength}][saturday]=${saturday}&timeslots[${step3ParamsLength}][sunday]=${sunday}&timeslots[${step3ParamsLength}][min_hour]=${data.minHour}&timeslots[${step3ParamsLength}][multiple_event]=${data.multipleEvent}&timeslots[${step3ParamsLength}][time_between_event]=${data.timeBetweenEvent}&`
                        }
                        else{
                          step3FormData = step3FormData + `timeslots[${step3ParamsLength}][id]=${data.id}&timeslots[${step3ParamsLength}][fixed_rental]=0&timeslots[${step3ParamsLength}][addl_hours]=${data.additionalCost ? 1 : 0}&timeslots[${step3ParamsLength}][start_time]=${startTime}&timeslots[${step3ParamsLength}][end_time]=${endTime}&timeslots[${step3ParamsLength}][monday]=${monday}&timeslots[${step3ParamsLength}][tuesday]=${tuesday}&timeslots[${step3ParamsLength}][wednesday]=${wednesday}&timeslots[${step3ParamsLength}][thursday]=${thursday}&timeslots[${step3ParamsLength}][friday]=${friday}&timeslots[${step3ParamsLength}][saturday]=${saturday}&timeslots[${step3ParamsLength}][sunday]=${sunday}&timeslots[${step3ParamsLength}][min_hour]=${data.minHour}&timeslots[${step3ParamsLength}][multiple_event]=${data.multipleEvent}&`
                        }
                      }
                      
                    }
                    else if (data.id && flexibleRental === false) {
                      if(data.maxHour && data.showMaxHour){
                        if(data.multipleEvent === 1){
                          step3FormData = step3FormData + `timeslots[${step3ParamsLength}][id]=${data.id}&timeslots[${step3ParamsLength}][deleted]=1&timeslots[${step3ParamsLength}][fixed_rental]=0&timeslots[${step3ParamsLength}][addl_hours]=${data.additionalCost ? 1 : 0}&timeslots[${step3ParamsLength}][start_time]=${startTime}&timeslots[${step3ParamsLength}][end_time]=${endTime}&timeslots[${step3ParamsLength}][monday]=${monday}&timeslots[${step3ParamsLength}][tuesday]=${tuesday}&timeslots[${step3ParamsLength}][wednesday]=${wednesday}&timeslots[${step3ParamsLength}][thursday]=${thursday}&timeslots[${step3ParamsLength}][friday]=${friday}&timeslots[${step3ParamsLength}][saturday]=${saturday}&timeslots[${step3ParamsLength}][sunday]=${sunday}&timeslots[${step3ParamsLength}][max_hour]=${data.maxHour}&timeslots[${step3ParamsLength}][min_hour]=${data.minHour}&timeslots[${step3ParamsLength}][multiple_event]=${data.multipleEvent}&timeslots[${step3ParamsLength}][time_between_event]=${data.timeBetweenEvent}&`
                        }
                        else{
                          step3FormData = step3FormData + `timeslots[${step3ParamsLength}][id]=${data.id}&timeslots[${step3ParamsLength}][deleted]=1&timeslots[${step3ParamsLength}][fixed_rental]=0&timeslots[${step3ParamsLength}][addl_hours]=${data.additionalCost ? 1 : 0}&timeslots[${step3ParamsLength}][start_time]=${startTime}&timeslots[${step3ParamsLength}][end_time]=${endTime}&timeslots[${step3ParamsLength}][monday]=${monday}&timeslots[${step3ParamsLength}][tuesday]=${tuesday}&timeslots[${step3ParamsLength}][wednesday]=${wednesday}&timeslots[${step3ParamsLength}][thursday]=${thursday}&timeslots[${step3ParamsLength}][friday]=${friday}&timeslots[${step3ParamsLength}][saturday]=${saturday}&timeslots[${step3ParamsLength}][sunday]=${sunday}&timeslots[${step3ParamsLength}][max_hour]=${data.maxHour}&timeslots[${step3ParamsLength}][min_hour]=${data.minHour}&timeslots[${step3ParamsLength}][multiple_event]=${data.multipleEvent}&`
                        }
                      }
                      else{
                        if(data.multipleEvent === 1){
                          step3FormData = step3FormData + `timeslots[${step3ParamsLength}][id]=${data.id}&timeslots[${step3ParamsLength}][deleted]=1&timeslots[${step3ParamsLength}][fixed_rental]=0&timeslots[${step3ParamsLength}][addl_hours]=${data.additionalCost ? 1 : 0}&timeslots[${step3ParamsLength}][start_time]=${startTime}&timeslots[${step3ParamsLength}][end_time]=${endTime}&timeslots[${step3ParamsLength}][monday]=${monday}&timeslots[${step3ParamsLength}][tuesday]=${tuesday}&timeslots[${step3ParamsLength}][wednesday]=${wednesday}&timeslots[${step3ParamsLength}][thursday]=${thursday}&timeslots[${step3ParamsLength}][friday]=${friday}&timeslots[${step3ParamsLength}][saturday]=${saturday}&timeslots[${step3ParamsLength}][sunday]=${sunday}&timeslots[${step3ParamsLength}][min_hour]=${data.minHour}&timeslots[${step3ParamsLength}][multiple_event]=${data.multipleEvent}&timeslots[${step3ParamsLength}][time_between_event]=${data.timeBetweenEvent}&`
                        }
                        else{
                          step3FormData = step3FormData + `timeslots[${step3ParamsLength}][id]=${data.id}&timeslots[${step3ParamsLength}][deleted]=1&timeslots[${step3ParamsLength}][fixed_rental]=0&timeslots[${step3ParamsLength}][addl_hours]=${data.additionalCost ? 1 : 0}&timeslots[${step3ParamsLength}][start_time]=${startTime}&timeslots[${step3ParamsLength}][end_time]=${endTime}&timeslots[${step3ParamsLength}][monday]=${monday}&timeslots[${step3ParamsLength}][tuesday]=${tuesday}&timeslots[${step3ParamsLength}][wednesday]=${wednesday}&timeslots[${step3ParamsLength}][thursday]=${thursday}&timeslots[${step3ParamsLength}][friday]=${friday}&timeslots[${step3ParamsLength}][saturday]=${saturday}&timeslots[${step3ParamsLength}][sunday]=${sunday}&timeslots[${step3ParamsLength}][min_hour]=${data.minHour}&timeslots[${step3ParamsLength}][multiple_event]=${data.multipleEvent}&`
                        }
                      }
                      
                    }
                    else if(!data.id && flexibleRental === true) {
                      if(data.maxHour && data.showMaxHour){
                        if(data.multipleEvent === 1){
                          step3FormData = step3FormData + `timeslots[${step3ParamsLength}][fixed_rental]=0&timeslots[${step3ParamsLength}][addl_hours]=${data.additionalCost ? 1 : 0}&timeslots[${step3ParamsLength}][start_time]=${startTime}&timeslots[${step3ParamsLength}][end_time]=${endTime}&timeslots[${step3ParamsLength}][monday]=${monday}&timeslots[${step3ParamsLength}][tuesday]=${tuesday}&timeslots[${step3ParamsLength}][wednesday]=${wednesday}&timeslots[${step3ParamsLength}][thursday]=${thursday}&timeslots[${step3ParamsLength}][friday]=${friday}&timeslots[${step3ParamsLength}][saturday]=${saturday}&timeslots[${step3ParamsLength}][sunday]=${sunday}&timeslots[${step3ParamsLength}][max_hour]=${data.maxHour}&timeslots[${step3ParamsLength}][min_hour]=${data.minHour}&timeslots[${step3ParamsLength}][multiple_event]=${data.multipleEvent}&timeslots[${step3ParamsLength}][time_between_event]=${data.timeBetweenEvent}&`
                        }
                        else{
                          step3FormData = step3FormData + `timeslots[${step3ParamsLength}][fixed_rental]=0&timeslots[${step3ParamsLength}][addl_hours]=${data.additionalCost ? 1 : 0}&timeslots[${step3ParamsLength}][start_time]=${startTime}&timeslots[${step3ParamsLength}][end_time]=${endTime}&timeslots[${step3ParamsLength}][monday]=${monday}&timeslots[${step3ParamsLength}][tuesday]=${tuesday}&timeslots[${step3ParamsLength}][wednesday]=${wednesday}&timeslots[${step3ParamsLength}][thursday]=${thursday}&timeslots[${step3ParamsLength}][friday]=${friday}&timeslots[${step3ParamsLength}][saturday]=${saturday}&timeslots[${step3ParamsLength}][sunday]=${sunday}&timeslots[${step3ParamsLength}][max_hour]=${data.maxHour}&timeslots[${step3ParamsLength}][min_hour]=${data.minHour}&timeslots[${step3ParamsLength}][multiple_event]=${data.multipleEvent}&`
                        }
                      }
                      else{
                        if(data.multipleEvent === 1){
                          step3FormData = step3FormData + `timeslots[${step3ParamsLength}][fixed_rental]=0&timeslots[${step3ParamsLength}][addl_hours]=${data.additionalCost ? 1 : 0}&timeslots[${step3ParamsLength}][start_time]=${startTime}&timeslots[${step3ParamsLength}][end_time]=${endTime}&timeslots[${step3ParamsLength}][monday]=${monday}&timeslots[${step3ParamsLength}][tuesday]=${tuesday}&timeslots[${step3ParamsLength}][wednesday]=${wednesday}&timeslots[${step3ParamsLength}][thursday]=${thursday}&timeslots[${step3ParamsLength}][friday]=${friday}&timeslots[${step3ParamsLength}][saturday]=${saturday}&timeslots[${step3ParamsLength}][sunday]=${sunday}&timeslots[${step3ParamsLength}][min_hour]=${data.minHour}&timeslots[${step3ParamsLength}][multiple_event]=${data.multipleEvent}&timeslots[${step3ParamsLength}][time_between_event]=${data.timeBetweenEvent}&`
                        }
                        else{
                          step3FormData = step3FormData + `timeslots[${step3ParamsLength}][fixed_rental]=0&timeslots[${step3ParamsLength}][addl_hours]=${data.additionalCost ? 1 : 0}&timeslots[${step3ParamsLength}][start_time]=${startTime}&timeslots[${step3ParamsLength}][end_time]=${endTime}&timeslots[${step3ParamsLength}][monday]=${monday}&timeslots[${step3ParamsLength}][tuesday]=${tuesday}&timeslots[${step3ParamsLength}][wednesday]=${wednesday}&timeslots[${step3ParamsLength}][thursday]=${thursday}&timeslots[${step3ParamsLength}][friday]=${friday}&timeslots[${step3ParamsLength}][saturday]=${saturday}&timeslots[${step3ParamsLength}][sunday]=${sunday}&timeslots[${step3ParamsLength}][min_hour]=${data.minHour}&timeslots[${step3ParamsLength}][multiple_event]=${data.multipleEvent}&`
                        }
                      }
                    }
                  }
                  return step3FormData;
                })
             
              }
              this.props.timeslotCopy(this.state.spaceId, step3FormData,'',true).then((response) => {
                this.props.timeslotCopy(this.state.spaceId, step3FormData).then((response) => {
                  response.timeslots.map(function (data) {
                    timeSlotSave.push(data.id)
                  })
                if (response.id) {
                  this.props.getSpace(response.id).then((response) => {
          
            responeTimeslot.timeslots.map((timeslot, index) => {
             
                if (timeslot.event_types.length>0) {
            this.setState({copyEventTypes:true})
            timeslot.event_types.map(event => {
              weekdays=[]
              if (!spaceRatesGroup.includes(event.name)) {
                spaceRatesGroup.push(event.name)
              }
              if (event.pivot.weekdays.includes('1')) {
                weekdays.push('sunday')
              }
              if (event.pivot.weekdays.includes('2')) {
                weekdays.push('monday')
              }
              if (event.pivot.weekdays.includes('3')) {
                weekdays.push('tuesday')
              }
              if (event.pivot.weekdays.includes('4')) {
                weekdays.push('wednesday')
              }
              if (event.pivot.weekdays.includes('5')) {
                weekdays.push('thursday')
              }
              if (event.pivot.weekdays.includes('6')) {
                weekdays.push('friday')
              }
              if (event.pivot.weekdays.includes('7')) {
                weekdays.push('saturday')
              }
              const hasAdditionalCost = event.overage_rate ? true : false
              const pricingType = event.pivot.pricing_type === 1 ? 'Venue Rental' : event.pivot.pricing_type === 2 ? 'Food & Bev Minimum' : event.pivot.pricing_type === 3 ? 'Venue Rental + Food & Bev Minimum' : event.pivot.pricing_type === 4 ?'Per Person':''
              const rentalType = timeslot.fixed_rental === 1 ? 'fixed' : 'flexible'
              // const startTime = moment(timeslot.start_time, "HH:mm:ss").format('hh:mm A').toLowerCase().replace(/^0(?:0:0?)?/, '')
              // const endTime = moment(timeslot.end_time, "HH:mm:ss").format('hh:mm A').toLowerCase().replace(/^0(?:0:0?)?/, '')
              const startTime = timeslot.start_time.substring(0, timeslot.start_time.length - 3)
              const endTime = timeslot.end_time.substring(0, timeslot.end_time.length - 3)
              const flatRate = Math.trunc(parseFloat(event.pivot.base_rate)).toString()
              const fnbRate = Math.trunc(parseFloat(event.pivot.fnb_rate)).toString()
              const addRate = event.pivot.overage_rate === null ? "" :Math.trunc(parseFloat(event.pivot.overage_rate)).toString()
              if (timeslot.fixed_rental === 1 && !pricingCategoryArray[index]) {
                pricingCategoryArray.push([pricingType, ''])
              }
              else if (timeslot.fixed_rental === 0 && !pricingCategoryArray[index]) {
                pricingCategoryArray.push(['', pricingType])
              }
              let eventSpacesLen = 0
              const seqNo = eventSpaceRatesArray.length + 1;
              if(event.pivot.pricing_type === 0){
                eventSpacesLen = eventSpaceRatesArray.filter(function (eventSpaceRates) {
                  return eventSpaceRates.pricingType === 0 &&
                  eventSpaceRates.pricingTypeName === ''
                }).length
              }
              else{
                eventSpacesLen = eventSpaceRatesArray.filter(function (eventSpaceRates) {
                  return eventSpaceRates.pricingTypeName === pricingType &&
                    eventSpaceRates.rentalType === rentalType &&
                    eventSpaceRates.startTime === startTime &&
                    eventSpaceRates.endTime === endTime &&
                    eventSpaceRates.eventName === event.name
                }).length
                
              }              
              if(event.pivot.pricing_type_name !== null){
                eventSpaceRatesArray.push({ idx: eventSpacesLen + 1, seqNo:seqNo, id: '', flatRate:'',additionalFlatRate:flatRate, hasAddFlatRate:true, hasAddPricingTypeName:true, additionalRate: addRate, days: weekdays, eventName: event.name, eventId: event.id, additionalCost: hasAdditionalCost, sunday: timeslot.sunday === 1 ? true : false, monday: timeslot.monday === 1 ? true : false, tuesday: timeslot.tuesday ? true : false, wednesday: timeslot.wednesday ? true : false, thursday: timeslot.thursday ? true : false, friday: timeslot.friday ? true : false, saturday: timeslot.saturday ? true : false, pricingTypeName: pricingType, pricingType: event.pivot.pricing_type, rentalType: rentalType, startTime: startTime, endTime: endTime, timeSlotId: timeSlotSave[index], additionalPricingTypeName: event.pivot.pricing_type_name, fnbRate:fnbRate, minGuest:event.pivot.min_guest ? event.pivot.min_guest.toString():'', up:'yes' })
                //eventSpaceRatesArray.push({ idx: eventSpacesLen + 1, seqNo:seqNo, id: '', flatRate: '', additionalRate: '', days: weekdays, eventName: event.name, eventId: event.id, additionalCost: false, sunday: timeslot.sunday === 1 ? true : false, monday: timeslot.monday === 1 ? true : false, tuesday: timeslot.tuesday ? true : false, wednesday: timeslot.wednesday ? true : false, thursday: timeslot.thursday ? true : false, friday: timeslot.friday ? true : false, saturday: timeslot.saturday ? true : false, pricingTypeName: '', pricingType: 0, rentalType: rentalType, startTime: startTime, endTime: endTime, timeSlotId: timeSlotSave[index], additionalPricingTypeName: '', fnbRate:'', minGuest:'', up:'no' })
              }
              else{
                eventSpaceRatesArray.push({ idx: eventSpacesLen + 1, seqNo:seqNo, id: '', flatRate: flatRate,additionalFlatRate:'', hasAddFlatRate:false, hasAddPricingTypeName:false, additionalRate: addRate, days: weekdays, eventName: event.name, eventId: event.id, additionalCost: hasAdditionalCost, sunday: timeslot.sunday === 1 ? true : false, monday: timeslot.monday === 1 ? true : false, tuesday: timeslot.tuesday ? true : false, wednesday: timeslot.wednesday ? true : false, thursday: timeslot.thursday ? true : false, friday: timeslot.friday ? true : false, saturday: timeslot.saturday ? true : false, pricingTypeName: pricingType, pricingType: event.pivot.pricing_type, rentalType: rentalType, startTime: startTime, endTime: endTime, timeSlotId: timeSlotSave[index], additionalPricingTypeName: event.pivot.pricing_type_name === null ? '' : event.pivot.pricing_type_name, fnbRate:fnbRate, minGuest:event.pivot.min_guest ? event.pivot.min_guest.toString():'' })
                //eventSpaceRatesArray.push({ idx: eventSpacesLen + 1, seqNo:seqNo + 1, id: '', flatRate:'',additionalFlatRate:'', hasAddFlatRate:false, hasAddPricingTypeName:false, additionalRate: '', days: weekdays, eventName: event.name, eventId: event.id, additionalCost: false, sunday: timeslot.sunday === 1 ? true : false, monday: timeslot.monday === 1 ? true : false, tuesday: timeslot.tuesday ? true : false, wednesday: timeslot.wednesday ? true : false, thursday: timeslot.thursday ? true : false, friday: timeslot.friday ? true : false, saturday: timeslot.saturday ? true : false, pricingTypeName: '', pricingType: 0, rentalType: rentalType, startTime: startTime, endTime: endTime, timeSlotId: timeslot.id, additionalPricingTypeName: '', fnbRate:'', minGuest:'', up:'no' })
              }
              return eventSpaceRatesArray;
            })   }
            })
            step4FormData = []
            arr=[];
            step4ParamsLength = 0
            eventSpaceRatesArray.map(function (data, index) {
              step4ParamsLength = step4ParamsLength + 1
              const weekday1 = data.days.indexOf('sunday') !== -1 ? 1 : 0
              const weekday2 = data.days.indexOf('monday') !== -1 ? 2 : 0
              const weekday3 = data.days.indexOf('tuesday') !== -1 ? 3 : 0
              const weekday4 = data.days.indexOf('wednesday') !== -1 ? 4 : 0
              const weekday5 = data.days.indexOf('thursday') !== -1 ? 5 : 0
              const weekday6 = data.days.indexOf('friday') !== -1 ? 6 : 0
              const weekday7 = data.days.indexOf('saturday') !== -1 ? 7 : 0
              const flatRate = data.flatRate !== '' ? data.flatRate.replace(/\$|,/g, '') : ''
              const additionalFlatRate = data.additionalFlatRate !== '' ? data.additionalFlatRate.replace(/\$|,/g, ''):'0'
              const fnbRate = data.fnbRate !== 'NaN' && data.fnbRate.length > 0 ? data.fnbRate.replace(/\$|,/g, ''):0
              const addRate = data.additionalRate.replace(/\$|,/g, '')
          
              let weekday=[];
              if(weekday1!==0){
                weekday.push(1);
              }
              if(weekday2!==0){
                weekday.push(2);

              }
              if(weekday3!==0){
                weekday.push(3);

              }
      
              if(weekday4!==0){
                weekday.push(4);

              }
              if(weekday5!==0){
                weekday.push(5);

              }
              if(weekday6!==0){
                weekday.push(6);

              }
              if(weekday7!==0){
                weekday.push(7);

              }
              if (data.id) {
                let obj={};
                if(data.additionalPricingTypeName && data.additionalPricingTypeName !== '' && data.flatRate === ''){
                  if(data.pricingType === 4){
                    step4FormData = step4FormData + `event_type_timeslot_pricings[${step4ParamsLength}][id]=${data.id}${!values.spaceRatesGroup.includes(data.eventName) ? `&event_type_timeslot_pricings[${step4ParamsLength}][deleted]=1`:''}&event_type_timeslot_pricings[${step4ParamsLength}][timeslot_id]=${data.timeSlotId}&event_type_timeslot_pricings[${step4ParamsLength}][event_type_id]=${data.eventId}&event_type_timeslot_pricings[${step4ParamsLength}][pricing_type]=${data.pricingType}${weekday1 !== 0 ? `&event_type_timeslot_pricings[${step4ParamsLength}][weekdays][1]=${weekday1}` : ''}${weekday2 !== 0 ? `&event_type_timeslot_pricings[${step4ParamsLength}][weekdays][2]=${weekday2}` : ''}${weekday3 !== 0 ? `&event_type_timeslot_pricings[${step4ParamsLength}][weekdays][3]=${weekday3}` : ''}${weekday4 !== 0 ? `&event_type_timeslot_pricings[${step4ParamsLength}][weekdays][4]=${weekday4}` : ''}${weekday5 !== 0 ? `&event_type_timeslot_pricings[${step4ParamsLength}][weekdays][5]=${weekday5}` : ''}${weekday6 !== 0 ? `&event_type_timeslot_pricings[${step4ParamsLength}][weekdays][6]=${weekday6}` : ''}${weekday7 !== 0 ? `&event_type_timeslot_pricings[${step4ParamsLength}][weekdays][7]=${weekday7}` : ''}&event_type_timeslot_pricings[${step4ParamsLength}][base_rate]=${additionalFlatRate}${data.additionalRate ? `&event_type_timeslot_pricings[${step4ParamsLength}][overage_rate]=${addRate}` : ''}&event_type_timeslot_pricings[${step4ParamsLength}][min_guest]=${data.minGuest}&event_type_timeslot_pricings[${step4ParamsLength}][pricing_type_name]=${encodeURIComponent(data.additionalPricingTypeName)}&`
                    obj.id=data.id;
                    obj.timeslot_id=data.timeSlotId;
                    obj.event_type_id=data.eventId;
                    obj.pricing_type=data.pricingType;
                    obj.weekdays=weekday;
                    obj.base_rate=additionalFlatRate;
                    obj.min_guest=data.minGuest?data.minGuest:0; 
                    obj.pricing_type_name=data.additionalPricingTypeName;

                    if(!spaceRatesGroup.includes(data.eventName)){
                      obj.deleted=1;
                    }
                    if(data.additionalRate){
                      obj.overage_rate=addRate;
                    }
                    arr.push(obj);
                  }
                  else if(fnbRate && data.pricingType === 3){
                    
                    step4FormData = step4FormData + `event_type_timeslot_pricings[${step4ParamsLength}][id]=${data.id}${!values.spaceRatesGroup.includes(data.eventName) ? `&event_type_timeslot_pricings[${step4ParamsLength}][deleted]=1`:''}&&event_type_timeslot_pricings[${step4ParamsLength}][timeslot_id]=${data.timeSlotId}&event_type_timeslot_pricings[${step4ParamsLength}][event_type_id]=${data.eventId}&event_type_timeslot_pricings[${step4ParamsLength}][pricing_type]=${data.pricingType}${weekday1 !== 0 ? `&event_type_timeslot_pricings[${step4ParamsLength}][weekdays][1]=${weekday1}` : ''}${weekday2 !== 0 ? `&event_type_timeslot_pricings[${step4ParamsLength}][weekdays][2]=${weekday2}` : ''}${weekday3 !== 0 ? `&event_type_timeslot_pricings[${step4ParamsLength}][weekdays][3]=${weekday3}` : ''}${weekday4 !== 0 ? `&event_type_timeslot_pricings[${step4ParamsLength}][weekdays][4]=${weekday4}` : ''}${weekday5 !== 0 ? `&event_type_timeslot_pricings[${step4ParamsLength}][weekdays][5]=${weekday5}` : ''}${weekday6 !== 0 ? `&event_type_timeslot_pricings[${step4ParamsLength}][weekdays][6]=${weekday6}` : ''}${weekday7 !== 0 ? `&event_type_timeslot_pricings[${step4ParamsLength}][weekdays][7]=${weekday7}` : ''}&event_type_timeslot_pricings[${step4ParamsLength}][base_rate]=${additionalFlatRate}${data.additionalRate ? `&event_type_timeslot_pricings[${step4ParamsLength}][overage_rate]=${addRate}` : ''}&event_type_timeslot_pricings[${step4ParamsLength}][fnb_rate]=${fnbRate}&event_type_timeslot_pricings[${step4ParamsLength}][pricing_type_name]=${encodeURIComponent(data.additionalPricingTypeName)}&`
                    obj.id=data.id;
                    obj.timeslot_id=data.timeSlotId;
                    obj.event_type_id=data.eventId;
                    obj.pricing_type=data.pricingType;
                    obj.weekdays=weekday;
                    obj.base_rate=additionalFlatRate;
                    obj.fnb_rate=fnbRate; 
                    obj.pricing_type_name=data.additionalPricingTypeName;

                    if(!spaceRatesGroup.includes(data.eventName)){
                      obj.deleted=1;
                    }
                    if(data.additionalRate){
                      obj.overage_rate=addRate;
                    }
                    arr.push(obj);
                }
                  else{
                    step4FormData = step4FormData + `event_type_timeslot_pricings[${step4ParamsLength}][id]=${data.id}${!values.spaceRatesGroup.includes(data.eventName) ? `&event_type_timeslot_pricings[${step4ParamsLength}][deleted]=1`:''}&&event_type_timeslot_pricings[${step4ParamsLength}][timeslot_id]=${data.timeSlotId}&event_type_timeslot_pricings[${step4ParamsLength}][event_type_id]=${data.eventId}&event_type_timeslot_pricings[${step4ParamsLength}][pricing_type]=${data.pricingType}${weekday1 !== 0 ? `&event_type_timeslot_pricings[${step4ParamsLength}][weekdays][1]=${weekday1}` : ''}${weekday2 !== 0 ? `&event_type_timeslot_pricings[${step4ParamsLength}][weekdays][2]=${weekday2}` : ''}${weekday3 !== 0 ? `&event_type_timeslot_pricings[${step4ParamsLength}][weekdays][3]=${weekday3}` : ''}${weekday4 !== 0 ? `&event_type_timeslot_pricings[${step4ParamsLength}][weekdays][4]=${weekday4}` : ''}${weekday5 !== 0 ? `&event_type_timeslot_pricings[${step4ParamsLength}][weekdays][5]=${weekday5}` : ''}${weekday6 !== 0 ? `&event_type_timeslot_pricings[${step4ParamsLength}][weekdays][6]=${weekday6}` : ''}${weekday7 !== 0 ? `&event_type_timeslot_pricings[${step4ParamsLength}][weekdays][7]=${weekday7}` : ''}&event_type_timeslot_pricings[${step4ParamsLength}][base_rate]=${additionalFlatRate}${data.additionalRate ? `&event_type_timeslot_pricings[${step4ParamsLength}][overage_rate]=${addRate}` : ''}&event_type_timeslot_pricings[${step4ParamsLength}][pricing_type_name]=${encodeURIComponent(data.additionalPricingTypeName)}&`
                    obj.id=data.id;
                    obj.timeslot_id=data.timeSlotId;
                    obj.event_type_id=data.eventId;
                    obj.pricing_type=data.pricingType;
                    obj.weekdays=weekday;
                    obj.base_rate=additionalFlatRate;
                    obj.pricing_type_name=data.additionalPricingTypeName;

                    if(spaceRatesGroup.includes(data.eventName)){
                      obj.deleted=1;
                    }
                    if(data.additionalRate){
                      obj.overage_rate=addRate;
                    }
                    arr.push(obj);
                  }
                }
                else if(data.flatRate !== '' && data.additionalPricingTypeName === ''){
                  if(data.pricingType === 4){
                    step4FormData = step4FormData + `event_type_timeslot_pricings[${step4ParamsLength}][id]=${data.id}${!values.spaceRatesGroup.includes(data.eventName) ? `&event_type_timeslot_pricings[${step4ParamsLength}][deleted]=1`:''}&&event_type_timeslot_pricings[${step4ParamsLength}][timeslot_id]=${data.timeSlotId}&event_type_timeslot_pricings[${step4ParamsLength}][event_type_id]=${data.eventId}&event_type_timeslot_pricings[${step4ParamsLength}][pricing_type]=${data.pricingType}${weekday1 !== 0 ? `&event_type_timeslot_pricings[${step4ParamsLength}][weekdays][1]=${weekday1}` : ''}${weekday2 !== 0 ? `&event_type_timeslot_pricings[${step4ParamsLength}][weekdays][2]=${weekday2}` : ''}${weekday3 !== 0 ? `&event_type_timeslot_pricings[${step4ParamsLength}][weekdays][3]=${weekday3}` : ''}${weekday4 !== 0 ? `&event_type_timeslot_pricings[${step4ParamsLength}][weekdays][4]=${weekday4}` : ''}${weekday5 !== 0 ? `&event_type_timeslot_pricings[${step4ParamsLength}][weekdays][5]=${weekday5}` : ''}${weekday6 !== 0 ? `&event_type_timeslot_pricings[${step4ParamsLength}][weekdays][6]=${weekday6}` : ''}${weekday7 !== 0 ? `&event_type_timeslot_pricings[${step4ParamsLength}][weekdays][7]=${weekday7}` : ''}&event_type_timeslot_pricings[${step4ParamsLength}][base_rate]=${flatRate}${data.additionalRate ? `&event_type_timeslot_pricings[${step4ParamsLength}][overage_rate]=${addRate}` : ''}&event_type_timeslot_pricings[${step4ParamsLength}][min_guest]=${data.minGuest}&`
                
                    obj.id=data.id;
                    obj.timeslot_id=data.timeSlotId;
                    obj.event_type_id=data.eventId;
                    obj.pricing_type=data.pricingType;
                    obj.weekdays=weekday;
                    obj.base_rate=flatRate;
                    obj.min_guest=data.minGuest ? data.minGuest : 0; 

                    if(!spaceRatesGroup.includes(data.eventName)){
                      obj.deleted=1;
                    }
                    if(data.additionalRate){
                      obj.overage_rate=addRate;
                    }
                    arr.push(obj);
                  }
                  else if(fnbRate && data.pricingType === 3){
                    step4FormData = step4FormData + `event_type_timeslot_pricings[${step4ParamsLength}][id]=${data.id}&event_type_timeslot_pricings[${step4ParamsLength}][timeslot_id]=${data.timeSlotId}&${!values.spaceRatesGroup.includes(data.eventName) ? `&event_type_timeslot_pricings[${step4ParamsLength}][deleted]=1`:''}&event_type_timeslot_pricings[${step4ParamsLength}][event_type_id]=${data.eventId}&event_type_timeslot_pricings[${step4ParamsLength}][pricing_type]=${data.pricingType}${weekday1 !== 0 ? `&event_type_timeslot_pricings[${step4ParamsLength}][weekdays][1]=${weekday1}` : ''}${weekday2 !== 0 ? `&event_type_timeslot_pricings[${step4ParamsLength}][weekdays][2]=${weekday2}` : ''}${weekday3 !== 0 ? `&event_type_timeslot_pricings[${step4ParamsLength}][weekdays][3]=${weekday3}` : ''}${weekday4 !== 0 ? `&event_type_timeslot_pricings[${step4ParamsLength}][weekdays][4]=${weekday4}` : ''}${weekday5 !== 0 ? `&event_type_timeslot_pricings[${step4ParamsLength}][weekdays][5]=${weekday5}` : ''}${weekday6 !== 0 ? `&event_type_timeslot_pricings[${step4ParamsLength}][weekdays][6]=${weekday6}` : ''}${weekday7 !== 0 ? `&event_type_timeslot_pricings[${step4ParamsLength}][weekdays][7]=${weekday7}` : ''}&event_type_timeslot_pricings[${step4ParamsLength}][base_rate]=${flatRate}${data.additionalRate ? `&event_type_timeslot_pricings[${step4ParamsLength}][overage_rate]=${addRate}` : ''}&event_type_timeslot_pricings[${step4ParamsLength}][fnb_rate]=${fnbRate}&`
                    obj.id=data.id;
                    obj.timeslot_id=data.timeSlotId;
                    obj.event_type_id=data.eventId;
                    obj.pricing_type=data.pricingType;
                    obj.weekdays=weekday;
                    obj.base_rate=flatRate;
                    obj.fnb_rate=fnbRate; 

                    if(!spaceRatesGroup.includes(data.eventName)){
                      obj.deleted=1;
                    }
                    if(data.additionalRate){
                      obj.overage_rate=addRate;
                    }
                    arr.push(obj);

                  }
                  else{
                    step4FormData = step4FormData + `event_type_timeslot_pricings[${step4ParamsLength}][id]=${data.id}&${!spaceRatesGroup.includes(data.eventName) ? `&event_type_timeslot_pricings[${step4ParamsLength}][deleted]=1`:''}&event_type_timeslot_pricings[${step4ParamsLength}][timeslot_id]=${data.timeSlotId}&event_type_timeslot_pricings[${step4ParamsLength}][event_type_id]=${data.eventId}&event_type_timeslot_pricings[${step4ParamsLength}][pricing_type]=${data.pricingType}${weekday1 !== 0 ? `&event_type_timeslot_pricings[${step4ParamsLength}][weekdays][1]=${weekday1}` : ''}${weekday2 !== 0 ? `&event_type_timeslot_pricings[${step4ParamsLength}][weekdays][2]=${weekday2}` : ''}${weekday3 !== 0 ? `&event_type_timeslot_pricings[${step4ParamsLength}][weekdays][3]=${weekday3}` : ''}${weekday4 !== 0 ? `&event_type_timeslot_pricings[${step4ParamsLength}][weekdays][4]=${weekday4}` : ''}${weekday5 !== 0 ? `&event_type_timeslot_pricings[${step4ParamsLength}][weekdays][5]=${weekday5}` : ''}${weekday6 !== 0 ? `&event_type_timeslot_pricings[${step4ParamsLength}][weekdays][6]=${weekday6}` : ''}${weekday7 !== 0 ? `&event_type_timeslot_pricings[${step4ParamsLength}][weekdays][7]=${weekday7}` : ''}&event_type_timeslot_pricings[${step4ParamsLength}][base_rate]=${flatRate}${data.additionalRate ? `&event_type_timeslot_pricings[${step4ParamsLength}][overage_rate]=${addRate}` : ''}&`
                    obj.id=data.id;
                    obj.timeslot_id=data.timeSlotId;
                    obj.event_type_id=data.eventId;
                    obj.pricing_type=data.pricingType;
                    obj.weekdays=weekday;
                    obj.base_rate=flatRate;

                    if(!spaceRatesGroup.includes(data.eventName)){
                      obj.deleted=1;
                    }
                    if(data.additionalRate){
                      obj.overage_rate=addRate;
                    }
                    arr.push(obj);

                  }
                }
              }
              else {
                let obj={};
                if(data.additionalPricingTypeName && data.additionalPricingTypeName !== '' && data.flatRate === ''){
                  if(data.pricingType === 4){
                    step4FormData = step4FormData = step4FormData + `event_type_timeslot_pricings[${step4ParamsLength}][timeslot_id]=${data.timeSlotId}&event_type_timeslot_pricings[${step4ParamsLength}][event_type_id]=${data.eventId}&event_type_timeslot_pricings[${step4ParamsLength}][pricing_type]=${data.pricingType}${weekday1 !== 0 ? `&event_type_timeslot_pricings[${step4ParamsLength}][weekdays][1]=${weekday1}` : ''}${weekday2 !== 0 ? `&event_type_timeslot_pricings[${step4ParamsLength}][weekdays][2]=${weekday2}` : ''}${weekday3 !== 0 ? `&event_type_timeslot_pricings[${step4ParamsLength}][weekdays][3]=${weekday3}` : ''}${weekday4 !== 0 ? `&event_type_timeslot_pricings[${step4ParamsLength}][weekdays][4]=${weekday4}` : ''}${weekday5 !== 0 ? `&event_type_timeslot_pricings[${step4ParamsLength}][weekdays][5]=${weekday5}` : ''}${weekday6 !== 0 ? `&event_type_timeslot_pricings[${step4ParamsLength}][weekdays][6]=${weekday6}` : ''}${weekday7 !== 0 ? `&event_type_timeslot_pricings[${step4ParamsLength}][weekdays][7]=${weekday7}` : ''}&event_type_timeslot_pricings[${step4ParamsLength}][base_rate]=${additionalFlatRate}${data.additionalRate ? `&event_type_timeslot_pricings[${step4ParamsLength}][overage_rate]=${addRate}` : ''}&event_type_timeslot_pricings[${step4ParamsLength}][min_guest]=${data.minGuest}&event_type_timeslot_pricings[${step4ParamsLength}][pricing_type_name]=${encodeURIComponent(data.additionalPricingTypeName)}&`
                    
                    obj.timeslot_id=data.timeSlotId;
                    obj.event_type_id=data.eventId;
                    obj.pricing_type=data.pricingType;
                    obj.weekdays=weekday;
                    obj.base_rate=additionalFlatRate;
                    obj.min_guest=data.minGuest ? data.minGuest:0; 
                    obj.pricing_type_name=data.additionalPricingTypeName;

                    if(data.additionalRate){
                      obj.overage_rate=addRate;
                    }


                    arr.push(obj);

                  }
                  else if(data.fnbRate && data.pricingType === 3){
                    step4FormData = step4FormData + `event_type_timeslot_pricings[${step4ParamsLength}][timeslot_id]=${data.timeSlotId}&event_type_timeslot_pricings[${step4ParamsLength}][event_type_id]=${data.eventId}&event_type_timeslot_pricings[${step4ParamsLength}][pricing_type]=${data.pricingType}${weekday1 !== 0 ? `&event_type_timeslot_pricings[${step4ParamsLength}][weekdays][1]=${weekday1}` : ''}${weekday2 !== 0 ? `&event_type_timeslot_pricings[${step4ParamsLength}][weekdays][2]=${weekday2}` : ''}${weekday3 !== 0 ? `&event_type_timeslot_pricings[${step4ParamsLength}][weekdays][3]=${weekday3}` : ''}${weekday4 !== 0 ? `&event_type_timeslot_pricings[${step4ParamsLength}][weekdays][4]=${weekday4}` : ''}${weekday5 !== 0 ? `&event_type_timeslot_pricings[${step4ParamsLength}][weekdays][5]=${weekday5}` : ''}${weekday6 !== 0 ? `&event_type_timeslot_pricings[${step4ParamsLength}][weekdays][6]=${weekday6}` : ''}${weekday7 !== 0 ? `&event_type_timeslot_pricings[${step4ParamsLength}][weekdays][7]=${weekday7}` : ''}&event_type_timeslot_pricings[${step4ParamsLength}][base_rate]=${additionalFlatRate}${data.additionalRate ? `&event_type_timeslot_pricings[${step4ParamsLength}][overage_rate]=${addRate}` : ''}&event_type_timeslot_pricings[${step4ParamsLength}][fnb_rate]=${fnbRate}&event_type_timeslot_pricings[${step4ParamsLength}][pricing_type_name]=${encodeURIComponent(data.additionalPricingTypeName)}&`
                    obj.timeslot_id=data.timeSlotId;
                    obj.event_type_id=data.eventId;
                    obj.pricing_type=data.pricingType;
                    obj.weekdays=weekday;
                    obj.base_rate=additionalFlatRate;
                    obj.fnb_rate=fnbRate; 
                    obj.pricing_type_name=data.additionalPricingTypeName;

                    if(data.additionalRate){
                      obj.overage_rate=addRate;
                    }
                    arr.push(obj);
                  }
                  else{
                    step4FormData = step4FormData + `event_type_timeslot_pricings[${step4ParamsLength}][timeslot_id]=${data.timeSlotId}&event_type_timeslot_pricings[${step4ParamsLength}][event_type_id]=${data.eventId}&event_type_timeslot_pricings[${step4ParamsLength}][pricing_type]=${data.pricingType}${weekday1 !== 0 ? `&event_type_timeslot_pricings[${step4ParamsLength}][weekdays][1]=${weekday1}` : ''}${weekday2 !== 0 ? `&event_type_timeslot_pricings[${step4ParamsLength}][weekdays][2]=${weekday2}` : ''}${weekday3 !== 0 ? `&event_type_timeslot_pricings[${step4ParamsLength}][weekdays][3]=${weekday3}` : ''}${weekday4 !== 0 ? `&event_type_timeslot_pricings[${step4ParamsLength}][weekdays][4]=${weekday4}` : ''}${weekday5 !== 0 ? `&event_type_timeslot_pricings[${step4ParamsLength}][weekdays][5]=${weekday5}` : ''}${weekday6 !== 0 ? `&event_type_timeslot_pricings[${step4ParamsLength}][weekdays][6]=${weekday6}` : ''}${weekday7 !== 0 ? `&event_type_timeslot_pricings[${step4ParamsLength}][weekdays][7]=${weekday7}` : ''}&event_type_timeslot_pricings[${step4ParamsLength}][base_rate]=${additionalFlatRate}${data.additionalRate ? `&event_type_timeslot_pricings[${step4ParamsLength}][overage_rate]=${addRate}` : ''}&event_type_timeslot_pricings[${step4ParamsLength}][pricing_type_name]=${encodeURIComponent(data.additionalPricingTypeName)}&`
                    obj.timeslot_id=data.timeSlotId;
                    obj.event_type_id=data.eventId;
                    obj.pricing_type=data.pricingType;
                    obj.weekdays=weekday;
                    obj.base_rate=additionalFlatRate;
                    obj.pricing_type_name=data.additionalPricingTypeName;

                    if(data.additionalRate){
                      obj.overage_rate=addRate;
                    }


                    arr.push(obj);
                  }
                }
                else if(data.flatRate !== '' && data.additionalPricingTypeName === ''){
                  if(data.pricingType === 4){
                    step4FormData = step4FormData = step4FormData + `event_type_timeslot_pricings[${step4ParamsLength}][timeslot_id]=${data.timeSlotId}&event_type_timeslot_pricings[${step4ParamsLength}][event_type_id]=${data.eventId}&event_type_timeslot_pricings[${step4ParamsLength}][pricing_type]=${data.pricingType}${weekday1 !== 0 ? `&event_type_timeslot_pricings[${step4ParamsLength}][weekdays][1]=${weekday1}` : ''}${weekday2 !== 0 ? `&event_type_timeslot_pricings[${step4ParamsLength}][weekdays][2]=${weekday2}` : ''}${weekday3 !== 0 ? `&event_type_timeslot_pricings[${step4ParamsLength}][weekdays][3]=${weekday3}` : ''}${weekday4 !== 0 ? `&event_type_timeslot_pricings[${step4ParamsLength}][weekdays][4]=${weekday4}` : ''}${weekday5 !== 0 ? `&event_type_timeslot_pricings[${step4ParamsLength}][weekdays][5]=${weekday5}` : ''}${weekday6 !== 0 ? `&event_type_timeslot_pricings[${step4ParamsLength}][weekdays][6]=${weekday6}` : ''}${weekday7 !== 0 ? `&event_type_timeslot_pricings[${step4ParamsLength}][weekdays][7]=${weekday7}` : ''}&event_type_timeslot_pricings[${step4ParamsLength}][base_rate]=${flatRate}${data.additionalRate ? `&event_type_timeslot_pricings[${step4ParamsLength}][overage_rate]=${addRate}` : ''}&event_type_timeslot_pricings[${step4ParamsLength}][min_guest]=${data.minGuest}&`
                    obj.timeslot_id=data.timeSlotId;
                    obj.event_type_id=data.eventId;
                    obj.pricing_type=data.pricingType;
                    obj.weekdays=weekday;
                    obj.base_rate=flatRate;
                    obj.min_guest=data.minGuest ? data.minGuest : 0; 

                    if(data.additionalRate){
                      obj.overage_rate=addRate;
                    }


                    arr.push(obj);
                  }
                  else if(data.fnbRate && data.pricingType === 3){
                    step4FormData = step4FormData + `event_type_timeslot_pricings[${step4ParamsLength}][timeslot_id]=${data.timeSlotId}&event_type_timeslot_pricings[${step4ParamsLength}][event_type_id]=${data.eventId}&event_type_timeslot_pricings[${step4ParamsLength}][pricing_type]=${data.pricingType}${weekday1 !== 0 ? `&event_type_timeslot_pricings[${step4ParamsLength}][weekdays][1]=${weekday1}` : ''}${weekday2 !== 0 ? `&event_type_timeslot_pricings[${step4ParamsLength}][weekdays][2]=${weekday2}` : ''}${weekday3 !== 0 ? `&event_type_timeslot_pricings[${step4ParamsLength}][weekdays][3]=${weekday3}` : ''}${weekday4 !== 0 ? `&event_type_timeslot_pricings[${step4ParamsLength}][weekdays][4]=${weekday4}` : ''}${weekday5 !== 0 ? `&event_type_timeslot_pricings[${step4ParamsLength}][weekdays][5]=${weekday5}` : ''}${weekday6 !== 0 ? `&event_type_timeslot_pricings[${step4ParamsLength}][weekdays][6]=${weekday6}` : ''}${weekday7 !== 0 ? `&event_type_timeslot_pricings[${step4ParamsLength}][weekdays][7]=${weekday7}` : ''}&event_type_timeslot_pricings[${step4ParamsLength}][base_rate]=${flatRate}${data.additionalRate ? `&event_type_timeslot_pricings[${step4ParamsLength}][overage_rate]=${addRate}` : ''}&event_type_timeslot_pricings[${step4ParamsLength}][fnb_rate]=${fnbRate}&`
                    obj.timeslot_id=data.timeSlotId;
                    obj.event_type_id=data.eventId;
                    obj.pricing_type=data.pricingType;
                    obj.weekdays=weekday;
                    obj.base_rate=flatRate;
                    obj.fnb_rate=fnbRate; 

                    if(data.additionalRate){
                      obj.overage_rate=addRate;
                    }


                    arr.push(obj);
                  }
                  else{
                    step4FormData = step4FormData + `event_type_timeslot_pricings[${step4ParamsLength}][timeslot_id]=${data.timeSlotId}&event_type_timeslot_pricings[${step4ParamsLength}][event_type_id]=${data.eventId}&event_type_timeslot_pricings[${step4ParamsLength}][pricing_type]=${data.pricingType}${weekday1 !== 0 ? `&event_type_timeslot_pricings[${step4ParamsLength}][weekdays][1]=${weekday1}` : ''}${weekday2 !== 0 ? `&event_type_timeslot_pricings[${step4ParamsLength}][weekdays][2]=${weekday2}` : ''}${weekday3 !== 0 ? `&event_type_timeslot_pricings[${step4ParamsLength}][weekdays][3]=${weekday3}` : ''}${weekday4 !== 0 ? `&event_type_timeslot_pricings[${step4ParamsLength}][weekdays][4]=${weekday4}` : ''}${weekday5 !== 0 ? `&event_type_timeslot_pricings[${step4ParamsLength}][weekdays][5]=${weekday5}` : ''}${weekday6 !== 0 ? `&event_type_timeslot_pricings[${step4ParamsLength}][weekdays][6]=${weekday6}` : ''}${weekday7 !== 0 ? `&event_type_timeslot_pricings[${step4ParamsLength}][weekdays][7]=${weekday7}` : ''}&event_type_timeslot_pricings[${step4ParamsLength}][base_rate]=${flatRate}${data.additionalRate ? `&event_type_timeslot_pricings[${step4ParamsLength}][overage_rate]=${addRate}` : ''}&`
                    obj.timeslot_id=data.timeSlotId;
                    obj.event_type_id=data.eventId;
                    obj.pricing_type=data.pricingType;
                    obj.weekdays=weekday;
                    obj.base_rate=flatRate;

                    if(data.additionalRate){
                      obj.overage_rate=addRate;
                    }


                    arr.push(obj);
                  }
                }
              }
              return step4FormData;
            })
            let obj_final={};
            obj_final.p=arr;
            console.table(obj_final)
            this.props.updateSpace(this.state.spaceId, obj_final,'spaceEditpub').then((response) => {
               }).catch(error => {})
              })
              .catch(error => {})
          
                  if (submissionType === 'save') {
                    if (this.props.match.params.spaceId) {
                      this.props.history.push(`/spaceProfileEdit/${response.id}`)
                    }
                    else {
                      this.props.history.push(`/spaceComplete/${response.id}`)
                    }
                  }
                  // else if (submissionType === 'next') {
                  //   this.incrementStep(actions.setFieldValue, values)
                  // }
                }
              })
              .catch(error => {})
              })
                .catch(error => {})
            }
            
          
        }
      if(this.props.eventTypeList.length > 0){
        for(let i=0; i<= this.props.eventTypeList.length-1; i++){
          let fixedArrayVal = []
          let flexibleArrayVal = []
          response.timeslots.map((timeslot, index) => {
            if (timeslot.fixed_rental === 1) {
              fixedArrayVal.push({anchorEl:null, isToggleOpen:false, name:'', price:''})
            }
            else if (timeslot.fixed_rental === 0) {
              flexibleArrayVal.push({anchorEl:null, isToggleOpen:false, name:'', price:''})
            }
            return flexibleArrayVal;
          })
          if(fixedArrayVal.length > 0){
            fixedEventPopper.push(fixedArrayVal)
          }
          if(flexibleArrayVal.length > 0){
            flexibleEventPopper.push(flexibleArrayVal)
          }
        }
      }
      this.setState({ spaceAvailabilityGroup: timeSlotType, fixedEventPopper: fixedEventPopper, flexibleEventPopper: flexibleEventPopper })
    })
      .catch(error => {})
  //}
  }
  incrementStep = (setFieldValue, values) => {
    if (this.state.step === 1) {
      this.setState({ step: this.state.step + 1 })
    }
    else if (this.state.step === 2) {
      this.setState({ step: this.state.step + 1 })
    }
    else if (this.state.step === 3) {
      this.setState({ step: this.state.step + 1 })
      // let arr = [], array = []
    }
  }
  decrementStep = () => {
    this.setState({ step: this.state.step - 1 })
    if(this.state.step===4)
    {
      step4FixedHasError = false
      step4FlexibleHasError = false
    }
  }
  onFlexibleDaySelect = (value, action) => {
    let flexibleRentalDaysArray = this.state.flexibleRentalDaysArray
    if (action === 'remove') {
      const index = flexibleRentalDaysArray.indexOf(value)
      flexibleRentalDaysArray.splice(index, 1)
    }
    else if (action === 'add') {
      flexibleRentalDaysArray.push(value)
    }
    this.setState({ flexibleRentalDaysArray: flexibleRentalDaysArray })
  }

  onRentalChange = (action,setFieldValue) => {
    if(action=='flexibleRental'){
      setFieldValue('flexibleRental',true)
      setFieldValue('fixedValue',false)
      setFieldValue('spaceAvailabilityGroup',['flexibleRental']);
    }
    if(action=='fixedValue'){
      setFieldValue('flexibleRental',false)
      setFieldValue('fixedValue',true)
      setFieldValue('spaceAvailabilityGroup',['fixedValue']);
    }    
  }


  onFixedDaySelect = (value, action) => {
    
    let fixedRentalDaysArray = this.state.fixedRentalDaysArray
    if (action === 'remove') {
      const index = fixedRentalDaysArray.indexOf(value)
      fixedRentalDaysArray.splice(index, 1)
    }
    else if (action === 'add') {
      fixedRentalDaysArray.push(value)
    }
    this.setState({ fixedRentalDaysArray: fixedRentalDaysArray })
  }
  handleAddTimeSlot = (values, pricingCategoryValue, type) => {
    if(type === 'fixed'){
      let fixedRentalAvailabilityArray = this.state.fixedRentalAvailability
      const len = fixedRentalAvailabilityArray.length + 1
      fixedRentalAvailabilityArray.push({ idx:len ,startTime: '', endTime: '', days: [], sunday: false, monday: false, tuesday: false, wednesday: false, thursday: false, friday: false, saturday: false, additionalCost: false })
      this.setState({ fixedRentalAvailability: fixedRentalAvailabilityArray, eventSpaceRatesArray: values.eventSpaceRatesArray, spaceRatesGroup: values.spaceRatesGroup, pricingCategory: pricingCategoryValue })
      // this.setState({ fixedRentalAvailability: values.fixedRentalAvailability, pricingCategory: pricingCategoryValue })
    }
    else if(type === 'flexible'){
      let flexibleRentalAvailabilityArray = this.state.flexibleRentalAvailability
      const len = flexibleRentalAvailabilityArray.length + 1
      flexibleRentalAvailabilityArray.push({ idx: len, startTime: '', endTime: '', days: [], sunday: false, monday: false, tuesday: false, wednesday: false, thursday: false, friday: false, saturday: false, additionalCost: false, maxHour: '', multipleEvent: 0, timeBetweenEvent: '', minHour:'', showMaxHour:false})
      this.setState({ flexibleRentalAvailability: flexibleRentalAvailabilityArray, eventSpaceRatesArray: values.eventSpaceRatesArray, spaceRatesGroup: values.spaceRatesGroup, pricingCategory: pricingCategoryValue })
      // this.setState({ flexibleRentalAvailability: values.flexibleRentalAvailability, pricingCategory: pricingCategoryValue })
    }
  }




  deleteTimeslotForFixed = (val, values, setFieldValue) => {
 
    let formData = ''
    const obj = values.fixedRentalAvailability.find(rental => rental.idx === val);
    const spaceAvailabilityGroupArray = values.spaceAvailabilityGroup
    let fixedRentalDaysArray = this.state.fixedRentalDaysArray
    const fixedRentalArray = []
    values.fixedRentalAvailability.map((fixed) => {
      if (fixed.idx !== obj.idx) {
        fixedRentalArray.push(fixed)
      }
      else {
        const startTime = moment(fixed.startTime, "h:mm A").format("HH:mm:ss")
        const endTime = moment(fixed.endTime, "h:mm A").format("HH:mm:ss")
        fixed.days.map((day) => {
          const sunday = fixed.days.indexOf('sunday') !== -1 ? 1 : 0
          const monday = fixed.days.indexOf('monday') !== -1 ? 1 : 0
          const tuesday = fixed.days.indexOf('tuesday') !== -1 ? 1 : 0
          const wednesday = fixed.days.indexOf('wednesday') !== -1 ? 1 : 0
          const thursday = fixed.days.indexOf('thursday') !== -1 ? 1 : 0
          const friday = fixed.days.indexOf('friday') !== -1 ? 1 : 0
          const saturday = fixed.days.indexOf('saturday') !== -1 ? 1 : 0
          if (fixed.id) {
            formData = formData + `timeslots[${fixed.idx}][id]=${fixed.id}&timeslots[${fixed.idx}][deleted]=1&timeslots[${fixed.idx}][fixed_rental]=1&timeslots[${fixed.idx}][addl_hours]=${fixed.additionalCost ? 1 : 0}&timeslots[${fixed.idx}][start_time]=${startTime}&timeslots[${fixed.idx}][end_time]=${endTime}&timeslots[${fixed.idx}][monday]=${monday}&timeslots[${fixed.idx}][tuesday]=${tuesday}&timeslots[${fixed.idx}][wednesday]=${wednesday}&timeslots[${fixed.idx}][thursday]=${thursday}&timeslots[${fixed.idx}][friday]=${friday}&timeslots[${fixed.idx}][saturday]=${saturday}&timeslots[${fixed.idx}][sunday]=${sunday}&`
          }
          const idx = fixedRentalDaysArray.indexOf(day)
          fixedRentalDaysArray.splice(idx, 1);
          return fixedRentalDaysArray;
        })
      }
      return fixedRentalDaysArray;
    })
    if (formData !== '') {
      this.props.updateSpace(this.state.spaceId, formData).then((response) => {
        if (fixedRentalArray.length > 0) {
          this.setState({ fixedRentalAvailability: fixedRentalArray, fixedValue: true, spaceAvailabilityGroup: spaceAvailabilityGroupArray })
          setFieldValue('fixedRentalAvailability', fixedRentalArray)
          setFieldValue('fixedValue', true)
          setFieldValue('spaceAvailabilityGroup', spaceAvailabilityGroupArray)
        }
        else {
          fixedRentalArray.push({ idx: 1, id: '', startTime: '', endTime: '', days: [], additionalCost: false, sunday: false, monday: false, tuesday: false, wednesday: false, thursday: false, friday: false, saturday: false })
          this.setState({ fixedRentalAvailability: fixedRentalArray, fixedValue: true, spaceAvailabilityGroup: spaceAvailabilityGroupArray })
          setFieldValue('fixedRentalAvailability', fixedRentalArray)
          setFieldValue('spaceAvailabilityGroup', spaceAvailabilityGroupArray)
          setFieldValue('fixedValue', true)
        }
      })
    }
    else {
      if (fixedRentalArray.length > 0) {
        this.setState({ fixedRentalAvailability: fixedRentalArray, fixedValue: true, fixedRentalDaysArray: fixedRentalDaysArray, spaceAvailabilityGroup: spaceAvailabilityGroupArray })
        setFieldValue('fixedRentalAvailability', fixedRentalArray)
        setFieldValue('fixedValue', true)
        setFieldValue('spaceAvailabilityGroup', spaceAvailabilityGroupArray)
      }
      else if (fixedRentalArray.length === 0) {
        fixedRentalArray.push({ idx: 1, id: '', startTime: '', endTime: '', days: [], additionalCost: false, sunday: false, monday: false, tuesday: false, wednesday: false, thursday: false, friday: false, saturday: false })
        this.setState({ fixedRentalAvailability: fixedRentalArray, fixedValue: true, fixedRentalDaysArray: [], spaceAvailabilityGroup: spaceAvailabilityGroupArray })
        setFieldValue('fixedRentalAvailability', fixedRentalArray)
        setFieldValue('spaceAvailabilityGroup', spaceAvailabilityGroupArray)
        setFieldValue('fixedValue', true)
      }
    }
  }

  deleteTimeslotForFlexible = (val, values, setFieldValue) => {
    let formData = ''
    const obj = values.flexibleRentalAvailability.find(rental => rental.idx === val);
    const spaceAvailabilityGroupArray = values.spaceAvailabilityGroup
    let flexibleRentalDaysArray = this.state.flexibleRentalDaysArray
    let flexibleRentalArray = []
    values.flexibleRentalAvailability.map((flexible) => {
      if (flexible.idx !== obj.idx) {
        flexibleRentalArray.push(flexible)
      }
      else {
        const startTime = moment(flexible.startTime, "h:mm A").format("HH:mm:ss")
        const endTime = moment(flexible.endTime, "h:mm A").format("HH:mm:ss")
        flexible.days.map((day) => {
          const sunday = flexible.days.indexOf('sunday') !== -1 ? 1 : 0
          const monday = flexible.days.indexOf('monday') !== -1 ? 1 : 0
          const tuesday = flexible.days.indexOf('tuesday') !== -1 ? 1 : 0
          const wednesday = flexible.days.indexOf('wednesday') !== -1 ? 1 : 0
          const thursday = flexible.days.indexOf('thursday') !== -1 ? 1 : 0
          const friday = flexible.days.indexOf('friday') !== -1 ? 1 : 0
          const saturday = flexible.days.indexOf('saturday') !== -1 ? 1 : 0
          if (flexible.id) {
            formData = formData + `timeslots[${flexible.idx}][id]=${flexible.id}&timeslots[${flexible.idx}][deleted]=1&timeslots[${flexible.idx}][fixed_rental]=1&timeslots[${flexible.idx}][addl_hours]=${flexible.additionalCost ? 1 : 0}&timeslots[${flexible.idx}][start_time]=${startTime}&timeslots[${flexible.idx}][end_time]=${endTime}&timeslots[${flexible.idx}][monday]=${monday}&timeslots[${flexible.idx}][tuesday]=${tuesday}&timeslots[${flexible.idx}][wednesday]=${wednesday}&timeslots[${flexible.idx}][thursday]=${thursday}&timeslots[${flexible.idx}][friday]=${friday}&timeslots[${flexible.idx}][saturday]=${saturday}&timeslots[${flexible.idx}][sunday]=${sunday}&`
          }
          const idx = flexibleRentalDaysArray.indexOf(day)
          flexibleRentalDaysArray.splice(idx, 1);
          return flexibleRentalDaysArray;
        })
      }
      return flexibleRentalArray;
    })
    if (formData !== '') {
      this.props.updateSpace(this.state.spaceId, formData).then((response) => {
        if (flexibleRentalArray.length > 0) {
          this.setState({ flexibleRentalAvailability: flexibleRentalArray, flexibleRental: true, spaceAvailabilityGroup: spaceAvailabilityGroupArray })
          setFieldValue('flexibleRentalAvailability', flexibleRentalArray)
          setFieldValue('flexibleRental', true)
          setFieldValue('spaceAvailabilityGroup', spaceAvailabilityGroupArray)
        }
        else {
          flexibleRentalArray.push({ idx: 1, id: '', startTime: '', endTime: '', days: [], additionalCost: false, sunday: false, monday: false, tuesday: false, wednesday: false, thursday: false, friday: false, saturday: false })
          this.setState({ flexibleRentalAvailability: flexibleRentalArray, flexibleRental: true, spaceAvailabilityGroup: spaceAvailabilityGroupArray })
          setFieldValue('flexibleRentalAvailability', flexibleRentalArray)
          setFieldValue('spaceAvailabilityGroup', spaceAvailabilityGroupArray)
          setFieldValue('flexibleRental', true)
        }
      })
    }
    else {
      if (flexibleRentalArray.length > 0) {
        this.setState({ flexibleRentalAvailability: flexibleRentalArray, flexibleRental: true, flexibleRentalDaysArray: flexibleRentalDaysArray, spaceAvailabilityGroup: spaceAvailabilityGroupArray })
        setFieldValue('flexibleRentalAvailability', flexibleRentalArray)
        setFieldValue('spaceAvailabilityGroup', spaceAvailabilityGroupArray)
        setFieldValue('flexibleRental', true)
      }
      else if (flexibleRentalArray.length === 0) {
        flexibleRentalArray.push({ idx: 1, id: '', startTime: '', endTime: '', days: [], additionalCost: false, sunday: false, monday: false, tuesday: false, wednesday: false, thursday: false, friday: false, saturday: false, maxHour:'', minHour:'', showMaxHour:false, multipleEvent:1, timeBetweenEvent:'' })
        this.setState({ flexibleRentalAvailability: flexibleRentalArray, flexibleRental: true, flexibleRentalDaysArray: [], spaceAvailabilityGroup: spaceAvailabilityGroupArray })
        setFieldValue('flexibleRentalAvailability', flexibleRentalArray)
        setFieldValue('spaceAvailabilityGroup', spaceAvailabilityGroupArray)
        setFieldValue('fixedValue', true)
      }
    }
  }

  deleteEvent = (val, values, setFieldValue, eventName) => {
    let spaceRatesGroup = this.state.spaceRatesGroup
    let formData = ''
    let eventSpaceRatesArray = []
    let step4ParamsLength = 0
    const obj = values.eventSpaceRatesArray.find(event => event.seqNo === val);
    values.eventSpaceRatesArray.map((data) => {
      var index = spaceRatesGroup.indexOf(eventName);
      if (index !== -1) spaceRatesGroup.splice(index, 1);
      spaceRatesGroup.push(eventName)
      if (data.seqNo.toString() !== obj.seqNo.toString()) {
        const event = data
        event.seqNo = eventSpaceRatesArray.length + 1
        eventSpaceRatesArray.push(event)
      }
      else {
          step4ParamsLength = step4ParamsLength + 1
          const weekday1 = data.days.indexOf('sunday') !== -1 ? 1 : 0
          const weekday2 = data.days.indexOf('monday') !== -1 ? 2 : 0
          const weekday3 = data.days.indexOf('tuesday') !== -1 ? 3 : 0
          const weekday4 = data.days.indexOf('wednesday') !== -1 ? 4 : 0
          const weekday5 = data.days.indexOf('thursday') !== -1 ? 5 : 0
          const weekday6 = data.days.indexOf('friday') !== -1 ? 6 : 0
          const weekday7 = data.days.indexOf('saturday') !== -1 ? 7 : 0
          let additionalFlatRate = data.additionalFlatRate.replace(/\$|,/g, '')
          const fnbRate = data.fnbRate ? data.fnbRate.replace(/\$|,/g, ''):''
          const addRate = data.additionalRate.replace(/\$|,/g, '')
        if(additionalFlatRate===''){
          additionalFlatRate=0;
        }
          if (data.id) {
            if(data.additionalPricingTypeName){
              if(data.minGuest && data.pricingType === 4){
                formData = formData + `event_type_timeslot_pricings[${step4ParamsLength}][id]=${data.id}&event_type_timeslot_pricings[${step4ParamsLength}][timeslot_id]=${data.timeSlotId}&event_type_timeslot_pricings[${step4ParamsLength}][event_type_id]=${data.eventId}&event_type_timeslot_pricings[${step4ParamsLength}][pricing_type]=${data.pricingType}${weekday1 !== 0 ? `&event_type_timeslot_pricings[${step4ParamsLength}][weekdays][1]=${weekday1}` : ''}${weekday2 !== 0 ? `&event_type_timeslot_pricings[${step4ParamsLength}][weekdays][2]=${weekday2}` : ''}${weekday3 !== 0 ? `&event_type_timeslot_pricings[${step4ParamsLength}][weekdays][3]=${weekday3}` : ''}${weekday4 !== 0 ? `&event_type_timeslot_pricings[${step4ParamsLength}][weekdays][4]=${weekday4}` : ''}${weekday5 !== 0 ? `&event_type_timeslot_pricings[${step4ParamsLength}][weekdays][5]=${weekday5}` : ''}${weekday6 !== 0 ? `&event_type_timeslot_pricings[${step4ParamsLength}][weekdays][6]=${weekday6}` : ''}${weekday7 !== 0 ? `&event_type_timeslot_pricings[${step4ParamsLength}][weekdays][7]=${weekday7}` : ''}&event_type_timeslot_pricings[${step4ParamsLength}][base_rate]=${additionalFlatRate}${data.additionalRate ? `&event_type_timeslot_pricings[${step4ParamsLength}][overage_rate]=${addRate}` : ''}&event_type_timeslot_pricings[${step4ParamsLength}][min_guest]=${data.minGuest}&event_type_timeslot_pricings[${step4ParamsLength}][pricing_type_name]=${encodeURIComponent(data.additionalPricingTypeName)}&event_type_timeslot_pricings[${step4ParamsLength}][deleted]=1&`
              }
              else if(fnbRate && data.pricingType === 3){
                formData = formData + `event_type_timeslot_pricings[${step4ParamsLength}][id]=${data.id}&event_type_timeslot_pricings[${step4ParamsLength}][timeslot_id]=${data.timeSlotId}&event_type_timeslot_pricings[${step4ParamsLength}][event_type_id]=${data.eventId}&event_type_timeslot_pricings[${step4ParamsLength}][pricing_type]=${data.pricingType}${weekday1 !== 0 ? `&event_type_timeslot_pricings[${step4ParamsLength}][weekdays][1]=${weekday1}` : ''}${weekday2 !== 0 ? `&event_type_timeslot_pricings[${step4ParamsLength}][weekdays][2]=${weekday2}` : ''}${weekday3 !== 0 ? `&event_type_timeslot_pricings[${step4ParamsLength}][weekdays][3]=${weekday3}` : ''}${weekday4 !== 0 ? `&event_type_timeslot_pricings[${step4ParamsLength}][weekdays][4]=${weekday4}` : ''}${weekday5 !== 0 ? `&event_type_timeslot_pricings[${step4ParamsLength}][weekdays][5]=${weekday5}` : ''}${weekday6 !== 0 ? `&event_type_timeslot_pricings[${step4ParamsLength}][weekdays][6]=${weekday6}` : ''}${weekday7 !== 0 ? `&event_type_timeslot_pricings[${step4ParamsLength}][weekdays][7]=${weekday7}` : ''}&event_type_timeslot_pricings[${step4ParamsLength}][base_rate]=${additionalFlatRate}${data.additionalRate ? `&event_type_timeslot_pricings[${step4ParamsLength}][overage_rate]=${addRate}` : ''}&event_type_timeslot_pricings[${step4ParamsLength}][fnb_rate]=${fnbRate}&event_type_timeslot_pricings[${step4ParamsLength}][pricing_type_name]=${encodeURIComponent(data.additionalPricingTypeName)}&event_type_timeslot_pricings[${step4ParamsLength}][deleted]=1&`
              }
              else{
                formData = formData + `event_type_timeslot_pricings[${step4ParamsLength}][id]=${data.id}&event_type_timeslot_pricings[${step4ParamsLength}][timeslot_id]=${data.timeSlotId}&event_type_timeslot_pricings[${step4ParamsLength}][event_type_id]=${data.eventId}&event_type_timeslot_pricings[${step4ParamsLength}][pricing_type]=${data.pricingType}${weekday1 !== 0 ? `&event_type_timeslot_pricings[${step4ParamsLength}][weekdays][1]=${weekday1}` : ''}${weekday2 !== 0 ? `&event_type_timeslot_pricings[${step4ParamsLength}][weekdays][2]=${weekday2}` : ''}${weekday3 !== 0 ? `&event_type_timeslot_pricings[${step4ParamsLength}][weekdays][3]=${weekday3}` : ''}${weekday4 !== 0 ? `&event_type_timeslot_pricings[${step4ParamsLength}][weekdays][4]=${weekday4}` : ''}${weekday5 !== 0 ? `&event_type_timeslot_pricings[${step4ParamsLength}][weekdays][5]=${weekday5}` : ''}${weekday6 !== 0 ? `&event_type_timeslot_pricings[${step4ParamsLength}][weekdays][6]=${weekday6}` : ''}${weekday7 !== 0 ? `&event_type_timeslot_pricings[${step4ParamsLength}][weekdays][7]=${weekday7}` : ''}&event_type_timeslot_pricings[${step4ParamsLength}][base_rate]=${additionalFlatRate}${data.additionalRate ? `&event_type_timeslot_pricings[${step4ParamsLength}][overage_rate]=${addRate}` : ''}&event_type_timeslot_pricings[${step4ParamsLength}][pricing_type_name]=${encodeURIComponent(data.additionalPricingTypeName)}&event_type_timeslot_pricings[${step4ParamsLength}][deleted]=1&`
              }
            }
            else{
              if(data.minGuest && data.pricingType === 4){
                formData = formData + `event_type_timeslot_pricings[${step4ParamsLength}][id]=${data.id}&event_type_timeslot_pricings[${step4ParamsLength}][timeslot_id]=${data.timeSlotId}&event_type_timeslot_pricings[${step4ParamsLength}][event_type_id]=${data.eventId}&event_type_timeslot_pricings[${step4ParamsLength}][pricing_type]=${data.pricingType}${weekday1 !== 0 ? `&event_type_timeslot_pricings[${step4ParamsLength}][weekdays][1]=${weekday1}` : ''}${weekday2 !== 0 ? `&event_type_timeslot_pricings[${step4ParamsLength}][weekdays][2]=${weekday2}` : ''}${weekday3 !== 0 ? `&event_type_timeslot_pricings[${step4ParamsLength}][weekdays][3]=${weekday3}` : ''}${weekday4 !== 0 ? `&event_type_timeslot_pricings[${step4ParamsLength}][weekdays][4]=${weekday4}` : ''}${weekday5 !== 0 ? `&event_type_timeslot_pricings[${step4ParamsLength}][weekdays][5]=${weekday5}` : ''}${weekday6 !== 0 ? `&event_type_timeslot_pricings[${step4ParamsLength}][weekdays][6]=${weekday6}` : ''}${weekday7 !== 0 ? `&event_type_timeslot_pricings[${step4ParamsLength}][weekdays][7]=${weekday7}` : ''}&event_type_timeslot_pricings[${step4ParamsLength}][base_rate]=${additionalFlatRate}${data.additionalRate ? `&event_type_timeslot_pricings[${step4ParamsLength}][overage_rate]=${addRate}` : ''}&event_type_timeslot_pricings[${step4ParamsLength}][min_guest]=${data.minGuest}&event_type_timeslot_pricings[${step4ParamsLength}][deleted]=1&`
              }
              else if(fnbRate && data.pricingType === 3){
                formData = formData + `event_type_timeslot_pricings[${step4ParamsLength}][id]=${data.id}&event_type_timeslot_pricings[${step4ParamsLength}][timeslot_id]=${data.timeSlotId}&event_type_timeslot_pricings[${step4ParamsLength}][event_type_id]=${data.eventId}&event_type_timeslot_pricings[${step4ParamsLength}][pricing_type]=${data.pricingType}${weekday1 !== 0 ? `&event_type_timeslot_pricings[${step4ParamsLength}][weekdays][1]=${weekday1}` : ''}${weekday2 !== 0 ? `&event_type_timeslot_pricings[${step4ParamsLength}][weekdays][2]=${weekday2}` : ''}${weekday3 !== 0 ? `&event_type_timeslot_pricings[${step4ParamsLength}][weekdays][3]=${weekday3}` : ''}${weekday4 !== 0 ? `&event_type_timeslot_pricings[${step4ParamsLength}][weekdays][4]=${weekday4}` : ''}${weekday5 !== 0 ? `&event_type_timeslot_pricings[${step4ParamsLength}][weekdays][5]=${weekday5}` : ''}${weekday6 !== 0 ? `&event_type_timeslot_pricings[${step4ParamsLength}][weekdays][6]=${weekday6}` : ''}${weekday7 !== 0 ? `&event_type_timeslot_pricings[${step4ParamsLength}][weekdays][7]=${weekday7}` : ''}&event_type_timeslot_pricings[${step4ParamsLength}][base_rate]=${additionalFlatRate}${data.additionalRate ? `&event_type_timeslot_pricings[${step4ParamsLength}][overage_rate]=${addRate}` : ''}&event_type_timeslot_pricings[${step4ParamsLength}][fnb_rate]=${fnbRate}&event_type_timeslot_pricings[${step4ParamsLength}][deleted]=1&`
              }
              else{
                formData = formData + `event_type_timeslot_pricings[${step4ParamsLength}][id]=${data.id}&event_type_timeslot_pricings[${step4ParamsLength}][timeslot_id]=${data.timeSlotId}&event_type_timeslot_pricings[${step4ParamsLength}][event_type_id]=${data.eventId}&event_type_timeslot_pricings[${step4ParamsLength}][pricing_type]=${data.pricingType}${weekday1 !== 0 ? `&event_type_timeslot_pricings[${step4ParamsLength}][weekdays][1]=${weekday1}` : ''}${weekday2 !== 0 ? `&event_type_timeslot_pricings[${step4ParamsLength}][weekdays][2]=${weekday2}` : ''}${weekday3 !== 0 ? `&event_type_timeslot_pricings[${step4ParamsLength}][weekdays][3]=${weekday3}` : ''}${weekday4 !== 0 ? `&event_type_timeslot_pricings[${step4ParamsLength}][weekdays][4]=${weekday4}` : ''}${weekday5 !== 0 ? `&event_type_timeslot_pricings[${step4ParamsLength}][weekdays][5]=${weekday5}` : ''}${weekday6 !== 0 ? `&event_type_timeslot_pricings[${step4ParamsLength}][weekdays][6]=${weekday6}` : ''}${weekday7 !== 0 ? `&event_type_timeslot_pricings[${step4ParamsLength}][weekdays][7]=${weekday7}` : ''}&event_type_timeslot_pricings[${step4ParamsLength}][base_rate]=${additionalFlatRate}${data.additionalRate ? `&event_type_timeslot_pricings[${step4ParamsLength}][overage_rate]=${addRate}` : ''}&event_type_timeslot_pricings[${step4ParamsLength}][deleted]=1&`
              }
            }
          }
          else{
            this.setState({eventSpaceRatesArray: eventSpaceRatesArray, spaceRatesGroup: spaceRatesGroup})
          }
      }

      return spaceRatesGroup;
    })
    if (formData !== '') {
      this.props.updateSpace(this.state.spaceId, formData).then((response) => {
        if (eventSpaceRatesArray.length > 0) {
          this.setState({ eventSpaceRatesArray: eventSpaceRatesArray })
          setFieldValue('eventSpaceRatesArray', eventSpaceRatesArray)
        }
      })
    }
    else {
      if (eventSpaceRatesArray.length > 0) {
        this.setState({ eventSpaceRatesArray: eventSpaceRatesArray })
        setFieldValue('eventSpaceRatesArray', eventSpaceRatesArray)
      }
    }
  }


  handleHelpIconClick = (event) => {
    this.setState({
      anchorEl: event.currentTarget,
      isToggleOpen: true
    })
  }
  handleCloseHelpIcon = () => {
    this.setState({
      anchorEl: null,
      isToggleOpen: false
    });
  }

  handleOpenFixedPopper = (event) => {
    this.setState({
      anchorElFixed: event.currentTarget,
      isFixedToggleOpen: true
    })
  }
  handleCloseFixedPopper = () => {
    this.setState({
      anchorElFixed: null,
      isFixedToggleOpen: false
    });
  }

  handleOpenFlexiblePopper = (event) => {
    this.setState({
      anchorElFlexible: event.currentTarget,
      isFlexibleToggleOpen: true
    })
  }
  handleCloseFlexiblePopper = () => {
    this.setState({
      anchorElFlexible: null,
      isFlexibleToggleOpen: false
    });
  }
  handleExit = (result) => {
     this.setState({exitclick:result})
  }
  handleCopyTimeSlotPopup=(event,values)=>{
        this.setState({copyTargetSpaceId:event.target.value})
        this.setState({copyAvailableName:event.target.value,copyAvailable:true})
        this.setState({open:true})

  }
  closeCopyTimeslotPopup=()=>{
    this.setState({open:false})
    this.setState({copyTargetSpaceId:''})
    this.setState({copyAvailableName:'',copyAvailable:false})

  }
  render() {
    const initialValues = {
      spaceName: this.state.spaceName,
      checkboxGroup: this.state.checkboxGroup,
      spaceAvailabilityGroup: this.state.spaceAvailabilityGroup,
      sitdown: this.state.sitdown,
      classroom: this.state.classroom,
      boardroom: this.state.boardroom,
      theater: this.state.theater,
      reception: this.state.reception,
      tradeshow: this.state.tradeshow,
      preexisting: this.state.preexisting,
      empty: this.state.empty,
      sitdownMin: this.state.sitdownMin,
      classroomMin: this.state.classroomMin,
      boardroomMin: this.state.boardroomMin,
      theaterMin: this.state.theaterMin,
      receptionMin: this.state.receptionMin,
      tradeshowMin: this.state.tradeshowMin,
      preexistingMin: this.state.preexistingMin,
      emptyMin: this.state.emptyMin,
      sitdownMax: this.state.sitdownMax,
      classroomMax: this.state.classroomMax,
      boardroomMax: this.state.boardroomMax,
      theaterMax: this.state.theaterMax,
      receptionMax: this.state.receptionMax,
      tradeshowMax: this.state.tradeshowMax,
      preexistingMax: this.state.preexistingMax,
      emptyMax: this.state.emptyMax,
      submitAction: this.state.submitAction,
      fixedValue: this.state.fixedValue,
      flexibleRental: this.state.flexibleRental,
      copyAvailable: this.state.copyAvailable,
      copyAvailableName: this.state.copyAvailableName,
      fixedRentalAvailability: this.state.fixedRentalAvailability,
      flexibleRentalAvailability: this.state.flexibleRentalAvailability,
      spaceRatesGroup: this.state.spaceRatesGroup,
      eventTypesGroup: this.state.eventTypesGroup,
      eventSpaceRatesArray: this.state.eventSpaceRatesArray,
      copyEventTypeAndPricing: this.state.copyEventTypeAndPricing,
      copyEventTypeAndPricingName: this.state.copyEventTypeAndPricingName,
      pricingCategory: this.state.pricingCategory,
      pricingCategories: this.state.pricingCategories,
      anotherPricingVariable: this.state.anotherPricingVariable,
      arrangements: this.state.arrangements,
      addVariable: this.state.addVariable,
      fixedEventPopper: this.state.fixedEventPopper,
      flexibleEventPopper: this.state.flexibleEventPopper
    }
    // const venueName = this.props.venueDetails && this.props.venueDetails.name
    return (
      <div className="add_space fh-container">
        <Formik
          enableReinitialize = {this.state.step !== 4}
          initialValues={initialValues}
          validationSchema={this.state.step === 1 ? Step1ValidationSchema : this.state.step === 2 ? Step2ValidationSchema : this.state.step === 3 ? Step3ValidationSchema : Step4ValidationSchema}
          onSubmit={(values, actions) => {
            this.handleSubmit(values, actions)
          }}
          render={props => (
            <form  onSubmit={props.handleSubmit} >
              <StepProgressBar changeSubmission={this.changeSubmission} handleSubmit={props.handleSubmit} values={props.values} formName={this.props.match.params.spaceId ? "Edit Space" : "Add Space"} step={this.state.step} decrementStep={this.decrementStep} handleExit={this.handleExit}/>
              {this.state.step === 1 ?
                <AddSpaceName formikProps={props} />
                : this.state.step === 2 ?
                  <AddSpaceCapacity venueName={this.state.venueName} arrangementsError={this.state.arrangementsError} formikProps={props} spaceName={this.state.spaceName} values={props.values} spaceData={this.props.spaceData} />
                  : this.state.step === 3 ?
                    <AddSpaceAvailability handleAddTimeSlot={this.handleAddTimeSlot} spaceList={this.state.spaceList} deleteTimeslotForFlexible={this.deleteTimeslotForFlexible} deleteTimeslotForFixed={this.deleteTimeslotForFixed} fixedRentalDaysArray={this.state.fixedRentalDaysArray} flexibleRentalDaysArray={this.state.flexibleRentalDaysArray} venueName={this.state.venueName} formikProps={props} spaceName={this.state.spaceName} onFixedDaySelect={this.onFixedDaySelect} onFlexibleDaySelect={this.onFlexibleDaySelect} handleCopyTimeSlot={this.handleCopyTimeSlot} anchorElFixed={this.state.anchorElFixed} handleCloseFixedPopper={this.handleCloseFixedPopper} handleOpenFixedPopper={this.handleOpenFixedPopper} isFixedToggleOpen={this.state.isFixedToggleOpen} anchorElFlexible={this.state.anchorElFlexible} handleCloseFlexiblePopper={this.handleCloseFlexiblePopper} handleOpenFlexiblePopper={this.handleOpenFlexiblePopper}  isFlexibleToggleOpen={this.state.isFlexibleToggleOpen} onRentalChange={this.onRentalChange} open={this.state.open} closeCopyTimeslotPopup={this.closeCopyTimeslotPopup} handleCopyTimeSlotPopup={this.handleCopyTimeSlotPopup}/>
                    :
                    <AddSpaceRates errors={props.errors} 
                    spaceList={this.state.spaceList} 
                    copyTimeSlot={this.state.copyTimeSlot} 
                    venueName={this.state.venueName} 
                    formikProps={props} 
                    spaceName={this.state.spaceName} 
                    // step4Error={this.state.step4Error} 
                    step4Error={step4Error} 
                    fixedRentalAvailabilitySortedDays={this.state.fixedRentalAvailabilitySortedDays} 
                    flexibleRentalAvailabilitySortedDays={this.state.flexibleRentalAvailabilitySortedDays} 
                    anchorEl={this.state.anchorEl} 
                    handleCloseHelpIcon={this.handleCloseHelpIcon} 
                    handleHelpIconClick={this.handleHelpIconClick} 
                    isToggleOpen={this.state.isToggleOpen} 
                    anchorElFixedAddOn={this.state.anchorElFixedAddOn} 
                    isFixedAddonToggleOpen={this.state.isFixedAddonToggleOpen} 
                    deleteEvent={this.deleteEvent}
                    fixedEventPopper={this.state.fixedEventPopper} 
                    flexibleEventPopper={this.state.flexibleEventPopper}/>
              }
            </form>
          )}
        />
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    spaceData: state.data.spaces.spaceData,
    eventTypeList: state.data.master.eventTypeList,
    venueDetails: state.data.venues.venueDetails,
    arrangements: state.data.master.arrangements
  };
};

export default connect(
  mapStateToProps,
  dispatch => bindActionCreators({
    addSpace,
    updateSpace,
    eventTypes,
    getVenue,
    updateSpaceName,
    getSpace,
    getArrangements,
    timeslotCopy,
    getSpaceTimeslots
  }, dispatch),
)(withRouter(AddSpace));
