import React, { Component } from "react";
import CustomizedCheckBox from "../../../shared/components/CustomizedCheckbox";
import SyncCheckBox from "../../../shared/components/SyncCheckbox";
import CustomSelectWithScroll from "../../../shared/components/CustomSelectWithScroll";
import "../assets/styles/search.scss";

import { Formik, Form } from "formik";
import PropTypes from "prop-types";
import {
  Grid,
  //Hidden,
  Icon,
  //Popper,
  //Typography,
  //Paper,
  Button
} from "@material-ui/core";
import { Redirect } from "react-router-dom";
import { getSpace } from "../../../../src/data/spaces/actions";
import { userDetails,venueList } from "../../../shared/venueList/actions";
import { getGoogleToken } from "../../../../src/data/venues/actions/getGoogleToken";
import { getGoogleCalendaEvents } from "../../../../src/data/venues/actions/getGoogleCalendaEvents";
import { getGoogleCalendarSingleEvents } from "../../../../src/data/venues/actions/getSignleEvent";
import { getVenue } from "../../../../src/data/venues/actions";
import { saveGoogleCalendaEvents } from '../../../../src/data/venues/actions/saveGoogleCalendaEvents';
import {googleSyncedEvents,updateGoogleCalendarEvents,getAllGoogleSyncedEvents } from "../../../../src/data/venues/actions";
import VenueSpaces  from "../../../../src/venueOwner/components/venueSpaces";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators, compose } from "redux";
import moment from "moment";
import {MenuItem,Typography,FormControl,Select} from '@material-ui/core';
import { CenterFocusStrong } from "@material-ui/icons";
import { saveNotApplyGsEvents } from '../../../../src/data/venues/actions/saveNotApplyGsEvents';
import { getNotApplyGsEvents } from '../../../../src/data/venues/actions/getNotApplyGsEvents';

//const elements = ["1", "2", "3"];
let fhwithArray = [];
let fhwithOutArray = [];
// const searchstr = [];
// const status = "";
// const selectedHoldVal = "";
let optionsHold = [];
let formData = {};
let sendWithArray =[];
let sendWithOutArray =[];
let secondScreenDropArr = [];
let checkSelect=[];
let holdBookedEevents = [];
let holdAllBookedEevents=[]
var gsDayNames = [
  'Sunday',
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday'
];

class Search extends Component {
  constructor(props) {
    super(props);
    this.handleClick = this.handleClick.bind(this);
    this.handleValeChange = this.handleValeChange.bind(this);
  }
  state = {
    spaceId: "",
    vanueId: "",
    eventsShow: 1,
    eventItems: [],
    eventMaxHold: "",
    eventNext: 1,
    satetHolds: "",
    holdVal: [],
    holdValWithOut: [],
    selectedOption: "",
    eventWithArry: [],
    eventWithOutArry: [],
    venueArr:[],
    loading:true,
    venuenSpaces:false,
    selectedSpaces:[],
    selectedSpaceIndex:0,
    selectedSpaceType:1,
    spaceDetail:{},
    syncYears:[
      {"id":moment().year(),"name":moment().format("MMM")+" "+moment().year()+' - '+moment().add(2, 'years').subtract(1,'months').format("MMM")+" "+moment().add(2, 'years').year()}
     ,{"id":moment().add(2, 'years').year(),"name":moment().add(2, 'years').format("MMM")+" "+moment().add(2, 'years').year()+' - '+moment().add(4, 'years').subtract(1,'months').format("MMM")+" "+moment().add(4, 'years').year()}
     ,{"id":moment().add(4, 'years').year(),"name":moment().add(4, 'years').format("MMM")+" "+moment().add(4, 'years').year()+' - '+moment().add(6 ,'years').subtract(1,'months').format("MMM")+" "+moment().add(6, 'years').year()}
     ,{"id":moment().add(6, 'years').year(),"name":moment().add(6, 'years').format("MMM")+" "+moment().add(6, 'years').year()+' - '+moment().add(8, 'years').subtract(1,'months').format("MMM")+" "+moment().add(8, 'years').year()}
     ],
    syncYear:moment().year(),
    googleNextSyncToken:'',
    loadingMore:false,
    doesNotApply:[],
    doesNotApplyEvents:[],
    statusDisable:false,
    repeatInstances:false,
    repeatCheck:[],
    page:1 ,
    loadingMoreSyncedItems:false,
    holdBookedEventsItems:[],
    syncedNextPageToken:null,
    holdAllSyncedEvents:[]
    
  };
  componentWillMount() {
    fhwithArray = []
    fhwithOutArray = []
    sendWithArray = []
    sendWithOutArray = []
    secondScreenDropArr = []
    optionsHold = []
    let t=this;
    holdBookedEevents = [];
    holdAllBookedEevents=[]
    const calendarId = decodeURIComponent(this.props.match.params.calendarId);
    const spaceId    = this.props.match.params.spaceId.toString();
    this.props.venueList().then(() => {}).catch(error =>{});    
    this.props
      .getSpace(this.props.match.params.spaceId)
      .then(res => {
        if (res.calendar_id) {
            this.setState({
              vanueId: res.venue_id,
              spaceDetail:res
            });
            setTimeout(() => {
              this.loadEvents();
            }, 800);
           
        }
        else{
          this.setState({eventsShow: 2, eventNext:3});
        }
      })
      .catch(error => this.setState({eventsShow: 2, eventNext:3,loading:false}));
      this.props.getNotApplyGsEvents(spaceId).then(responsEvent=>{
       this.setState({doesNotApplyEvents:responsEvent})
      }
      )
  }

  loadEvents = (year = moment().year(),dFlag=0)=>{
    const calendarId = decodeURIComponent(this.props.match.params.calendarId);
    const spaceId    = this.props.match.params.spaceId.toString();
    this.props.
    googleSyncedEvents(this.props.match.params.spaceId,year,this.props.match.params.calendarId,1).then(hbEvents=>{   
             
              hbEvents.data.map(function (arr) {               
                holdBookedEevents.push({title:arr.event.name,start_date:arr.event.start_date,end_date:arr.event.end_date,hold_rank:arr.hold_rank,hold_type:arr.hold_type,id:arr.parent_id,sync:arr.sync_from_google,planner_id:arr.event.event_planner.id,start_time:arr.event.start_time,end_time:arr.event.end_time});
              }); 
              // this.props.
              // getAllGoogleSyncedEvents(this.props.match.params.spaceId,year,this.props.match.params.calendarId,1).then(hbAllEvents=>{   
              //   hbAllEvents.data.map(function (arr) {               
              //     holdAllBookedEevents.push({title:arr.event.name,start_date:arr.event.start_date,end_date:arr.event.end_date,hold_rank:arr.hold_rank,hold_type:arr.hold_type,id:arr.parent_id,sync:arr.sync_from_google,planner_id:arr.event.event_planner.id,start_time:arr.event.start_time,end_time:arr.event.end_time});
              //   });       
                this.props
                .getGoogleToken()
                .then(response => {
                  this.props
                    .getGoogleCalendaEvents(calendarId,year)
                    .then(eventsResponse => {           
                      if (eventsResponse && eventsResponse.items.length>0) {
                        let stateArray = [];
                        let googleStartEventDate=new Date(eventsResponse.items[0].start.dateTime).toISOString().substring(0, 10)
                        let googleEndEventDate=new Date(eventsResponse.items[eventsResponse.items.length-1].end.dateTime).toISOString().substring(0, 10)
                        this.props.
                        getAllGoogleSyncedEvents(this.props.match.params.spaceId,year,this.props.match.params.calendarId,googleStartEventDate,googleEndEventDate).then(hbAllEvents=>{   
                          hbAllEvents.data.map(function (arr) {               
                            holdAllBookedEevents.push({title:arr.event.name,start_date:arr.event.start_date,end_date:arr.event.end_date,hold_rank:arr.hold_rank,hold_type:arr.hold_type,id:arr.parent_id,sync:arr.sync_from_google,planner_id:arr.event.event_planner.id,start_time:arr.event.start_time,end_time:arr.event.end_time});
                          });
                        })
                        for (let i = 0; i < eventsResponse.items.length; i++) {
                          let dbToken=false;
                          for(let j=0;j < this.state.spaceDetail.event_timeslots.length; j++){
                            if(eventsResponse.items[i].summary==this.state.spaceDetail.event_timeslots[j].event.name){
                                dbToken=true;
                                break;
                            }
                          
                          }
                          if(dbToken==false){
                            if(this.state.doesNotApplyEvents.length>0)
                            {  
                              let notApplyToken=false;
                              let notApplyCheck=false;
                            // this.state.doesNotApplyEvents.map(function (data, index) {
                              for(let k=0;k < this.state.doesNotApplyEvents.length; k++){
                                notApplyToken=notApplyCheck;
                              if(eventsResponse.items[i].id===this.state.doesNotApplyEvents[k].gs_event_id)
                              {
                                notApplyToken=true;
                                break;
                              }
                            }
                            //)
                            if(notApplyToken===false)
                            {
                              stateArray.push(eventsResponse.items[i]);
 
                            }
                            }
                            else{
                              
                              stateArray.push(eventsResponse.items[i]);
                            }
                          }
                        }                        
                        secondScreenDropArr.push(stateArray);
                        let itemsArray = [];
                        
                        itemsArray.push(stateArray);
                        
                        this.setState({
                          eventItems: itemsArray,
                          googleNextSyncToken:eventsResponse.nextPageToken,
                          holdBookedEventsItems:holdBookedEevents,
                          holdAllSyncedEvents:holdAllBookedEevents
                        });
                         if(hbEvents.pagination.current_page!==hbEvents.pagination.last_page)
                         {
                          setTimeout(() => {
                            this.setState({page:this.state.page+1,syncedNextPageToken:hbEvents.pagination.next_page_url})
                          }, 1000);
                         }
                         else
                         {
                          this.setState({syncedNextPageToken:null})

                         }
                        if (this.state.eventItems.length >0) {

                          this.setState({
                            eventsShow: 2
                          });
                          this.props
                            .getVenue(this.state.vanueId)
                            .then(response => {
                              
                              this.setState({venueArr: response});

                              this.setState({
                                eventMaxHold: response.data.max_hold_limit
                              });
                              if (this.state.eventMaxHold) {
                                optionsHold.push({
                                  id: "booked",
                                  name: "Booked"
                                });
                                optionsHold.push({
                                  id: "blocked",
                                  name: "Blocked"
                                });
                                for (let i = 1; i <= this.state.eventMaxHold; i++) {
                                  let opLabel = this.ordinal_suffix_of(i) + " Hold";
                                  optionsHold.push({
                                    id: opLabel,
                                    name: opLabel
                                  });
                                }                                                            
                                this.setState({
                                  satetHolds: optionsHold
                                });
                              }

                              let eventstatus = "";
                              let status = [];
                              let statusWihOut = [];
                              
                              // this.state.eventItems.map((data)=> {
                                this.state.eventItems.map((data)=> {
                               
                                  data.map((eventArr) => {
                                    
                                    let eventstatus = "";
                                    let date = "";
                                    let time = ""; let sTime = ""; let eTime =""; let sDate =""; let eDate="";
                                    let startDate = eventArr.start.dateTime?new Date(moment(eventArr.start.dateTime)):new Date(moment(eventArr.start.date));
                                    let endDate = eventArr.end.dateTime?new Date((eventArr.end.dateTime)):new Date(moment(eventArr.end.date).subtract(1, "days"));
                                    let dateFormat = 'MM/DD/YY';
                                    if(moment(moment(startDate).format(dateFormat),dateFormat,true).isValid()===true){
                                        sDate = `${moment(startDate, 'MM/DD/YY', true).format("MM/DD/YY")}`;
                                        sTime =  eventArr.start.dateTime?`${moment(startDate, 'hh:mm A', true).format("hh:mm a")}`:'';
                                        if(sTime && sTime[0].toString()==='0'){
                                            sTime=sTime.replace('0','');
                                        }
                                        sTime=sTime.replace('m','.m.');
                                        if(sDate[0] && sDate[0].toString()==='0'){
                                            sDate=sDate.replace('0','');
                                        }
                                        sDate=sDate.replace('/0','/')
                                    }
                                    
                                    if(moment(moment(endDate).format(dateFormat),dateFormat,true).isValid()===true){
                                    
                                        eDate = `${moment(endDate, 'MM/DD/YY', true).format("MM/DD/YY")}`;
                                        eTime =  eventArr.end.dateTime?`${moment(endDate, 'hh:mm A', true).format("hh:mm a")}`:'';
                                        if(eTime && eTime[0].toString()==='0'){
                                            eTime=eTime.replace('0','');
                                        }
                                        eTime=eTime.replace('m','.m.');
                                        if(eDate[0] && eDate[0].toString()==='0'){
                                            eDate=eDate.replace('0','');
                                        }
                                        eDate=eDate.replace('/0','/');
                                    }
                                    
                                    if (sDate === eDate) {
                                        var d = new Date(sDate);
                                        var dayName = gsDayNames[d.getDay()];
                                        date = dayName + " " +sDate;
                                    } else {
                                        date = sDate + " - " + eDate;
                                        if(sDate && sDate.toString()!=='' && eDate && eDate.toString()!==''){
                                            date = sDate + " - " + eDate;
                                        }
                                        else {
                                            date = sDate + " - " + eDate;
                                        }   
                                    }
                                    
                                    if (sTime === eTime) {
                                        time = sTime;
                                    } else {
                                        time = sTime + " - " + eTime;
                                    }
                                    
                                    let title='';
                                    if(eventArr.summary===''){
                                        title = eventArr.description;
                                    }
                                    else{
                                        title = eventArr.summary;
                                    }
  
                                    let recurrence='';    
                                    if(eventArr.recurringEventId){
                                        recurrence = eventArr.recurringEventId;
                                    } 
                                   
                                    if(title && title.toString()!=='' && date && date.toString()!==''){    
                                     
                                        let eSDate = `${moment(startDate, 'MM/DD/YY', true).format("YYYY-MM-DD")}`;
                                        let eEDate = `${moment(endDate, 'MM/DD/YY', true).format("YYYY-MM-DD")}`;
                                      
                                        let searchObj = holdAllBookedEevents.find(event => (event.title === eventArr.summary &&  event.start_date === eSDate && event.end_date === eEDate && (parseInt(event.sync)==1 || parseInt(event.planner_id)==parseInt(localStorage.getItem('id')))));
                                     
                                        if(searchObj!=undefined){                             
                                           
                                            if(searchObj.hold_type==3){
                                              eventstatus = "booked";
                                            }else if(searchObj.hold_type == 4){
                                              eventstatus = "blocked";
                                            }else{
                                              eventstatus = this.ordinal_suffix_of(searchObj.hold_rank) + " Hold";
                                            }
                                            
                                            // sendWithArray.push(eventArr);
                                            // status.push(eventstatus);                                  
                                            // fhwithArray.push({
                                            //     id:eventArr.id,
                                            //     recurrence:recurrence,
                                            //     reason: eventArr.summary,
                                            //     date: date,
                                            //     time: time,
                                            //     event_timeslot_id:searchObj.id,
                                            //     status: eventstatus,
                                            //     spaces: [spaceId]
                                            // });
                                        }else{       
                                          statusWihOut.push(eventstatus);
                                          sendWithOutArray.push(eventArr);
                                          fhwithOutArray.push({
                                            id:eventArr.id,
                                            recurrence:recurrence,
                                            reason: title,
                                            date: date,
                                            time: time,
                                            status: eventstatus,
                                            spaces: [spaceId]
                                          });
                                        }
                                    }
                                    return fhwithArray;
                                  });
                                  return fhwithArray;
                                });
                               
                                holdBookedEevents.map((eventArr) => {
                                 let isoStartDate= eventArr.start_date+" "+eventArr.start_time;
                                 let parsedStartDate = moment(isoStartDate, 'YYYY-MM-DD H:mm:ss')
                                let isoParsedStartDate=(parsedStartDate.toISOString());
                                let isoEndDate=eventArr.end_date+" "+eventArr.end_time;
                                let ParsedEndDate=moment(isoEndDate, 'YYYY-MM-DD H:mm:ss')
                                let isoParsedEndDate=(ParsedEndDate.toISOString()); 
                                  let eventstatus = "";
                                  let date = "";
                                  let time = ""; let sTime = ""; let eTime =""; let sDate =""; let eDate="";
                                  let startDate = eventArr.start_date?new Date(moment(isoParsedStartDate)):new Date(moment(isoParsedStartDate));
                                  let endDate = eventArr.end_date?new Date((isoParsedEndDate)):new Date(moment(isoParsedEndDate).subtract(1, "days"));
                                  let dateFormat = 'MM/DD/YY';
                                  if(moment(moment(startDate).format(dateFormat),dateFormat,true).isValid()===true){
                                      sDate = `${moment(startDate, 'MM/DD/YY', true).format("MM/DD/YY")}`;
                                      sTime =  isoParsedStartDate?`${moment(startDate, 'hh:mm A', true).format("hh:mm a")}`:'';
                                      if(sTime && sTime[0].toString()==='0'){
                                          sTime=sTime.replace('0','');
                                      }
                                      sTime=sTime.replace('m','.m.');
                                      if(sDate[0] && sDate[0].toString()==='0'){
                                          sDate=sDate.replace('0','');
                                      }
                                      sDate=sDate.replace('/0','/')
                                  }
                                  
                                  if(moment(moment(endDate).format(dateFormat),dateFormat,true).isValid()===true){
                                  
                                      eDate = `${moment(endDate , 'MM/DD/YY', true).format("MM/DD/YY")}`;
                                      eTime =  isoParsedEndDate?`${moment(endDate , 'hh:mm A', true).format("hh:mm a")}`:'';
                                      if(eTime && eTime[0].toString()==='0'){
                                          eTime=eTime.replace('0','');
                                      }
                                      eTime=eTime.replace('m','.m.');
                                      if(eDate[0] && eDate[0].toString()==='0'){
                                          eDate=eDate.replace('0','');
                                      }
                                      eDate=eDate.replace('/0','/');
                                  }
                                  
                                  if (sDate === eDate) {
                                      var d = new Date(sDate);
                                      var dayName = gsDayNames[d.getDay()];
                                      date = dayName + " " +sDate;
                                  } else {
                                      date = sDate + " - " + eDate;
                                      if(sDate && sDate.toString()!=='' && eDate && eDate.toString()!==''){
                                          date = sDate + " - " + eDate;
                                      }
                                      else {
                                          date = sDate + " - " + eDate;
                                      }   
                                  }
                                  
                                  if (sTime === eTime) {
                                      time = sTime;
                                  } else {
                                      time = sTime + " - " + eTime;
                                  }
                                  
                                  let title='';
                                  if(eventArr.summary===''){
                                      title = eventArr.description;
                                  }
                                  else{
                                      title = eventArr.title;
                                  }

                                  let recurrence='';    
                                  if(eventArr.recurringEventId){
                                      recurrence = eventArr.recurringEventId;
                                  } 
                                 
                                  if(title && title.toString()!=='' && date && date.toString()!==''){    
                                   
                                      let eSDate = `${moment(startDate, 'MM/DD/YY', true).format("YYYY-MM-DD")}`;
                                      let eEDate = `${moment(endDate, 'MM/DD/YY', true).format("YYYY-MM-DD")}`;
                                    
                                       let searchObj =eventArr

                                      if(searchObj!=undefined){                             
                                          if(searchObj.hold_type==3){
                                            eventstatus = "booked";
                                          }else if(searchObj.hold_type == 4){
                                            eventstatus = "blocked";
                                          }else{
                                            eventstatus = this.ordinal_suffix_of(searchObj.hold_rank) + " Hold";
                                          }
                                          
                                          sendWithArray.push(searchObj);
                                          status.push(eventstatus);                                  
                                          fhwithArray.push({
                                              id:searchObj.id,
                                              recurrence:recurrence,
                                              reason: title,
                                              date: date,
                                              time: time,
                                              event_timeslot_id:searchObj.id,
                                              status: eventstatus,
                                              spaces: [spaceId]
                                          });
                                        }
                                        //});
                                      // }else{       
                                      //   statusWihOut.push(eventstatus);
                                      //   sendWithOutArray.push(eventArr);
                                      //   fhwithOutArray.push({
                                      //     id:eventArr.id,
                                      //     recurrence:recurrence,
                                      //     reason: title,
                                      //     date: date,
                                      //     time: time,
                                      //     status: eventstatus,
                                      //     spaces: [spaceId]
                                      //   });
                                      }
                                  
                                  return fhwithArray;
                                });
                              //   return fhwithArray;
                              // });
                              this.setState({holdVal: status });
                              this.setState({holdValWithOut: statusWihOut });
                              this.setState({loading:false})
                              this.setState({eventWithArry: fhwithArray});
                              this.setState({eventWithOutArry: fhwithOutArray});
                              if (this.state.eventsShow === 2) {
                                let fhWithArrLength = this.state.eventWithArry.length;
                                if (fhWithArrLength === 0 && parseInt(this.state.eventNext) === 1 && dFlag ==0) {
                                  this.setState({eventNext: 2});
                                }
                              }
                            })
                        }
                      } else if(dFlag ==0){
                        this.setState({ eventNext: 3, eventsShow: 2 ,loading:false });                        
                      }else{
                        this.setState({ loading:false }); 
                      }
                    })                  
                })
              })
            //})
  }


  handleValeChange = (event,index) => {
    const newSatate = this.state.holdVal;
    newSatate[index] = event.target.value;
    this.setState({ holdVal: newSatate });
      
  };
  handleValeWithOutChange = (event,index) => {
    const newSatate = this.state.holdValWithOut;
    newSatate[index] = event.target.value;
    // secondScreenDropArr[index] = name;
    this.setState({ holdValWithOut: newSatate });
      
  };

  handleYearChange = (event) =>{
    let sYear = event.target.value;
    fhwithArray = []
    fhwithOutArray = []
    sendWithArray = []
    sendWithOutArray = []
    secondScreenDropArr = []
    optionsHold = []
    holdBookedEevents = [];
    this.setState({syncYear:sYear,holdVal: [],holdValWithOut:[],loading:true,eventWithArry:[],eventWithOutArry:[]});
    this.loadEvents(sYear,1);               
  }

  setRedirect = () => {
    this.setState({
      redirect: true
    });
  };
  renderRedirect = () => {
    if (this.state.redirect) {
      return <Redirect to={'/spaceProfileEdit/'+this.props.match.params.spaceId} />
     
    }
  };
  ordinal_suffix_of(i) {
    var j = i % 10,
      k = i % 100;
    if (j === 1 && k !== 11) {
      return i + "st";
    }
    if (j === 2 && k !== 12) {
      return i + "nd";
    }
    if (j === 3 && k !== 13) {
      return i + "rd";
    }
    return i + "th";
  }
  handleClick = () => {
    this.setState({ eventNext: 2 });
  };
  handleNextDiv = () => {
    if (document.getElementById("firstDivNextBtn")) {
      document.getElementById("firstDivNextBtn").click();
    }
  };

  handleSubmit = (values, actions) => {
      let saveEvntArr = [];
      //let venueArr = this.state.venueArr;
      //let gotoStepVar =this;
      const calendarId=this.props.match.params.calendarId;         
      localStorage.removeItem('googleCalendarVal');
      if (sendWithArray.length > 0) {
        let holdRank = '1';
        let holdType = '';
        let stateHoldVal = this.state.holdVal;
        this.state.eventWithArry.map(function (data, index) {            
          if (stateHoldVal[index] !== data.status) {

            if (stateHoldVal[index].includes('Hold') === true) {                  
              let holdData = stateHoldVal[index].replace(/(\d+)(st|nd|rd|th)/, "$1");
                let getStr1 = holdData.split(' ');
                if (parseInt(getStr1[0]) === 1) {
                  holdRank = 1;
                }
                if (parseInt(getStr1[0]) === 2) {
                  holdRank = 2;
                }
                if (parseInt(getStr1[0]) === 3) {
                  holdRank = 3;
                }
                if (parseInt(getStr1[0]) === 4) {
                  holdRank = 4;
                }
                if (parseInt(getStr1[0]) === 5) {
                  holdRank = 5;
                }
                if (parseInt(getStr1[0]) === 6) {
                  holdRank = 6;
                }
                if (parseInt(getStr1[0]) === 7) {
                  holdRank = 7;
                }
                holdType = 1;
            }               
            
            if (stateHoldVal[index] === 'booked') {
              holdRank = 3;
              holdType = 3;
            } 
            
            if (stateHoldVal[index] === 'blocked') {
              holdRank = 4;
              holdType = 4;
            }         
          

            saveEvntArr.push({
              event_timeslot_id:data.event_timeslot_id,
              hold_rank: holdRank,
              hold_type: holdType
            })
          }
          return saveEvntArr;
        });

      }         
      
      if (saveEvntArr.length > 0) {        
        formData = {
          "data": saveEvntArr,
          "space_id": this.props.match.params.spaceId,
          "sync"    : "1"
        };
        this.props.updateGoogleCalendarEvents(formData)
          .then(res => {
            // let doesNotApplyFormaData={
            //   "data": this.state.doesNotApply,
            //     "space_id": this.props.match.params.spaceId,
            // }
            // this.props.saveNotApplyGsEvents(doesNotApplyFormaData)  
            // .then(res => {
            //   this.props.getNotApplyGsEvents(this.props.match.params.spaceId).then(responsEvent=>{
            //     this.setState({doesNotApplyEvents:responsEvent})
            //    }
            //    )
            // })
            // .catch(error => {})
            this.handleClick();
          })
          .catch(error => {})

      } else {
        this.handleClick();
      }
  }

  handleSave = async (values, actions) => {
    let saveEvntArr =[];
    //let venueArr = this.state.venueArr;
    let t=this;
    const calendarId=this.props.match.params.calendarId;   
    localStorage.removeItem('googleCalendarVal');
    if (sendWithOutArray.length > 0) {
      let holdRank = '1';
      let holdType = '';
      let sTime = '';
      let eTime = '';
      let sDate = '';
      let eDate = '';
      let stateholdValWithOut = this.state.holdValWithOut;
      let notApplyIdList=this.state.doesNotApply;
      sendWithOutArray.map(function (data, index) {
        if (stateholdValWithOut[index] !== '') {
          sTime = data.start.dateTime?`${moment(data.start.dateTime).format("HH:mm:ss")}`:'-';
          eTime = data.end.dateTime?`${moment(data.end.dateTime).format("HH:mm:ss")}`:'-';
          sDate = data.start.dateTime?`${moment(data.start.dateTime).format("YYYY-MM-DD")}`:`${moment(data.start.date).format("YYYY-MM-DD")}`;
          eDate = data.end.dateTime?`${moment(data.end.dateTime).format("YYYY-MM-DD")}`:`${moment(data.end.date).subtract(1, "days").format("YYYY-MM-DD")}`;

          if (stateholdValWithOut[index] && stateholdValWithOut[index].toUpperCase().includes('hold'.toUpperCase()) === true) {
            let holdData = stateholdValWithOut[index].replace(/(\d+)(st|nd|rd|th)/, "$1");
            let getStr1 = holdData.split(' ');

            if (parseInt(getStr1[0]) === 1) {
              holdRank = 1;
            }
            
            if (parseInt(getStr1[0]) === 2) {
              holdRank = 2;
            }
            
            if (parseInt(getStr1[0]) === 3) {
              holdRank = 3;
            }
            if (parseInt(getStr1[0]) === 4) {
              holdRank = 4;
            }
            if (parseInt(getStr1[0]) === 5) {
              holdRank = 5;
            }
            if (parseInt(getStr1[0]) === 6) {
              holdRank = 6;
            }
            if (parseInt(getStr1[0]) === 7) {
              holdRank = 7;
            }
            
            holdType = 1;
          }
          if (stateholdValWithOut[index] && stateholdValWithOut[index].toUpperCase().includes('challenge'.toUpperCase()) === true) {
            let challengeStep = '';
            challengeStep = stateholdValWithOut[index];
            let getStr = challengeStep.split('_hc');
            if(getStr[0].replace(/(\d+)(st|nd|rd|th)/, "$1"))
            {
              holdRank = getStr[0].replace(/(\d+)(st|nd|rd|th)/, "$1");
            }
            
            holdType = 2;
          }
          if (stateholdValWithOut[index] && stateholdValWithOut[index].toUpperCase().includes('booked'.toUpperCase()) === true) {
            if (stateholdValWithOut[index] === 'booked') {
              holdRank = 3;
            }
            
            holdType = 3;
          }
          if (stateholdValWithOut[index] && stateholdValWithOut[index].toUpperCase().includes('blocked'.toUpperCase()) === true) {
            if (stateholdValWithOut[index] === 'blocked') {
              holdRank = 4;
            }
            
            holdType = 4;
          }
          let title ='';
              if(data.summary && data.summary==='')
              {
                title = data.description;
              }else
              {
                title = data.summary;
              }

              let hRank1 = '';
              if(holdRank==='')
              {
                hRank1 =0;
              }
              else
              {
                hRank1 =holdRank;
              }
              
              let titleName='';
              if(title && title!=='' && title!==undefined)
              {
                titleName = title;
              }
              else
              {
                titleName = '--';
              }
              let recurrence='';
              if(calendarId.includes('holiday')){
                recurrence=["RRULE:FREQ=YEARLY"];
              }
              if(checkSelect.length>0){
                for(let i=0;i<checkSelect.length;i++){
                  if(data.id===checkSelect[i].id){
                    if(calendarId.includes('holiday')){
                      recurrence=["RRULE:FREQ=YEARLY"];
                    }
                    else{
                      if(checkSelect[i].rec){
                        recurrence=checkSelect[i].rec;
                      }                        
                    }
                    break;
                  }
                }
              }
              let disableSync=notApplyIdList.includes(data.id)
              if(disableSync===false)
              {
              saveEvntArr.push({
                recurrence:recurrence,
                name: titleName,
                city: '--',
                state: '--',
                start_date: sDate,
                start_time: sTime,
                end_date: eDate,
                end_time: eTime,
                hold_rank: hRank1,
                hold_type: holdType,
                space_id:fhwithOutArray[index].spaces.join(',')
              })
            }
            
        }
        return saveEvntArr;
      });

    }
    
    if (saveEvntArr.length > 0) {  
      formData = {
        "data": saveEvntArr,
        "space_id": this.props.match.params.spaceId,
        "sync"    : "1",
        "calendar_type":"GS",
        "calendar_id":decodeURIComponent(this.props.match.params.calendarId)

      };

      this.props.saveGoogleCalendaEvents(formData)
        .then(res => {
          this.props.history.push('/spaceProfileEdit/' + this.props.match.params.spaceId)
          // window.location.href = '/spaceProfileEdit/' + this.props.match.params.spaceId;
          //return <Redirect to={'/spaceProfileEdit/'+this.props.match.params.spaceId} />
        })
        .catch(error => {})
    } else {
      this.props.history.push('/spaceProfileEdit/' + this.props.match.params.spaceId)
      // window.location.href = '/spaceProfileEdit/' + this.props.match.params.spaceId;
    }
    let doesNotApplyFormaData={
      "data": this.state.doesNotApply,
        "space_id": this.props.match.params.spaceId,
    }
    if(this.state.statusDisable===true)
    {
    this.props.saveNotApplyGsEvents(doesNotApplyFormaData)  
    .then(res => {
      this.props.getNotApplyGsEvents([this.props.match.params.spaceId]).then(responsEvent=>{
        this.setState({doesNotApplyEvents:responsEvent})
       }
       )
    })
    .catch(error => {})
  }
 
  }

  onSelectChange=(e,r,t,value)=>{
    let repeatInstancesList=[]
    repeatInstancesList=this.state.repeatCheck
      if(!this.props.match.params.calendarId.includes(`holiday`)){
        this.setState({disabled:true})
        const calendarId = decodeURIComponent(this.props.match.params.calendarId);
        if(e.target.value.toString()==='' || e.target.value.toString()==='0' ||  e.target.value.toString()==='false') {
          repeatInstancesList.push(e.target.name)
                this.props.getGoogleCalendarSingleEvents(calendarId,value.recurrence).then(recRes => {
                  checkSelect.push({id:t,rec:recRes.recurrence});
                  this.setState({disabled:false,repeatInstances:true,repeatCheck:repeatInstancesList})  
        });
      }
      else{
        this.setState({disabled:false,repeatInstances:false})
        checkSelect=checkSelect.filter(data => data.id!==t.id);
       repeatInstancesList = repeatInstancesList.filter(val => val !== e.target.name);
        this.setState({repeatCheck:repeatInstancesList})
      }
    }
  }

  onMultipleSpace = (e,type,index) =>{
    let sSpaces = [];
    if(type == 1){
      sSpaces = this.state.eventWithArry[index].spaces;
    }
    if(type == 2){
      sSpaces = this.state.eventWithOutArry[index].spaces;
    }
    this.setState({venuenSpaces:true,selectedSpaceIndex:index,selectedSpaceType:type,selectedSpaces:sSpaces});
  }

  onModalClose = ()=>{
    this.setState({venuenSpaces:false,selectedSpaces:[]});
  }

  onSpaceApplied = (value) =>{
    if(this.state.selectedSpaceType == 1){
      let withArray = this.state.eventWithArry;
      withArray[this.state.selectedSpaceIndex].spaces = value;
      this.setState({eventWithArry:withArray});
    }
    if(this.state.selectedSpaceType == 2){
      let withOutArray = this.state.eventWithOutArry;
      withOutArray[this.state.selectedSpaceIndex].spaces = value;
      this.setState({eventWithOutArry:withOutArray});
    }
    this.setState({venuenSpaces:false,selectedSpaces:[]});
  }
  loadMoreGCalenderItems=()=>{
    this.setState({loadingMore:true})
    fhwithArray = []
    fhwithOutArray = []
    sendWithArray = []
    sendWithOutArray = []
    secondScreenDropArr = []
    optionsHold = []
    let t=this;
    holdBookedEevents = [];
    const calendarId = decodeURIComponent(this.props.match.params.calendarId);
    const spaceId    = this.props.match.params.spaceId.toString();
    // this.props.venueList().then(() => {}).catch(error =>{});    
    // this.props
    //   .getSpace(this.props.match.params.spaceId)
    //   .then(res => {
    //     if (res.calendar_id) {
    //         this.setState({
    //           vanueId: res.venue_id,
    //           spaceDetail:res
    //         });
            setTimeout(() => {
              this.loadMoreEvents();
            }, 800);
           
        //}
      //   else{
      //     this.setState({eventsShow: 2, eventNext:3});
      //   }
      // })
     // .catch(error => this.setState({eventsShow: 2, eventNext:3,loading:false}));     
  }

  loadMoreEvents = (year = moment().year(),dFlag=0)=>{
    let nextPageToken=""
    nextPageToken= this.state.googleNextSyncToken
    const calendarId = decodeURIComponent(this.props.match.params.calendarId);
    const spaceId    = this.props.match.params.spaceId.toString();
    // this.props.
    // googleSyncedEvents(this.props.match.params.spaceId,year,this.props.match.params.calendarId).then(hbEvents=>{   
             
    //           hbEvents.data.map(function (arr) {               
    //             holdBookedEevents.push({title:arr.event.name,start_date:arr.event.start_date,end_date:arr.event.end_date,hold_rank:arr.hold_rank,hold_type:arr.hold_type,id:arr.parent_id,sync:arr.sync_from_google,planner_id:arr.event.event_planner.id});
    //           }); 

                this.props
                .getGoogleToken()
                .then(response => {
                  this.props
                    .getGoogleCalendaEvents(calendarId,year,nextPageToken)
                    .then(eventsResponse => {           
                      if (eventsResponse && eventsResponse.items.length>0) {
                        let stateArray = [];
                        let googleStartEventDate=new Date(eventsResponse.items[0].start.dateTime).toISOString().substring(0, 10)
                        let googleEndEventDate=new Date(eventsResponse.items[eventsResponse.items.length-1].end.dateTime).toISOString().substring(0, 10)
                        this.props.
                        getAllGoogleSyncedEvents(this.props.match.params.spaceId,year,this.props.match.params.calendarId,googleStartEventDate,googleEndEventDate).then(hbAllEvents=>{   
                          hbAllEvents.data.map(function (arr) {               
                            holdAllBookedEevents.push({title:arr.event.name,start_date:arr.event.start_date,end_date:arr.event.end_date,hold_rank:arr.hold_rank,hold_type:arr.hold_type,id:arr.parent_id,sync:arr.sync_from_google,planner_id:arr.event.event_planner.id,start_time:arr.event.start_time,end_time:arr.event.end_time});
                          });
                        })
                        for (let i = 0; i < eventsResponse.items.length; i++) {
                          let dbToken=false;
                          for(let j=0;j < this.state.spaceDetail.event_timeslots.length; j++){
                            if(eventsResponse.items[i].summary==this.state.spaceDetail.event_timeslots[j].event.name){
                                dbToken=true;
                                break;
                            }
                          
                          }
                          // if(dbToken==false){
                          //   // stateArray.push(eventsResponse.items[i]);
                            if(dbToken==false){
                              if(this.state.doesNotApplyEvents.length>0)
                            {  
                              let notApplyToken=false;
                              let notApplyCheck=false;
                              for(let k=0;k < this.state.doesNotApplyEvents.length; k++){
                                notApplyToken=notApplyCheck;
                              if(eventsResponse.items[i].id===this.state.doesNotApplyEvents[k].gs_event_id)
                              {
                                notApplyToken=true;
                                break;
                              }
                            }
                            if(notApplyToken===false)
                            {
                              stateArray.push(eventsResponse.items[i]);
 
                            }
                            }
                              else{
                                
                                stateArray.push(eventsResponse.items[i]);
                              }
                            }
                          }                      
                        secondScreenDropArr.push(stateArray);
                        let itemsArray = [];
                        
                        itemsArray.push(stateArray);
                        if (itemsArray.length>0)
                        {
                        this.setState({
                          eventItems: this.state.eventItems.concat(itemsArray),
                          googleNextSyncToken:eventsResponse.nextPageToken,
                          holdAllSyncedEvents:holdAllBookedEevents
                        });
                      }
                        if (this.state.eventItems.length >0) {

                          this.setState({
                            eventsShow: 2
                          });
                          this.props
                            .getVenue(this.state.vanueId)
                            .then(response => {
                              
                              this.setState({venueArr: response});

                              this.setState({
                                eventMaxHold: response.data.max_hold_limit
                              });
                              if (this.state.eventMaxHold) {
                                optionsHold.push({
                                  id: "booked",
                                  name: "Booked"
                                });
                                optionsHold.push({
                                  id: "blocked",
                                  name: "Blocked"
                                });
                                for (let i = 1; i <= this.state.eventMaxHold; i++) {
                                  let opLabel = this.ordinal_suffix_of(i) + " Hold";
                                  optionsHold.push({
                                    id: opLabel,
                                    name: opLabel
                                  });
                                }                                                            
                                this.setState({
                                  satetHolds: optionsHold
                                });
                              }

                              let eventstatus = "";
                              let status = [];
                              let statusWihOut = [];
                              
                              this.state.eventItems.map((data)=> {
                               
                                data.map((eventArr) => {
                                  
                                  let eventstatus = "";
                                  let date = "";
                                  let time = ""; let sTime = ""; let eTime =""; let sDate =""; let eDate="";
                                  let startDate = eventArr.start.dateTime?new Date(moment(eventArr.start.dateTime)):new Date(moment(eventArr.start.date));
                                  let endDate = eventArr.end.dateTime?new Date((eventArr.end.dateTime)):new Date(moment(eventArr.end.date).subtract(1, "days"));
                                  let dateFormat = 'MM/DD/YY';
                                  if(moment(moment(startDate).format(dateFormat),dateFormat,true).isValid()===true){
                                      sDate = `${moment(startDate, 'MM/DD/YY', true).format("MM/DD/YY")}`;
                                      sTime =  eventArr.start.dateTime?`${moment(startDate, 'hh:mm A', true).format("hh:mm a")}`:'';
                                      if(sTime && sTime[0].toString()==='0'){
                                          sTime=sTime.replace('0','');
                                      }
                                      sTime=sTime.replace('m','.m.');
                                      if(sDate[0] && sDate[0].toString()==='0'){
                                          sDate=sDate.replace('0','');
                                      }
                                      sDate=sDate.replace('/0','/')
                                  }
                                  
                                  if(moment(moment(endDate).format(dateFormat),dateFormat,true).isValid()===true){
                                  
                                      eDate = `${moment(endDate, 'MM/DD/YY', true).format("MM/DD/YY")}`;
                                      eTime =  eventArr.end.dateTime?`${moment(endDate, 'hh:mm A', true).format("hh:mm a")}`:'';
                                      if(eTime && eTime[0].toString()==='0'){
                                          eTime=eTime.replace('0','');
                                      }
                                      eTime=eTime.replace('m','.m.');
                                      if(eDate[0] && eDate[0].toString()==='0'){
                                          eDate=eDate.replace('0','');
                                      }
                                      eDate=eDate.replace('/0','/');
                                  }
                                  
                                  if (sDate === eDate) {
                                      var d = new Date(sDate);
                                      var dayName = gsDayNames[d.getDay()];
                                      date = dayName + " " +sDate;
                                  } else {
                                      date = sDate + " - " + eDate;
                                      if(sDate && sDate.toString()!=='' && eDate && eDate.toString()!==''){
                                          date = sDate + " - " + eDate;
                                      }
                                      else {
                                          date = sDate + " - " + eDate;
                                      }   
                                  }
                                  
                                  if (sTime === eTime) {
                                      time = sTime;
                                  } else {
                                      time = sTime + " - " + eTime;
                                  }
                                  
                                  let title='';
                                  if(eventArr.summary===''){
                                      title = eventArr.description;
                                  }
                                  else{
                                      title = eventArr.summary;
                                  }

                                  let recurrence='';    
                                  if(eventArr.recurringEventId){
                                      recurrence = eventArr.recurringEventId;
                                  } 
                                 
                                  if(title && title.toString()!=='' && date && date.toString()!==''){    
                                   
                                      let eSDate = `${moment(startDate, 'MM/DD/YY', true).format("YYYY-MM-DD")}`;
                                      let eEDate = `${moment(endDate, 'MM/DD/YY', true).format("YYYY-MM-DD")}`;
                                    
                                      let searchObj = this.state.holdAllSyncedEvents.find(event => (event.title === eventArr.summary &&  event.start_date === eSDate && event.end_date === eEDate && (parseInt(event.sync)==1 || parseInt(event.planner_id)==parseInt(localStorage.getItem('id')))));
                                   
                                      if(searchObj!=undefined){                             
                                         
                                          if(searchObj.hold_type==3){
                                            eventstatus = "booked";
                                          }else if(searchObj.hold_type == 4){
                                            eventstatus = "blocked";
                                          }else{
                                            eventstatus = this.ordinal_suffix_of(searchObj.hold_rank) + " Hold";
                                          }
                                          
                                          sendWithArray.push(eventArr);
                                          status.push(eventstatus);                                  
                                          fhwithArray.push({
                                              id:eventArr.id,
                                              recurrence:recurrence,
                                              reason: eventArr.summary,
                                              date: date,
                                              time: time,
                                              event_timeslot_id:searchObj.id,
                                              status: eventstatus,
                                              spaces: [spaceId]
                                          });
                                      }else{       
                                        statusWihOut.push(eventstatus);
                                        sendWithOutArray.push(eventArr);
                                        fhwithOutArray.push({
                                          id:eventArr.id,
                                          recurrence:recurrence,
                                          reason: title,
                                          date: date,
                                          time: time,
                                          status: eventstatus,
                                          spaces: [spaceId]
                                        });
                                      }
                                  }
                                  return fhwithArray;
                                });
                                return fhwithArray;
                              });
                              this.setState({holdVal: status });
                              this.setState({holdValWithOut: statusWihOut });
                              this.setState({loading:false})
                              this.setState({eventWithArry: fhwithArray});
                              this.setState({eventWithOutArry: fhwithOutArray});
                              this.setState({loadingMore:false})
                              if (this.state.eventsShow === 2) {
                                let fhWithArrLength = this.state.eventWithArry.length;
                                if (fhWithArrLength === 0 && parseInt(this.state.eventNext) === 1 && dFlag ==0) {
                                  this.setState({eventNext: 2});
                                }
                              }
                            })
                        }
                      } else if(dFlag ==0){
                        this.setState({ eventNext: 3, eventsShow: 2 ,loading:false });                        
                      }else{
                        this.setState({ loading:false }); 
                      }
                    })                  
                })
            //})
  }
  doesNotApply= (e) =>{
    let notApplyIdList=[]
    notApplyIdList=this.state.doesNotApply
    if(e.target.value==='false' ||e.target.value==='')
    {
   notApplyIdList.push(e.target.name.replace("Gid",""))
   this.setState({doesNotApply:notApplyIdList,statusDisable:true})
   
    }
    if(e.target.value==='true')
    {
      notApplyIdList = notApplyIdList.filter(val => val !== e.target.name.replace("Gid",""));
   this.setState({doesNotApply:notApplyIdList,statusDisable:false}) 
    }
  }
  loadMoreSyncedItems=()=>{
    this.setState({loadingMoreSyncedItems:true})
    fhwithArray = []
    // fhwithOutArray = []
    sendWithArray = []
    sendWithOutArray = []
    secondScreenDropArr = []
    optionsHold = []
    let t=this;
    holdBookedEevents = [];
    const calendarId = decodeURIComponent(this.props.match.params.calendarId);
    const spaceId    = this.props.match.params.spaceId.toString();
    // this.props.venueList().then(() => {}).catch(error =>{});    
    // this.props
    //   .getSpace(this.props.match.params.spaceId)
    //   .then(res => {
    //     if (res.calendar_id) {
    //         this.setState({
    //           vanueId: res.venue_id,
    //           spaceDetail:res
    //         });
            setTimeout(() => {
              this.loadMoreGSyncedItems();
            }, 800);
           
      //   }
      //   else{
      //     this.setState({eventsShow: 2, eventNext:3});
      //   }
      // })
      //.catch(error => this.setState({eventsShow: 2, eventNext:3,loading:false}));     
  }


  loadMoreGSyncedItems = (year = moment().year(),dFlag=0)=>{
    const calendarId = decodeURIComponent(this.props.match.params.calendarId);
    const spaceId    = this.props.match.params.spaceId.toString();
    this.props.
    googleSyncedEvents(this.props.match.params.spaceId,year,this.props.match.params.calendarId,this.state.page).then(hbEvents=>{  
      let eventstatus = "";
      let statusWihOut = []; 
    
              hbEvents.data.map(function (arr) {               
                holdBookedEevents.push({title:arr.event.name,start_date:arr.event.start_date,end_date:arr.event.end_date,hold_rank:arr.hold_rank,hold_type:arr.hold_type,id:arr.parent_id,sync:arr.sync_from_google,planner_id:arr.event.event_planner.id,start_time:arr.event.start_time,end_time:arr.event.end_time});
              }); 
              if(hbEvents.pagination.current_page!==hbEvents.pagination.last_page)
              {
               setTimeout(() => {
                 this.setState({page:this.state.page+1,syncedNextPageToken:hbEvents.pagination.next_page_url})
               }, 1000);
              }
              else
              {
               this.setState({syncedNextPageToken:null})

              }

                       this.setState({ holdBookedEventsItems: this.state.holdBookedEventsItems.concat(holdBookedEevents),syncedNextPageToken:hbEvents.pagination.next_page_url
                       })
                      //  this.state.eventItems.map((data)=> {
                               
                      //   data.map((eventArr) => {
                          
                      //     let eventstatus = "";
                      //     let date = "";
                      //     let time = ""; let sTime = ""; let eTime =""; let sDate =""; let eDate="";
                      //     let startDate = eventArr.start.dateTime?new Date(moment(eventArr.start.dateTime)):new Date(moment(eventArr.start.date));
                      //     let endDate = eventArr.end.dateTime?new Date((eventArr.end.dateTime)):new Date(moment(eventArr.end.date).subtract(1, "days"));
                      //     let dateFormat = 'MM/DD/YY';
                      //     if(moment(moment(startDate).format(dateFormat),dateFormat,true).isValid()===true){
                      //         sDate = `${moment(startDate, 'MM/DD/YY', true).format("MM/DD/YY")}`;
                      //         sTime =  eventArr.start.dateTime?`${moment(startDate, 'hh:mm A', true).format("hh:mm a")}`:'';
                      //         if(sTime && sTime[0].toString()==='0'){
                      //             sTime=sTime.replace('0','');
                      //         }
                      //         sTime=sTime.replace('m','.m.');
                      //         if(sDate[0] && sDate[0].toString()==='0'){
                      //             sDate=sDate.replace('0','');
                      //         }
                      //         sDate=sDate.replace('/0','/')
                      //     }
                          
                      //     if(moment(moment(endDate).format(dateFormat),dateFormat,true).isValid()===true){
                          
                      //         eDate = `${moment(endDate, 'MM/DD/YY', true).format("MM/DD/YY")}`;
                      //         eTime =  eventArr.end.dateTime?`${moment(endDate, 'hh:mm A', true).format("hh:mm a")}`:'';
                      //         if(eTime && eTime[0].toString()==='0'){
                      //             eTime=eTime.replace('0','');
                      //         }
                      //         eTime=eTime.replace('m','.m.');
                      //         if(eDate[0] && eDate[0].toString()==='0'){
                      //             eDate=eDate.replace('0','');
                      //         }
                      //         eDate=eDate.replace('/0','/');
                      //     }
                          
                      //     if (sDate === eDate) {
                      //         var d = new Date(sDate);
                      //         var dayName = gsDayNames[d.getDay()];
                      //         date = dayName + " " +sDate;
                      //     } else {
                      //         date = sDate + " - " + eDate;
                      //         if(sDate && sDate.toString()!=='' && eDate && eDate.toString()!==''){
                      //             date = sDate + " - " + eDate;
                      //         }
                      //         else {
                      //             date = sDate + " - " + eDate;
                      //         }   
                      //     }
                          
                      //     if (sTime === eTime) {
                      //         time = sTime;
                      //     } else {
                      //         time = sTime + " - " + eTime;
                      //     }
                          
                      //     let title='';
                      //     if(eventArr.summary===''){
                      //         title = eventArr.description;
                      //     }
                      //     else{
                      //         title = eventArr.summary;
                      //     }
                
                      //     let recurrence='';    
                      //     if(eventArr.recurringEventId){
                      //         recurrence = eventArr.recurringEventId;
                      //     } 
                         
                      //     if(title && title.toString()!=='' && date && date.toString()!==''){    
                           
                      //         let eSDate = `${moment(startDate, 'MM/DD/YY', true).format("YYYY-MM-DD")}`;
                      //         let eEDate = `${moment(endDate, 'MM/DD/YY', true).format("YYYY-MM-DD")}`;
                            
                      //         let searchObj = this.state.holdAllSyncedEvents.find(event => (event.title === eventArr.summary &&  event.start_date === eSDate && event.end_date === eEDate && (parseInt(event.sync)==1 || parseInt(event.planner_id)==parseInt(localStorage.getItem('id')))));
                           
                      //         if(searchObj!=undefined){                             
                                 
                      //             if(searchObj.hold_type==3){
                      //               eventstatus = "booked";
                      //             }else if(searchObj.hold_type == 4){
                      //               eventstatus = "blocked";
                      //             }else{
                      //               eventstatus = this.ordinal_suffix_of(searchObj.hold_rank) + " Hold";
                      //             }
                                  
                      //             // sendWithArray.push(eventArr);
                      //             // status.push(eventstatus);                                  
                      //             // fhwithArray.push({
                      //             //     id:eventArr.id,
                      //             //     recurrence:recurrence,
                      //             //     reason: eventArr.summary,
                      //             //     date: date,
                      //             //     time: time,
                      //             //     event_timeslot_id:searchObj.id,
                      //             //     status: eventstatus,
                      //             //     spaces: [spaceId]
                      //             // });
                      //         }else{       
                      //           statusWihOut.push(eventstatus);
                      //           sendWithOutArray.push(eventArr);
                      //           fhwithOutArray.push({
                      //             id:eventArr.id,
                      //             recurrence:recurrence,
                      //             reason: title,
                      //             date: date,
                      //             time: time,
                      //             status: eventstatus,
                      //             spaces: [spaceId]
                      //           });
                      //         }
                      //     }
                      //     return fhwithArray;
                      //   });
                      //   return fhwithArray;
                      // });

                              let status = [];
                               
                                this.state.holdBookedEventsItems.map((eventArr) => {
                                 let isoStartDate= eventArr.start_date+" "+eventArr.start_time;
                                 let parsedStartDate = moment(isoStartDate, 'YYYY-MM-DD H:mm:ss')
                                let isoParsedStartDate=(parsedStartDate.toISOString());
                                let isoEndDate=eventArr.end_date+" "+eventArr.end_time;
                                let ParsedEndDate=moment(isoEndDate, 'YYYY-MM-DD H:mm:ss')
                                let isoParsedEndDate=(ParsedEndDate.toISOString()); 
                                  let eventstatus = "";
                                  let date = "";
                                  let time = ""; let sTime = ""; let eTime =""; let sDate =""; let eDate="";
                                  let startDate = eventArr.start_date?new Date(moment(isoParsedStartDate)):new Date(moment(isoParsedStartDate));
                                  let endDate = eventArr.end_date?new Date((isoParsedEndDate)):new Date(moment(isoParsedEndDate).subtract(1, "days"));
                                  let dateFormat = 'MM/DD/YY';
                                  if(moment(moment(startDate).format(dateFormat),dateFormat,true).isValid()===true){
                                      sDate = `${moment(startDate, 'MM/DD/YY', true).format("MM/DD/YY")}`;
                                      sTime =  isoParsedStartDate?`${moment(startDate, 'hh:mm A', true).format("hh:mm a")}`:'';
                                      if(sTime && sTime[0].toString()==='0'){
                                          sTime=sTime.replace('0','');
                                      }
                                      sTime=sTime.replace('m','.m.');
                                      if(sDate[0] && sDate[0].toString()==='0'){
                                          sDate=sDate.replace('0','');
                                      }
                                      sDate=sDate.replace('/0','/')
                                  }
                                  
                                  if(moment(moment(endDate).format(dateFormat),dateFormat,true).isValid()===true){
                                  
                                      eDate = `${moment(endDate , 'MM/DD/YY', true).format("MM/DD/YY")}`;
                                      eTime =  isoParsedEndDate?`${moment(endDate , 'hh:mm A', true).format("hh:mm a")}`:'';
                                      if(eTime && eTime[0].toString()==='0'){
                                          eTime=eTime.replace('0','');
                                      }
                                      eTime=eTime.replace('m','.m.');
                                      if(eDate[0] && eDate[0].toString()==='0'){
                                          eDate=eDate.replace('0','');
                                      }
                                      eDate=eDate.replace('/0','/');
                                  }
                                  
                                  if (sDate === eDate) {
                                      var d = new Date(sDate);
                                      var dayName = gsDayNames[d.getDay()];
                                      date = dayName + " " +sDate;
                                  } else {
                                      date = sDate + " - " + eDate;
                                      if(sDate && sDate.toString()!=='' && eDate && eDate.toString()!==''){
                                          date = sDate + " - " + eDate;
                                      }
                                      else {
                                          date = sDate + " - " + eDate;
                                      }   
                                  }
                                  
                                  if (sTime === eTime) {
                                      time = sTime;
                                  } else {
                                      time = sTime + " - " + eTime;
                                  }
                                  
                                  let title='';
                                  if(eventArr.summary===''){
                                      title = eventArr.description;
                                  }
                                  else{
                                      title = eventArr.title;
                                  }

                                  let recurrence='';    
                                  if(eventArr.recurringEventId){
                                      recurrence = eventArr.recurringEventId;
                                  } 
                                 
                                  if(title && title.toString()!=='' && date && date.toString()!==''){    
                                   
                                      let eSDate = `${moment(startDate, 'MM/DD/YY', true).format("YYYY-MM-DD")}`;
                                      let eEDate = `${moment(endDate, 'MM/DD/YY', true).format("YYYY-MM-DD")}`;
                                    
                                       let searchObj =eventArr
                                      //holdBookedEevents.map((searchObj) => {

                                      if(searchObj!=undefined){                             
                                          if(searchObj.hold_type==3){
                                            eventstatus = "booked";
                                          }else if(searchObj.hold_type == 4){
                                            eventstatus = "blocked";
                                          }else{
                                            eventstatus = this.ordinal_suffix_of(searchObj.hold_rank) + " Hold";
                                          }
                                          
                                          sendWithArray.push(searchObj);
                                          status.push(eventstatus);                                  
                                          fhwithArray.push({
                                              id:searchObj.id,
                                              recurrence:recurrence,
                                              reason: title,
                                              date: date,
                                              time: time,
                                              event_timeslot_id:searchObj.id,
                                              status: eventstatus,
                                              spaces: [spaceId]
                                          });
                                        }
                                      }
                                  return fhwithArray;
                                });
                              this.setState({holdVal: status });
                              this.setState({loading:false})
                              this.setState({eventWithArry: fhwithArray});
                              // this.setState({eventWithOutArry: fhwithOutArray});
                              if (this.state.eventsShow === 2) {
                                let fhWithArrLength = this.state.eventWithArry.length;
                                if (fhWithArrLength === 0 && parseInt(this.state.eventNext) === 1 && dFlag ==0) {
                                  this.setState({eventNext: 2});
                                }
                              }
                            
                        
                      else if(dFlag ==0){
                        this.setState({ eventNext: 3, eventsShow: 2 ,loading:false });                        
                      }else{
                        this.setState({ loading:false }); 
                      }
                      this.setState({loadingMoreSyncedItems:false})
                    })
                  }
                    

  render() {    
    return (
      <div>
              {this.state.eventsShow === 1 ? (
                <div className="home_page">
                  <div className="search_page">
                    <Icon className="icon1">search</Icon>
                    <h1>Searching for entries...</h1>
                    <p>
                      Please standby while FIRST-HOLD searches for any existing
                      entries in your calendar.
                    </p>
                  </div>
                </div>
              ) : (
                <div className="home_page syncing_calendar">
                  <div className="search_page"></div>
                  <Grid container>  
                  {this.state.eventNext === 3 ? (
                    <Grid container>
                     <Grid container className="main-form">
                        <Grid container className="main-form">
                          <Grid item md={8} lg={8} xl={8}>
                            <p>
                              No event found in this calendar.
                            </p>                            
                          </Grid>  
                          <Grid item md={8} lg={8} xl={8}>
                          {this.renderRedirect()}
                          <button disabled={this.state.disabled} onClick={this.setRedirect}>Next</button>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  ) :(
                  <Grid container>
                    {this.state.eventNext === 1 ? (
                      <Grid container className="main-form">
                           <Grid container className="main-form">
                           <Grid item md={12} lg={12} xl={12} alignContent={`flex-end`} className="sync-year-container" >  
                           <FormControl >
                              <Typography className="select-label">
                              Change Year :
                              </Typography>
                            </FormControl>                          
                            <FormControl variant="outlined" className="select-control" > 
                                <Select
                                labelId="demo-simple-select-outlined-label"
                                id="demo-simple-select-outlined"
                                className="sync-select"
                                value={this.state.syncYear}    
                                onChange={e => { this.handleYearChange(e)} }                      
                                label="Select">
                                <MenuItem value="" disabled>
                                 Select
                                </MenuItem>
                                {
                                  this.state.syncYears.map(res=>{
                                    return <MenuItem value={res.id}>{res.name}</MenuItem>
                                  })
                                }                          
                              </Select>
                            </FormControl>
                          </Grid>
                          </Grid>
                          <Formik
                          initialValues = {
                            {
                              eventhold: [],
                              syncyear:moment().year()
                            }
                          }
                          onSubmit = {
                              (values, actions) => {
                                this.handleSubmit(values, actions);

                              }
                            } >
                            {
                              ({
                                isSubmitting,
                                values,
                                handleBlur,
                                handleChange,
                                handleValeChange,
                                setFieldValue
                              }) => ( 
                        <Form>
                        {this.state.eventWithArry.length>0 && 
                        <Grid container className="main-form">
                          
                          <Grid item md={8} lg={8} xl={8}>                           
                            <p>
                              The following events <span className="blue-text">have</span> been assigned a status on your
                              FIRST-HOLD calendar.  You can manually UPDATE their status 
                              now, if applicable, before proceeding.
                            </p>
                          </Grid>
                          <Grid item md={4} lg={4} xl={4} className="top-next"> 
                            <Button type="submit"
                              variant="outline-danger"
                              id="firstDivNextBtn2"
                              class="red-button"
                              disabled={this.state.disabled}>
                              Next
                            </Button>
                          </Grid>
                          <Grid item md={12} lg={12} xl={12}>
                           
                            <h3>FIRST-HOLD Status</h3>

                          </Grid>
                          {this.state.eventWithArry.map((value, index) => {
                            
                            return (
                              <Grid container className="data-table">
                              <Grid
                                  item
                                  md={3}
                                  lg={3}
                                  xl={3}
                                  className="vertical_align_date"
                                >
                                  <h5>{value.date}</h5>
                                  <p className="small">{value.time}</p>
                                </Grid>
                                <Grid
                                  item
                                  md={3}
                                  lg={3}
                                  xl={3}
                                  className="vertical_align_reason"
                                >
                                  <h6>{value.reason}</h6>
                                </Grid>
                                <Grid item md={6} lg={6} xl={6}>
                                  <CustomSelectWithScroll
                                    setFieldValue={setFieldValue}
                                    value={this.state.holdVal[index]}
                                    placeholder="Select"
                                    options={this.state.satetHolds}
                                    handleChange={e => {
                                      handleChange(e);
                                      this.handleValeChange(e,index);
                                    }}
                                    handleBlur={e => {
                                      handleBlur(e);
                                      this.handleValeChange(e,index);
                                    }}
                                   name= {`eventhold_.${index}`}
                                    label="Select" key={index}
                                  />
                                  {/*<CustomizedCheckBox                                      
                                       name={value.id}                                            
                                       onChange={this.onSelectChange}                                      
                                       handleChange={handleChange}
                                       values={value}
                                       disabled={this.state.disabled} 
                                    label="Apply status to repeat events"
                                  />
                                    <SyncCheckBox                                      
                                      name={"space-"+value.id}   
                                      onChange={this.onMultipleSpace}  
                                      handleChange={handleChange}
                                      values={value.spaces}
                                      type={1}
                                      index={index}
                                      label={value.spaces.length > 1 ? 'Applied to '+value.spaces.length+' spaces': 'Apply status to more spaces'}
                                  />*/}
                                </Grid>
                              </Grid>
                            );
                          })}
                           {this.state.syncedNextPageToken!==null&&
                          <Grid item md={12} lg={12} xl={12} style={{textAlign:'center'}} className="loadmore-btn-block">
                            <Button type=""
                              variant="outline-danger"
                              id="firstDivNextBtn"
                              class="load-more-events"
                              // style={{width:'94px',padding:'6px 10px'}}
                              disabled={this.state.loadingMoreSyncedItems===false?false:true}
                              onClick={this.loadMoreSyncedItems}>
                               {this.state.loadingMoreSyncedItems===false?'Load More Events':'Loading Events...'}
                            </Button>
                          </Grid>
                         }
                          <Grid item md={12} lg={12} xl={12} style={{textAlign:'right'}}>
                            <Button type="submit"
                              variant="outline-danger"
                              id="firstDivNextBtn"
                              class="red-button"
                              disabled={this.state.disabled}>
                              Next
                            </Button>
                          </Grid>
                        </Grid>
                        }
                        </Form>
                   
                      )}
                      </Formik>
                      {
                          (this.state.loading==false && this.state.eventWithArry.length==0) &&
                          <Grid container  direction="column" item  className="main-form">
                            <Grid item md={12} lg={12} xl={12} style={{textAlign:'right'}}>
                              <p>No Events Found</p>
                              <Button type="button"
                                variant="outline-danger"
                                id="firstDivNextBtn"
                                onClick={this.handleClick}
                                class="red-button">
                                Next
                              </Button>
                            </Grid>
                          </Grid>
                        }
                        {
                          this.state.loading==true &&
                          <Grid container  direction="column" item  className="main-form">
                            <Grid item md={12} lg={12} xl={12}>
                              <div>Loading...</div>
                            </Grid>
                          </Grid>
                       }
                      </Grid>
                    ) : (
                      <Grid container className="main-form" >
                         <Grid container className="main-form">
                       <Grid item md={12} lg={12} xl={12} alignContent={`flex-end`} className="sync-year-container" >
                            <FormControl >
                              <Typography className="select-label">
                              Change Year :
                              </Typography>
                            </FormControl> 
                              <FormControl variant="outlined" className="select-control" >                       
                                  <Select
                                  labelId="demo-simple-select-outlined-label"
                                  id="demo-simple-select-outlined"
                                  className="sync-select"
                                  value={this.state.syncYear}    
                                  onChange={e => { this.handleYearChange(e)} }                      
                                  label="Select">
                                  <MenuItem value="" disabled>
                                    Select
                                  </MenuItem>
                                  {
                                    this.state.syncYears.map(res=>{
                                      return <MenuItem value={res.id}>{res.name}</MenuItem>
                                    })
                                  }                          
                                </Select>
                              </FormControl>
                      </Grid>  
                      </Grid>
                      <Formik
                            initialValues={{
                            eventholdWithOut: [],
                            syncyear:moment().year()
                            }}
                            onSubmit={(values, actions) => {
                            this.handleSave(values, actions);

                            }}
                            >
                            {({
                            isSubmitting,
                            values,
                            handleBlur,
                            handleChange,
                            handleValeChange,
                            setFieldValue
                            }) => (
                            <Form>
                            {this.state.eventWithOutArry.length>0 && 
                            <Grid container className="main-form">                              
                            <Grid item md={8} lg={8} xl={8}>
                            <p>
                              The following events <span className="blue-text">have not</span> been assigned a status on your
                              FIRST-HOLD calendar.  You can manually assign them a status 
                              now, if applicable, before proceeding.
                            </p>
                                {/* <p className="small">
                                  They will be assigned a hold status in the
                                  FIRST-HOLD system. Review and make edits below:{" "}
                                </p> */}
                            </Grid>
                            <Grid item md={4} lg={4} xl={4} className="top-next"> 
                              <Button type="submit"
                                variant="outline-danger"
                                id="firstDivNextBtn"
                                class="red-button"
                                disabled={this.state.disabled}>
                                Next
                              </Button>
                            </Grid>
                            <Grid item  md={12} lg={12} xl={12} >
                              <h3>FIRST-HOLD Status</h3>
                            </Grid>

                            {this.state.eventWithOutArry.map((value2, index2) => {
                              return (
                                <Grid container className="data-table">
                                    <Grid item md={3} lg={3} xl={3} className="">
                                      <h5>{value2.date}</h5>
                                      <p className="small">{value2.time}</p>
                                    </Grid>
                                    <Grid
                                      item
                                      sm={3}
                                      md={3}
                                      lg={3}
                                      xl={3}
                                      className=""
                                    >
                                      <h6>{value2.reason}</h6>
                                    </Grid>
                                    {this.state.doesNotApply.includes(value2.id)===false&&
                                    <Grid item sm={12} md={6} lg={6} xl={6}>
                                          <CustomSelectWithScroll
                                            value={this.state.holdValWithOut[index2]}
                                            setFieldValue={setFieldValue}
                                            placeholder="Select"
                                            options={this.state.satetHolds}
                                            handleChange={e => {
                                              handleChange(e);
                                              this.handleValeWithOutChange(e,index2);
                                            }}
                                            handleBlur={e => {
                                              handleBlur(e);
                                              this.handleValeChange(e,index2);
                                            }}
                                            name= {`eventholdWithOut_.${index2}`}
                                            label="Select" key={index2}
                                          />
                                         
                                      </Grid>
                                  }
                                  {this.state.doesNotApply.includes(value2.id)===false&&
                                      <Grid item sm={6} md={4} lg={4} xl={4} >
                                         <CustomizedCheckBox 
                                            name={value2.id}
                                            onChange={this.onSelectChange}
                                            handleChange={handleChange}
                                            values={value2}
                                            value={this.state.repeatCheck.includes(value2.id)===true?true:false}
                                            disabled={this.state.disabled} 
                                            label="Apply status to repeat events"
                                          />  
                                         </Grid>
                            }
                             {this.state.doesNotApply.includes(value2.id)===false&&
                                      <Grid item sm={6} md={4} lg={4} xl={4} >
                                        <SyncCheckBox                                      
                                            name={"space-"+value2.id} 
                                            onChange={this.onMultipleSpace}  
                                            type={2}
                                            index={index2}
                                            handleChange={handleChange}
                                            values={value2.spaces}
                                            label={value2.spaces.length > 1 ? 'Applied to '+value2.spaces.length+' spaces': 'Apply status to more spaces'}
                                          />
                                         </Grid>
                            }
                            
                            {
                              this.state.doesNotApply.includes(value2.id)===true&&
                              <Grid item sm={12} md={6} lg={6} xl={6}  className="disabled-block">
                                          <CustomSelectWithScroll
                                            value={''}
                                            setFieldValue={setFieldValue}
                                            placeholder="Select"
                                            options={''}
                                            handleChange={e => {
                                              handleChange(e);
                                              this.handleValeWithOutChange(e,index2);
                                            }}
                                            handleBlur={e => {
                                              handleBlur(e);
                                              this.handleValeChange(e,index2);
                                            }}
                                            name= {`eventholdWithOut__.${index2}`}
                                            label="Select" key={index2}
                                          />

                                        
                                      </Grid>
                            }
                            {/* {this.state.doesNotApply.includes(value2.id)===false&&
                                      <Grid item md={12} lg={12} xl={12}>
                                        <SyncCheckBox                                      
                                            name={"space-"+value2.id} 
                                            onChange={this.onMultipleSpace}  
                                            type={2}
                                            index={index2}
                                            handleChange={handleChange}
                                            values={value2.spaces}
                                            label={value2.spaces.length > 1 ? 'Applied to '+value2.spaces.length+' spaces': 'Apply status to more spaces'}
                                          />
                                      </Grid>
                            } */}
                             {this.state.doesNotApply.includes(value2.id)===true&&
                                      <Grid item sm={6} md={4} lg={4} xl={4}  className="disabled-block">
                                       <CustomizedCheckBox 
                                            name={'check_Disabled'}
                                            onChange={this.onSelectChange}
                                            handleChange={handleChange}
                                            values={''}
                                            disabled={true} 
                                            label="Apply status to more spaces"
                                          /> 
                                      </Grid>
                            }
                            {this.state.doesNotApply.includes(value2.id)===true&&
                                      <Grid item sm={6} md={4} lg={4} xl={4}  className="disabled-block">
                                     
                                            <CustomizedCheckBox 
                                            name={'checkDisabled'}
                                            onChange={this.onSelectChange}
                                            handleChange={handleChange}
                                            values={''}
                                            disabled={true} 
                                            label="Apply status to repeat events"
                                          />   
                                      </Grid>
                            }
                                      <Grid item sm={6} md={4} lg={4} xl={4}>
                                        <SyncCheckBox                                      
                                            name={"Gid"+value2.id} 
                                            onChange={e=>{this.doesNotApply(e)}}
                                            type={''}
                                            index={''}
                                            handleChange={handleChange}
                                            values={this.state.doesNotApply.includes(value2.id)?value2.id:''}
                                            label={'Does not apply to FIRST-HOLD'}
                                          />
                                      </Grid>
                                </Grid>
                              );
                            })
                            }
                            
                            {this.state.googleNextSyncToken!==''&&this.state.googleNextSyncToken!==undefined&&
                          <Grid item md={12} lg={12} xl={12} style={{textAlign:'center'}} className="loadmore-btn-block">
                            <Button type=""
                              variant="outline-danger"
                              id="firstDivNextBtn"
                              class="load-more-events"
                              // style={{width:'94px',padding:'6px 10px'}}
                              disabled={this.state.loadingMore===false?false:true}
                              onClick={this.loadMoreGCalenderItems}>
                               {this.state.loadingMore===false?'Load More Events':'Loading Events...'}
                            </Button>
                          </Grid>
                         }
                         <Grid item md={12} lg={12} xl={12} style={{textAlign:'right'}}>
                            <Button type="submit"
                                variant="outline-danger"
                                id="firstDivNextBtn"
                                class="red-button next-btn-right"
                                disabled={this.state.disabled}>
                                Next
                              </Button>
                            </Grid>
                        </Grid>
                        
                        }
                        
                        </Form>
                        )}
                        </Formik>
                        {
                          (this.state.loading==false && this.state.eventWithOutArry.length==0) &&
                          <Grid container  direction="column" item  className="main-form">
                          <Grid item md={12} lg={12} xl={12} style={{textAlign:'right'}}>
                            <p>No Events Found</p>
                            {this.renderRedirect()}
                            <Button type="button"
                              variant="outline-danger"
                              id="firstDivNextBtn"
                              onClick={this.setRedirect}
                              class="red-button">
                              Next
                            </Button>
                          </Grid>
                        </Grid>
                        }
                        {
                          this.state.loading==true &&
                          <Grid container  direction="column" item  className="main-form">
                            <Grid item md={12} lg={12} xl={12}>
                              <div>Loading...</div>
                            </Grid>
                          </Grid>
                        }
                         
                      </Grid>
                    )}
                    
                  </Grid>
                  )}

                  </Grid>
                  
                </div>
              )}

              {
                 this.state.venuenSpaces? <VenueSpaces open={this.state.venuenSpaces} spaceId={this.props.match.params.spaceId} selectedSpaces={this.state.selectedSpaces} onSpaceApplied={this.onSpaceApplied}   venues={this.props.list} onClose={this.onModalClose}  /> : null
              }
             
      </div>
    );
  }
}

Search.propTypes = {
  classes: PropTypes.object.isRequired,
  venueList: PropTypes.func.isRequired
};
const mapStateToProps = state => {
  return {
    list: state.data.usersVenue.venueList,
  };
};

const enhance = compose(
  withRouter,
  connect(
    mapStateToProps,
    dispatch =>
      bindActionCreators(
        {
          userDetails,
          getGoogleToken,
          getSpace,
          getVenue,
          getGoogleCalendaEvents,
          getGoogleCalendarSingleEvents,
          saveGoogleCalendaEvents,
          venueList,
          googleSyncedEvents, 
          updateGoogleCalendarEvents,
          getNotApplyGsEvents,
          saveNotApplyGsEvents ,
          getAllGoogleSyncedEvents       
        },
        dispatch
      )
  )
);
export default enhance(Search);
