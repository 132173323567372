import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
// import "./assets/style/placeHold.scss";
import "./assets/style/venuePublishPopup.scss";
import { object } from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators, compose } from "redux";
import { withRouter } from "react-router-dom";

const styles = theme => ({
    iconImage:{
        height:"24px",
        width:"24px !important",
        marginLeft:"26px",
        '&:hover': {
          backgroundColor:'#d80e2a'
        }
      },
root: {
margin: 0,
},
closeButton: {
position: 'absolute',
top: '8px',
color: '#9e9e9e',
right: '8px',
},
});

withStyles(styles)(props => {
const { children, classes, onClose } = props;
return (
<MuiDialogTitle disableTypography className={classes.root}>
<Typography variant="h6">{children}</Typography>
{onClose ? (
<IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
<CloseIcon />
</IconButton>
) : null}
</MuiDialogTitle>
);
});

const DialogContent = withStyles(theme => ({
root: {
},
}))(MuiDialogContent);

const DialogActions = withStyles(theme => ({
root: {
},
}))(MuiDialogActions);

class copyTimeslotPopup extends React.Component {
state = {
open: this.props.open,
};

handleCancelClick = () => {
  this.setState({open:false})
};

handleClose = () => {
this.props.closeCopyTimeslotPopup()
  this.setState({open:false})
};

render() {
    // const { classes } = props;
return (
<div>

<Dialog 
className="success_modal_container view-listing-popup"
aria-labelledby="customized-dialog-title"
open={this.props.open} >
<DialogActions className="dialogActions">
<span
className="material-icons close closeButton"
onClick={this.handleClose}
>
close
</span>
</DialogActions>
<DialogContent className="dialog-content1" >
<Grid container className="modalBox_content">
<Grid item xs={12}>
<Grid container className="">


<Grid item xs={12} sm={12}>

    <div className="venue-publish-popup-box submit-for-approval">

      <p>Are you sure you want to copy availability and pricing from another space?</p>
      <p>This will remove all existing availability and pricing.</p>

           <div className="approval-btn">
                <Button onClick={()=>{this.props.handleCopyTimeSlot();this.handleClose()}} class='red-button'>
                      Yes                        
                </Button>
                <Button onClick={()=>{this.handleClose()}} class='red-button'>
                      No                       
                </Button>
           </div>
    </div>

</Grid>

</Grid>
      

</Grid>

</Grid>

</DialogContent>


</Dialog>

</div>
);
}
}

copyTimeslotPopup.propTypes = {
classes: object.isRequired,
};

const mapStateToProps = state => {
return {

};
};

const enhance = compose(
withRouter,
connect(mapStateToProps,dispatch =>
bindActionCreators(
{

},
dispatch
)
)
);

export default enhance(copyTimeslotPopup);

