import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import moment from 'moment';
import { object, func } from "prop-types";
import { getHolds,getPlannerProfile } from 'data/myHold/actions';
import HoldTab from '../components/myHoldTab';
import { Grid, Icon, IconButton , MenuItem , Menu } from '@material-ui/core';
import '../assets/styles/myHolds.scss';
import PlannerPopup  from './plannerPopup';
import ReasonPopup  from './reasonPopup';

import EditEvent  from './editEvent';
import EditBlockEvent from './editBlockEvent';
import ReleaseEvent  from './releaseEvent';
import Book  from './bookEvent';

import { deleteEvent } from "../../shared/deleteEvent/actions";
import { unblockEvent } from "../../shared/unblockEvent/actions";
import { bookEvent } from "../../shared/bookEvent/actions";
import InfiniteScroll from 'react-infinite-scroller';
import Select from '@material-ui/core/Select';
import TablePagination from '@material-ui/core/TablePagination';
import { userDetails } from "shared/venueList/actions";
import DatePicker from "react-datepicker";
import Button from "@material-ui/core/Button";
import 'react-datepicker/dist/react-datepicker.css';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';

const logo = require("../../shared/components/assets/images/user-default.png");

function ordinal_suffix_of(i) {
i = parseInt(i);
var j = i % 10,
k = i % 100;
if (j === 1 && k !== 11) {
return i + "st";
}
if (j === 2 && k !== 12) {
return i + "nd";
}
if (j === 3 && k !== 13) {
return i + "rd";
}
return i + "th";
}

function tConvert(time) {
time = time.toString().match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [
time
];
if (time.length > 1) {
time = time.slice(1);
time[5] = +time[0] < 12 ? " AM" : " PM";
time[0] = +time[0] % 12 || 12;
}
return time.join("");
}
class MyHold extends Component {
state={
next:true,
open:false,
evnet_planner:'',
editOpen:false,
reasonOpen:false,
anchorEl:null,
setAnchorEl:null,
releaseOpen:false,
holds:[],
text:'all',
follow:[],
all:[],
details:false,
apiCall:false,
page:1,
popUpId: '',
sortBy:'event_start_date',
orderBy:'asc',
limit:10,
pagination:{},
allPagination:{},
blockEdit:false,
isFollow:0,
tripleseat_status:0,
selectedStartDate:'',
selectedEndDate:'',
event_temple_status:0
}

UNSAFE_componentWillMount(){
this.setState({holds:[],page:1})
this.props.getHolds().then(() => {
this.setState({apiCall:true})
this.setState({holds:this.props.holds.event_timeslots.data,follow:this.props.holds.event_follows,all:this.props.holds.event_timeslots.data,pagination:this.props.holds.event_timeslots.pagination,allPagination:this.props.holds.event_timeslots.pagination})
})
this.props.userDetails().then((response) => {
this.setState({tripleseat_status:response.userInfo.data.tripleseat_status})
this.setState({event_temple_status:response.userInfo.data.event_temple_status})
})
}
openPopup=(event_planner,event)=>{
if(parseInt(event_planner.id)!==parseInt(localStorage.getItem('id'))){
this.props.getPlannerProfile(event_planner.id).then((response) => {
this.setState({open:true,event_planner:response.data,eventData:event})
}).catch(e=>{
this.setState({open:true,event_planner:event_planner,eventData:event})
});
}else{
this.setState({open:true,event_planner:event_planner,eventData:event})
}
     
}
reasonPopup=(data)=>{
this.setState({reasonOpen:true,dataCity:data})
}
close=()=>{
this.setState({open:false});
}
reasonClose=()=>{
this.setState({reasonOpen:false});
}
editEvent=(event_planner,details,data)=>{
if(parseInt(data.event.event_planner && data.event.event_planner.id)===parseInt(localStorage.getItem('id'))){
let cLink = 'https://calendar.google.com/calendar/u/0/r/month/'+moment(event_planner.start_date,"YYYY-MM-DD").year()+'/'+(moment(event_planner.start_date,"YYYY-MM-DD").format("MM"))+"/"+moment(event_planner.start_date,"YYYY-MM-DD").format("DD");
if(data.url){
let strArr = data.url.split('event_name');
if(strArr[0]!=='https://calendar.google.com/calendar/u/0/r/eventedit' && strArr[0]!=='https://calendar.google.com/calendar/u/1/r/eventedit' && strArr[0]!=='https://calendar.google.com/calendar/u/2/r/eventedit' && strArr[0]!=='https://calendar.google.com/calendar/u/3/r/eventedit'){
cLink = strArr[0];
}
} 
window.open(cLink, "_blank"); 
}else if(event_planner.state && data.space){
if(details==='details'){
this.setState({details:true})
}else if(details==='block'){
this.setState({blockEdit:true})
}
else
{
this.setState({details:false})
}
this.setState({editOpen:true,event_planner:event_planner,dataCity:data})
} 
}
editClose=()=>{
this.setState({editOpen:false,blockEdit:false})
}
releaseHold=(data)=>{ 
	let newStartDate=''
let newEndDate=''
if(this.state.selectedStartDate!=''&&this.state.selectedStartDate!=null)
{
let days = this.state.selectedStartDate.getDate();
let month =  this.state.selectedStartDate.getMonth();
month += 1;  
let year = this.state.selectedStartDate.getFullYear();
        
newStartDate=(year+ "-" + month + "-" + days);
}
if(this.state.selectedEndDate!=''&&this.state.selectedEndDate!=null)
{
let days = this.state.selectedEndDate.getDate();
let month =  this.state.selectedEndDate.getMonth();
month += 1;  
let year = this.state.selectedEndDate.getFullYear();
        
newEndDate=(year+ "-" + month + "-" + days);
}
this.setState({apiCall:false}) 
if(data.hold_type!=4){
this.props.deleteEvent(data.id).then(() => {
this.props.getHolds(this.state.text == 'all'?'':data.hold_type,'paging',this.state.searchKey,this.state.page,this.state.sortBy,this.state.orderBy,this.state.limit,newStartDate,newEndDate).then(() => {
this.setState({apiCall:true})
if(this.state.text != 'all' || this.state.text != ''){
this.setState({holds:this.props.holds.event_timeslots.data,pagination:this.props.holds.event_timeslots.pagination})
}else{
this.setState({all:this.props.holds.event_timeslots.data,allPagination:this.props.holds.event_timeslots.pagination})
}
})
})
}else{ 
this.props.unblockEvent({start_date:data.event.start_date,end_date:data.event.end_date,start_time:data.event.start_time,end_time:data.event.end_time,space_id:data.space_id,timeslot_id:data.timeslot_id,name:data.event.name,reason:"",event_timeslot_id:data.id}).then(() => {
this.props.getHolds(this.state.text == 'all'?'':data.hold_type,'paging',this.state.searchKey,this.state.page,this.state.sortBy,this.state.orderBy,this.state.limit,newStartDate,newEndDate).then(() => {
this.setState({apiCall:true})
if(this.state.text != 'all' || this.state.text != ''){
this.setState({holds:this.props.holds.event_timeslots.data,pagination:this.props.holds.event_timeslots.pagination})
}else{
this.setState({all:this.props.holds.event_timeslots.data,allPagination:this.props.holds.event_timeslots.pagination})
}
})
}) 
} 
        
}
releaseHoldPopup=(e,data)=>{
if(data.event && data.event.state && data.space){
this.setState({releaseOpen:true,event_planner:data});
}
}
releaseClose=()=>{
this.setState({releaseOpen:false});
}
bookPopup=(id)=>{
this.setState({bookOpen:true,event_planner:id});
}
bookClose=()=>{
this.setState({bookOpen:false});
}
handleClick = (event, id) => {
if(parseInt(event.currentTarget.id) === parseInt(id)){

this.setState({anchorEl: event.currentTarget, popUpId: event.currentTarget.id});
}
else{
this.setState({anchorEl: null})
}
};

handleClose = () => {
this.setState({anchorEl: null});
};
localUpdate=()=>{
	let newStartDate=''
	let newEndDate=''
	if(this.state.selectedStartDate!=''&&this.state.selectedStartDate!=null)
	{
	let days = this.state.selectedStartDate.getDate();
	let month =  this.state.selectedStartDate.getMonth();
	month += 1;  
	let year = this.state.selectedStartDate.getFullYear();
			
	newStartDate=(year+ "-" + month + "-" + days);
	}
	if(this.state.selectedEndDate!=''&&this.state.selectedEndDate!=null)
	{
	let days = this.state.selectedEndDate.getDate();
	let month =  this.state.selectedEndDate.getMonth();
	month += 1;  
	let year = this.state.selectedEndDate.getFullYear();
			
	newEndDate=(year+ "-" + month + "-" + days);
	}
this.setState({apiCall:false})
let value=''; 
if(this.state.text==='hold'){
value='1';
} 
else if(this.state.text==='book'){
value='3';
}
else if(this.state.text==='block'){
value='4';
}
this.props.getHolds(value,'paging',this.state.searchKey,this.state.page,this.state.sortBy,this.state.orderBy,this.state.limit,newStartDate,newEndDate).then(() => {
this.setState({apiCall:true})
if(this.state.text != 'all' || this.state.text != ''){
this.setState({holds:this.props.holds.event_timeslots.data,pagination:this.props.holds.event_timeslots.pagination})
}else{
this.setState({all:this.props.holds.event_timeslots.data,allPagination:this.props.holds.event_timeslots.pagination})
}
})
}
bookEvent = (data) => {
	let newStartDate=''
	let newEndDate=''
	if(this.state.selectedStartDate!=''&&this.state.selectedStartDate!=null)
	{
	let days = this.state.selectedStartDate.getDate();
	let month =  this.state.selectedStartDate.getMonth();
	month += 1;  
	let year = this.state.selectedStartDate.getFullYear();
			
	newStartDate=(year+ "-" + month + "-" + days);
	}
	if(this.state.selectedEndDate!=''&&this.state.selectedEndDate!=null)
	{
	let days = this.state.selectedEndDate.getDate();
	let month =  this.state.selectedEndDate.getMonth();
	month += 1;  
	let year = this.state.selectedEndDate.getFullYear();
			
	newEndDate=(year+ "-" + month + "-" + days);
	}
this.setState({apiCall:false})
this.props.bookEvent(data).then((response) => {
this.props.getHolds(this.state.text == 'all'?'':1,'paging',this.state.searchKey,this.state.page,this.state.sortBy,this.state.orderBy,this.state.limit,newStartDate,newEndDate).then(() => {
this.setState({apiCall:true});
if(this.state.text != 'all' || this.state.text != ''){
this.setState({holds:this.props.holds.event_timeslots.data,pagination:this.props.holds.event_timeslots.pagination})
}else{
this.setState({all:this.props.holds.event_timeslots.data,allPagination:this.props.holds.event_timeslots.pagination})
}
})
})
.catch(error => {})

}
changeTabs = (value) =>{
this.setState({holds:[],page:1,searchKey:'',next:true,apiCall:false,selectedStartDate:'',selectedEndDate:''});
let text;
        let sortBy   = this.state.sortBy;
let isFollow = 0;

if(sortBy == 'hold_rank'){
sortBy = 'hold_type';
this.setState({sortBy:'hold_type'});
}

if(value===0)
{
text='all';
this.setState({holds:this.state.all,pagination:this.state.allPagination,apiCall:true})
             
}
if(value===1)
{
text='hold';
if(sortBy == 'hold_type'){
sortBy = 'hold_rank';
this.setState({sortBy:'hold_rank'});
}
          
this.props.getHolds(1,'','','',sortBy,this.state.orderBy,this.state.limit).then(() => {
this.setState({apiCall:true}); 
this.setState({holds:this.props.holds.event_timeslots.data,holdData:this.props.holds.event_timeslots.data,pagination:this.props.holds.event_timeslots.pagination})
})
}
if(value===2)
{
text='book';
this.props.getHolds(3,'','','',sortBy,this.state.orderBy,this.state.limit).then(() => {
this.setState({apiCall:true});
this.setState({holds:this.props.holds.event_timeslots.data,bookData:this.props.holds.event_timeslot,pagination:this.props.holds.event_timeslots.pagination})
})
}
if(value===3)
{
text='block';
this.props.getHolds(4,'','','',sortBy,this.state.orderBy,this.state.limit).then(() => {
this.setState({apiCall:true});
this.setState({holds:this.props.holds.event_timeslots.data,bookData:this.props.holds.event_timeslot,pagination:this.props.holds.event_timeslots.pagination})
})
}

if(value===4)
{ 
text='follow';
isFollow = 1
this.props.getHolds(4,'','','',sortBy,this.state.orderBy,this.state.limit,'','',1).then(() => {
this.setState({apiCall:true});
this.setState({holds:this.props.holds.event_follows,pagination:{}}); 
})
            
}
   
this.setState({text:text,isFollow:isFollow});

}
handleText = (event) =>{
this.setState({searchKey:event.target.value})
} 

handleSearch = () => {
let newStartDate=''
let newEndDate=''
if(this.state.selectedStartDate!=''&&this.state.selectedStartDate!=null)
{
let days = this.state.selectedStartDate.getDate();
let month =  this.state.selectedStartDate.getMonth();
month += 1;  
let year = this.state.selectedStartDate.getFullYear();
        
newStartDate=(year+ "-" + month + "-" + days);
}
if(this.state.selectedEndDate!=''&&this.state.selectedEndDate!=null)
{
let days = this.state.selectedEndDate.getDate();
let month =  this.state.selectedEndDate.getMonth();
month += 1;  
let year = this.state.selectedEndDate.getFullYear();
        
newEndDate=(year+ "-" + month + "-" + days);
}
let value='';
if(this.state.text==='hold'){
value='1';
}
else if(this.state.text==='book')
{
value='3';
}
else if(this.state.text==='block')
{
value='4';
}
else{
value='';
}
this.props.getHolds(value,'search',this.state.searchKey,1,this.state.sortBy,this.state.orderBy,this.state.limit,newStartDate,newEndDate).then(() => {
// this.setState({searchKey:''});
this.setState({holds:this.props.holds.event_timeslots.data,pagination:this.props.holds.event_timeslots.pagination})
})
}

loadFunc = (event,page) =>{ 
	let newStartDate=''
let newEndDate=''
if(this.state.selectedStartDate!=''&&this.state.selectedStartDate!=null)
{
let days = this.state.selectedStartDate.getDate();
let month =  this.state.selectedStartDate.getMonth();
month += 1;  
let year = this.state.selectedStartDate.getFullYear();
        
newStartDate=(year+ "-" + month + "-" + days);
}
if(this.state.selectedEndDate!=''&&this.state.selectedEndDate!=null)
{
let days = this.state.selectedEndDate.getDate();
let month =  this.state.selectedEndDate.getMonth();
month += 1;  
let year = this.state.selectedEndDate.getFullYear();
        
newEndDate=(year+ "-" + month + "-" + days);
}
setTimeout(() => {
if(this.state.called!=='calling' && page < this.state.pagination.last_page){
               
this.setState({called:'calling'})
let value='';
       
if(this.state.text==='hold'){
value='1';
}
                
else if(this.state.text==='book'){
value='3';

}

else if(this.state.text==='block'){
value='4';
}
                
this.props.getHolds(value,'paging',this.state.searchKey,page+1,this.state.sortBy,this.state.orderBy,this.state.limit,newStartDate,newEndDate).then((response) => {
this.setState({called:true})
let arr=this.state.holds;
this.setState({pagination:response.data.event_timeslots.pagination});
if(response.data.event_timeslots.data.length>0){
// for(let i=0;i<response.data.event_timeslots.data.length;i++){
                            //     arr.push(response.data.event_timeslots.data[i]);
// }
this.setState({holds:response.data.event_timeslots.data,next:true,page:page+1})
}
else{
this.setState({next:false})
} 
})
}else{
this.setState({next:false})
}
      
}, 1500);
} 
    
handleChangeRowsPerPage = (event) => { 
 	let newStartDate=''
let newEndDate=''
if(this.state.selectedStartDate!=''&&this.state.selectedStartDate!=null)
{
let days = this.state.selectedStartDate.getDate();
let month =  this.state.selectedStartDate.getMonth();
month += 1;  
let year = this.state.selectedStartDate.getFullYear();
        
newStartDate=(year+ "-" + month + "-" + days);
}
if(this.state.selectedEndDate!=''&&this.state.selectedEndDate!=null)
{
let days = this.state.selectedEndDate.getDate();
let month =  this.state.selectedEndDate.getMonth();
month += 1;  
let year = this.state.selectedEndDate.getFullYear();
        
newEndDate=(year+ "-" + month + "-" + days);
}     
if(this.state.limit!==event.target.value){
this.setState({limit: event.target.value,page:1});
setTimeout(() => {
let value=''; 
if(this.state.text==='hold'){
value='1';
} 
else if(this.state.text==='book'){
value='3';
}
else if(this.state.text==='block'){
value='4';
}
this.props.getHolds(value,'paging',this.state.searchKey,null,this.state.sortBy,this.state.orderBy,this.state.limit,newStartDate,newEndDate).then(() => {
this.setState({holds:this.props.holds.event_timeslots.data,pagination:this.props.holds.event_timeslots.pagination});
}) 
}, 100); 
}
};

eventSorting = (sortBy)=>{
	let newStartDate=''
	let newEndDate=''
	if(this.state.selectedStartDate!=''&&this.state.selectedStartDate!=null)
	{
	let days = this.state.selectedStartDate.getDate();
	let month =  this.state.selectedStartDate.getMonth();
	month += 1;  
	let year = this.state.selectedStartDate.getFullYear();
			
	newStartDate=(year+ "-" + month + "-" + days);
	}
	if(this.state.selectedEndDate!=''&&this.state.selectedEndDate!=null)
	{
	let days = this.state.selectedEndDate.getDate();
	let month =  this.state.selectedEndDate.getMonth();
	month += 1;  
	let year = this.state.selectedEndDate.getFullYear();
			
	newEndDate=(year+ "-" + month + "-" + days);
	}
if(sortBy=='expired_on' && (this.state.text == 'book' || this.state.text == 'block')){
return false;
}else if(this.state.text == 'follow'){
return false;
}
this.setState({sortBy:sortBy,page:1});
if(this.state.sortBy==sortBy){
let orderBy = this.state.orderBy == 'desc' ? 'asc' : 'desc';
this.setState({orderBy:orderBy});
} 
if(this.state.orderBy == null){
this.setState({orderBy:'asc'});
}
let value='';
if(this.state.text==='hold'){
value='1';
} 
else if(this.state.text==='book'){
value='3';
}
else if(this.state.text==='block'){
value='4';
} 
setTimeout(() => {
this.props.getHolds(value,'paging',this.state.searchKey,null,sortBy,this.state.orderBy,this.state.limit,newStartDate,newEndDate).then(() => {
this.setState({holds:this.props.holds.event_timeslots.data,pagination:this.props.holds.event_timeslots.pagination});
}) 
}, 100); 
}

onSearch = e => { 
if (e.keyCode === 13) {
this.handleSearch();
}
}
handleStartDate=(data)=>{
this.setState({selectedStartDate:data})
this.setState({selectedEndDate:''})
        }
        handleEndDate=(data)=>{
                if(this.state.selectedStartDate==''||this.state.selectedStartDate==null)
                {
                        this.setState({selectedStartDate:data})
                }
                
                this.setState({selectedEndDate:data})
                        }
                        handleClear=()=>{
                                this.setState({selectedEndDate:'',selectedStartDate:'',searchKey:''})
                                //this.setState({holds:this.state.all,pagination:this.state.allPagination,apiCall:true})
                                let value='';
if(this.state.text==='hold'){
value='1';
}
else if(this.state.text==='book')
{
value='3';
}
else if(this.state.text==='block')
{
value='4';
}
else{
value='';
} 
	this.props.getHolds(value,'search','',1,this.state.sortBy,this.state.orderBy,this.state.limit,'',).then(() => {
		// this.setState({searchKey:''});
		this.setState({holds:this.props.holds.event_timeslots.data,pagination:this.props.holds.event_timeslots.pagination})
		})

                                        }
render() {
const oneDay = 24 * 60 * 60 * 1000;
const currentDate = new Date(); 
        const eventTimeSlot =  this.state.holds && this.state.holds.length > 0 && this.state.holds.map((data, index) => {
const startDate = moment(data.event.start_date).format("MMM DD").toString();
const endDate = moment(data.event.end_date).format("MMM DD").toString();
const expiredDate = moment(data.event.end_date).format("MMM DD, YYYY").toString();
// const expiredDate = data.expired_on !== null ? moment(data.expired_on).format("MMM DD, YYYY").toString() : ''
const createdAt = moment.utc(data.created_at, "YYYY-MM-DD HH:mm:ss").toDate();
const diffDate = Math.round(Math.abs((currentDate - createdAt) / oneDay));
let time = data.event.state?data.event.state:null;
time=time!='none' && time && time.includes('}')?JSON.parse(time):'none';
if(time.start_time && time.start_time == '-'){
time.start_time = '00:00:00';
} 
if(time.end_time && time.end_time == '-'){
time.end_time = '00:00:00';
} 
       
return(
<div id={data.id} className="my-hold">
<div className="first-div"> 
{/* <div className="hold-image">

<img className="image"
src=
{parseInt(data.event.event_planner && data.event.event_planner.id)!==parseInt(localStorage.getItem('id'))?
data.event.event_planner && data.event.event_planner.profilePhoto ? data.event.event_planner.profilePhoto.path : logo
:logo}
alt="FIRST-HOLD"
height="60"
width="60"
/>
</div> */}
<div className="hold-details">
{parseInt(data.event.event_planner && data.event.event_planner.id)===parseInt(localStorage.getItem('id')) && time!='none' ?
<div>
<p style={{cursor:"pointer"}} onClick={()=>this.openPopup(data.event.event_planner,data.event)} className="hold-name">{time && time.planner_name ? time.planner_name : data.event.event_planner && data.event.event_planner.first_name+" "+data.event.event_planner.last_name}</p>
<p className="d-ago" style={{color:'#1397e1',fontWeight:'bold',fontSize:'12px'}}>{time && time.company_name? time.company_name:null}</p>
</div> 
                   
:
<div>
<p style={{cursor:"pointer"}} onClick={()=>this.openPopup(data.event.event_planner,data.event)} className="hold-name">{time && time.planner_name ? time.planner_name : data.event.event_planner && data.event.event_planner.first_name+" "+data.event.event_planner.last_name}</p>
<p className="d-ago" style={{color:'#1397e1',fontWeight:'bold',fontSize:'12px'}}>{time && time.company_name ? time.company_name : data.event.event_planner && data.event.event_planner.company_name?data.event.event_planner.company_name:null}</p>
</div>
}

</div>
</div>
<div className="middle-div"> 
<div className="mid-one-sec">
<p className="d-ago">{ diffDate?diffDate +'d ago':diffDate===0?'0d ago':'N/A'}</p>

<p className="date-hold">{startDate.toString()===endDate.toString()?expiredDate: startDate+"-"+expiredDate }</p>
                      
{parseInt(data.event.event_planner && data.event.event_planner.id)===parseInt(localStorage.getItem('id')) && time!='none' ?
<p className="all-day">{data.event.start_time=='00:00:00' ? 
tConvert(time.start_time.substring(0,5))=='12:00 AM' && tConvert(time.end_time.substring(0,5)) ==='12:00 AM'?
'All Day'
:
tConvert(time.start_time.substring(0,5)) +'-'+ tConvert(time.end_time.substring(0,5)):
tConvert(data.event.start_time.substring(0,5))=='12:00 AM' && tConvert(data.event.start_time.substring(0,5)) ==='12:00 AM'?
'All Day'
:(tConvert(data.event.start_time.substring(0,5))+'-'+tConvert(data.event.end_time.substring(0,5))) 
}</p>
:
(tConvert(data.event.start_time.substring(0,5))=='12:00 AM' && tConvert(data.event.end_time.substring(0,5))=='12:00 AM')
?

<p className="all-day">All Day</p>
:
<p className="all-day">{tConvert(data.event.start_time.substring(0,5))} - {tConvert(data.event.end_time.substring(0,5))}</p>}
</div>
                    
<div className="mid-two-sec"> 
<p className="d-ago">&nbsp;</p>
{ (this.state.text!='block' && this.state.text!='book' && this.state.isFollow == 0) &&
<p className="date-expired">{ (parseInt(data.hold_type)===1 && data.expired_on_diff)?data.expired_on_diff:""}</p>
}
<p className="all-day">&nbsp;</p>
</div>
                   
<div className="mid-three-sec">
                   
<p className="d-ago" style={{color:"red",fontWeight:'bold'}}>
{parseInt(data.event.event_planner && data.event.event_planner.id)===parseInt(localStorage.getItem('id'))?

data.event.name ? 
data.event.name:
time.name
:
time.name ? time.name: data.event.name
}</p>

<p className="date-hold">{ data.space && data.space.name?data.space.name:""}</p>
                        <p className="all-day">{  data.space && data.space.venue?data.space.venue.name:""}</p>
</div>
</div>
<div className="last-div">
<div className="last-one-sec"> 
{
this.state.isFollow == 0?
<p className="last-text">{data.hold_by}</p>
:
parseInt(data.event.event_planner && data.event.event_planner.id)===parseInt(localStorage.getItem('id'))
?
<p className="last-text">{data.space.venue.contacts[0].name}</p>
:
<p className="last-text">FIRST-HOLD</p>
} 
                                      
</div>
<div className="last-two-sec">

{this.state.text==='follow' ? <p className="last-text">FOLLOWING</p>:
parseInt(data.hold_type)===1 ? <p className="last-text">{ordinal_suffix_of(data.hold_rank)} HOLD</p>
:
                    parseInt(data.hold_type)===3 ?  <p className="last-text">BOOKED</p>
:
                    parseInt(data.hold_type)===4 ?  <p className="last-text">BLOCKED</p>
:
'N/A'}
</div>

<div className="last-three-sec">
{/* <div className="container"> */}
{/* 
<Checkboxes />
<span className="checkmark"></span> */}
{/* </div> */}
</div>

{this.state.text!=='follow'
?
<div className="last-four-sec">
{/* <Button aria-controls="simple-menu" aria-haspopup="true" onClick={(event)=>this.handleClick(event)} > */}
{data.sync_status!=3 &&
<IconButton id={data.id} aria-haspopup="true" color="inherit" onClick={(event)=>this.handleClick(event, data.id)}>
<Icon>more_vert</Icon>
</IconButton>}
{/* </Button> */}
{/* {this.state.text==='book'?
<Menu
id={data.id}
anchorEl={this.state.anchorEl}
keepMounted
open={Boolean(this.state.anchorEl) && this.state.popUpId === data.id}
onClose={this.handleClose}
>
<MenuItem id={data.id} onClick={(e)=>this.releaseHoldPopup(e,data)}>Release</MenuItem>
<MenuItem id={data.id} onClick={()=>this.editEvent(data.event,'details')}>Details</MenuItem>
</Menu>
: */}
<Menu
id={data.id}
anchorEl={this.state.anchorEl}
keepMounted
open={Boolean(this.state.anchorEl) && parseInt(this.state.popUpId) === parseInt(data.id)}
onClose={this.handleClose}
>
{(parseInt(data.hold_type) !==3 && parseInt(data.hold_type) !==4) &&
<MenuItem id={data.id} onClick={()=>this.bookPopup(data.id)}>Book</MenuItem>
}

{/*(parseInt(data.hold_type) ==4) &&
<MenuItem id={data.id} onClick={()=>this.reasonPopup(data)}>View Reason</MenuItem>
*/}
<MenuItem id={data.id} onClick={(e)=>this.releaseHoldPopup(e,data)}>{ parseInt(data.hold_type)===4? 'Unblock':'Release'}</MenuItem>
               { (parseInt(data.hold_type) !==4)  &&
                    <MenuItem id={data.id} onClick={()=>this.editEvent(data.event,'',data)}>Edit</MenuItem>
}
               { (parseInt(data.hold_type) ==4)  &&
<MenuItem id={data.id} onClick={()=>this.editEvent(data.event,'block',data)}>Edit</MenuItem>
}
{/* (data.hold_type===3)?
<MenuItem id={data.id} onClick={()=>this.editEvent(data.event,'details',data)}>Details</MenuItem> 
:
''*/}
</Menu>
{/* } */}
                      
</div>
:
null
}
</div>
</div>
)
})


return (
<div className="my_hold_page myholdpage">
{this.state.open===true ?
<PlannerPopup close={this.close} eventData={this.state.eventData} eventPlanner={this.state.event_planner} open={this.state.open}/>
:
null
}
{this.state.editOpen===true && this.state.blockEdit ===false ?
<EditEvent dataCity={this.state.dataCity} EditUpdate={this.localUpdate} details={this.state.details} close={this.editClose} eventPlanner={this.state.event_planner} open={this.state.editOpen}/>
:
null
}
{ this.state.editOpen===true && this.state.blockEdit ===true ?
<EditBlockEvent dataCity={this.state.dataCity} EditUpdate={this.localUpdate} details={false} close={this.editClose} eventPlanner={this.state.event_planner} open={this.state.editOpen}/>
:
null
}
{this.state.releaseOpen===true ?
<ReleaseEvent releaseHold={this.releaseHold} close={this.releaseClose} eventPlanner={this.state.event_planner} open={this.state.releaseOpen}/>
:
null
}
{this.state.bookOpen===true ?
<Book eventPlanner={this.state.event_planner} bookEvent={this.bookEvent} close={this.bookClose} open={this.state.bookOpen}/>
:
null
}

{this.state.reasonOpen===true ?
            <ReasonPopup close={this.reasonClose} dataCity={this.state.dataCity}  open={this.state.reasonOpen}/>
:
null
}


<Grid container alignItems="center">
<Grid className="myhold-header">
<div className="filter-tabs">
<HoldTab changeTabs={this.changeTabs}/>
</div>
{ this.state.isFollow == 0 &&
<div className="search-hold calendar-block ">
	<div className="searchBox blocks">
				<DatePicker
				allowClear={true}
				showIcon
				selected={this.state.selectedStartDate}
				onChange={(date) => this.handleStartDate(date)}
				placeholderText={'From'} 
				name='start' 
				dateFormat="dd/MM/yyyy"
				id={'openCalendarStart'}
				/>
				<label htmlFor='openCalendarStart'><CalendarTodayIcon className="calendar-icon"/></label> 
	</div>	
	<div className="searchBox blocks">
		<DatePicker
		showIcon
		selected={this.state.selectedEndDate}
		onChange={(date) => this.handleEndDate(date)}
		placeholderText={'To'} 
		name='start' 
		dateFormat="dd/MM/yyyy"
		minDate={this.state.selectedStartDate}
		id={'openCalndar'}
		/>
		<label htmlFor='openCalndar'><CalendarTodayIcon className="calendar-icon"/></label> 
	</div>	
	<div className="searchBox new">
		<input id="search" type="text" value={this.state.searchKey} placeholder="Search" onKeyDown={this.onSearch} onChange={this.handleText}/>
		<div className="search-image"> 
		
		</div>	
	</div>
	<div className="searchBox btn">
		<Button size={"small"} fullWidth={false} onClick={()=>this.handleSearch()} title='Search'>
			<i class="material-icons"> search</i>
		</Button>
	</div>

	<div className="searchBox btn">
		<Button size={"small"} fullWidth={false} onClick={this.handleClear} title='Clear Filter'>
		<i class="material-icons"> clear</i>
			{/* <span class="material-icons-outlined">clear</span> */}
		</Button>	
	</div>
        
	{/* <div className="download">
		<a href="#">
		<Icon>get_app</Icon>
		</a>
	</div> */}
</div>
} 
</Grid>

        
        
<Grid item xs={12} className="myhold-content">


<div className="event-header">
<div className="first-div">
<p className={this.state.text=='follow'?'disable':''} onClick={()=>this.eventSorting('event_planner_first_name')}>Planner 
{ (this.state.isFollow == 0 && this.state.sortBy =='event_planner_first_name' && this.state.orderBy=='desc') && 
<Icon>arrow_downward</Icon>
}
{ (this.state.isFollow == 0 && this.state.sortBy =='event_planner_first_name' && this.state.orderBy=='asc') && 
<Icon>arrow_upward</Icon>
} 
{ (this.state.isFollow == 0 && this.state.sortBy !='event_planner_first_name') && 
<Icon className="disable">arrow_upward</Icon>
} 
</p>
</div>
<div className="middle-div">
<div class="mid-one-sec">
<p className={this.state.text=='follow'?'disable':''} onClick={()=>this.eventSorting('event_start_date')}>When 
{ (this.state.isFollow == 0 && this.state.sortBy =='event_start_date' && this.state.orderBy=='desc') && 
<Icon>arrow_downward</Icon>
}
{ (this.state.isFollow == 0 && this.state.sortBy =='event_start_date' && this.state.orderBy=='asc') && 
<Icon>arrow_upward</Icon>
}
{ (this.state.isFollow == 0 && this.state.sortBy !='event_start_date') && 
<Icon className="disable">arrow_upward</Icon>
}
</p>
</div>

                                               
<div class="mid-two-sec">
{
(this.state.text!='block' && this.state.text!='book' && this.state.isFollow == 0) && 
<p className={(this.state.text=='follow' || this.state.text=='block'|| this.state.text=='book')?'disable':''} onClick={()=>this.eventSorting('expired_on')}>Remaining
                                    { (this.state.isFollow == 0 &&  this.state.sortBy =='expired_on' && this.state.orderBy=='desc') && 
<Icon>arrow_downward</Icon>
}
                                    { (this.state.isFollow == 0 &&  this.state.sortBy =='expired_on' && this.state.orderBy=='asc') && 
<Icon>arrow_upward</Icon>
}
{ (this.state.isFollow == 0 && this.state.sortBy !='expired_on') && 
<Icon className="disable">arrow_upward</Icon>
}
</p>
}
</div>
                       

<div class="mid-three-sec">
<p className={this.state.text=='follow'?'disable':''} onClick={()=>this.eventSorting('venue_name')}>Where & Why? 
{ (this.state.isFollow == 0 && this.state.sortBy =='venue_name' && this.state.orderBy=='desc') && 
<Icon>arrow_downward</Icon>
}
{ (this.state.isFollow == 0 && this.state.sortBy =='venue_name' && this.state.orderBy=='asc') && 
<Icon>arrow_upward</Icon>
}
{ (this.state.isFollow == 0 && this.state.sortBy !='venue_name') && 
<Icon className="disable">arrow_upward</Icon>
}
</p>
</div>
</div>
<div className="last-div">
<div class="last-one-sec">
<p className={this.state.text=='follow'?'disable':''} onClick={()=>this.eventSorting('hold_by')}>By 
{ (this.state.isFollow == 0 && this.state.sortBy =='hold_by' && this.state.orderBy=='desc') && 
<Icon>arrow_downward</Icon>
}
{ (this.state.isFollow == 0 && this.state.sortBy =='hold_by' && this.state.orderBy=='asc') && 
<Icon>arrow_upward</Icon>
}
{ (this.state.isFollow == 0 && this.state.sortBy !='hold_by') && 
<Icon className="disable">arrow_upward</Icon>
}
</p>
</div>
<div class="last-two-sec">
<p className={this.state.text=='follow'?'disable':''} onClick={()=>this.eventSorting(this.state.text=='hold'?'hold_rank':'hold_type')}>Status 
{ ((this.state.isFollow == 0 && this.state.sortBy =='hold_type' || this.state.sortBy =='hold_rank') && this.state.orderBy=='desc') && 
<Icon>arrow_downward</Icon>
}
{ ((this.state.isFollow == 0 && this.state.sortBy =='hold_type' || this.state.sortBy =='hold_rank') && this.state.orderBy=='asc') && 
<Icon>arrow_upward</Icon>
}
{ (this.state.isFollow == 0 && this.state.sortBy !='hold_type' && this.state.sortBy !='hold_rank') && 
<Icon className="disable">arrow_upward</Icon>
}
</p>
</div>
<div class="last-three-sec"></div>
<div class="last-four-sec"></div>
</div>
</div> 

{
this.state.holds.length > 0 ?
<div>
{eventTimeSlot}
</div>
:
this.state.apiCall===true && this.state.holds.length===0?
<div>
{this.state.text==='follow'?
<div className="my-hold no-data">No Followings</div>
:
this.state.text==='hold'?
<div className="my-hold no-data">No Holds Found</div>
:
this.state.text==='book'?
<div className="my-hold no-data">No Bookings Found</div>
:
this.state.text==='block'?
<div className="my-hold no-data">No Block Found</div>
:
<div className="my-hold no-data">No Data Found</div>}</div>
:
<div className="no-data">Loading...</div>
}


                {  Object.keys(this.state.pagination).length > 0 && this.state.apiCall===true ?
<div className="my-hold entry-box">
<TablePagination
component="div"
count={this.state.pagination.total}
page={this.state.page-1}
classes={{ select: 'page-select' }}
labelDisplayedRows={({ from, to, count }) => `Showing ${from} to ${to} of total ${count} entries`}
onChangePage={this.loadFunc}
rowsPerPage={this.state.pagination.per_page}
onChangeRowsPerPage={this.handleChangeRowsPerPage}
/>
</div>
: null 
}
</Grid> 
          
        
</Grid>
       
</div>
);
}
}
MyHold.propTypes = {
classes: object.isRequired,
deleteEvent:func.isRequired,
bookEvent: func.isRequired,
unblockEvent:func.isRequired
};
  
const mapStateToProps = state => {
return {
holds: state.data.myHold.holds,
fetching: state.data.myHold.fetching,
fetched: state.data.myHold.fetched
};
};
  
export default connect(
mapStateToProps,
dispatch => bindActionCreators({
getHolds,
deleteEvent,
bookEvent,
unblockEvent,
getPlannerProfile,
userDetails
}, dispatch),
)(withRouter(MyHold));
