import { GET_USER } from 'shared/state/types';
import { requestForUnauthorised } from 'shared/auth';
import { request } from 'shared/auth';
import { LOGIN } from 'shared/state/types';
function getUserRequested() {
  return {
    type: GET_USER.REQUEST,
  };
}

function getUserFailed(error) {
  return {
    type: GET_USER.FAILURE,
    error:error,
  };
}
function loginSuccess(data) {
    return {
      type: LOGIN,
      payload: data,
    };
  }

export function updatedUser(params) {
  if(params===true)
  {
  return dispatch => {
    dispatch(getUserRequested());
    return requestForUnauthorised().get(`/people/secondaryUser`, params)
      .then(response =>{
          dispatch(loginSuccess(response.data.data))
        }
      )
      .catch(errors => {
        if(errors && errors.response){
          dispatch(getUserFailed(errors.response.data.errors));
        }
        else{
          dispatch(getUserFailed("Unable to access api"));
        }
        throw errors;
      });
  };
}
}
