import { UPDATE_SPACE } from 'shared/state/types';
import { request } from 'shared/auth';
function updateSpaceRequested() {
  return {
    type: UPDATE_SPACE.REQUEST,
  };
}
function updateSpaceFailed(error) {
  return {
    type: UPDATE_SPACE.FAILURE,
    error:error,
  };
}
function updateSpaceSuccess(data) {
  return {
    type: UPDATE_SPACE.SUCCESS,
    spaceData: data,
  };
}
export function timeslotCopy(spaceId, params,name,copyTimeslot) {
  return dispatch => {
    dispatch(updateSpaceRequested());
    if(name==='spaceEditpub'){
      return request().put(`/spaces/${spaceId}`,params)
      .then(response =>{
          if(response.status === 200){
            dispatch(updateSpaceSuccess(response.data.data))
            return response.data.data
          }
          else if(response.status === 400){
            dispatch(updateSpaceFailed("The request was invalid."));
          }
          else if(response.status === 401){
            dispatch(updateSpaceFailed("The request did not include an authentication token or the authentication token was expired."));
          }
          else if(response.status === 403){
            dispatch(updateSpaceFailed("The client did not have permission to access the requested resource."));
          }
          else if(response.status === 404){
            dispatch(updateSpaceFailed("The requested resource was not found."));
          }
          else if(response.status === 405){
            dispatch(updateSpaceFailed("The HTTP method in the request was not supported by the resource."));
          }
          else if(response.status === 409){
            dispatch(updateSpaceFailed("The request could not be completed due to a conflict. "));
          }
          else if(response.status === 500){
            dispatch(updateSpaceFailed("The request was not completed due to an internal error on the server side."));
          }
          else if(response.status === 503){
            dispatch(updateSpaceFailed("The server was unavailable."));
          }
          else{
            dispatch(updateSpaceFailed("The server was unavailable."));
          }
        }
      )
      .catch(errors => {
        dispatch(updateSpaceFailed(errors.response.data.errors));
        throw errors;
      });
    }
    else{
        
      let data=params.replace(/event_type_timeslot_pricings/g,'p')
     
    return request().put(`/spaces/${spaceId}?${data}&copyTimeslot=${copyTimeslot}`)
      .then(response =>{
          if(response.status === 200){
            dispatch(updateSpaceSuccess(response.data.data))
            return response.data.data
          }
          else if(response.status === 400){
            dispatch(updateSpaceFailed("The request was invalid."));
          }
          else if(response.status === 401){
            dispatch(updateSpaceFailed("The request did not include an authentication token or the authentication token was expired."));
          }
          else if(response.status === 403){
            dispatch(updateSpaceFailed("The client did not have permission to access the requested resource."));
          }
          else if(response.status === 404){
            dispatch(updateSpaceFailed("The requested resource was not found."));
          }
          else if(response.status === 405){
            dispatch(updateSpaceFailed("The HTTP method in the request was not supported by the resource."));
          }
          else if(response.status === 409){
            dispatch(updateSpaceFailed("The request could not be completed due to a conflict. "));
          }
          else if(response.status === 500){
            dispatch(updateSpaceFailed("The request was not completed due to an internal error on the server side."));
          }
          else if(response.status === 503){
            dispatch(updateSpaceFailed("The server was unavailable."));
          }
          else{
            dispatch(updateSpaceFailed("The server was unavailable."));
          }
        }
      )
      .catch(errors => {
        dispatch(updateSpaceFailed(errors.response.data.errors));
        throw errors;
      });
    }
  };
}
