import React from 'react';
import { connectedRouterRedirect } from 'redux-auth-wrapper/history4/redirect';
import locationHelperBuilder from 'redux-auth-wrapper/history4/locationHelper';
import axios from 'axios';
import 'axios-progress-bar/dist/nprogress.css'
import { loadProgressBar } from 'axios-progress-bar'


// const id= localStorage.getItem('id')
const logo = require("../../shared/components/assets/images/user-default.png");

export const request = (baseURL = process.env.REACT_APP_API_URL) => {
  const headers = {};

  const token = getToken();
  if (token) {
    headers['Authorization'] = 'Bearer ' + token;
  }
  const axiosInstance = axios.create({
    baseURL,
    headers,
  })
  loadProgressBar({ showSpinner: true }, axiosInstance)
  return axiosInstance
};

export const requestForUnauthorised = (baseURL = process.env.REACT_APP_API_URL) => {
  const headers = {};
  let token
  if(localStorage.getItem('fhtoken') === null){
    token = localStorage.getItem('temptoken');
  }
  else{
    token = localStorage.getItem('fhtoken');
  }
  if (token) {
    headers['Authorization'] = 'Bearer ' + token;
  }
  const axiosInstance = axios.create({
    baseURL,
    headers,
  })
  loadProgressBar({ showSpinner: true }, axiosInstance)
  return axiosInstance
};


export const getTempToken = () => {
  if(localStorage.getItem('fhtoken') === null){
    return localStorage.getItem('temptoken');
  }
  else{
    return localStorage.getItem('fhtoken');
  }
};

export const getToken = () => {
  return localStorage.getItem('fhtoken');
};

export const setToken = (token, person) => {
  // return localStorage.set('fhtoken', token, { domain: CONFIG.COOKIE_DOMAIN );

  // if(person.profilePhoto && person.profilePhoto.path)
  // {
  //   var c = document.getElementById("myCanvas");
  //     var ctx = c.getContext("2d");
  //     var img = document.getElementById("imgProfile");

  //      img.onload=()=>{
  //      }
  
    
  
  // }
  localStorage.setItem('type', person.type);
  if(person.primary_user_id)
  {
  localStorage.setItem('primaryUserId',person.primary_user_id)
  }
  localStorage.setItem('profilePhoto',person.profilePhoto && person.profilePhoto.path ? person.profilePhoto.path : logo) ;
  if(localStorage.getItem('widgetLogin')!='1'&&localStorage.getItem('adminLogin')!='1'&&localStorage.getItem('googleSignIn')!='1'&&localStorage.getItem('msSignIn')!='1'&&localStorage.getItem('googleRegister')!='1'&&localStorage.getItem('microsoftRegister')!='1'){
    localStorage.setItem('tempid', person.id);  
    return localStorage.setItem('temptoken', token)
  }
  else{
    localStorage.removeItem('tempid')
    localStorage.removeItem('temptoken')
    localStorage.setItem('id', person.id);  
    return localStorage.setItem('fhtoken', token);
  }
};

export const removeToken = () => {
  let extensionPopup=localStorage.getItem('disablePopup')
  localStorage.clear();
  localStorage.setItem('disablePopup',extensionPopup)
  return localStorage.removeItem('fhtoken');
};

export const userIsAuthenticated = connectedRouterRedirect({
  redirectPath: '/login',
  authenticatedSelector: state => state.data.auth.get('id') !== 0,
  wrapperDisplayName: 'UserIsAuthenticated',
  authenticatingSelector: state => state.data.auth.get('id') !== 0,
  AuthenticatingComponent: () => (<div>doing something...</div>),
});

const locationHelper = locationHelperBuilder({});

export const userIsNotAuthenticated = connectedRouterRedirect({
  redirectPath: (state, ownProps) => locationHelper.getRedirectQueryParam(ownProps) || '/login',
  allowRedirectBack: false,
  authenticatedSelector: state => state.data.auth.get('id') === 0,
  wrapperDisplayName: 'UserIsNotAuthenticated',
});
